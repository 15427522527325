<div class="modal-body p-0">
    <button type="button" class="close" aria-label="Close" (click)="close('close')" ngbTooltip="Close">
        <span aria-hidden="true">&times;</span>
    </button>
    <img src="assets/images/eventsbg_black.jpg" class="img-fluid main-bg" alt="eventsbg_black" title="eventsbg_black">
        <div class="main-pricing-box">
            <div class="w-100">
            <div class="modal-logo-box">
                <img src="assets/images/logo/logo.png" alt="logo" title="logo" class="img-fluid">
            </div>
            <!-- <div class="subtitle-heddingbox">
                <h4>Subscription Plan</h4>
            </div> -->
            <div class="d-flex flex-wrap justify-content-center align-items-center mt-4">
                <!-- <div class="pricingtable">
                    <div class="pricingtable-header">
                    <h3 class="title">Standard</h3>
                    </div>
                    <div class="price-value"><span class="currency">$</span><span class="amount">10</span><span class="duration">/mo</span></div>
                    <ul class="pricing-content">
                        <li>50GB Disk Space</li>
                        <li>50 Email Accounts</li>
                        <li>Maintenance</li>
                        <li>15 Subdomains</li>
                    </ul>
                    <div class="pricingtable-signup">
                        <a class="btn btn-primary btn-lg" href="javascript:void(0)">Buy Now</a>
                    </div>
                </div>
                <div class="pricingtable">
                    <div class="pricingtable-header">
                    <h3 class="title">Premium</h3>
                    </div>
                    <div class="price-value">
                        <span class="currency">$</span>
                        <span class="amount">20</span>
                        <span class="duration">/mo</span>
                    </div>
                    <ul class="pricing-content">
                        <li>10% on all product</li>
                        <li>50 Email Accounts</li>
                        <li>Maintenance</li>
                        <li>15 Subdomains</li>
                    </ul>
                    <div class="pricingtable-signup">
                        <a class="btn btn-primary btn-lg" href="javascript:void(0)">Buy Now</a>
                    </div>
                </div> -->
                <div class="pricingtable">
                    <!-- <div class="pricingtable-header">
                    <h3 class="title">Full Acocunts Package</h3>
                    </div> -->
                    <div class="price-value">
                        <div class="line-though">
                            <h3><span class="currency">$</span> 70</h3>
                        </div>
                        <span class="currency">$</span>
                        <span class="amount">49.99</span>
                        <!-- <span class="duration">/mo</span> -->
                    </div>
                    <ul class="pricing-content">
                        <li>Rename Playbook to fit your scheme</li>
                        <li>Includes Full LR playbook
                            <ol class="innerlist mt-3">
                                <li>Over 200 videos</li>
                                <li>G/T counter</li>
                                <li>Shallow Cross</li>
                                <li>Bender</li>
                                <li>And more</li>
                            </ol>
                        </li>
                        <!-- <li>Maintenance</li>
                        <li>15 Subdomains</li> -->
                    </ul>
                    <div class="pricingtable-signup">
                        <a class="btn btn-primary btn-lg" href="mailto:mark@strongside.app?subject=I'd like to purchase the full playbook package.">Buy Now</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>