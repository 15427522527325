<div class="container-fluid">
  <div class="row">
    <div
      class="col-xl-7"
      [ngStyle]="{
        'background-image': 'url(assets/images/login/login-screen-img.jpg)',
        'background-size': 'cover',
        display: 'block'
      }"
    >
      <img
        class="bg-img-cover bg-center d-none"
        src="assets/images/login/login-screen-img.jpg"
        alt="looginpage"
        title="looginpage"
      />
    </div>
    <div class="col-xl-5 p-0">
      <div class="login-card">
        <div>
          <div>
            <a class="logo text-left">
              <img
                class="img-fluid for-light"
                src="assets/images/logo/login.png"
                alt="looginpage"
                title="looginpage"
              />
              <img
                class="img-fluid for-dark"
                src="assets/images/logo/logo_dark.png"
                alt="looginpage"
                title="looginpage"
              />
            </a>
          </div>
          <div class="login-main">
            <form
              class="theme-form needs-validation"
              [class.was-validated]="!regForm.valid"
              novalidate=""
              [formGroup]="regForm"
              *ngIf="isRegFormShow"
            >
              <h4>Register your account</h4>
              <p>Enter your email to get OTP</p>
              <div class="form-group">
                <!-- <label class="col-form-label">Email Address</label> -->
                <input
                  class="form-control"
                  type="email"
                  required=""
                  placeholder="app@yourmail.com"
                  formControlName="email"
                />
                <div
                  class="invalid-feedback"
                  *ngIf="
                    regForm.controls.email.touched &&
                    regForm.controls.email.errors?.required
                  "
                >
                  Email is required.
                </div>
                <div
                  class="cust-invalid-feedback"
                  *ngIf="
                    regForm.controls.email.touched &&
                    regForm.controls.email.errors?.email
                  "
                >
                  Invalid Email Address.
                </div>
              </div>
              <div class="form-group mb-0">
                <button
                  class="btn btn-primary btn-block"
                  type="button"
                  [disabled]="!regForm.valid"
                  (click)="onEmailSubmit()"
                >
                  Get OTP
                </button>
              </div>
            </form>
            <form
              class="theme-form needs-validation"
              [class.was-validated]="!otpForm.valid"
              novalidate=""
              [formGroup]="otpForm"
              *ngIf="isOTPFormShow"
            >
              <div class="text-center mt-4 mb-4">
                <span class="reset-password-link"
                  >If don't receive OTP?  
                  <a class="btn-link text-danger" (click)="onResend()"
                    >Resend</a
                  >
                </span>
              </div>
              <div class="form-group">
                <!-- <label class="col-form-label pt-0">Enter OTP</label> -->
                <div class="row">
                  <div class="col">
                    <input
                      class="form-control text-center opt-text"
                      type="text"
                      maxlength="1"
                      formControlName="otp1"
                      required=""
                    />
                  </div>
                  <div class="col">
                    <input
                      class="form-control text-center opt-text"
                      type="text"
                      maxlength="1"
                      formControlName="otp2"
                      required=""
                    />
                  </div>
                  <div class="col">
                    <input
                      class="form-control text-center opt-text"
                      type="text"
                      maxlength="1"
                      formControlName="otp3"
                      required=""
                    />
                  </div>
                  <div class="col">
                    <input
                      class="form-control text-center opt-text"
                      type="text"
                      maxlength="1"
                      formControlName="otp4"
                      required=""
                    />
                  </div>
                </div>
                <div class="col-12">
                  <button
                    class="btn btn-primary btn-block m-t-10"
                    (click)="onOTPsubmit()"
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
            <form
              class="theme-form needs-validation"
              [class.was-validated]="!passForm.valid"
              novalidate=""
              [formGroup]="passForm"
              *ngIf="isPasswordFormShow"
            >
              <h6 class="mt-4">Create Your Password</h6>
              <div class="form-group">
                <!-- <label class="col-form-label">Email</label> -->
                <input
                  class="form-control"
                  type="email"
                  formControlName="email"
                  readonly
                />
              </div>
              <div class="form-group">
                <!-- <label class="col-form-label">New Password</label> -->
                <input
                  class="form-control"
                  [type]="show ? 'text' : 'password'"
                  name="login[password]"
                  required=""
                  placeholder="New Password"
                  formControlName="password"
                />
                <div
                  class="invalid-feedback"
                  *ngIf="
                    passForm.controls.password.touched &&
                    passForm.controls.password.errors?.required
                  "
                >
                  Password is required.
                </div>
                <div
                  class="cust-invalid-feedback"
                  *ngIf="
                    passForm.controls.password.touched &&
                    passForm.controls.password.errors?.minlength
                  "
                >
                  Password must 8 characters long.
                </div>
                <div
                  class="cust-invalid-feedback"
                  *ngIf="passForm.get('password')?.hasError('pattern')"
                >
                  (Contain min one Capital Letter [A-Z], at lest one [a-z],
                  contain spacial character like [! @@ # $ % ^ & *] & at least
                  one number [0-9]).
                </div>
                <div class="show-hide" (click)="showPassword()" *ngIf="!show">
                  <span class="show"></span>
                </div>
                <div class="show-hide" (click)="showPassword()" *ngIf="show">
                  <span class="Hide"></span>
                </div>
              </div>
              <div class="form-group">
                <!-- <label class="col-form-label">Retype Password</label> -->
                <input
                  class="form-control"
                  type="password"
                  name="login[password]"
                  required=""
                  placeholder="Retype Password"
                  formControlName="confirmPassword"
                />
                <div
                  class="invalid-feedback"
                  *ngIf="
                    passForm.controls.confirmPassword.touched &&
                    passForm.controls.confirmPassword.errors?.required
                  "
                >
                  Confirm Password is required.
                </div>
                <div
                  class="cust-invalid-feedback"
                  *ngIf="
                    !passForm.controls.confirmPassword.errors?.required &&
                    passForm.hasError('notSame')
                  "
                >
                  Password and Confirm Password not match.
                </div>
              </div>
              <div class="form-group mb-0">
                <button
                  class="btn btn-primary btn-block"
                  type="submit"
                  (click)="onNewPassSubmit()"
                  [disabled]="!passForm.valid"
                >
                  Done
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
