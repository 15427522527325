<!-- <div class="modal-header">
    <h5 class="modal-title">View Files</h5>
    <button type="button" class="close" data-dismiss="modal" (click)="closeModal('dismiss')">
        <span aria-hidden="true">&times;</span>
    </button>
</div> -->
<div class="modal-body c-media-upload-popup">
    <h4 class="modal-title">Add Media</h4>
    <button type="button" class="close" aria-label="Close" (click)="closeModal('dismiss')" ngbTooltip="Close">
        <span aria-hidden="true">&times;</span>
    </button>
    <!-- <form class="theme-form"> -->
        <div class="form-row">
            <div class="col-md-12 col-xl-12 col-12">
                <div class="custom-dropzone" ngx-dropzone accept=".png, .jpg, .mp4, .gif" (change)="onSelect($event)"
                    [maxFileSize]=50000000>
                    <ngx-dropzone-label>Drop files here or click to select files</ngx-dropzone-label>
                    <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
                        <ngx-dropzone-label style="max-width: 150px;">{{ f.name }}</ngx-dropzone-label>
                    </ngx-dropzone-preview>
                </div>
            </div>
            <div class="col-md-12 col-xl-12 col-12 mt-2 mb-4">
               <h4>Add Url</h4>
               <input class="form-control" placeholder="Enter media url (Image / Video)" type="text" [(ngModel)]="downloadUrl">
            </div>
        </div>
    <!-- </form> -->
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-air-primary btn-outline-primary" ngbTooltip="Upload" (click)="uploadDoc()"> Upload </button>
    <button type="button" class="btn btn-outline-danger" ngbTooltip="Close" (click)="closeModal('dismiss')"> Close</button>
</div>