<div class="customviewmodal">
    <!-- <div class="modal-header">
        <h5 class="modal-title">View Files</h5>
        <button type="button" class="close" data-dismiss="modal" (click)="closeModal('dismiss')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div> -->
    <div class="modal-body">
        <button type="button" class="close" aria-label="Close" (click)="closeModal('dismiss')" ngbTooltip="Close">
            <span aria-hidden="true">&times;</span>
        </button>

        <ngb-carousel *ngIf="!isSingleFile">
            <ng-template ngbSlide *ngFor="let file of files; index as i">
                <div class="boxvideo" id="img.id">
                    <img [src]="file.src" *ngIf="!file.isVideo" alt="{{file.fileName ? file.fileName : 'view-image'}}"
                        title="{{file.fileName ? file.fileName : 'view-image'}}">
                    <video id="video{{i + 1}}" crossorigin="anonymous" width="100%" height="100%" controls
                        *ngIf="file.isVideo" (ended)="handleVideoEnded($event)">
                        <source src="{{file.src}}" type="video/mp4">
                    </video>
                </div>
                <div class="d-flex justify-content-center" *ngIf="isVideo && captureShow">
                    <button class="video-capture" (click)="capture(i + 1)" ngbTooltip="Capture Screenshot"> <i
                            class="fa fa-camera"></i></button>
                </div>
            </ng-template>
        </ngb-carousel>

        <!-- <div class="boxvideo" *ngIf="isSingleFile && isVideo" style="position: relative;">
            <video id="video0" crossorigin="anonymous" width="100%" height="100%" controls>
                <source src="{{fileUrl}}" type="video/mp4" />
            </video>
            <div class="d-flex justify-content-center" *ngIf="isSingleFile && isVideo && captureShow">
                <button class="video-capture" (click)="capture(0)" ngbTooltip="Capture Screenshot"><i class="fa fa-camera"></i></button>
            </div>
        </div> -->
        <div class="boxvideo" *ngIf="isSingleFile && isVideo" style="position: relative;">
            <video id="video0" crossorigin="anonymous" width="100%" height="100%" controls
                (ended)="handleVideoEnded($event)">
                <source src="{{fileUrl}}" type="video/mp4" />
            </video>
            <div class="d-flex justify-content-center" *ngIf="isSingleFile && isVideo && captureShow">
                <button class="video-capture" (click)="capture(0)" ngbTooltip="Capture Screenshot"><i
                        class="fa fa-camera"></i></button>
            </div>
        </div>
        <div class="boxvideo" *ngIf="isSingleFile && !isVideo">
            <img [src]="fileUrl" alt="{{fileName ? fileName : 'view-image'}}">
        </div>

        <div class="boxvideocntn" *ngIf="!isSingleFile">
            <div class="d-flex justify-content-between align-items-center ttlfav">
                <a title="{{modalData.name}}" href="{{modalData.routeUrl}}" class="link">{{modalData.name}}</a>
                <img src="../../../../../assets/images/icr.png" alt="Favorite" *ngIf="modalData.isFavorite"
                    title="Favorite">
            </div>
            <div class="d-flex justify-content-between align-items-center">
                <p class="m-0 f-w-500">{{modalData.origin}}</p>
                <p class="m-0">{{modalData.activityBy}} ({{modalData.activityOn}})</p>
            </div>
        </div>

        <div class="row" *ngIf="userFullAccess && !isRelated && isVideo && !gamePlay">
            <div class="col-md-12" *ngIf="showTagField">
                <label for="name">Tags</label>
                <tag-input [(ngModel)]="tagModel" placeholder="Select / Enter a new tag"
                    (onTextChange)="onVideoTagSearch($event)" (onAdd)="videoTagUpdate()" (onRemove)="videoTagUpdate()">
                    <tag-input-dropdown [showDropdownIfEmpty]="true" [appendToBody]="body" [autocompleteItems]="tags"
                        placeholder="Select / Enter a new tag">
                    </tag-input-dropdown>
                </tag-input>
            </div>
        </div>

        <div class="row" *ngIf="userFullAccess && !isRelated && gamePlay">
            <div class="col-md-12" *ngIf="showTagField">
                <label for="name">Tags</label>
                <tag-input [(ngModel)]="tagModel" placeholder="Select / Enter a new tag"
                    (onTextChange)="onVideoTagSearch($event)" (onAdd)="videoTagUpdate()" (onRemove)="videoTagUpdate()">
                    <tag-input-dropdown [showDropdownIfEmpty]="true" [appendToBody]="body" [autocompleteItems]="tags"
                        placeholder="Select / Enter a new tag">
                    </tag-input-dropdown>
                </tag-input>
            </div>
        </div>
    </div>
    <!-- <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="closeModal('close')">Close</button>
    </div> -->
</div>

<!--Capture Image View modal -->
<ng-template #captureImgViewcontent let-c="close" let-d="dismiss" let-modal>
    <div class="modal-body">
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
        <div class="boxvideo">
            <img src="{{cartureImg}}" alt="" width="100%" height="100%" alt="Capture Image" title="Capture Image">
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-primary" (click)="saveImg(modal)">Save Screenshot</button>
        <button type="button" class="btn btn-danger" (click)="modal.close()">Cancel</button>
    </div>
</ng-template>
<!--  -->