<div class="rootBox">
    <nav class="navbar navbar-expand-lg">
        <a class="navbar-brand" href="#">
            <img src="../../assets/images/logo/logo.png" alt="logo" title="logo" class="img-fluid">
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
            aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <!-- <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav">
            <li class="nav-item active">
              <a class="nav-link" href="#">Home <span class="sr-only">(current)</span></a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">Features</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">Pricing</a>
            </li>
            <li class="nav-item">
              <a class="nav-link disabled" href="#">Disabled</a>
            </li>
          </ul>
        </div> -->
    </nav>
    <div class="container-fluid" [class.sidebaron]="openSidebar">
        <div class="product-grid">
            <!-- <div class="feature-products">
                <div class="row">
                    <div class="col-md-6 products-total mb-3">
                    </div>
                    <div class="col-md-6 text-right"><span class="f-w-600 m-r-5">Showing Install
                            {{(page.count>0)? (((page.offset - 1) * page.limit) + 1) : 0 }} -
                            {{(page.limit < page.count) ? (((page.offset - 1) * page.limit) + page.limit) : page.count}} Of
                                {{page.count}} Results</span>
                    </div>
                </div>
            </div> -->
            <div class="product-wrapper-grid mt-4" [class.list-view]="listView">
                <div class="" *ngIf="viewType === 'List'">
                    <div class="col-sm-12 col-xl-12 ng-star-inserted"
                        *ngFor="let data of rows; let i=index; last as isLastParent">
                        <div class="list_tem tbl c-list-w">
                            <div class="item_header d-flex align-items-center justify-content-start" style="flex: 1;">
                                <p class="mb-0 title" title="{{data.name}}">
                                    <a class="link" (click)="print(data.id)">{{data.name}}</a>
                                </p>
                                <div style="flex: 1;">
                                    <p class="mb-0 mr-1">
                                        <span><img src="../../assets/images/strategy.png" alt="plays" title="plays"
                                                width="15px" />&nbsp; Plays: {{data.playsCount}}</span> &nbsp;
                                        <span><i class="icofont icofont-layers"></i>&nbsp; Concept:
                                            {{data.conceptCount}}</span> &nbsp;
                                        <span><i class="icofont icofont-cube"></i>&nbsp; Formation:
                                            {{data.formationCount}}</span> &nbsp;
                                        <span><i class="icofont icofont-navigation-menu"></i>&nbsp; Formation Set:
                                            {{data.stackCount}}</span> &nbsp;
                                    </p>
                                    <!-- <p class="mb-1">
                                        <span class="badge badge-pill badge-secondary" style="padding: 5px 10px;" *ngFor="let tag of data.tags;">{{tag}}</span>
                                    </p> -->
                                </div>

                                <div class="d-flex align-items-center justify-content-between pl-1">
                                    <div class="list_info">
                                        <span>
                                            <i class="icon-alarm-clock"></i>{{data.daysCount}}({{data.coachName}})
                                        </span>
                                    </div>
                                    <div class="mr-2" style="width: 50px;">
                                    </div>
                                    <div class="d-inline-block">
                                        <ng-template #popContent>
                                            <div class="item_action_btn btn-horizontal">
                                                <ul>
                                                    <li ngbTooltip="Print" (click)="print(data.id)">
                                                        <i class="icofont icofont-printer"></i>
                                                    </li>
                                                    <li ngbTooltip="Presentation" (click)="presentation(data.id)">
                                                        <i class="icofont icofont-presentation-alt"></i>
                                                    </li>
                                                </ul>
                                            </div>
                                        </ng-template>
                                        <button type="button" class="drp_DN_btn drp-btn" [ngbPopover]="popContent"
                                            placement="left">
                                            <i class="icon-more-alt"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <ngb-pagination class="d-flex justify-content-center" [collectionSize]="page.count" [pageSize]="page.limit"
                    (page)="page.offset" [maxSize]="5" (pageChange)="loadPage($event)" aria-label="Pagination" *ngIf="rows && rows.length > 0">
                </ngb-pagination> -->
            </div>
        </div>
    </div>
    <footer class="footer">
        <div class="container-fluid">
            <div class="col-md-12 footer-copyright text-center">
                <p class="m-0">&copy; Strongside 2023 All Rights Reserved.</p>
            </div>
        </div>
    </footer>
</div>