import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ProfileService } from '../data/services/team-management';
import { LoaderService } from '../shared/services/loader.service';

@Component({
  selector: 'app-public-profile',
  templateUrl: './public-profile.component.html',
  styleUrls: ['./public-profile.component.css']
})
export class PublicProfileComponent implements OnInit {
  user_Name: any;
  profileData: any = {};

  public profileUrl: any;
  public coverUrl: any;
  public isPlayerProfileAvaiable: boolean = false;
  public is404Show: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private profileService: ProfileService,
    private router: Router,
    private loader: LoaderService
  ) { }

  ngOnInit(): void {
    this.user_Name = this.route.snapshot.paramMap.get('_user');
    this.loader.isLoading.next(true);
    this.profileService.getPublic(this.user_Name).subscribe(data => {
      this.loader.isLoading.next(false);
      this.profileData = data;
      this.isPlayerProfileAvaiable = true;
      if (data.pro_Seq) {
        this.profileUrl = data.pro_Seq;
      }
      if (data.cov_Seq) {
        this.coverUrl = data.cov_Seq;
      }
    },
      error => {
        this.loader.isLoading.next(false);
        this.is404Show = true;
        // window.location.href = environment.siteUrl;
      });
  }
  updateLink(linkType: string) {
    if (linkType == "F") {
      if (this.profileData.facebookLink) {
        window.open(this.profileData.facebookLink, '_blank')
      }
      else {
        window.open('https://www.facebook.com/StrongsideApp', '_blank')
      }
    }
    else if (linkType == "H") {
      if (this.profileData.hudlLink) {
        window.open(this.profileData.hudlLink, '_blank')
      }
      else {
        window.open('https://www.youtube.com/user/pyralis35/featured', '_blank')
      }
    }
    else if (linkType == "T") {
      if (this.profileData.twitterLink) {
        window.open(this.profileData.twitterLink, '_blank')
      }
      else {
        window.open('https://twitter.com/StrongsideApp', '_blank')
      }
    }
    else if (linkType == "I") {
      if (this.profileData.instagramLink) {
        window.open(this.profileData.instagramLink, '_blank')
      }
      else {
        window.open('https://www.instagram.com/strongside.app', '_blank')
      }
    }
    else if (linkType == "M") {
      if (this.profileData.maxprepsLink) {
        window.open(this.profileData.maxprepsLink, '_blank')
      }
      else {
        window.open('https://www.youtube.com/user/pyralis35/featured', '_blank')
      }
    }
  };

  handleVideoEnded(event: Event) {
    const videoElement: HTMLVideoElement = event.target as HTMLVideoElement;
    if (videoElement) {
      videoElement.playbackRate = 0.25;
      videoElement.play();
    }
  }
}
