<section class="c-football-mind-w">
    <div class="c-football-mind-top">
        <div class="c-football-mind-top-user">
            <img src="../../../../assets/images/user-new.png" alt=""
                *ngIf="!profileData?.profileImageUrl && showDefaultProfile">
            <img src="{{profileData?.profileImageUrl}}" alt="player-profile" title="player-profile"
                *ngIf="profileData?.profileImageUrl">
        </div>
        <h3>{{profileData?.name}}</h3>
        <ul class="c-football-mind-top-social">
            <li *ngIf="profileData?.facebookLink">
                <a (click)="goToLink('F')">
                    <img src="../../../../assets/images/facebook.png" alt="facebook" title="facebook">
                </a>
            </li>
            <li *ngIf="profileData?.instagramLink">
                <a (click)="goToLink('I')">
                    <img src="../../../../assets/images/instagram.png" alt="instagram" title="instagram">
                </a>
            </li>
            <li *ngIf="profileData?.twitterLink">
                <a (click)="goToLink('T')">
                    <img src="../../../../assets/images/twitter.png" alt="twitter" title="twitter">
                </a>
            </li>
            <li *ngIf="profileData?.tiktokLink">
                <a (click)="goToLink('Tok')">
                    <img src="../../../../assets/images/tik-tok.png" alt="tiktok" title="tiktok">
                </a>
            </li>
            <li *ngIf="profileData?.youtubeLink">
                <a (click)="goToLink('U')">
                    <img src="../../../../assets/images/youtube.png" alt="youtube" title="youtube">
                </a>
            </li>
        </ul>
        <div class="c-football-mind-bottom-video">
            <div *ngIf="(allVideos.length + allImages.length) > 2">
                <owl-carousel-o [options]="owlcarousel30Options"
                    *ngIf="!selectTabPlays && !selectTabConcepts && !selectTabFormation">
                    <ng-template carouselSlide class="item" *ngFor="let vdo of allVideos">
                        <div class="c-football-mind-bottom-video-box">
                            <img [src]="[vdo.thumbnailUrl]" alt="{{vdo.thumbnailSeq}}" title="{{vdo.thumbnailSeq}}"
                                *ngIf="vdo.thumbnailUrl">
                            <video width="100%" class="dash-video" *ngIf="!vdo.thumbnailUrl">
                                <source src="{{vdo.fileUrl}}" type="video/mp4">
                            </video>
                            <div class="vdicnbx" *ngIf="vdo.fileUrl">
                                <i class="icofont icofont-play-alt-2" (click)="openVideoModal(vdo)"></i>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide class="item" *ngFor="let img of allImages">
                        <div class="c-football-mind-bottom-video-box">
                            <img [src]="[img]" alt="{{img}}" title="{{img}}">
                            <div class="d-flex justify-content-center align-items-center">
                                <button class="btn btn-outline-primary btn-eye-outline mr-2"
                                    (click)="image_view(img, true)"><i class="icofont icofont-eye-alt"
                                        ngbTooltip="View"></i></button>
                            </div>
                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>

            <div *ngIf="(allVideos.length + allImages.length) < 3">
                <div class="row justify-content-center"
                    *ngIf="!selectTabPlays && !selectTabConcepts && !selectTabFormation">
                    <div class="col-4" *ngFor="let vdo of allVideos">
                        <div class="c-football-mind-bottom-video-box">
                            <img [src]="[vdo.thumbnailUrl]" alt="{{vdo.thumbnailSeq}}" title="{{vdo.thumbnailSeq}}"
                                *ngIf="vdo.thumbnailUrl">
                            <video width="100%" class="dash-video" *ngIf="!vdo.thumbnailUrl">
                                <source src="{{vdo.fileUrl}}" type="video/mp4">
                            </video>
                            <div class="vdicnbx" *ngIf="vdo.fileUrl">
                                <i class="icofont icofont-play-alt-2" (click)="openVideoModal(vdo)"></i>
                            </div>
                        </div>
                    </div>
                    <div class="col-4" *ngFor="let img of allImages">
                        <div class="c-football-mind-bottom-video-box">
                            <img [src]="[img]" alt="{{img}}" title="{{img}}">
                            <div class="d-flex justify-content-center align-items-center">
                                <button class="btn btn-outline-primary btn-eye-outline mr-2"
                                    (click)="image_view(img, true)"><i class="icofont icofont-eye-alt"
                                        ngbTooltip="View"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="(playsVideos.length + playsImages.length) > 2">
                <owl-carousel-o [options]="owlcarousel30Options" *ngIf="selectTabPlays">
                    <ng-template carouselSlide class="item" *ngFor="let vdo of playsVideos">
                        <div class="c-football-mind-bottom-video-box">
                            <img [src]="[vdo.thumbnailUrl]" alt="{{vdo.thumbnailSeq}}" title="{{vdo.thumbnailSeq}}"
                                *ngIf="vdo.thumbnailUrl">
                            <video width="100%" class="dash-video" *ngIf="!vdo.thumbnailUrl">
                                <source src="{{vdo.fileUrl}}" type="video/mp4">
                            </video>
                            <div class="vdicnbx" *ngIf="vdo.fileUrl">
                                <i class="icofont icofont-play-alt-2" (click)="openVideoModal(vdo)"></i>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide class="item" *ngFor="let img of playsImages">
                        <div class="c-football-mind-bottom-video-box">
                            <img [src]="[img]" alt="{{img}}" title="{{img}}">
                            <div class="d-flex justify-content-center align-items-center">
                                <button class="btn btn-outline-primary btn-eye-outline mr-2"
                                    (click)="image_view(img, true)"><i class="icofont icofont-eye-alt"
                                        ngbTooltip="View"></i></button>
                            </div>
                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>

            <div *ngIf="(playsVideos.length + playsImages.length) < 3">
                <div class="row justify-content-center" *ngIf="selectTabPlays">
                    <div class="col-4" *ngFor="let vdo of playsVideos">
                        <div class="c-football-mind-bottom-video-box">
                            <img [src]="[vdo.thumbnailUrl]" alt="{{vdo.thumbnailSeq}}" title="{{vdo.thumbnailSeq}}"
                                *ngIf="vdo.thumbnailUrl">
                            <video width="100%" class="dash-video" *ngIf="!vdo.thumbnailUrl">
                                <source src="{{vdo.fileUrl}}" type="video/mp4">
                            </video>
                            <div class="vdicnbx" *ngIf="vdo.fileUrl">
                                <i class="icofont icofont-play-alt-2" (click)="openVideoModal(vdo)"></i>
                            </div>
                        </div>
                    </div>
                    <div class="col-4" *ngFor="let img of playsImages">
                        <div class="c-football-mind-bottom-video-box">
                            <img [src]="[img]" alt="{{img}}" title="{{img}}">
                            <div class="d-flex justify-content-center align-items-center">
                                <button class="btn btn-outline-primary btn-eye-outline mr-2"
                                    (click)="image_view(img, true)"><i class="icofont icofont-eye-alt"
                                        ngbTooltip="View"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="(conceptsVideos.length + conceptsImages.length) > 2">
                <owl-carousel-o [options]="owlcarousel30Options" *ngIf="selectTabConcepts">
                    <ng-template carouselSlide class="item" *ngFor="let vdo of conceptsVideos">
                        <div class="c-football-mind-bottom-video-box">
                            <img [src]="[vdo.thumbnailUrl]" alt="{{vdo.thumbnailSeq}}" title="{{vdo.thumbnailSeq}}"
                                *ngIf="vdo.thumbnailUrl">
                            <video width="100%" class="dash-video" *ngIf="!vdo.thumbnailUrl">
                                <source src="{{vdo.fileUrl}}" type="video/mp4">
                            </video>
                            <div class="vdicnbx" *ngIf="vdo.fileUrl">
                                <i class="icofont icofont-play-alt-2" (click)="openVideoModal(vdo)"></i>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide class="item" *ngFor="let img of conceptsImages">
                        <div class="c-football-mind-bottom-video-box">
                            <img [src]="[img]" lt="{{img}}" title="{{img}}">
                            <div class="d-flex justify-content-center align-items-center">
                                <button class="btn btn-outline-primary btn-eye-outline mr-2"
                                    (click)="image_view(img, true)"><i class="icofont icofont-eye-alt"
                                        ngbTooltip="View"></i></button>
                            </div>
                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>

            <div *ngIf="(conceptsVideos.length + conceptsImages.length) < 3">
                <div class="row justify-content-center" *ngIf="selectTabConcepts">
                    <div class="col-4" *ngFor="let vdo of conceptsVideos">
                        <div class="c-football-mind-bottom-video-box">
                            <img [src]="[vdo.thumbnailUrl]" alt="{{vdo.thumbnailSeq}}" title="{{vdo.thumbnailSeq}}"
                                *ngIf="vdo.thumbnailUrl">
                            <video width="100%" class="dash-video" *ngIf="!vdo.thumbnailUrl">
                                <source src="{{vdo.fileUrl}}" type="video/mp4">
                            </video>
                            <div class="vdicnbx" *ngIf="vdo.fileUrl">
                                <i class="icofont icofont-play-alt-2" (click)="openVideoModal(vdo)"></i>
                            </div>
                        </div>
                    </div>
                    <div class="col-4" *ngFor="let img of conceptsImages">
                        <div class="c-football-mind-bottom-video-box">
                            <img [src]="[img]" alt="{{img}}" title="{{img}}">
                            <div class="d-flex justify-content-center align-items-center">
                                <button class="btn btn-outline-primary btn-eye-outline mr-2"
                                    (click)="image_view(img, true)"><i class="icofont icofont-eye-alt"
                                        ngbTooltip="View"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="(formationVideos.length + formationImages.length) > 2">
                <owl-carousel-o [options]="owlcarousel30Options" *ngIf="selectTabFormation">
                    <ng-template carouselSlide class="item" *ngFor="let vdo of formationVideos">
                        <div class="c-football-mind-bottom-video-box">
                            <img [src]="[vdo.thumbnailUrl]" alt="{{vdo.thumbnailSeq}}" title="{{vdo.thumbnailSeq}}"
                                *ngIf="vdo.thumbnailUrl">
                            <video width="100%" class="dash-video" *ngIf="!vdo.thumbnailUrl">
                                <source src="{{vdo.fileUrl}}" type="video/mp4">
                            </video>
                            <div class="vdicnbx" *ngIf="vdo.fileUrl">
                                <i class="icofont icofont-play-alt-2" (click)="openVideoModal(vdo)"></i>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide class="item" *ngFor="let img of formationImages">
                        <div class="c-football-mind-bottom-video-box">
                            <img [src]="[img]" alt="{{img}}" title="{{img}}">
                            <div class="d-flex justify-content-center align-items-center">
                                <button class="btn btn-outline-primary btn-eye-outline mr-2"
                                    (click)="image_view(img, true)"><i class="icofont icofont-eye-alt"
                                        ngbTooltip="View"></i></button>
                            </div>
                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>

            <div *ngIf="(formationVideos.length + formationImages.length) < 3">
                <div class="row justify-content-center" *ngIf="selectTabFormation">
                    <div class="col-4" *ngFor="let vdo of formationVideos">
                        <div class="c-football-mind-bottom-video-box">
                            <img [src]="[vdo.thumbnailUrl]" alt="{{vdo.thumbnailSeq}}" title="{{vdo.thumbnailSeq}}"
                                *ngIf="vdo.thumbnailUrl">
                            <video width="100%" class="dash-video" *ngIf="!vdo.thumbnailUrl">
                                <source src="{{vdo.fileUrl}}" type="video/mp4">
                            </video>
                            <div class="vdicnbx" *ngIf="vdo.fileUrl">
                                <i class="icofont icofont-play-alt-2" (click)="openVideoModal(vdo)"></i>
                            </div>
                        </div>
                    </div>
                    <div class="col-4" *ngFor="let img of formationImages">
                        <div class="c-football-mind-bottom-video-box">
                            <img [src]="[img]" alt="{{img}}" title="{{img}}">
                            <div class="d-flex justify-content-center align-items-center">
                                <button class="btn btn-outline-primary btn-eye-outline mr-2"
                                    (click)="image_view(img, true)"><i class="icofont icofont-eye-alt"
                                        ngbTooltip="View"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="c-football-mind-top-iframe">
            <div class="c-football-mind-top-iframe-in">
                <ngb-tabset (tabChange)="tab_change($event)" #ngbTabset #t="ngbTabset">
                    <ngb-tab title="All" id="tab-selectbyid0">
                        <ng-template ngbTabContent *ngIf="someAllVideoLink?.length > 0">
                            <div *ngFor="let link of someAllVideoLink">
                                <ul class="c-football-mind-iframe-box-list">
                                    <li *ngIf="link.videoLink">
                                        <a href="{{link.videoLink}}" class="link" target="_blank">{{link.videoLink}}</a>
                                    </li>
                                    <li><a href="{{link.routeUrl}}" class="link" target="_blank"><i
                                                class="fa fa-user-circle-o" aria-hidden="true"></i> {{link.name}}</a>
                                    </li>
                                </ul>
                            </div>
                            <button class="btn btn-secondary mt-3" (click)="view_more('a')"
                                *ngIf="allVideoLink?.length > 5 && !allViewMind">View All</button>
                        </ng-template>
                    </ngb-tab>
                    <ngb-tab title="Plays" id="tab-selectbyid1">
                        <ng-template ngbTabContent *ngIf="someplaysVideoLink?.length > 0">
                            <div *ngFor="let link of someplaysVideoLink">
                                <ul class="c-football-mind-iframe-box-list">
                                    <li *ngIf="link.videoLink">
                                        <a href="{{link.videoLink}}" class="link" target="_blank">{{link.videoLink}}</a>
                                    </li>
                                    <li><a href="{{link.routeUrl}}" class="link" target="_blank"><i
                                                class="fa fa-user-circle-o" aria-hidden="true"></i> {{link.name}}</a>
                                    </li>
                                </ul>
                            </div>
                            <button class="btn btn-secondary mt-3" (click)="view_more('p')"
                                *ngIf="playsVideoLink?.length > 5 && !playsViewMind">View All</button>
                        </ng-template>
                    </ngb-tab>
                    <ngb-tab title="Concepts" id="tab-selectbyid2">
                        <ng-template ngbTabContent *ngIf="someconceptsVideoLink?.length > 0">
                            <div *ngFor="let link of someconceptsVideoLink">
                                <ul class="c-football-mind-iframe-box-list">
                                    <li *ngIf="link.videoLink">
                                        <a href="{{link.videoLink}}" class="link" target="_blank">{{link.videoLink}}</a>
                                    </li>
                                    <li><a href="{{link.routeUrl}}" class="link" target="_blank"><i
                                                class="fa fa-user-circle-o" aria-hidden="true"></i> {{link.name}}</a>
                                    </li>
                                </ul>
                            </div>
                            <button class="btn btn-secondary mt-3" (click)="view_more('c')"
                                *ngIf="conceptsVideoLink?.length > 5 && !conceptViewMind">View All</button>
                        </ng-template>
                    </ngb-tab>
                    <ngb-tab title="Formation" id="tab-selectbyid3">
                        <ng-template ngbTabContent *ngIf="someformationVideoLink?.length > 0">
                            <div *ngFor="let link of someformationVideoLink">
                                <ul class="c-football-mind-iframe-box-list">
                                    <li *ngIf="link.videoLink">
                                        <a href="{{link.videoLink}}" class="link" target="_blank">{{link.videoLink}}</a>
                                    </li>
                                    <li><a href="{{link.routeUrl}}" class="link" target="_blank"><i
                                                class="fa fa-user-circle-o" aria-hidden="true"></i> {{link.name}}</a>
                                    </li>
                                </ul>
                            </div>
                            <button class="btn btn-secondary mt-3" (click)="view_more('f')"
                                *ngIf="formationVideoLink?.length > 5 && !formationViewMind">View All</button>
                        </ng-template>
                    </ngb-tab>
                </ngb-tabset>
            </div>
        </div>
        <div class="c-football-mind-bottom" *ngIf="randomFootballMinds?.length > 0">
            <div class="row justify-content-center">
                <div class="col-4" *ngFor="let minds of randomFootballMinds">
                    <div class="c-football-mind-bottom-box">
                        <a href="{{minds.routeUrl}}" target="_blank" class="link">
                            <img src="{{minds.image}}" aalt="football-mind-profile" title="football-mind-profile"
                                *ngIf="minds.image">
                            <img src="../../../../assets/images/demo-img.png" alt="" *ngIf="!minds.image">
                            <h3 ngbtooltip="minds.name">{{minds.name}}</h3>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Video view Modal -->
<ng-template #foootballMindVideoViewContent let-c="close" let-d="dismiss" let-modal>
    <div class="modal-body c-model-w">
        <button type="button" class="close" aria-label="Close" (click)="closeModal(modal)">
            <span aria-hidden="true">&times;</span>
        </button>
        <div class="boxvideo">
            <video controls controlsList="nodownload" width="100%" (ended)="handleVideoEnded($event)">
                <source src="{{modalData.fileUrl}}" type="video/mp4" />
            </video>
        </div>
    </div>
</ng-template>
<!-- END -->