import { Injectable, Inject } from '@angular/core';
import { HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class ApiInterceptor implements HttpInterceptor {
    private requests: HttpRequest<any>[] = [];

    constructor(
        @Inject('BASE_API_PATH') private baseApiPath: string,
        private router: Router,
        public toster: ToastrService,
        // private loaderService: LoaderService,
        // private dialogService: DialogService,
        // private authenticationService: AuthenticationService
    ) { }

    removeRequest(req: HttpRequest<any>) {
        const i = this.requests.indexOf(req);
        if (i >= 0) {
            this.requests.splice(i, 1);
        }
        // this.loaderService.isLoading.next(this.requests.length > 0);
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        request = request.clone({ url: `${this.baseApiPath}${request.url}` });
        //const currentUser = sessionStorage.getItem('__qu__');
        const currentUser = localStorage.getItem('__qu__');
        if (currentUser) {
            const token: string = JSON.parse(currentUser).token;
            if (token) {
                request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
            }
        }

        this.requests.push(request);
        // console.log('No of requests--->' + this.requests.length);
        // this.loaderService.isLoading.next(true);

        return new Observable(observer => {
            const subscription = next.handle(request)
                .subscribe(
                    event => {
                        if (event instanceof HttpResponse) {
                            this.removeRequest(request);
                            observer.next(event);
                        }
                    },
                    err => {
                        if (err.status === 401) {
                            if (err.headers.has('Token-Expired')) {
                                //sessionStorage.removeItem('__qu__');
                                localStorage.removeItem('__qu__');
                                this.router.navigate(['/auth/login']);
                            } else {
                                // this.dialogService.alert('You are not authenticated - either not authenticated at all or authenticated incorrectly. \
                                // Please contact Administrator for any help.');
                            }
                        } else if (err.status === 403) {
                            //   this.dialogService.alert('You are not authorized to do this task. Please contact Administrator for your request.');
                        } else {
                            // alert('error returned : ' + err.status + ' | ' + err.message);
                            // this.router.navigate(['/auth/login']);
                        }

                        this.removeRequest(request);
                        observer.error(err);
                    },
                    () => {
                        this.removeRequest(request);
                        observer.complete();
                    });
            // remove request from queue when cancelled
            return () => {
                this.removeRequest(request);
                subscription.unsubscribe();
            };
        });
    }
}
