import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from "./shared/shared.module";
import { AppRoutingModule } from './app-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';

// for HttpClient import:
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
// for Router import:
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
// for Core import:
import { LoadingBarModule } from '@ngx-loading-bar/core';

import { AuthService } from './shared/services/firebase/auth.service';
import { AdminGuard } from './shared/guard/admin.guard';
import { SecureInnerPagesGuard } from './shared/guard/SecureInnerPagesGuard.guard';
import { CookieService } from 'ngx-cookie-service';
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";

import { environment } from '../environments/environment';
import { AngularFirestoreModule } from '@angular/fire/firestore';

import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { TagInputModule } from 'ngx-chips';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { CareersComponent } from './careers/careers.component';
import { PublicProfileComponent } from './public-profile/public-profile.component';
import { DepthchartComponent } from './depthchart/depthchart.component';

import { DragulaModule } from 'ng2-dragula';
import { StripePaymentsComponent } from './stripe-payments/stripe-payments.component';
import { GameCountDownComponent } from './game-count-down/game-count-down.component';
import { PackageDetailComponent } from './package-detail/package-detail.component';
import { PlaybookPackagePurchaseComponent } from './playbook-package-purchase/playbook-package-purchase.component';
import { ShowtimeProfileComponent } from './showtime-profile/showtime-profile.component';
import { PlayerRegComponent } from './player-reg/player-reg.component';
import { AppDownloadComponent } from './app-download/app-download.component';
import { BigboardPrototypeComponent } from './bigboard-prototype/bigboard-prototype.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { PlayerInvitationComponent } from './player-invitation/player-invitation.component';
import { PublicInstallsComponent } from './public-installs/public-installs.component';
import { PublicInstallPresentationsComponent } from './public-install-presentations/public-install-presentations.component';
import { LightboxModule } from 'ngx-lightbox';
import { RuleBookComponent } from './rule-book/rule-book.component';
import { PublicFootballMindProfileComponent } from './public-football-mind-profile/public-football-mind-profile.component';
import { NgxPrintModule } from 'ngx-print';
import { EventAttendancePlayerLoginComponent } from './pages/authentication/event-attendance-player-login/event-attendance-player-login.component';
import { Error404Component } from './error404/error404.component';
import { PublicFaqComponent } from './public-faq/public-faq.component';
import { ReportingIssueComponent } from './reporting-issue/reporting-issue.component';
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}
import { DROPZONE_CONFIG, DropzoneConfigInterface, DropzoneModule } from 'ngx-dropzone-wrapper';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { DndModule } from 'ngx-drag-drop';
import { UrlSharingComponent } from './url-sharing/url-sharing.component';
const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  // Change this to your upload POST address:
  url: 'https://httpbin.org/post',
  acceptedFiles: 'image/*',
  createImageThumbnails: true
};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutComponent,
    CareersComponent,
    PublicProfileComponent,
    DepthchartComponent,
    StripePaymentsComponent,
    GameCountDownComponent,
    PackageDetailComponent,
    PlaybookPackagePurchaseComponent,
    ShowtimeProfileComponent,
    PlayerRegComponent,
    AppDownloadComponent,
    BigboardPrototypeComponent,
    PlayerInvitationComponent,
    PublicInstallsComponent,
    PublicInstallPresentationsComponent,
    RuleBookComponent,
    PublicFootballMindProfileComponent,
    EventAttendancePlayerLoginComponent,
    Error404Component,
    PublicFaqComponent,
    ReportingIssueComponent,
    UrlSharingComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    CarouselModule,
    CoreModule,
    SharedModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    LightboxModule,
    ToastrModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    NgSelectModule,
    TagInputModule,
    NgxPrintModule,
    DragulaModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
    }),
    // for HttpClient use:
    LoadingBarHttpClientModule,
    // for Router use:
    LoadingBarRouterModule,
    // for Core use:
    LoadingBarModule,
    DndModule,
    DropzoneModule,
    NgxDropzoneModule,
  ],
  providers: [AuthService, AdminGuard, SecureInnerPagesGuard, CookieService, { provide: DROPZONE_CONFIG, useValue: DEFAULT_DROPZONE_CONFIG }],
  bootstrap: [AppComponent]
})
export class AppModule { }
