import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { DefensivePlayService } from 'src/app/data/services/defense';
import { TagService } from 'src/app/data/services/global';
import { ConceptService, FormationService, FormationStackService, OffensePlayService } from 'src/app/data/services/offense';

@Component({
  selector: 'app-files-view-modal',
  templateUrl: './files-view-modal.component.html',
  styleUrls: ['./files-view-modal.component.css']
})
export class FilesViewModalComponent implements OnInit {
  @Input() fromParent: any;
  files: any = [];
  isRelated: boolean = true;
  modalData: any = {};
  userFullAccess: boolean = false;
  isSingleFile: boolean = false;
  fileUrl: any;
  fileUrls: any;
  isVideo: boolean = false;
  captureShow: boolean = false;
  tags: any[] = [];
  tagModel: any;
  @ViewChild('captureImgViewcontent', { static: true })
  captureImgViewcontent!: ElementRef<HTMLElement>
  cartureImg = '';

  showTagField: boolean = true;
  gamePlay: boolean = false;
  constructor(
    public activeModal: NgbActiveModal,
    private tagService: TagService,
    public toster: ToastrService,
    private conceptService: ConceptService,
    private formationService: FormationService,
    private formationStackService: FormationStackService,
    private playService: OffensePlayService,
    private defensivePlayService: DefensivePlayService,
    private modalService: NgbModal,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.files = this.fromParent.files;
    this.isRelated = this.fromParent.isRelated;
    this.modalData = this.fromParent.modalData;
    this.userFullAccess = this.fromParent.userFullAccess;
    this.isSingleFile = this.fromParent.isSingleFile;
    this.fileUrl = this.fromParent.fileUrl;
    this.fileUrls = this.getSafeUrl(this.fromParent.fileUrl);
    this.isVideo = this.fromParent.isVideo;
    this.captureShow = this.fromParent.captureShow;
    this.gamePlay = this.fromParent?.gamePlaylist;

    if (this.isSingleFile && this.isVideo && !this.gamePlay) {
      this.getVideoTag(this.modalData.id);
    }

    if (this.isSingleFile && this.gamePlay) {
      this.getVideoTag(this.modalData.id);
    }

    if (this.modalData?.origin == 'DPlays') {
      this.showTagField = false;
      origin = 'mdp'
    }
  }

  getSafeUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url)
  };

  closeModal(sendData: any) {
    this.activeModal.close(sendData);
  };

  getVideoTag(id?: string) {
    this.tagService.getByReference(id).subscribe(resp => {
      var tagValueArry: any[] = resp;
      var getTagValueArry: any[] = [];
      tagValueArry.forEach(data => {
        getTagValueArry.push({ display: data, value: data });
      });
      this.tagModel = getTagValueArry;
    });
  };

  onVideoTagSearch(event: any) {
    var searchText: string = ""
    if (event) {
      searchText = event.toLowerCase();
    }
    var origin = '';
    if (this.modalData.origin) {
      if (this.modalData.origin == 'Plays') {
        origin = 'mp'
      }
      else if (this.modalData.origin == 'Concept') {
        origin = 'mc'
      }
      else if (this.modalData.origin == 'Formation') {
        origin = 'mf'
      }
      else if (this.modalData.origin == 'Formation Stack') {
        origin = 'ms'
      }
      else if (this.modalData.origin == 'DPlays') {
        origin = 'mdp'
      }
      else if (this.modalData.origin == 'Game Playlist') {
        //origin = 'gp'
      }
    }
    this.tagService.getTagName(searchText, origin).subscribe(resp => {
      this.tags = resp
    });
  };

  videoTagUpdate() {
    var origin = '';
    if (this.modalData.origin) {
      if (this.modalData.origin == 'Plays') {
        origin = 'mp'
      }
      else if (this.modalData.origin == 'Concept') {
        origin = 'mc'
      }
      else if (this.modalData.origin == 'Formation') {
        origin = 'mf'
      }
      else if (this.modalData.origin == 'Formation Stack') {
        origin = 'ms'
      }
      else if (this.modalData.origin == 'Game Playlist') {
        origin = 'gp'
      }
    }
    var saveData: any = {};
    saveData.type = 'm';
    saveData.prefix = origin;
    saveData.originRefId = this.modalData.id;
    saveData.parentControllerName = "Media";

    var tagValueArry: any[] = this.tagModel;
    if (tagValueArry && tagValueArry.length) {
      var searchTagValueArry: any[] = [];
      tagValueArry.forEach(data => {
        searchTagValueArry.push(data.value);
      });
      saveData.tagList = searchTagValueArry;
    }

    this.tagService.modify(saveData).subscribe((resp) => {
      if (!resp) {
        this.toster.error("Failed to add / remove tag");
      }
    });
  };

  capture = (index: number) => {
    const video: any = document.getElementById("video" + index);
    const canvas: any = document.createElement("canvas");
    // scale the canvas accordingly
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    // draw the video at that frame
    canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);

    this.cartureImg = canvas.toDataURL();
    //console.log(canvas.toDataURL());
    this.modalService.open(this.captureImgViewcontent, { centered: true, size: 'lg' });
  };

  saveImg(modal: any) {
    let capFileName = "";
    capFileName = this.modalData.fileSeq.substr(0, this.modalData.fileSeq.indexOf('.'));
    const imageBlob = this.dataURItoBlob(this.cartureImg);
    const imageFile = new File([imageBlob], capFileName + '_capture.png', { type: 'image/png' });

    let formData = new FormData();
    formData.append('isWebUpload', 'true');
    formData.append('files[' + 0 + ']', imageFile);

    if (this.modalData.origin == 'Formation Stack') {
      formData.append('formationSetId', this.modalData.originId);
      this.formationStackService.uploadScreenshot(formData).subscribe((result: any) => {
        if (result.success === true) {
          this.toster.success(result.responseMsg, 'Success', {
            progressBar: true,
            positionClass: 'toast-top-right'
          });
        } else {
          this.toster.error(result.responseMsg, 'Error', {
            progressBar: true,
            positionClass: 'toast-top-right'
          });
        }
      });
    }
    else if (this.modalData.origin == 'Formation') {
      formData.append('formationId', this.modalData.originId);
      this.formationService.uploadScreenshot(formData).subscribe((result: any) => {
        if (result.success === true) {
          this.toster.success(result.responseMsg, 'Success', {
            progressBar: true,
            positionClass: 'toast-top-right'
          });
        } else {
          this.toster.error(result.responseMsg, 'Error', {
            progressBar: true,
            positionClass: 'toast-top-right'
          });
        }
      });
    }
    else if (this.modalData.origin == 'Concept') {
      formData.append('conceptId', this.modalData.originId);
      this.conceptService.uploadScreenshot(formData).subscribe((result: any) => {
        if (result.success === true) {
          this.toster.success(result.responseMsg, 'Success', {
            progressBar: true,
            positionClass: 'toast-top-right'
          });
        } else {
          this.toster.error(result.responseMsg, 'Error', {
            progressBar: true,
            positionClass: 'toast-top-right'
          });
        }
      });
    }
    else if (this.modalData.origin == 'Plays') {
      formData.append('playsId', this.modalData.originId);
      this.playService.uploadScreenshot(formData).subscribe((result: any) => {
        if (result.success === true) {
          this.toster.success(result.responseMsg, 'Success', {
            progressBar: true,
            positionClass: 'toast-top-right'
          });
        } else {
          this.toster.error(result.responseMsg, 'Error', {
            progressBar: true,
            positionClass: 'toast-top-right'
          });
        }
      });
    }
    else if (this.modalData.origin == 'DPlays') {
      formData.append('defensivePlaysId', this.modalData.originId);
      this.defensivePlayService.uploadScreenshot(formData).subscribe((result: any) => {
        if (result.success === true) {
          this.toster.success(result.responseMsg, 'Success', {
            progressBar: true,
            positionClass: 'toast-top-right'
          });
        } else {
          this.toster.error(result.responseMsg, 'Error', {
            progressBar: true,
            positionClass: 'toast-top-right'
          });
        }
      });
    }
    else if (this.modalData.origin == 'DFront') {
      formData.append('defensivePlaysId', this.modalData.originId);
      this.defensivePlayService.uploadScreenshot(formData).subscribe((result: any) => {
        if (result.success === true) {
          this.toster.success(result.responseMsg, 'Success', {
            progressBar: true,
            positionClass: 'toast-top-right'
          });
        } else {
          this.toster.error(result.responseMsg, 'Error', {
            progressBar: true,
            positionClass: 'toast-top-right'
          });
        }
      });
    }
    modal.dismiss("closed");
  };

  dataURItoBlob(dataURI: any) {
    var imageData = dataURI.toString();
    var byteCharacters = atob(imageData.replace(/^data:image\/(png|jpeg|jpg);base64,/, ''));
    var byteNumbers = new Array(byteCharacters.length);
    for (var i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);
    var blob = new Blob([byteArray], {
      type: undefined
    });
    return blob;
  };

  handleVideoEnded(event: Event) {
    const videoElement: HTMLVideoElement = event.target as HTMLVideoElement;
    if (videoElement) {
      videoElement.playbackRate = 0.25;
      videoElement.play();
    }
  }
}
