import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styles: [
    '.modal-dialog-scrollable .modal-content { overflow: visible !important; } ngb-modal-window .component-host-scrollable { overflow: visible !important; } .modal-dialog-scrollable .modal-body { overflow-y: visible !important; } .ng2-dropdown-menu { z-index: 9999 !important; }'
  ],
  encapsulation: ViewEncapsulation.None
})
export class ConfirmModalComponent implements OnInit {
  @Input() message: any;
  constructor(
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
  }
  close(resp: any) {
    this.activeModal.close(resp);
  };
}
