import { AfterViewInit, Component, Directive, ElementRef, HostListener, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import * as feather from 'feather-icons';
import { LayoutService } from '../../../services/layout.service';
import { NavService } from '../../../services/nav.service';
import { fadeInAnimation } from '../../../data/router-animation/router-animation';
import { getMaxListeners } from 'process';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReportingIssueComponent } from 'src/app/reporting-issue/reporting-issue.component';
import { SearchFocusService } from 'src/app/data/services/global/search-focus.service';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
  animations: [fadeInAnimation]
})
export class ContentComponent implements OnInit, AfterViewInit {

  constructor(public navServices: NavService,
    public layout: LayoutService,
    private modalService: NgbModal,
    private searchFocusService: SearchFocusService) {
  }

  ngAfterViewInit() {
    setTimeout(() => {
      feather.replace();
    });
  }

  public getRouterOutletState(outlet: any) {
    return outlet.isActivated ? outlet.activatedRoute : '';
  }

  ngOnInit() {
  }

  goToReportingIssue() {
    this.modalService.open(ReportingIssueComponent, { centered: true });
    this.searchFocusService.setModalOpen(true);
  };
}
