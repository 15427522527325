<div class="container-fluid">
    <div class="row">
        <div class="col-xl-7"
            [ngStyle]="{'background-image': 'url(assets/images/login/login-screen-img.jpg)','background-size': 'cover', 'display': 'block'}">
            <img class="bg-img-cover bg-center d-none" src="assets/images/login/login-screen-img.jpg" alt="looginpage"
                title="looginpage">
        </div>
        <div class="col-xl-5 p-0">
            <div class="login-card">
                <div>
                    <div (click)="imgLogoclick()">
                        <a class="logo text-left">
                            <img class="img-fluid for-light" src="assets/images/logo/login.png" alt="looginpage"
                                title="looginpage">
                            <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="looginpage"
                                title="looginpage">
                        </a>
                    </div>
                    <div class="login-main">
                        <form class="theme-form needs-validation" [class.was-validated]="!loginForm.valid" novalidate=""
                            [formGroup]="loginForm">
                            <h4>Sign in to account</h4>
                            <p>Enter your email or user name & password to login</p>
                            <div class="form-group">
                                <!-- <label class="col-form-label">Email Address</label> -->
                                <input class="form-control" type="email" required="" placeholder="Email or User Name"
                                    formControlName="email">
                                <div class="invalid-feedback"
                                    *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.required">
                                    Email or User Name is
                                    required.</div>
                                <!-- <div class="cust-invalid-feedback"
                                    *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.email">
                                    Invalid Email
                                    Address.</div> -->
                            </div>
                            <div class="form-group">
                                <!-- <label class="col-form-label">Password</label> -->
                                <input class="form-control" [type]="show ? 'text' : 'password'" name="login[password]"
                                    required="" placeholder="Password" formControlName="password">
                                <div class="invalid-feedback"
                                    *ngIf="loginForm.controls.password.touched && loginForm.controls.password.errors?.required">
                                    Password
                                    is required.</div>
                                <div class="show-hide" (click)="showPassword()" *ngIf="!show"><span class="show"></span>
                                </div>
                                <div class="show-hide" (click)="showPassword()" *ngIf="show"><span class="Hide"></span>
                                </div>
                            </div>
                            <div class="form-group mb-0">
                                <div class="checkbox p-0">
                                    <input id="checkbox1" type="checkbox" formControlName="isRemeber">
                                    <label class="text-muted" for="checkbox1">Remember password</label>
                                </div>
                                <a href="javascript:void(0)" class="link" (click)="navigateToForgotPsw()">Forgot
                                    password?</a>
                                <!-- <button class="btn btn-primary btn-block" type="button" [disabled]="!loginForm.valid" (click)="login()">Sign in</button> -->
                            </div>
                            <div class="d-flex justify-content-between align-items-center c-mobile-login">
                                <button class="btn btn-primary col-md-5" type="button" [disabled]="!loginForm.valid"
                                    (click)="login()">Sign in</button>
                                <p class="horizontal-line-text c-other-login">
                                    <span>or</span>
                                </p>
                                <div id="buttonDiv" class="text-center d-flex justify-content-center"
                                    style="opacity: 0.8"></div>
                            </div>
                            <button class="btn btn-dark btn-block mt-3" type="button" (click)="goToReg()">Register
                                Yourself</button>
                            <!-- <p class="horizontal-line-text c-other-login">
                            <span>or</span>
                          </p>
                          <div id="buttonDiv" class="text-center d-flex justify-content-center" style="opacity: 0.8" ></div>
                              
                          <p class="mt-4 mb-0">Not Sure Sign In! 
                              <a  href="javascript:void(0)" class="link" (click)="goToReg()" class="ml-2">Register Yourself</a>
                          </p> -->
                            <button class="btn btn-primary btn-block mt-3" (click)="goToReportingIssue()"
                                style="cursor: pointer;">Report Issue</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>