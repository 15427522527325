import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { FormationStack, Modifier } from 'src/app/data/models/offense';
import { FormationStackService, ModifierService } from 'src/app/data/services/offense';
import { LoaderService } from 'src/app/shared/services/loader.service';

@Component({
  selector: 'app-modifier-rule',
  templateUrl: './modifier-rule.component.html',
  styles: [
    '.modal-dialog-scrollable .modal-content { overflow: visible !important; } ngb-modal-window .component-host-scrollable { overflow: visible !important; } .modal-dialog-scrollable .modal-body { overflow-y: visible !important; } .ng2-dropdown-menu { z-index: 9999 !important; }'
  ],
  encapsulation: ViewEncapsulation.None
})
export class ModifierRuleComponent implements OnInit {
  @Input() fromParent: any;

  detailForm: FormGroup;
  modifiers: Modifier[] = [];
  public validate = false;
  formationSets: FormationStack[] = [];

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private modifierService: ModifierService,
    private formationStackService: FormationStackService,
    private loaderService: LoaderService,
    public toster: ToastrService,
  ) {
    this.detailForm = this.formBuilder.group({
      referenceId: [],
      formationStackId: []
    });
  }

  ngOnInit(): void {
    if (this.fromParent.referenceValue) {
      this.detailForm.controls[`referenceId`].setValue(this.fromParent.referenceValue);
    }
    if (this.fromParent.formationStackRules) {
      this.detailForm.controls[`formationStackId`].setValue(this.fromParent.formationStackRules);
    }
    this.modifierService.getByModifier(this.fromParent.modifierId).subscribe(resp => {
      // const array: any = [];
      // resp.forEach(data => {
      //   array.push({ value: data.id, label: data.name })       
      // });

      this.modifiers = resp;
    });

    this.formationStackService.getAll().subscribe(resp => {
      this.formationSets = resp;
    });
  }

  submit() {
    if (this.detailForm.invalid) {
      this.validate = !this.validate;
      return;
    }

    if (this.fromParent.modifierId) {
      let UnPairedIds: string[] = [];
      if (this.detailForm.controls[`referenceId`].value && this.detailForm.controls[`referenceId`].value.length > 0) {
        this.detailForm.controls[`referenceId`].value.forEach((event: any) => {
          UnPairedIds.push(event.value);
        });
      }

      let UnFormationIds: string[] = [];
      if (this.detailForm.controls[`formationStackId`].value && this.detailForm.controls[`formationStackId`].value.length > 0) {
        this.detailForm.controls[`formationStackId`].value.forEach((event: any) => {
          UnFormationIds.push(event.id);
        });
      }

      let obj: any = {};
      obj.modifierId = this.fromParent.modifierId;
      obj.unPairedIds = UnPairedIds;
      obj.formationStackIds = UnFormationIds;

      this.loaderService.isLoading.next(true);
      this.loaderService.loadingMessage.next('Adding Rules..')
      this.modifierService.addRule(obj).subscribe(resp => {
        this.loaderService.isLoading.next(false);
        if (resp && resp != null) {
          this.closeModal(resp);
        }
        else if (resp == null) {
          this.toster.warning("Rule already adeed with this modifier");
        }
      },
        error => {
          this.loaderService.isLoading.next(false);
        });
    }
    else {
      let formData = this.detailForm.value;
      this.closeModal(formData);
    }
  };

  closeModal(data: any) {
    this.activeModal.close(data);
  };
}
