<div class="modal-header">
    <h5 class="modal-title">New Rule</h5>
    <button type="button" class="close" data-dismiss="modal" (click)="closeModal('dismiss')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="detailForm" autocomplete="off" class="needs-validation" novalidate
        [ngClass]="{'was-validated': validate}">
        <div class="form-row">
            <div class="col-md-12 mb-3">
                <!-- <label for="tag-type">Can not Pair with Modifier</label> -->
                <ng-select [items]="modifiers" placeholder="Select Modifier" formControlName="referenceId"
                    [multiple]="true">
                    <ng-template ng-header-tmp>
                        Select Modifier
                    </ng-template>
                </ng-select>
            </div>
            <div class="col-md-12 mb-3">
                <!-- <label for="tag-type">Can not Pair with Formation Stack</label> -->
                <ng-select [items]="formationSets" bindLabel="name" placeholder="Select Formation Set"
                    formControlName="formationStackId" [multiple]="true">
                    <ng-template ng-header-tmp>
                        Select Formation Set
                    </ng-template>
                </ng-select>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="submit()">Add Rule</button>
    <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="closeModal(null)">Close</button>
</div>