<header class="main-header">
	<div class="container">
		<div class="row">
			<div class="col-12 p-0">
				<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
					<a class="navbar-brand" href="index.html"><img src="../../assets/images/home-logo-ss.png"
							alt="STRONGSIDE" height="40" title="STRONGSIDE" /></a>
					<button class="navbar-toggler" type="button" data-toggle="collapse"
						data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
						aria-expanded="false" aria-label="Toggle navigation">
						<span class="navbar-toggler-icon"></span>
					</button>
					<div class="collapse navbar-collapse" id="navbarSupportedContent">
						<ul class="navbar-nav ml-auto">
							<li class="nav-item">
								<a class="nav-link" href="#">HOME</a>
							</li>
							<li class="nav-item">
								<a class="nav-link" href="#prices">PRICE</a>
							</li>
							<li class="nav-item">
								<a class="nav-link" href="/docs">FAQ</a>
							</li>
							<li class="nav-item">
								<a class="nav-link" href="/about">ABOUT US</a>
							</li>
							<li class="nav-item">
								<a class="nav-link" href="/careers">CAREERS</a>
							</li>
						</ul>
						<ul class="navbar-nav ml-auto">
							<div class="btn-grid m-0">
								<a href="#" class="primary-link-btn" title="Start">Sign Up</a> &nbsp;
								<a href="auth/login" class="default-link-btn" title="Sign In">sign in</a>
							</div>
						</ul>
					</div>
				</nav>
			</div>
		</div>
	</div>
</header>

<div class="whyus-sec">
	<div class="container" style="padding: 30px; border: 2px solid #08eaba; border-radius: 10px">
		<div class="row justify-content-center">
			<div class="col">
				<div class="site-main-title">
					<h1 class="primary-title">Affiliate Program</h1>
					<h1 class="default-title">
						Strongside Affiliate Program Terms of Service
					</h1>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col text-white">
				<h4>Agreement</h4>
				<p>
					By signing up to be an Affiliate in the Strongside Affiliate Program
					(“Program”) you are agreeing to be bound by the following terms and
					conditions (“Terms of Service”).
				</p>
				<p>
					Strongside reserves the right to update and change the Terms of Service
					from time to time without notice. Any new features that augment or enhance
					the current Program, including the release of new tools and resources,
					shall be subject to the Terms of Service. Continued use of the Program
					after any such changes shall constitute your consent to such changes.
				</p>
				<p>
					Violation of any of the terms below will result in the termination of your
					Account and for forfeiture of any outstanding affiliate commission payments
					earned during the violation. You agree to use the Affiliate Program at your
					own risk.
				</p>
				<h4>What to call yourself</h4>
				<p>
					As we have multiple ways to partner with Strongside and several ways to
					reference those relationships, here are some guidelines around what you can
					and cannot call yourself within this Strongside program. This includes
					mentions in press releases, references in videos, listings on your website,
					or in any other marketing material you may be using.
				</p>
				<strong>Do say you are a(n):</strong>
				<ul>
					<li>“Strongside Marketing Affiliate”</li>
					<li>“Affiliate”</li>
				</ul>
				<strong>Do NOT refer to yourself as a:</strong>
				<ul>
					<li>“Strategic Partner”</li>
					<li>“Strongside employee”</li>
					<li>“Strongside subcontractor”</li>
					<li>“Strongside consultant”</li>
				</ul>
				<p>
					You may not in any manner misrepresent or embellish the relationship
					between us and you, say you develop our products, say you are part of
					Strongside or express or imply any relationship or affiliation between us
					and you or any other person or entity except as expressly permitted by this
					Agreement (including by expressing or implying that we support, sponsor,
					endorse, or contribute money to any charity or other cause).
				</p>
				<p>
					You may not purchase products through your affiliate links for your own
					use. Such purchases may result (in our sole discretion) in the withholding
					of referral fees and/or the termination of this Agreement.
				</p>
				<h4>Attribution & Limitations</h4>
				<strong>Our current affiliate tracking system relies on links.</strong>
				<p>
					<i>What if I refer someone to Strongside and they had previously clicked on
						another affiliates link to visit the site before using my link?</i>
					<br />
					If a customer is referred by 2 affiliates, the second affiliate (the one
					who convinced them to make the purchase) will be credited for the purchase
					made.
				</p>
				<p>
					Once you have signed up for the Affiliate Program, you will be assigned a
					unique Affiliate Code. You are permitted to place links, banners, or other
					graphics we provide with your Affiliate Code on your site, in your emails,
					or in other communications. Any guidelines, link styles, and graphical
					artwork we provide to use in linking to Strongside will require you to use.
					We may change the design of the artwork at any time without notice.
				</p>
				<p>
					To permit accurate tracking, reporting, and referral fee accrual, we will
					provide you with special link formats to be used in all links between your
					site and the Strongside. You must ensure that each of the links between
					your site and the Strongside properly utilizes such special link formats.
					Links to the Strongside placed on your site pursuant to this Agreement and
					which properly utilize such special link formats are referred to as
					“Special Links.” You will earn referral fees only with respect to sales on
					a Strongside product occurring directly through Special Links; we will not
					be liable to you with respect to any failure by you or someone you refer to
					use Special Links or incorrectly type your Affiliate Code, including to the
					extent that such failure may result in any reduction of amounts that would
					otherwise be paid to you pursuant to this Agreement.
				</p>
				<p>
					Affiliate links should point to the page of the product being promoted.
				</p>
				<h4>Promotion Guidelines</h4>
				<strong>Do:</strong>
				<ul>
					<li>Use any language and assets provided and request new assets</li>
				</ul>
				<strong>Do Not:</strong>
				<ul>
					<li>
						Use false or misleading statements on the benefits of using Strongside.
					</li>
					<li>
						Modify or adjust the Strongside asset and branding guidelines Where to
						promote your link
					</li>
					<li>
						As of Febuary 18th, 2021, you may purchase ads to drive buyers to our
						website using your affiliate links. If this changes, we will send you an
						email.
					</li>
					<li>You may use your Strongside affiliate link in marketing emails.</li>
				</ul>
				<p>Policies that don’t fit into the other buckets</p>
				<ol>
					<li>
						Sharing Placements: When requested, you’ll share the places you’ve used
						your affiliate link. This includes, but is not limited to, links,
						screenshots, and email sends.
					</li>
				</ol>
				<h4>FAQ</h4>
				<p>
					<i>Can I use paid ads on Facebook & Google to refer new business to
						Strongside ?</i>
				</p>
				<p>
					Yes! Most affiliates do not allow this, at this point in our growth, we do.
					If this changes at any point in the future, you will get an email with a 30
					day notice so that you have time to make the necessary changes. Just make
					sure you follow any provided brand guidelines.
				</p>
				<h4>Account Terms</h4>
				<ul>
					<li>You must be 18 years or older to be part of this Program.</li>
					<li>
						You must be a human. Accounts registered by “bots” or other automated
						methods are not permitted.
					</li>
					<li>
						You must provide your legal full name, a valid email address, and any
						other information requested in order to complete the signup process.
					</li>
					<li>
						Your login may only be used by one person – a single login shared by
						multiple people is not permitted.
					</li>
					<li>
						You are responsible for maintaining the security of your account and
						password. Strongside cannot and will not be liable for any loss or damage
						from your failure to comply with this security obligation.
					</li>
					<li>
						You are responsible for all Content posted and activity that occurs under
						your account.
					</li>
					<li>One person or legal entity may not maintain more than one account.</li>
					<li>
						You may not use the Affiliate Program for any illegal or unauthorized
						purpose. You must not, in the use of the Service, violate any laws in your
						jurisdiction (including but not limited to copyright laws).
					</li>
					<li>
						You may not use the Affiliate Program to earn money on your own Strongside
						product accounts.
					</li>
					<li>Referral fees/commissions and payment</li>
				</ul>
				<p>
					For a Product sale to be eligible to earn a referral fee, the customer must
					click-through a Special Link from your site, email, or social media post to
					our domain and subdomains with strongside.app and complete an order for a
					product within 90 days.
				</p>
				<p>
					We will only pay commissions on links that are automatically tracked and
					reported by our systems. We will not pay commissions if someone says they
					purchased or someone says they entered a referral code if it was not
					tracked by our system. We can only pay commissions on business generated
					through properly formatted special links that were automatically tracked by
					our systems.
				</p>
				<p>
					We reserve the right to disqualify commissions earned through fraudulent,
					illegal, or overly aggressive, questionable sales or marketing methods.
				</p>
				<p>
					Payments only begin once you’ve earned more than $199 in affiliate income.
					If your affiliate account never crosses the $100 threshold, your
					commissions will not be realized or paid. We are only responsible for
					paying accounts that have crossed the $100 threshold.
				</p>
				<p>
					As of Febuary 18th 2021, You will receive a $200 credit for each new
					Strongside subscriber. Once your account accumulates $100 in credit, we
					will schedule your next payout. Payouts will be provided within 60 days of
					being credited. Payout Amounts are subject to change.
				</p>
				<p>You are not credited for incomplete transactions.</p>
				<p>
					You are not credited for purchases made through 3rd party sites. Example:
					if you refer a friend to Strongside but they make their purchase through an
					offer we have on a secondary site.
				</p>
				<h4>Payment schedule</h4>
				<p>
					As long as your current affiliate earning are over $100, your payments will
					be sent out within 60 days of the credit being tracked. If you haven’t
					earned $199 since your last payment, we’ll pay you the following month
					after you’ve crossed the threshold.
				</p>
				<h4>Customer definition</h4>
				<p>
					Customers who buy products through this Program will be deemed to be our
					customers. Accordingly, all of our rules, policies, and operating
					procedures concerning customer orders, customer service, and product sales
					will apply to those customers. We may change our policies and operating
					procedures at any time. For example, we will determine the prices to be
					charged for products sold under this Program in accordance with our own
					pricing policies. Product prices and availability may vary from time to
					time. Because price changes may affect Products that you have listed on
					your site, you should not display product prices on your site. We will use
					commercially reasonable efforts to present accurate information, but we
					cannot guarantee the availability or price of any particular product.
				</p>
				<h4>Your responsibilities</h4>
				<p>
					You will be solely responsible for the development, operation, and
					maintenance of your site and for all materials that appear on your site.
					For example, you will be solely responsible for:
				</p>
				<p>– The technical operation of your site and all related equipment</p>
				<p>
					– Ensuring the display of Special Links on your site does not violate any
					agreement between you and any third party (including without limitation any
					restrictions or requirements placed on you by a third party that hosts your
					site)
				</p>
				<p>
					– The accuracy, truth, and appropriateness of materials posted on your site
					(including, among other things, all Product-related materials and any
					information you include within or associate with Special Links)
				</p>
				<p>
					– Ensuring that materials posted on your site do not violate or infringe
					upon the rights of any third party (including, for example, copyrights,
					trademarks, privacy, or other personal or proprietary rights)
				</p>
				<p>
					– Ensuring that materials posted on your site are not libelous or otherwise
					illegal
				</p>
				<p>
					– Ensuring that your site accurately and adequately discloses, either
					through a privacy policy or otherwise, how you collect, use, store, and
					disclose data collected from visitors, including, where applicable, that
					third parties (including advertisers) may serve content and/or
					advertisements and collect information directly from visitors and may place
					or recognize cookies on visitors’ browsers.
				</p>
				<h4>Compliance with Laws</h4>
				<p>
					As a condition to your participation in the Program, you agree that while
					you are a Program participant you will comply with all laws, ordinances,
					rules, regulations, orders, licenses, permits, judgments, decisions or
					other requirements of any governmental authority that has jurisdiction over
					you, whether those laws, etc. are now in effect or later come into effect
					during the time you are a Program participant. Without limiting the
					foregoing obligation, you agree that as a condition of your participation
					in the Program you will comply with all applicable laws (federal, state or
					otherwise) that govern marketing email, including without limitation, the
					CAN-SPAM Act of 2003 and all other anti-spam laws.
				</p>
				<h4>Term of the Agreement and Program</h4>
				<p>
					The term of this Agreement will begin upon our acceptance of your Program
					application and will end when terminated by either party. Either you or we
					may terminate this Agreement at any time, with or without cause, by giving
					the other party written notice of termination. Upon the termination of this
					Agreement for any reason, you will immediately cease use of, and remove
					from your site, all links to Strongside.app or subdomains, and all of our
					trademarks, trade dress, and logos, and all other materials provided by or
					on behalf of us to you pursuant hereto or in connection with the Program.
					Strongside reserves the right to end the Program at any time. Upon program
					termination, Strongside will pay any outstanding earnings accrued above
					$199.
				</p>
				<h4>Termination</h4>
				<p>
					Strongside, in its sole discretion, has the right to suspend or terminate
					your account and refuse any and all current or future use of the Program,
					or any other Strongside service, for any reason at any time. Such
					termination of the Service will result in the deactivation or deletion of
					your Account or your access to your Account, and the forfeiture and
					relinquishment of all potential or to-be-paid commissions in your Account
					if they were earned through fraudulent, illegal, or overly aggressive,
					questionable sales or marketing methods. Strongside reserves the right to
					refuse service to anyone for any reason at any time.
				</p>
				<h4>Relationship of Parties</h4>
				<p>
					You and we are independent contractors, and nothing in this Agreement will
					create any partnership, joint venture, agency, franchise, sales
					representative, or employment relationship between the parties. You will
					have no authority to make or accept any offers or representations on our
					behalf. You will not make any statement, whether on your site or otherwise,
					that reasonably would contradict anything in this Section.
				</p>
				<h4>Limitations of Liability</h4>
				<p>
					We will not be liable for indirect, special, or consequential damages (or
					any loss of revenue, profits, or data) arising in connection with this
					Agreement or the Program, even if we have been advised of the possibility
					of such damages. Further, our aggregate liability arising with respect to
					this Agreement and the Program will not exceed the total referral fees paid
					or payable to you under this Agreement.
				</p>
				<h4>Disclaimers</h4>
				<p>
					We make no express or implied warranties or representations with respect to
					the Program or any products sold through the Program (including, without
					limitation, warranties of fitness, merchantability, non-infringement, or
					any implied warranties arising out of a course of performance, dealing, or
					trade usage). In addition, we make no representation that the operation of
					the Strongside will be uninterrupted or error-free, and we will not be
					liable for the consequences of any interruptions or errors.
				</p>
				<h4>Independent Investigation</h4>
				<p>
					YOU ACKNOWLEDGE THAT YOU HAVE READ THIS AGREEMENT AND AGREE TO ALL ITS
					TERMS AND CONDITIONS. YOU UNDERSTAND THAT WE MAY AT ANY TIME (DIRECTLY OR
					INDIRECTLY) SOLICIT CUSTOMER REFERRALS ON TERMS THAT MAY DIFFER FROM THOSE
					CONTAINED IN THIS AGREEMENT OR OPERATE WEB SITES THAT ARE SIMILAR TO OR
					COMPETE WITH YOUR WEB SITE. YOU HAVE INDEPENDENTLY EVALUATED THE
					DESIRABILITY OF PARTICIPATING IN THE PROGRAM AND ARE NOT RELYING ON ANY
					REPRESENTATION, GUARANTEE, OR STATEMENT OTHER THAN AS SET FORTH IN THIS
					AGREEMENT.
				</p>
				<h4>Arbitration</h4>
				<p>
					Any dispute relating in any way to this Agreement (including any actual or
					alleged breach hereof), any transactions or activities under this Agreement
					or your relationship with us or any of our affiliates shall be submitted to
					confidential arbitration, except that, to the extent you have in any manner
					violated or threatened to violate our intellectual property rights, we may
					seek injunctive or other appropriate relief in any state or federal court
					(and you consent to non-exclusive jurisdiction and venue in such courts) or
					any other court of competent jurisdiction. Arbitration under this agreement
					shall be conducted under the rules then prevailing of the American
					Arbitration Association. The arbitrator’s award shall be binding and may be
					entered as a judgment in any court of competent jurisdiction. To the
					fullest extent permitted by applicable law, no arbitration under this
					Agreement shall be joined to an arbitration involving any other party
					subject to this Agreement, whether through class arbitration proceedings or
					otherwise.
				</p>
				<h4>Miscellaneous</h4>
				<p>
					This Agreement will be governed by the laws of The United States, without
					reference to rules governing choice of laws. You may not assign this
					Agreement, by operation of law or otherwise, without our prior written
					consent. Subject to that restriction, this Agreement will be binding on,
					inure to the benefit of, and be enforceable against the parties and their
					respective successors and assigns. Our failure to enforce your strict
					performance of any provision of this Agreement will not constitute a waiver
					of our right to subsequently enforce such provision or any other provision
					of this Agreement.
				</p>
				<p>
					The failure of Strongside to exercise or enforce any right or provision of
					the Terms of Service shall not constitute a waiver of such right or
					provision. The Terms of Service constitutes the entire agreement between
					you and Strongside and govern your use of the Service, superseding any
					prior agreements between you and Strongside (including, but not limited to,
					any prior versions of the Terms of Service).
				</p>
			</div>
		</div>
	</div>
</div>

<div class="footer-sec" id="contact">
	<div class="container">
		<div class="row">
			<div class="col-lg-4 col-md-6 col-sm-12">
				<div class="footer-grid">
					<h1 class="title">QUICK LINKS</h1>
					<ul>
						<li><a href="#">HOME</a></li>
						<li><a href="#features">FEATURES</a></li>
						<li><a href="#prices">PRICE</a></li>
						<li><a href="#contact">CONTACT</a></li>
						<li><a href="#">CAREERS</a></li>
						<li><a href="/offense-public/free-playbook-register">Request Playbook Link</a></li>
					</ul>
				</div>
			</div>
			<div class="col-lg-4 col-md-6 col-sm-12">
				<div class="footer-grid">
					<h1 class="title">POLICIES</h1>
					<ul>
						<li><a href="/terms-condition">Terms & Conditions</a></li>
						<li><a href="/privacy-policy">Privacy Policy</a></li>
						<li><a href="#">Cookie Policy</a></li>
						<li><a href="/affiliate">Affiliate Program</a></li>
					</ul>
				</div>
			</div>
			<div class="col-lg-4 col-md-6 col-sm-12">
				<div class="footer-grid">
					<h1 class="title">CONTACT US</h1>
					<form>
						<div class="form-grid">
							<div class="form-group">
								<input type="text" class="foot-form-control" placeholder="example@example.com"
									required />
							</div>
							<div class="form-group">
								<textarea name="" class="foot-form-control" required></textarea>
							</div>
							<div class="form-group">
								<input type="submit" class="input-btn" value="SEND" />
							</div>
						</div>
					</form>
				</div>
			</div>
			<div class="col-lg-12">
				<p class="my-3 text-white">© Strongside 2020 All Rights Reserved.</p>
			</div>
		</div>
	</div>
</div>