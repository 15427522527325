import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { NgbModal, NgbTabset } from '@ng-bootstrap/ng-bootstrap';
import { UserAccess } from '../data/models/auth/session-user';
import { ActivatedRoute, Router } from '@angular/router';
import { FootballMindService } from '../data/services/global/football-mind.service';
import { AuthenticationService } from '../data/services/auth/authentication.service';
import { FilesViewModalComponent } from '../shared/components/modal/files-view-modal/files-view-modal.component';
import { LoaderService } from '../shared/services/loader.service';

@Component({
  selector: 'app-public-football-mind-profile',
  templateUrl: './public-football-mind-profile.component.html',
  styleUrls: ['./public-football-mind-profile.component.css']
})
export class PublicFootballMindProfileComponent implements OnInit {
  user: any;
  profileData: any;
  playsVideos: any[] = [];
  conceptsVideos: any[] = [];
  formationVideos: any[] = [];
  allVideos: any[] = [];
  playsImages: any[] = [];
  conceptsImages: any[] = [];
  formationImages: any[] = [];
  allImages: any[] = [];
  playsVideoLink: any;
  conceptsVideoLink: any;
  formationVideoLink: any;
  allVideoLink: any;
  someAllVideoLink: any;
  someplaysVideoLink: any;
  someconceptsVideoLink: any;
  someformationVideoLink: any;
  @ViewChild('videolinkcontent', { static: true })
  videolinkcontent!: ElementRef<HTMLElement>
  videolinkName: any;
  allMedia: any[] = [];
  allLink: any[] = [];
  @ViewChild('ngbTabset') ngbTabset?: NgbTabset;
  selectTabPlays: boolean = false;
  selectTabConcepts: boolean = false;
  selectTabFormation: boolean = false;
  selectTabAll: boolean = false;
  linkType: any;
  randomFootballMinds: any;
  modalData: any;
  @ViewChild("foootballMindVideoViewContent", { static: true })
  foootballMindVideoViewContent!: ElementRef<HTMLElement>;
  userAccess: UserAccess = {};
  allViewMind: boolean = false;
  playsViewMind: boolean = false;
  conceptViewMind: boolean = false;
  formationViewMind: boolean = false;
  showDefaultProfile: boolean = false;
  //@ViewChild('videoPlayer') videoPlayer!: ElementRef;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private loaderService: LoaderService,
    public footballMindService: FootballMindService,
    private authenticationService: AuthenticationService,
  ) {
  }

  ngOnInit(): void {
    this.getUserAccess();
    this.user = this.route.snapshot.paramMap.get('user');
    if (this.user) {
      this.loaderService.isLoading.next(true);
      this.footballMindService.getPublicAcessProfileData(this.user).subscribe(data => {
        this.loaderService.isLoading.next(false);
        this.showDefaultProfile = true;
        this.profileData = data;
        this.playsVideos = data.playsFootballMind?.videos;
        this.conceptsVideos = data.conceptsFootballMind?.videos;
        this.formationVideos = data.formationFootballMind?.videos;
        this.allVideos = data.allFootballMind?.videos;
        this.playsImages = data.playsFootballMind?.images;
        this.conceptsImages = data.conceptsFootballMind?.images;
        this.formationImages = data.formationFootballMind?.images;
        this.allImages = data.allFootballMind?.images;
        this.playsVideoLink = data.playsFootballMind?.footballMindVideoLinks;
        this.conceptsVideoLink = data.conceptsFootballMind?.footballMindVideoLinks;
        this.formationVideoLink = data.formationFootballMind?.footballMindVideoLinks;
        this.allVideoLink = data.allFootballMind?.footballMindVideoLinks;
        this.randomFootballMinds = data.randomFootballMinds;
        this.someAllVideoLink = this.allVideoLink.slice(0, 5);
        this.someplaysVideoLink = this.playsVideoLink.slice(0, 5);
        this.someconceptsVideoLink = this.conceptsVideoLink.slice(0, 5);
        this.someformationVideoLink = this.formationVideoLink.slice(0, 5);
      });
    }
  }

  getUserAccess() {
    this.userAccess = this.authenticationService.checkUserAccess(this.router.url);
  };

  tab_change(evt: any) {
    this.selectTabAll = false;
    this.selectTabPlays = false;
    this.selectTabConcepts = false;
    this.selectTabFormation = false;
    if (evt.nextId === 'tab-selectbyid0') {
      this.selectTabAll = true;
      this.someAllVideoLink = this.someAllVideoLink.slice(0, 5);
      this.allViewMind = false;
    }
    else if (evt.nextId === 'tab-selectbyid1') {
      this.selectTabPlays = true;
      this.someplaysVideoLink = this.playsVideoLink.slice(0, 5);
      this.playsViewMind = false;
    }
    else if (evt.nextId === 'tab-selectbyid2') {
      this.selectTabConcepts = true;
      this.someconceptsVideoLink = this.conceptsVideoLink.slice(0, 5);
      this.conceptViewMind = false;
    }
    else if (evt.nextId === 'tab-selectbyid3') {
      this.selectTabFormation = true;
      this.someformationVideoLink = this.formationVideoLink.slice(0, 5);
      this.formationViewMind = false;
    }
    else {
      this.selectTabAll = true;
    }
  }

  switchNgBTab(id: string) {
    this.ngbTabset?.select(id);
  };

  view_more(type: any) {
    if (type === 'a') {
      this.someAllVideoLink = this.allVideoLink;
      this.allViewMind = true;
    }
    else if (type === 'p') {
      this.someplaysVideoLink = this.playsVideoLink;
      this.playsViewMind = true;
    }
    else if (type === 'c') {
      this.someconceptsVideoLink = this.conceptsVideoLink;
      this.conceptViewMind = true;
    }
    else if (type === 'f') {
      this.someformationVideoLink = this.formationVideoLink;
      this.formationViewMind = true;
    }
  };

  openVideoModal(data: any) {
    this.modalData = data;
    this.modalService.open(this.foootballMindVideoViewContent, { centered: true, size: "xl" });
  };

  closeModal(modal: any) {
    modal.dismiss();
  };

  image_view(data: any, isImg: boolean) {
    const modalRef = this.modalService.open(FilesViewModalComponent, { centered: true, size: 'xl' });
    let obj = {
      files: [],
      isRelated: false,
      userFullAccess: this.userAccess.full,
      isSingleFile: true,
      fileUrl: data,
      isVideo: !isImg ? true : false,
      captureShow: true,
      //modalData: { 'origin': 'Formation Stack', 'originId': this.id, 'id': data.id, 'fileSeq': data.fileSeq }
    }
    modalRef.componentInstance.fromParent = obj;
    modalRef.result.then((result) => { },
      (reason) => { }
    );
  };

  owlcarousel10 = [
    { id: 1, img: "../../../../assets/images/demo-img.png" },
    { id: 2, img: "../../../../assets/images/demo-img-2.jpg" },
    { id: 3, img: "../../../../assets/images/Stadium_Img.png" },
    { id: 4, img: "../../../../assets/images/demo-img-2.jpg" },
    { id: 5, img: "../../../../assets/images/Stadium_Img.png" },
  ];

  //Options
  owlcarousel20Options = {
    loop: true,
    margin: 10,
    items: 4,
    nav: false,
    autoWidth: true,
    dots: false,
    navText: ['<i class="fa fa-angle-double-left"></i>', '<i class="fa fa-angle-double-right"></i>'],


  };

  owlcarousel30Options = {
    loop: true,
    margin: 10,
    items: 3,
    nav: false,
    autoWidth: true,
    dots: false,
    navText: ['<i class="fa fa-angle-double-left"></i>', '<i class="fa fa-angle-double-right"></i>'],

  };

  handleVideoEnded(event: Event) {
    const videoElement: HTMLVideoElement = event.target as HTMLVideoElement;
    if (videoElement) {
      videoElement.playbackRate = 0.25;
      videoElement.play();
    }
  };

  goToLink(linkType: string) {
    if (linkType == "F" && this.profileData.facebookLink) {
      if (this.profileData.facebookLink.includes('https://www.facebook.com/')) {
        window.open(this.profileData.facebookLink, '_blank');
      }
      else {
        window.open("https://www.facebook.com/" + this.profileData.facebookLink, '_blank');
      }
    }
    else if (linkType == "I" && this.profileData.instagramLink) {
      if (this.profileData.instagramLink.includes('https://www.instagram.com/')) {
        window.open(this.profileData.instagramLink, '_blank');
      }
      else {
        window.open("https://www.instagram.com/" + this.profileData.instagramLink, '_blank');
      }
    }
    else if (linkType == "T" && this.profileData.twitterLink) {
      if (this.profileData.twitterLink.includes('https://twitter.com/')) {
        window.open(this.profileData.twitterLink, '_blank');
      }
      else {
        window.open("https://twitter.com/" + this.profileData.twitterLink, '_blank');
      }
    }
    else if (linkType == "Tok" && this.profileData.tiktokLink) {
      if (this.profileData.tiktokLink.includes('https://www.tiktok.com/')) {
        window.open(this.profileData.tiktokLink, '_blank');
      }
      else {
        window.open("https://www.tiktok.com/" + this.profileData.tiktokLink, '_blank');
      }
    }
    else if (linkType == "U" && this.profileData.youtubeLink) {
      if (this.profileData.youtubeLink.includes('https://www.youtube.com/')) {
        window.open(this.profileData.youtubeLink, '_blank');
      }
      else {
        window.open("https://www.youtube.com/" + this.profileData.youtubeLink, '_blank');
      }
    }
  };
}
