<div class="modal-header">
    <h5 class="modal-title">Strongside Admin</h5>
    <button type="button" class="close" data-dismiss="modal" (click)="close('no')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="form-row">
        <div class="col-md-12 mb-3">
            <label for="message">{{message}}</label>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="close('yes')">Confirm</button>
    <button type="button" class="btn btn-outline-dark" (click)="close('no')">Cancel</button>
</div>