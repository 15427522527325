<div class="modal-header align-items-center">
    <h5 class="modal-title">Add to Accounts</h5>
    <form [formGroup]="accountSearchForm" autocomplete="off">
        <div class="row">
            <!-- <label for="table-filtering-search" class="col-md-2 col-12 col-sm-auto col-form-label">Search:</label> -->
            <div class="col-md-12 col-12 col-sm-auto">
                <input autocomplete="off" id="table-filtering-search" placeholder="Search by Account name" class="form-control" type="text" formControlName="searchTerm"/>
            </div>
        </div>
    </form>
    <button type="button" class="close" data-dismiss="modal" (click)="closeModal('dismiss')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    
    <div class="customtableheight">
        <table class="table table-striped table-bordered">
            <thead class="thead-dark">
                <tr>
                    <th scope="col" class="col-6">Account
                    </th>
                    <th scope="col" class="col-6">
                        <form [formGroup]="accountSearchForm">
                            <input type="checkbox" id="all" name="all" formControlName="selectAllAccount">
                            <label for="all" class="ml-2 mb-0">Select All </label>
                        </form>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let account of filterAccounts; index as i">
                    <td class="col-6">{{account.name}}</td>
                    <td class="col-6"><input name="checkBox{{i}}" id="checkBox{{i}}" type="checkbox" [(ngModel)]="account.isSelect" (click)="selectAccount(account.id, i)"/></td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div class="modal-footer justify-content-start">
    <div *ngIf="addedAccounts && addedAccounts.length > 0" class="mb-2">
        <p>Added Accounts -</p>
        <span class="badge badge-secondary" *ngFor="let acc of addedAccounts">{{acc}}</span>
    </div>
    <div class="row w-100 justify-content-end">
        <button type="button" class="btn btn-primary" (click)="submit()"><i class="fa fa-copy"></i> Copy</button>
        <button type="button" class="btn btn-danger ml-2" data-dismiss="modal" (click)="closeModal('close')">Close</button>
    </div>
</div>