import { Component, OnInit } from '@angular/core';
import { NotificationService } from '../../../../../data/services/global'
import { Notification } from '../../../../../data/models/global'
import { ActivatedRoute, Router } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { Location } from '@angular/common';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {

  public openNotification: boolean = false;
  public notification: any;
  public limit = 10;
  isShowmore: boolean = false;
  declare location: Location;
  constructor(
    private notificationService: NotificationService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.getNotification();
    setInterval(() => this.getNotification(), 600000);
  }

  ngOnInit() {
  }

  toggleNotificationMobile() {
    this.openNotification = !this.openNotification;
  }

  getNotification() {
    this.isShowmore = true;
    this.notificationService.all(this.limit).subscribe(resp => {
      this.isShowmore = false;
      this.notification = resp;
    });
  };

  show_more() {
    this.limit = this.limit + 10;
    this.getNotification();
  };

  view_all() {
    this.notificationService.view_all().subscribe(resp => {
      if (resp.success) {
        this.getNotification();
      }
    });
  };

  view_notification(item: any) {
    if (item.isOpen) {
      if (item.eventUrl) {
        this.router.navigate([item.eventUrl, { id: item.originRefId }]);
      }
    }
    else {
      this.notificationService.view(item.id).subscribe(resp => {
        if (item.eventUrl) {
          let path = this.router.url.split(";")[0];
          if (path === item.eventUrl) {
            this.router.navigate([item.eventUrl, { id: item.originRefId }])
              .then(() => {
                window.location.reload();
              });
          }
          else {
            this.router.navigate([item.eventUrl, { id: item.originRefId }])
          }
        }
      },
        error => {
          if (item.eventUrl) {
            this.router.navigate([item.eventUrl, { id: item.originRefId }]);
          }
        });
    }
  };
}
