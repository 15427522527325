<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Share {{origin}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="closeModal('dismiss')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form class="theme-form needs-validation" [class.was-validated]="!shareForm.valid" novalidate=""
        [formGroup]="shareForm">
        <div class="row">
            <div class="col-md-12">
                <label for="type">Share Type</label>
                <ng-select [items]="types" bindLabel="label" bindValue="value" formControlName="type" required=""
                    placeholder="Select Share Type" appendTo="body">
                    <ng-template ng-header-tmp>
                        Select Share Type
                    </ng-template>
                </ng-select>
                <div class="cust-invalid-feedback"
                    *ngIf="shareForm.controls.type.touched && shareForm.controls.type.errors?.required">Share Type is
                    required.</div>
            </div>
            <div class="col-md-12" *ngIf="shareForm.controls.type.value && shareForm.controls.type.value == 'C'">
                <!-- <label for="coach">Coach</label> -->
                <ng-select [items]="coaches" [multiple]="true" bindLabel="label" bindValue="value"
                    placeholder="Select Coaches" formControlName="originIds" appendTo="body">
                    <ng-template ng-header-tmp>
                        Select Coaches
                    </ng-template>
                </ng-select>
                <div class="cust-invalid-feedback"
                    *ngIf="shareForm.controls.originIds.touched && shareForm.controls.originIds.errors?.required">Coach
                    is required.</div>
            </div>
            <div class="col-md-12" *ngIf="shareForm.controls.type.value && shareForm.controls.type.value == 'P'">
                <!-- <label for="player">Player</label> -->
                <ng-select [items]="players" [multiple]="true" bindLabel="label" bindValue="value"
                    placeholder="Select Players" formControlName="originIds" appendTo="body">
                    <ng-template ng-header-tmp>
                        Select Players
                    </ng-template>
                </ng-select>
                <div class="cust-invalid-feedback"
                    *ngIf="shareForm.controls.originIds.touched && shareForm.controls.originIds.errors?.required">Player
                    is required.</div>
            </div>
            <div class="col-md-12" *ngIf="shareForm.controls.type.value && shareForm.controls.type.value == 'PG'">
                <!-- <label for="player">Position Group</label> -->
                <ng-select [items]="positionGroups" [multiple]="true" bindLabel="label" bindValue="value"
                    placeholder="Select Position group" formControlName="originIds" appendTo="body">
                    <ng-template ng-header-tmp>
                        Select Position Group
                    </ng-template>
                    <div class="cust-invalid-feedback"
                        *ngIf="shareForm.controls.originIds.touched && shareForm.controls.originIds.errors?.required">
                        Position Group is required.</div>
                </ng-select>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="submit" class="btn btn-secondary" [disabled]="!shareForm.valid" (click)="submit()">Share</button>
    <button type="button" class="btn btn-outline-danger" (click)="closeModal('dismiss')">Cancel</button>
</div>