import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AuthenticationService } from '../data/services/auth/authentication.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  public returnUrl: string = "";
  private return1: string = this.route.snapshot.queryParams.returnUrl;
  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    public cookieService: CookieService
  ) { }
  
  ngOnInit(): void {
    //set url on cookie
    var cookie_nameUrl = localStorage.getItem('isLogin')?.toString();
    const currentUser = localStorage.getItem('__qu__');
    var rememberUsr = localStorage.getItem('__qu__remeber_User__');
    if (rememberUsr) {
      const isRemeber: boolean = JSON.parse(rememberUsr);
      if (isRemeber == true && currentUser) {
        const token: string = JSON.parse(currentUser).token;
        if (token) {
          let email = JSON.parse(currentUser).userName;
          let userType = JSON.parse(currentUser).userType
          this.authenticationService.remeberLogin(userType, email)
            .pipe(first())
            .subscribe(
              user => {
                this.returnUrl = this.route.snapshot.queryParams.returnUrl;
                if (this.returnUrl === undefined) {
                  this.router.navigate(["/dashboard/default"]);
                }
                else {
                 
                  if(cookie_nameUrl=='Yes')
                  {
                    this.router.navigate(["/dashboard/default"]);
                  
                  }
                  else
                  {
                    
                    this.router.navigate(["/"]);
                  }
                  
                }
              },
              error => {
              });
        }

      }
    }
    else if (cookie_nameUrl == 'Yes') {
      this.router.navigate(["/auth/login"]);
    }
    else {
      this.router.navigate(["/"]);
    }
  }
}
