import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ConceptService, FormationService, FormationStackService, OffensePlayService } from 'src/app/data/services/offense';

@Component({
  selector: 'app-offense-update-required-modal',
  templateUrl: './offense-update-required-modal.html',
  styleUrls: ['./offense-update-required-modal.css']
})
export class UpdateModalComponent implements OnInit {
  @Input() fromParent: any;
  detailForm: FormGroup;
  text: string = "";

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private formationStackService: FormationStackService,
    private formationService: FormationService,
    private conceptService: ConceptService,
    private playService: OffensePlayService,
    public toster: ToastrService,
  ) {
    this.detailForm = this.formBuilder.group({
      id: [],
      updateRequiredMessage: [null, Validators.required],
    });
  }

  ngOnInit(): void {
    switch(this.fromParent.origin){
      case 'FS':
      this.text = "This Formation Set update require from Desktop";
      break;
      case 'F': 
      this.text = "This Formations update require from Desktop";
      break;
      case 'C': 
      this.text = "This Concepts update require from Desktop";
      break;
      case 'P': 
      this.text = "This Plays update require from Desktop";
      break;
    }
  }

  closeModal(sendData: any) {
    this.activeModal.close(sendData);
  };

  submit() {
    if (this.detailForm.invalid) {
      return;
    }
    this.detailForm.controls['id'].setValue(this.fromParent.originRefId);
    const dataToSave = this.detailForm.value;
    switch(this.fromParent.origin){
      case 'FS':
        this.formationStackService.updateReuqiredMessage(this.fromParent.originRefId, dataToSave.updateRequiredMessage).subscribe((result: any) => {
          if (result.success === true) {
            this.toster.success(result.responseMsg, 'Success', {
              progressBar: true,
              positionClass: 'toast-top-right'
            });
            this.closeModal('success');
          } else {
            this.toster.error(result.responseMsg, 'Error', {
              progressBar: true,
              positionClass: 'toast-top-right'
            });
          }
        })
      break;
      case 'F': 
      this.formationService.updateReuqiredMessage(this.fromParent.originRefId, dataToSave.updateRequiredMessage).subscribe((result: any) => {
        if (result.success === true) {
          this.toster.success(result.responseMsg, 'Success', {
            progressBar: true,
            positionClass: 'toast-top-right'
          });
          this.closeModal('success');
        } else {
          this.toster.error(result.responseMsg, 'Error', {
            progressBar: true,
            positionClass: 'toast-top-right'
          });
        }
      })
      break;
      case 'C': 
      this.conceptService.updateReuqiredMessage(this.fromParent.originRefId, dataToSave.updateRequiredMessage).subscribe((result: any) => {
        if (result.success === true) {
          this.toster.success(result.responseMsg, 'Success', {
            progressBar: true,
            positionClass: 'toast-top-right'
          });
          this.closeModal('success');
        } else {
          this.toster.error(result.responseMsg, 'Error', {
            progressBar: true,
            positionClass: 'toast-top-right'
          });
        }
      })
      break;
      case 'P': 
      this.playService.updateReuqiredMessage(this.fromParent.originRefId, dataToSave.updateRequiredMessage).subscribe((result: any) => {
        if (result.success === true) {
          this.toster.success(result.responseMsg, 'Success', {
            progressBar: true,
            positionClass: 'toast-top-right'
          });
          this.closeModal('success');
        } else {
          this.toster.error(result.responseMsg, 'Error', {
            progressBar: true,
            positionClass: 'toast-top-right'
          });
        }
      })
      break;
    }
  }
}
