// declare var google: any;
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { AuthenticationService } from "src/app/data/services/auth/authentication.service";
import { first } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
import { CookieService } from 'ngx-cookie-service';
import { ReportingIssueComponent } from "src/app/reporting-issue/reporting-issue.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
declare const require: any;
const Swal = require('sweetalert2');

@Component({
    selector: "app-login-validation",
    templateUrl: "./validation.component.html",
    styleUrls: ["./validation.component.scss"],
})
export class ValidationComponent implements OnInit {
    public loginForm: FormGroup;
    public show: boolean = false;
    public returnUrl: string = "";
    redirectUrl: any;
    google: any
    spinnerShow: boolean = true;
    constructor(
        private fb: FormBuilder,
        public router: Router,
        private route: ActivatedRoute,
        private authenticationService: AuthenticationService,
        public toster: ToastrService,
        public cookieService: CookieService,
        private activatedRoute: ActivatedRoute,
        private modalService: NgbModal,
        // private authService: SocialAuthService
    ) {
        this.loginForm = fb.group({
            email: ["", Validators.required],
            password: ["", Validators.required],
            isRemeber: []
        });

        this.google = (<any>window).google;
    }
    isLogin: string = 'Yes';
    ngOnInit() {
        this.google.accounts.id.initialize({
            //client_id: '333367936793-t45nm5b39se20qdrs4o0v57qtmhkcm1p.apps.googleusercontent.com',
            client_id: '1038458657773-f155jcvi5n1haro62luli4sg1211brk3.apps.googleusercontent.com',
            callback: (resp: any) => this.handleLogin(resp)
        });

        this.google.accounts.id.renderButton(
            document.getElementById("buttonDiv"),
            {
                theme: "filled_black",
                size: "medium",
                shape: 'rectangle',

            }  // customization attributes
        );


    }

    showPassword() {
        this.show = !this.show;
    }

    login() {
        if (this.loginForm.invalid) {
            return;
        }
        this.spinnerShow = false;
        this.authenticationService.login(this.loginForm.value["email"], this.loginForm.value["password"]).pipe(first()).subscribe(user => {
            this.spinnerShow = true;
            if (user && user.userType === 'P') {
                this.loginForm.controls[`isRemeber`].setValue(true);
            }
            if (this.loginForm.controls[`isRemeber`].value && this.loginForm.controls[`isRemeber`].value === true) {
                localStorage.setItem('__qu__remeber_User__', JSON.stringify(this.loginForm.controls[`isRemeber`].value));
            }
            this.returnUrl = this.route.snapshot.queryParams.returnUrl;
            if (this.returnUrl === undefined) {
                localStorage.setItem('isLogin', 'Yes');
                this.router.navigate(["/dashboard/default"]);
            }
            else {
                this.router.navigate([this.returnUrl]);
            }
        },
            error => {
                this.spinnerShow = true;
                this.toster.error(error.error);
                // "You have enter Wrong Email or Password."
            });
    };
    imgLogoclick() {

        localStorage.removeItem("isLogin");
        this.router.navigate([""]);
    }

    private decodeToken(token: string) {
        return JSON.parse(atob(token.split(".")[1]))
    }

    handleLogin(response: any) {
        if (response) {
            //decode the token
            const payload = this.decodeToken(response.credential)
            this.loginForm.controls['email'].setValue(payload.email);
            this.authenticationService.googlelogin(this.loginForm.controls['email'].value)
                .pipe(first())
                .subscribe(
                    user => {
                        if (user && user.userType === 'P') {
                            this.loginForm.controls[`isRemeber`].setValue(true);
                        }
                        if (this.loginForm.controls[`isRemeber`].value && this.loginForm.controls[`isRemeber`].value === true) {
                            localStorage.setItem('__qu__remeber_User__', JSON.stringify(this.loginForm.controls[`isRemeber`].value));
                        }
                        this.returnUrl = this.route.snapshot.queryParams.returnUrl;
                        if (this.returnUrl === undefined) {
                            localStorage.setItem('isLogin', 'Yes');
                            this.router.navigate(["/dashboard/default"]);
                        }
                        else {
                            this.router.navigate([this.returnUrl]);
                        }
                    },
                    error => {
                        //this.toster.error(error.error);
                        // "You have enter Wrong Email"
                        if (error.error.responseCode === 901) {
                            Swal.fire({
                                title: 'Your Google account is not registered',
                                text: "Do you want to Sign Up",
                                // type: 'warning',
                                showCancelButton: true,
                                confirmButtonText: 'Yes, Sign Up!'
                            }).then((result: any) => {
                                if (result.isConfirmed) {
                                    this.router.navigate(["/authentication/register", { userName: payload.email }]);
                                }
                            });
                        }
                        else {
                            this.toster.error(error.error);
                        }
                    });
            //store in session
            //navigate to home/browse
        }
    };

    goToReportingIssue() {
        this.modalService.open(ReportingIssueComponent, { centered: true });
    }
}
