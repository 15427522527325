<div class="modal-header">
    <h5 class="modal-title">* {{text}}</h5>
    <button type="button" class="close" data-dismiss="modal" (click)="closeModal('dismiss')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="detailForm" autocomplete="off" class="needs-validation">
        <div class="form-row">
            <div class="col-md-12 mb-3">
                <!-- <label class="d-block" for="chk-ani4"></label> -->
                <textarea id="exampleFormControlTextarea4" rows="3" class="form-control"
                    formControlName="updateRequiredMessage" placeholder="Enter Update Required Reason"
                    required></textarea>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="submit()">Save</button>
    <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="closeModal('close')">Close</button>
</div>