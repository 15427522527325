<!-- Step 1 -->
<section id="stepOne" *ngIf="step === 1">
    <div class="container">
        <div class="row fullHeight justify-content-center align-items-center">
            <div class="col-lg-6 col-md-8 col-12">
                <div class="logoBx">
                    <img src="assets/images/logo/login.png" alt="looginpage" title="looginpage" class="img-fluid">
                </div>
                <div class="main-player-box card">
                    <div class="card-body">
                        <div class="contentBox">
                            <p class="contenttext">Enter your phone and email to receive responses from players&nbsp;</p>
                        </div>
                        <form>
                            <div class="mb-3"><label class="form-label">Email Address :</label><input class="form-control" type="email"></div>
                            <div class="mb-3"><label class="form-label">Phone&nbsp;Number :</label><input class="form-control" type="email"></div>
                            <div class="mb-3"><a class="btn btn-primary" href="javascript:void(0);" (click)="nxtStep()">Test it out</a></div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Step 2 -->
<section id="stepTwo" *ngIf="step === 2">
    <div class="container">
        <div class="row fullHeight justify-content-center align-items-center">
            <div class="col-lg-6 col-md-8 col-12">
                <div class="logoBx">
                    <img src="assets/images/logo/login.png" alt="looginpage" title="looginpage" class="img-fluid">
                </div>
                <div class="main-player-box card">
                    <div class="card-body">
                        <div class="contentBox">
                            <p class="contenttext">Let's sent a new message</p>
                        </div>
                        <form>
                            <div class="mb-3"><label class="form-label">Phone&nbsp;Number :</label><input class="form-control" type="email"></div>
                            <div class="contentBox"></div>
                            <div class="mb-3"><a class="btn btn-primary" href="javascript:void(0);" (click)="nxtStep()">Make my message</a></div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Step 3 -->
<section id="stepThree" *ngIf="step === 3">
    <div class="container">
        <div class="row fullHeight justify-content-center align-items-center">
            <div class="col-lg-6 col-md-8 col-12">
                <div class="logoBx">
                    <img src="assets/images/logo/login.png" alt="looginpage" title="looginpage" class="img-fluid">
                </div>
                <div class="main-player-box card">
                    <div class="card-body">
                        <div class="contentBox mt-0">
                            <p class="contenttext">Let's make a message</p>
                        </div>
                        <div class="contentBox msgBox mb-4">
                            <p class="smsName">Hi <span id="firstName">John</span> ,</p>
                            <p class="smsBodytext">I wanted to invite you to our junior camp.</p>
                            <p class="smsBodytext">Are you interested ?</p>
                            <br/>
                            <p class="smsBodytext m-0"> <span id="cTitle">Coach, </span> <span id="cLname">Kasey Villanova</span></p>
                        </div>
                        <form>
                            <div class="mb-3 cat action">
                                <label>
                                   <input type="checkbox" value="1" onclick="chngFname()">
                                   <span>Player First Name</span>
                                </label>
                            </div>
                            <div class="mb-3 cat action">
                                <label>
                                   <input type="checkbox" value="1" onclick="chngTitle()">
                                   <span>My Title</span>
                                </label>
                            </div>
                            <div class="mb-3 cat action">
                                <label>
                                   <input type="checkbox" value="1" onclick="chngCLname()">
                                   <span>My Name</span>
                                </label>
                            </div>
                            <div class="mb-3"><a class="btn btn-primary" href="javascript:void(0);" (click)="nxtStep()">Send</a></div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Step 4 -->
<section id="stepFour" *ngIf="step === 4">
    <div class="container">
        <div class="row fullHeight justify-content-center align-items-center">
            <div class="col-lg-6 col-md-8 col-12">
                <div class="logoBx">
                    <img src="assets/images/logo/login.png" alt="looginpage" title="looginpage" class="img-fluid">
                </div>
                <div class="main-player-box">
                    <div class="chat-box">
                        <div class="chat-box-header">
                            John Doe
                            <span class="chat-box-toggle"><a (click)="preStep()"><i class="fas fa-times"></i></a></span>
                        </div>
                        <div class="chat-box-body">
                            <div class="chat-box-overlay">
                            </div>
                            <div class="chat-logs">
                                <div id="cm-msg-2" class="chat-msg self">
                                    <div class="cm-msg-text">
                                        <p class="smsName">Hi John,&nbsp;</p>
                                        <p class="smsBodytext">I wanted to invite you to our junior camp.</p>
                                        <p class="smsBodytext">Are you interested ?</p>
                                        <p class="smsBodytext">Coach, Kasey Villanova</p>
                                    </div>
                                </div>
                                <div class="chat-msg user">
                                    <div class="cm-msg-text">Yes </div>
                                </div>
                            </div><!--chat-log -->
                        </div>
                        <div class="chat-input">
                            <form>
                                <input type="text" id="chat-input" placeholder="Type Message here" />
                                <button type="submit" class="chat-submit" id="chat-submit"><i class="far fa-paper-plane"></i></button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>