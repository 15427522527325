import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthenticationService } from '../../data/services/auth/authentication.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
    ) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const currentUser = this.authenticationService.currentUserValue;
        if (currentUser) {
            if (currentUser.token !== '') {
                // authorised so return true
                return true;
            }
        }

        const expectedPattern  = /\/global\/(attendance-event-player)\//;
        const currentUrl = state.url;

        if (currentUrl.match(expectedPattern)) {
            return this.router.navigate(['auth/player-attendance-login'], { queryParams: { returnUrl: state.url } });
        }

        this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }

}
