<header class="main-header">
	<div class="container">
		<div class="row">
			<div class="col-12 p-0">
				<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
					<a class="navbar-brand" href="index.html"><img src="../../assets/images/home-logo-ss.png"
							alt="STRONGSIDE" height="40" title="STRONGSIDE" /></a>
					<button class="navbar-toggler" type="button" data-toggle="collapse"
						data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
						aria-expanded="false" aria-label="Toggle navigation">
						<span class="navbar-toggler-icon"></span>
					</button>
					<div class="collapse navbar-collapse" id="navbarSupportedContent">
						<ul class="navbar-nav ml-auto">
							<li class="nav-item">
								<a class="nav-link" href="#">HOME</a>
							</li>
							<!-- <li class="nav-item">
								<a class="nav-link" href="#prices">PRICE</a>
							</li> -->
							<li class="nav-item">
								<a class="nav-link" href="/docs">FAQ</a>
							</li>
							<li class="nav-item">
								<a class="nav-link" href="/about">ABOUT US</a>
							</li>
							<li class="nav-item">
								<a class="nav-link" href="/careers">CAREERS</a>
							</li>
						</ul>
						<ul class="navbar-nav ml-auto">
							<div class="btn-grid m-0">
								<a href="authentication/register" class="primary-link-btn" title="Start">Sign Up</a>
								&nbsp;
								<a href="auth/login" class="default-link-btn" title="Sign In">sign in</a>
							</div>
						</ul>
					</div>
				</nav>
			</div>
		</div>
	</div>
</header>

<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
	aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered" role="document">
		<div class="modal-content">
			<div class="modal-header" style="background: #1d4a45; border-bottom: none">
				<h5 class="modal-title" id="exampleModalLongTitle" style="color: #08eaba">
					SIGN UP FOR AFFILIATE PROGRAM
				</h5>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close" style="color: #08eaba">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body" style="background: #021220">
				<form>
					<div class="form-grid">
						<div class="form-group">
							<div class="row">
								<div class="col">
									<input type="text" class="foot-form-control" placeholder="First Name" required />
								</div>
								<div class="col">
									<input type="text" class="foot-form-control" placeholder="Last Name" required />
								</div>
							</div>
						</div>
						<div class="form-group">
							<input type="text" class="foot-form-control" placeholder="Email Id" required />
						</div>
						<div class="form-group">
							<input type="text" class="foot-form-control" placeholder="Contact No." required />
						</div>
						<div class="form-group">
							<textarea name="" class="foot-form-control" required placeholder="Address"></textarea>
						</div>
						<div class="form-group text-center">
							<input type="submit" class="input-btn" value="SIGN UP" />
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>

<div class="page-main-banner-section" id="home" style="position: relative;">
	<div class="container-fluid">
		<div class="row align-items-center">
			<div class="col-lg-6">
				<div class="banner-information">
					<h1 class="wow fadeInDown" data-wow-delay="1.8s">
						<span>STRONG</span>SIDE
					</h1>
					<h3 class="wow fadeIn text-right" data-wow-delay="1.8s">
						Our Team Values <b>Improving</b> Constantly
					</h3>
					<h3 class="wow fadeIn text-right" data-wow-delay="3.8s">
						Culture is bigger than one player, coach, or team
					</h3>
					<h3 class="wow fadeIn text-right" data-wow-delay="4.9s">
						We needed to <b>Improve</b> our tools
					</h3>
					<h3 class="wow fadeIn text-right" data-wow-delay="6.5s">
						If You're Always Getting Better
					</h3>
					<h3 class="wow fadeIn text-right" data-wow-delay="7.5s">
						Then You're Our Culture Too
					</h3>

					<h1 class="wow fadeInDown" data-wow-delay="1.8s">
						Welcome to <span> Our Football Program</span>
					</h1>
					<a href="Javascript:void(0);" title="Download Windows Application" class="wow fadeIn"
						data-wow-delay="0.1s"><i class="fab fa-windows"></i></a>
					<a href="Javascript:void(0);" title="Download For MacOS" class="wow fadeIn" data-wow-delay="0.1s"><i
							class="fab fa-apple"></i></a>
					<div class="btn-grid">
						<a href="authentication/register" class="primary-link-btn wow fadeIn" title="Get Better"
							data-wow-delay="1.8s">Sign Up</a>
						<a href="auth/login" class="default-link-btn wow fadeIn" title="Sign In"
							data-wow-delay="2.8s">sign in</a>
					</div>
				</div>
			</div>
			<div class="col-lg-6 p-0">
				<img src="../../assets/images/banner-img-3.png" alt="strongside" class="img-fluid in-mob-none"
					title="strongside" />
			</div>
			<div style="width: 50%;height: 400px;position: absolute;bottom: 17%;right: 7%;">
				<iframe src="https://demo.arcade.software/IHqSttSorYflinH28WO4/?embed" frameborder="0"
					webkitallowfullscreen mozallowfullscreen allowfullscreen
					style="width: 100%;height: 100%;overflow: hidden;" scrolling="no"></iframe>
			</div>
		</div>
	</div>
</div>
<div class="rewarder-sec">
	<div class="container">
		<div class="row justify-content-center">
			<div class="col">
				<div class="site-main-title">
					<h1 class="primary-title">Our Customer Stories</h1>
				</div>
			</div>
		</div>
	</div>
	<div id="testimonoalSlider" class="carousel slide" data-ride="carousel">
		<ul class="carousel-indicators">
			<li data-target="#testimonoalSlider" data-slide-to="0" class="active"></li>
			<li data-target="#testimonoalSlider" data-slide-to="1"></li>
			<li data-target="#testimonoalSlider" data-slide-to="2"></li>
		</ul>
		<div class="carousel-inner">
			<div class="carousel-item active">
				<div class="container">
					<div class="row justify-content-center">
						<div class="col">
							<img src="../../assets/images/Michael Acosta-01.svg" alt="Alternate Text"
								title="Alternate Text" />
						</div>
					</div>
				</div>
			</div>
			<div class="carousel-item">
				<div class="container">
					<div class="row justify-content-center">
						<div class="col">
							<img src="../../assets/images/Nigel Hadley.svg" alt="Alternate Text"
								title="Alternate Text" />
						</div>
					</div>
				</div>
			</div>
			<div class="carousel-item">
				<div class="container">
					<div class="row justify-content-center">
						<div class="col">
							<img src="../../assets/images/Shawn Yohn-01.svg" alt="Alternate Text"
								title="Alternate Text" />
						</div>
					</div>
				</div>
			</div>
		</div>
		<button class="carousel-control-prev" href="#testimonoalSlider" role="button" data-slide="prev">
			<span class="carousel-control-prev-icon" aria-hidden="true"></span>
			<span class="sr-only">Previous</span>
		</button>
		<button class="carousel-control-next" href="#testimonoalSlider" role="button" data-slide="next">
			<span class="carousel-control-next-icon" aria-hidden="true"></span>
			<span class="sr-only">Next</span>
		</button>
	</div>
</div>
<div class="features-wraper" id="features">
	<div class="container">
		<div class="row justify-content-center">
			<div class="col">
				<div class="site-main-title">
					<h1 class="primary-title">StrongSide</h1>
					<h1 class="default-title">FEATURES</h1>
				</div>
			</div>
		</div>
		<div class="row justify-content-center">
			<div class="col-lg-4 col-md-6 col-sm-12">
				<div class="features-grid wow fadeInDown" data-wow-delay="0.3s">
					<img class="img-fluid" src="../../assets/images/screen4.jpg" alt="MANAGE YOUR TEAM"
						title="MANAGE YOUR TEAM" />
					<h3 class="title">Quality Control</h3>
					<h4>Stop Guessing</h4>
					<p>
						See What Your Team Does, Development, Learning, Reports, Time Invested.
					</p>
				</div>
			</div>
			<div class="col-lg-4 col-md-6 col-sm-12">
				<div class="features-grid wow fadeInDown" data-wow-delay="0.3s">
					<img class="img-fluid" src="../../assets/images/formation2.jpg" alt="COMPLETE TACTICAL CONTROL"
						title="COMPLETE TACTICAL CONTROL" />
					<h3 class="title">FieldVision</h3>
					<h4>Diagram The Game, Not Lines</h4>
					<p>
						We noticed our playbook was built for a different game. A game played on a
						45 foot wide field. And we couldn't find anything better. So we fixed the
						scale. Then turned it into 3D. And Added every perspective. And so much
						more.
					</p>
				</div>
			</div>
			<div class="col-lg-4 col-md-6 col-sm-12">
				<div class="features-grid wow fadeInDown" data-wow-delay="0.3s">
					<img class="img-fluid" src="../../assets/images/Formation-Stack.jpg" alt="MANAGE FORMATION"
						title="MANAGE FORMATION" />
					<h3 class="title">Playbook Management</h3>
					<h4>3D Views in Web</h4>
					<p>
						Everything in your playbook - fast. We built playbooks like coaches think.
						So those plays you know you have and you didn't have time to think of. See
						Them Now
					</p>
				</div>
			</div>
			<div class="col-lg-4 col-md-6 col-sm-12">
				<div class="features-grid wow fadeInUp" data-wow-delay="0.5s">
					<img class="img-fluid" src="../../assets/images/Question.jpg" alt="COMPLETE TACTICAL ANALYSIS"
						title="COMPLETE TACTICAL ANALYSIS" />
					<h3 class="title">We Asked Them</h3>
					<h4>Your team uses film for making highlights</h4>
					<p>
						That's Not why you get film
						<br />
						Turn it into something better
						<br />
						Install gameplans overnight
						<br />
						Put your chalk talk on thier phone
					</p>
				</div>
			</div>
			<div class="col-lg-4 col-md-6 col-sm-12">
				<div class="features-grid wow fadeInUp" data-wow-delay="0.5s">
					<img class="img-fluid" src="../../assets/images/Organizations.jpg" alt="FULL USER CONTROLE"
						title="FULL USER CONTROLE" />
					<h3 class="title">Build Your Roster Like A Team Would</h3>
					<p>
						Your WRs Position is not WR. So stop calling him that in your software.
						Customize positions because Your Slot Reciever might be "A" Or "H" Or
						"that fast kid". And you and I know his position isn't the same as "the
						tall one with the teeth"
					</p>
				</div>
			</div>
			<div class="col-lg-4 col-md-6 col-sm-12">
				<div class="features-grid wow fadeInUp" data-wow-delay="0.5s">
					<img class="img-fluid" src="../../assets/images/Edit-Player.jpg" alt="INDIVIDUAL PLAYER ANALYSIS"
						title="INDIVIDUAL PLAYER ANALYSIS" />
					<h3 class="title">INDIVIDUALS</h3>
					<p>
						Everything you need to know about EACH person in your program, easily.
						Parents Name isn't there. Notify Them. Need to tell a recuirter an
						athletes GPA. Know it. Need to tell all the sophmores something. Press a
						Button.
					</p>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="faq-sec" id="faqs">
	<div class="container">
		<div class="row justify-content-center">
			<div class="col">
				<div class="site-main-title">
					<h1 class="default-title">frequently asked questions</h1>
					<h3 class="text-white">or 'why constant improvement matters'</h3>
				</div>
			</div>
		</div>
		<div class="row my-5">
			<div class="col-lg-6 col-sm-12">
				<img class="img-fluid" src="../../assets/images/faq-img.png" alt="FAQS" title="FAQS" />
			</div>
			<div class="col-lg-6 col-sm-12">
				<div class="faq-grid">
					<div id="accordion">
						<h3>Lifetime Access * (asterisk)?</h3>
						<div>
							<p>
								Yeah, I don't like to see astrisks and sports mixed. This is a cheat you
								benefit from. Whatever version of Fieldvision is out when you're
								finished with us that's your version. Forever. Anything you diagrammed
								it'll be there for you. If we don't improve - you shouldn't pay for it.
							</p>
						</div>
						<h3>What if I can't afford Helmets for my team?</h3>
						<div>
							<p>
								If your school is less than 200 students then that Freshman option is
								yours free. If that's not you and you're on a tight budget then we're
								builing a pay for what you use option. Either way - message us. The
								sport improves when all of us improve.
							</p>
						</div>
						<h3>4k film</h3>
						<div>
							<p>
								There is an associated cost based on use for 4k film. Getting film to
								you is the expensive part. There is a reason web video shows you ads.
								Every football software makes more money when your teams DO NOT watch
								film. They don't improve if it got better, they pay for it.
							</p>
						</div>
						<h3>Early Access to New Features?</h3>
						<div>
							<p>
								Check out the About Us page - this was built for one team. The one I
								coach. If you're reading this you're already looking at 'old features'.
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="footer-sec" id="contact">
	<div class="container">
		<div class="row">
			<div class="col-lg-4 col-md-6 col-sm-12">
				<div class="footer-grid">
					<h1 class="title">QUICK LINKS</h1>
					<ul>
						<li><a href="#">HOME</a></li>
						<li><a href="#features">FEATURES</a></li>
						<!-- <li><a href="#prices">PRICE</a></li> -->
						<li><a href="#contact">CONTACT</a></li>
						<li><a href="#">CAREERS</a></li>
						<li><a href="/offense-public/dashboard">Request Playbook Link</a></li>
					</ul>
				</div>
			</div>
			<div class="col-lg-4 col-md-6 col-sm-12">
				<div class="footer-grid">
					<h1 class="title">POLICIES</h1>
					<ul>
						<li><a href="/terms-condition">Terms & Conditions</a></li>
						<li><a href="/privacy-policy">Privacy Policy</a></li>
						<li><a href="#">Cookie Policy</a></li>
						<li><a href="/affiliate">Affiliate Program</a></li>
					</ul>
				</div>
			</div>
			<div class="col-lg-4 col-md-6 col-sm-12">
				<div class="footer-grid">
					<h1 class="title">CONTACT US</h1>
					<form>
						<div class="form-grid">
							<div class="form-group">
								<input type="text" class="foot-form-control" placeholder="example@example.com"
									required />
							</div>
							<div class="form-group">
								<textarea name="" class="foot-form-control" required></textarea>
							</div>
							<div class="form-group">
								<input type="submit" class="input-btn" value="SEND" />
							</div>
						</div>
					</form>
				</div>
			</div>
			<div class="col-lg-12">
				<p class="my-3 text-white">© Strongside 2020 All Rights Reserved.</p>
			</div>
		</div>
	</div>
</div>