import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PlayerService } from 'src/app/data/services/team-management';

@Component({
  selector: 'app-user-modal',
  templateUrl: './user-modal.component.html',
  styles: [
    '.modal-dialog-scrollable .modal-content { overflow: visible !important; } ngb-modal-window .component-host-scrollable { overflow: visible !important; } .modal-dialog-scrollable .modal-body { overflow-y: visible !important; } .ng2-dropdown-menu { z-index: 9999 !important; }'
  ],
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./user-modal.component.css']
})
export class UserModalComponent implements OnInit {

  @Input() fromParent: any;

  public isAllSelect = false;
  players: any[] = [];
  filterPlayers: any[] = [];
  searchForm: FormGroup;

  constructor(
    public activeModal: NgbActiveModal,
    private playerService: PlayerService,
    private formBuilder: FormBuilder,
  ) {
    this.searchForm = this.formBuilder.group({
      srchText: []
    });
  }

  ngOnInit(): void {
    this.playerService.allByMedia(this.fromParent.fileSequence).subscribe(resp => {
      this.players = resp;
      this.filterPlayers = resp;

      let allSelectFilterplayer = true;
      this.filterPlayers.forEach(event => {
        if (!event.isMediaAttached) {
          allSelectFilterplayer = false;
        }
      });

      if (allSelectFilterplayer == true) {
        this.isAllSelect = true;
      } else {
        this.isAllSelect = false;
      }
    });

    this.searchForm.controls['srchText']?.valueChanges.subscribe(value => {
      if (value) {
        this.filterPlayers = this.players.filter(player => { return player.name && player.name.toLowerCase().includes(value.toLowerCase()) });
      }
      else {
        this.filterPlayers = this.players;
      }

      let allSelectFilterplayer = true;
      this.filterPlayers.forEach(event => {
        if (!event.isMediaAttached) {
          allSelectFilterplayer = false;
        }
      });

      if (allSelectFilterplayer == true) {
        this.isAllSelect = true;
      } else {
        this.isAllSelect = false;
      }
    });
  }

  checkBoxClick(id: string) {
    var mainIndex = this.players.findIndex(value => { return value.userId == id });
    if (mainIndex > -1) {
      this.players[mainIndex].isMediaAttached = !this.players[mainIndex].isMediaAttached;
    }

    let allSelectFilterplayer = true;
    this.filterPlayers.forEach(event => {
      if (!event.isMediaAttached) {
        allSelectFilterplayer = false;
      }
    });

    if (allSelectFilterplayer == true) {
      this.isAllSelect = true;
    } else {
      this.isAllSelect = false;
    }
  };

  checkAll() {
    this.isAllSelect = !this.isAllSelect;
    if (this.isAllSelect == true) {
      this.filterPlayers.forEach(event => {
        event.isMediaAttached = true;
      });
      this.players.forEach(event => {
        event.isMediaAttached = true;
      });
    }
    else {
      this.filterPlayers.forEach(event => {
        event.isMediaAttached = false;
      });
      this.players.forEach(event => {
        event.isMediaAttached = false;
      });
    }
  };

  save_player_media() {
    let obj: any = {};
    obj.fileSequence = this.fromParent.fileSequence;
    obj.referenceId = this.fromParent.referenceId;
    obj.referenceType = this.fromParent.referenceType;
    obj.isVideo = this.fromParent.isVideo;
    obj.users = this.filterPlayers;


    this.playerService.saveMedia(obj).subscribe(resp => {
      this.closeModal('ok');
    });
  };

  closeModal(sendData: any) {
    this.activeModal.close(sendData);
  };
}
