<form [formGroup]="detailForm" class="form-inline search-full" [class.open]="navServices.search">
  <div class="w-100 divFlex">
    <!-- <input class="form-control-plaintext" [(ngModel)]="text" [ngModelOptions]="{standalone: true}"
           (keyup)="searchTerm(text)" type="text" autocomplete="off" placeholder="Search.."> -->
           <button type="button" class="icnbtn" title="Search">
             <i class="fa fa-search"></i>
           </button>
           <tag-input class="form-control-plaintext mainsearch w-100"
                formControlName="tagNames"
                placeholder="Search.."
                secondaryPlaceholder="Search.."
                (onTextChange)="onTextChange($event)"
                (onAdd)="onItemAdded()"
                (onRemove)="onItemRemoved()">
                <tag-input-dropdown
                    placeholder="Search.."
                    secondaryPlaceholder="Search.."
                    [showDropdownIfEmpty]="true"
                    [autocompleteItems]="tags">
                </tag-input-dropdown>
           </tag-input>
          <app-feather-icons [icon]="'x'" class="close-search" (click)="searchToggle()"></app-feather-icons>
    <div class="Typeahead-menu srcoll" [class.is-open]="searchResult" *ngIf="globalResults?.length && globalResults.length > 1" id="search-outer">
      <!-- <div class="ProfileCard u-cf" *ngFor="let menuItem of menuItems | slice:0:8">
        <div class="ProfileCard-avatar">
          <app-feather-icons [icon]="menuItem.icon" class="header-search"></app-feather-icons>
        </div>
        <div class="ProfileCard-details">
          <div class="ProfileCard-realName">
            <a [routerLink]="menuItem?.path" class="realname" (click)="removeFix()">{{menuItem?.title}}</a>
          </div>
        </div>
      </div> -->

      <!-- box card main-->
      <div class="searchboxcrad">
        <!-- box -->
        <div class="searchinnercard" *ngFor="let data of globalResults; let i=index">
          <div class="innercardheader">
            <h5>{{data.pageName}} ({{data.globalTags.length}})</h5>
          </div>
          <div class="innercardlist">
            <ul>
              <li *ngFor="let origin of data.globalTags"><a href="{{origin.url}}">{{origin.name}}</a></li>
            </ul>
          </div>
        </div>
        <!-- /end -->
      </div>
      <!-- /end -->
    </div>
    <div class="Typeahead-menu" [class.is-open]="searchResultEmpty">
      <div class="tt-dataset tt-dataset-0">
        <div class="EmptyMessage">
          Opps!! There are no result found.
        </div>
      </div>
    </div>
  </div>
</form>