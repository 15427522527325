<div style="width: 36px;height: 36px;padding: 5px;text-align: center;border-radius: 50% !important;border: 2px solid #7c7c7c;" class="notification-box" (click)="toggleNotificationMobile()">
  <app-feather-icons [icon]="'bell'"></app-feather-icons>
  <span class="badge badge-pill badge-secondary">{{notification?.unOpenCount}}</span>
</div>
<ul class="notification-dropdown onhover-show-div" [class.active]="openNotification">
  <li style="padding: 8px 10px;background-color: #ffffff;color: #000000;letter-spacing: 0.6px;">
    <app-feather-icons [icon]="'bell'"></app-feather-icons>
    <div class="d-flex justify-content-between align-items-center">
      <h6 class="f-18 mb-0">Notifications <span style="position: static !important;" class="badge badge-secondary">{{notification?.unOpenCount}}</span></h6>
      <div class="readnoti" *ngIf="notification?.notifications && notification?.notifications.length > 0 && notification.unOpenCount > 0">
        <span style="position: static;padding: 7px;" class="badge badge-primary" (click)="view_all()">Read All</span>
      </div>
    </div>
  </li>
  <li style="padding: 0;">
    <ul class="notification_list">
      <li *ngFor="let item of notification?.notifications" [ngClass]="!item?.isOpen ? 'unread' : ''" (click)="view_notification(item)">
        <div class="d-flex justify-content-arround align-items-center">
          <!-- <i class="fa fa-circle-o mr-3 mt-1 font-primary"></i> -->
          <div class="small_imgbx">
            <img src="../../../../../assets/images/favicon.ico" alt="favorite" title="favorite" class="img-fluid">
          </div>
          <div class="noticntn">
            <p style="font-size: 11px;margin: 0px;color: #2b2b2b;opacity: 1;line-height: 16px;" title="{{item.description}}">{{item.description}}</p>
            <div class="d-flex justify-content-between align-items-center">
              <p class="m-0" style="font-size: 12px;">{{item.notifiedBy}}</p>
              <p class="m-0 unread_time"  style="font-size: 12px;"><span>{{item.notityTime}}.</span></p>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </li> 
  <div class="loader-box" *ngIf="isShowmore">
    <div class="loader-2"></div>
</div>
  <li *ngIf="notification && notification?.count > 10 && notification?.count >= limit && !isShowmore">
    <span (click)="show_more()" style="color: var(--theme-secondary);text-align:center;display:block;padding: 7px;cursor: pointer;">Show more <i class="fa fa-angle-down"></i> </span>
  </li>
  <li *ngIf="!notification || notification?.notifications.length == 0">
    <p>
      No notification found
    </p>
  </li> 
  <!-- <li>
    <p>
      <i class="fa fa-circle-o mr-3 font-primary"></i>Delivery processing <span class="pull-right">10 min.</span>
    </p>
  </li>
  <li>
    <p>
      <i class="fa fa-circle-o mr-3 font-success"></i>Order Complete<span class="pull-right">1 hr</span>
    </p>
  </li>
  <li>
    <p>
      <i class="fa fa-circle-o mr-3 font-info"></i>Tickets Generated<span class="pull-right">3 hr</span>
    </p>
  </li>
  <li>
    <p>
      <i class="fa fa-circle-o mr-3 font-danger"></i>Delivery Complete<span class="pull-right">6 hr</span>
    </p>
  </li> --> 
  <!-- <li *ngIf="notification?.notifications && notification?.notifications.length > 0 && notification.unOpenCount > 0">
    <a class="btn btn-primary btn-block" (click)="view_all()">Check all notification</a>
  </li> -->
</ul>