import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-package-payment-modal',
  templateUrl: './package-payment-modal.component.html',
  styleUrls: ['./package-payment-modal.component.scss']
})
export class PackagePaymentModalComponent implements OnInit {
  @Input() fromParent: any;
  constructor( public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }
  
  close(resp: any) {
    this.activeModal.close(resp);
  };
}
