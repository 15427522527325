import { Component, ViewEncapsulation, HostListener } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Menu, NavService } from '../../services/nav.service';
import { LayoutService } from '../../services/layout.service';
import { AuthenticationService } from 'src/app/data/services/auth/authentication.service';
import { BehaviorSubject, Observable } from 'rxjs';
declare var encodeURIComponent: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SidebarComponent {

  public iconSidebar: any;
  public menuItems!: Menu[];
  public url: any;
  public fileurl: any;

  // For Horizontal Menu
  public margin: any = 0;
  public width: any = window.innerWidth;
  public leftArrowNone: boolean = true;
  public rightArrowNone: boolean = false;

  constructor(
    private router: Router,
    public navServices: NavService,
    public layout: LayoutService,
    private authenticationService: AuthenticationService
  ) {
    this.navServices.collapseSidebar = true;
    // this.navServices.items.subscribe(menuItems => {
    //   this.menuItems = menuItems;
    //   this.router.events.subscribe((event) => {
    //     if (event instanceof NavigationEnd) {
    //       menuItems.filter(items => {
    //         if (items.path === event.url) {
    //           this.setNavActive(items);
    //         }
    //         if (!items.children) { return false; }
    //         items.children.filter(subItems => {
    //           if (subItems.path === event.url) {
    //             this.setNavActive(subItems);
    //           }
    //           if (!subItems.children) { return false; }
    //           subItems.children.filter(subSubItems => {
    //             if (subSubItems.path === event.url) {
    //               this.setNavActive(subSubItems);
    //             }
    //           });
    //         });
    //       });
    //     }
    //   });
    // });
    // localStorage.getItem('slideBar')


    var currentUser: any;
    currentUser = this.authenticationService.currentUserValue;
    if (currentUser) {
      const menus = currentUser.value ? new BehaviorSubject<Menu[]>(currentUser.value.menus)
        : new BehaviorSubject<Menu[]>(currentUser.menus);
      menus.subscribe(menuItems => {
        this.menuItems = menuItems;
        this.router.events.subscribe((event) => {
          if (event instanceof NavigationEnd) {

            var s_count = event.url ? event.url.split('/').length - 1 : 0;
            let matchUrl = s_count > 2 ? event.url.substring(0, event.url.lastIndexOf('/')) : event.url;

            menuItems?.filter(items => {
              if (items.path === matchUrl) {
                this.setNavActive(items);
              }
              if (!items.children) { return false; }
              items.children.filter(subItems => {
                if (subItems.path === matchUrl) {
                  this.setNavActive(subItems);
                }
                if (!subItems.children) { return false; }
                subItems.children.filter(subSubItems => {
                  if (subSubItems.path === matchUrl) {
                    this.setNavActive(subSubItems);
                  }
                });
              });
            });
          }
        });
      });
    }
    else {
      this.router.navigate(["/auth/login"]);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: { target: { innerWidth: number; }; }) {
    this.width = event.target.innerWidth - 500;
  }

  sidebarToggle() {
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
    //window.localStorage.setItem('slideBar', JSON.stringify(this.navServices.collapseSidebar))
  }

  // Active Nave state
  setNavActive(item: Menu) {
    this.navServices.collapseSidebar = true;
    this.menuItems.filter(menuItem => {
      if (menuItem !== item) {
        menuItem.active = false;
      }
      if (menuItem.children && menuItem.children.includes(item)) {
        menuItem.active = true;

        //added for active menu--11-01-2023
        menuItem.children.filter(submenuItems => {
          if (submenuItems.path == item.path) {
            submenuItems.active = true;
          }
          else {
            submenuItems.active = false;
          }
        });
      }
      if (menuItem.children) {
        menuItem.children.filter(submenuItems => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true;
            submenuItems.active = true;
          }
        });
      }
    });
  }

  // Click Toggle menu
  toggletNavActive(item: Menu) {
    if (!item.active) {
      this.menuItems.forEach(a => {
        if (this.menuItems.includes(item)) {
          this.navServices.collapseSidebar = false;
          a.active = false;
        }
        if (!a.children) { return false; }
        a.children.forEach(b => {
          if (a.children?.includes(item)) {
            b.active = false;
          }
        });
      });
    }
    item.active = !item.active;
  }


  // For Horizontal Menu
  scrollToLeft() {
    if (this.margin >= -this.width) {
      this.margin = 0;
      this.leftArrowNone = true;
      this.rightArrowNone = false;
    } else {
      this.margin += this.width;
      this.rightArrowNone = false;
    }
  }

  scrollToRight() {
    if (this.margin <= -3051) {
      this.margin = -3464;
      this.leftArrowNone = false;
      this.rightArrowNone = true;
    } else {
      this.margin += -this.width;
      this.leftArrowNone = false;
    }
  }

  oldProjectMove(menu: Menu) {
    if (menu.path != null && menu.path.includes('ref=')) {
      // let paramValue = menu.path.split('=')[1];
      // let url = menu.path.substring(0, menu.path.indexOf(";"));
      // this.router.navigate([url, { ref: paramValue }]);
      window.location.href = menu.path;
    }
    else {
      this.router.navigate([menu.path]);
    }
    // this.router.navigate(["/offense/playbook/detail", { ref: 'tab-5' }]);
    // if (!menu.frontendMenu) {
    //   if (menu.path) {
    //     window.location.href = menu.path;
    //   }
    // }
  };

  getDynamicRoute(childrenItem: any): any[] {
    if (childrenItem?.path === '/offense/playbook/detail;ref=tab-1') {
      return ['/offense/playbook/detail', { ref: 'tab-1' }];
    }
    if (childrenItem?.path === '/offense/playbook/detail;ref=tab-3') {
      return ['/offense/playbook/detail', { ref: 'tab-3' }];
    }
    else if (childrenItem?.path === '/offense/playbook/detail;ref=tab-4') {
      return ['/offense/playbook/detail', { ref: 'tab-4' }];
    }
    else if (childrenItem?.path === '/offense/playbook/detail;ref=tab-5') {
      return ['/offense/playbook/detail', { ref: 'tab-5' }];
    }
    else {
      return [childrenItem?.path];
    }
  };
}
