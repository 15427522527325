<!-- Loader starts-->

<div class="loader-wrapper" [class.loderhide]="!show">
  <div _ngcontent-ivf-c169="" class="loader-box">
    <div _ngcontent-ivf-c169="" class="loader-16"></div>
  </div>
  <div>{{message}}</div>
  <!-- 
  Olld Loader
  <div class="loader-index"><span></span></div>
  <svg>
    <defs></defs>
    <filter id="goo">
      <fegaussianblur in="SourceGraphic" stddeviation="11" result="blur"></fegaussianblur>
      <fecolormatrix in="blur" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="goo"> </fecolormatrix>
    </filter>
  </svg> -->

</div>
<!-- Loader ends-->