<div class="container-fluid">
    <div class="row">
        <div class="col-xl-7"
            [ngStyle]="{'background-image': 'url(assets/images/login/login-screen-img.jpg)','background-size': 'cover', 'display': 'block'}">
            <img class="bg-img-cover bg-center d-none" src="assets/images/login/login-screen-img.jpg" alt="looginpage"
                title="looginpage">
        </div>
        <div class="col-xl-5 p-0">
            <div class="login-card">
                <div>
                    <div (click)="imgLogoclick()">
                        <a class="logo text-left">
                            <img class="img-fluid for-light" src="assets/images/logo/login.png" alt="looginpage"
                                title="looginpage">
                            <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="looginpage"
                                title="looginpage">
                        </a>
                    </div>
                    <div class="login-main">
                        <form class="theme-form needs-validation" [class.was-validated]="!loginForm.valid" novalidate=""
                            [formGroup]="loginForm">
                            <h4>Sign in to account</h4>
                            <p>Enter your email or user name & password to login</p>
                            <div class="form-group">
                                <!-- <label class="col-form-label">Email Address</label> -->
                                <input class="form-control" type="text" required="" placeholder="Email or User Name"
                                    formControlName="email">
                                <div class="invalid-feedback"
                                    *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.required">
                                    Email or User Name is
                                    required.</div>
                                <!-- <div class="cust-invalid-feedback"
                                    *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.email">
                                    Invalid Email
                                    Address.</div> -->
                            </div>
                            <div class="form-group">
                                <!-- <label class="col-form-label">Password</label> -->
                                <input class="form-control" [type]="show ? 'text' : 'password'" name="login[password]"
                                    required="" placeholder="Password" formControlName="password">
                                <div class="invalid-feedback"
                                    *ngIf="loginForm.controls.password.touched && loginForm.controls.password.errors?.required">
                                    Password
                                    is required.</div>
                                <div class="show-hide" (click)="showPassword()" *ngIf="!show"><span class="show"></span>
                                </div>
                                <div class="show-hide" (click)="showPassword()" *ngIf="show"><span class="Hide"></span>
                                </div>
                            </div>
                            <div class="form-group mb-0 cr-customLoader">
                                <div class="checkbox p-0">
                                    <input id="checkbox1" type="checkbox" formControlName="isRemeber">
                                    <label class="text-muted" for="checkbox1">Remember password</label>
                                </div>
                                <a [routerLink]="'/authentication/forgot-password'" class="link">Forgot password?</a>
                                <button class="btn btn-primary btn-block" type="button" [disabled]="!loginForm.valid"
                                    (click)="login()" *ngIf="spinnerShow">Sign in</button>
                                <div class="loader-box" *ngIf="!spinnerShow">
                                    <div class="loader-34"></div>
                                </div>
                            </div>
                            <!--<h6 class="text-muted mt-4 or">Or Sign in with</h6>
                            <div class="social mt-4">
                                <div class="btn-showcase">
                                    <a class="btn btn-light" href="https://www.linkedin.com/login" target="_blank">
                                        <app-feather-icons [icon]="'linkedin'" class="txt-linkedin"></app-feather-icons>
                                        LinkedIn
                                    </a>
                                    <a class="btn btn-light" href="https://twitter.com/login?lang=en" target="_blank">
                                        <app-feather-icons [icon]="'twitter'" class="txt-twitter"></app-feather-icons>
                                        twitter
                                    </a>
                                    <a class="btn btn-light" href="https://www.facebook.com/" target="_blank">
                                        <app-feather-icons [icon]="'facebook'" class="txt-fb"></app-feather-icons>
                                        facebook
                                    </a>
                                </div>
                            </div>-->
                            <p class="horizontal-line-text c-other-login">
                                <span>or</span>

                                <!-- <button class="btn btn-block google-sign-in" (click)="signInHandeler()">
                                    <img src="assets/images/google.png" alt="google">
                                    Continue with Google
                                </button> -->
                            </p>
                            <div id="buttonDiv" class="text-center d-flex justify-content-center" style="opacity: 0.8">
                            </div>


                            <p class="mt-4 mb-0">Don't have account?
                                <a [routerLink]="'/authentication/register'" class="ml-2">Create Account</a>
                            </p>
                        </form>
                        <div class="c-form-playbook-btn">
                            <a href="/offense-public/dashboard">Go To Free Playbook <span> <i class="fa fa-book"
                                        aria-hidden="true"></i> </span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="cr-msgCircle" (click)="goToReportingIssue()" style="cursor: pointer;">
        <!-- <span>25</span> -->
        <i class="fa fa-envelope"></i>
    </div>
</div>