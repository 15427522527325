import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { PlaybookService } from 'src/app/data/services/offense';
import { LoaderService } from 'src/app/shared/services/loader.service';

@Component({
  selector: 'app-playbook-add-modal',
  templateUrl: './playbook-add-modal.component.html',
  styleUrls: ['./playbook-add-modal.component.scss']
})
export class PlaybookAddModalComponent implements OnInit {
  @Input() fromParent: any;
  accounts: any[] = [];
  addedAccounts: any[] = [];
  accountSearchForm: FormGroup;
  filterAccounts: any[] = [];
  selectedAccounts: any[] = [];
  originIds: any[] = [];
  constructor(
    public activeModal: NgbActiveModal,
    private playbookService: PlaybookService,
    private formBuilder: FormBuilder,
    public toster: ToastrService,
    private loaderService: LoaderService
  ) {
    this.accountSearchForm = this.formBuilder.group({
      searchTerm: [''],
      selectAllAccount: [false],
    });
  }

  ngOnInit(): void {
    this.getAccountMaster();

    this.accountSearchForm.controls['searchTerm']?.valueChanges.subscribe(value => {
      this.searchAccount();
    });
    this.accountSearchForm.controls['selectAllAccount']?.valueChanges.subscribe(value => {
      this.checkAll();
    });
  }

  getAccountMaster() {
    this.playbookService.getAccountMaster(this.fromParent.originRefId).subscribe(resp => {
      this.accounts = resp;
      if (resp && resp.length > 0) {
        this.addedAccounts = resp[0]['addedAccount'];
      }
      this.searchAccount();
    },
      error => {

      });
  };

  searchAccount() {
    this.filterAccounts = [];
    this.accounts.forEach(elem => {
      if (this.selectedAccounts.length > 0) {
        var index = this.selectedAccounts.findIndex(value => value == elem.id);
        if (this.accountSearchForm.controls['searchTerm'].value) {
          if (elem.name.toLocaleLowerCase().includes(this.accountSearchForm.controls['searchTerm'].value.toLocaleLowerCase())) {
            this.filterAccounts.push({ id: elem.id, name: elem.name, isSelect: index > -1 ? true : false });
          }
          else {
            if (index > -1) {
              this.selectedAccounts.splice(index, 1);
            }
          }
        }
        else {
          this.filterAccounts.push({ id: elem.id, name: elem.name, isSelect: index > -1 ? true : false });
        }
      }
      else {
        if (this.accountSearchForm.controls['searchTerm'].value) {
          if (elem.name.toLocaleLowerCase().includes(this.accountSearchForm.controls['searchTerm'].value.toLocaleLowerCase())) {
            this.filterAccounts.push({ id: elem.id, name: elem.name, isSelect: false });
          }
        }
        else {
          this.filterAccounts.push({ id: elem.id, name: elem.name, isSelect: false });
        }
      }
    });
  };

  checkAll() {
    if (this.accountSearchForm.controls['selectAllAccount'].value === false) {
      this.filterAccounts.forEach(value => { value.isSelect = false });
      this.selectedAccounts = [];
    }
    else {
      this.filterAccounts.forEach(value => { value.isSelect = true, this.selectedAccounts.push(value.id) });
    }
  };

  selectAccount(accountId: string, index: number) {
    if (this.selectedAccounts.length > 0) {
      let i = this.selectedAccounts.findIndex(elem => elem == accountId);
      if (i > -1) {
        this.selectedAccounts.splice(i, 1);
      }
      else {
        this.selectedAccounts.push(accountId);
      }
    }
    else {
      this.selectedAccounts.push(accountId);
    }
    if (this.selectedAccounts.length === this.filterAccounts.length) {
      this.accountSearchForm.controls['selectAllAccount'].setValue(true);
    }
  };

  closeModal(sendData: any) {
    this.activeModal.close(sendData);
  };

  submit() {
    if (this.selectedAccounts.length == 0) {
      this.toster.warning('Plase select atleast one Account');
      return;
    }
    this.loaderService.isLoading.next(true);
    this.loaderService.loadingMessage.next('copying...')

    let dataToSave: any = {};
    this.originIds = this.fromParent.originRefIds;
    if(typeof(this.originIds) == "string"){
      this.originIds = [];
      this.originIds.push(this.fromParent.originRefIds);
    }
    dataToSave.originIds= this.originIds;
    dataToSave.origin = this.fromParent.origin;
    dataToSave.accounts = this.selectedAccounts;
    this.playbookService.copyData(dataToSave).subscribe(resp => {
      this.loaderService.isLoading.next(false);
      if (resp && resp.success) {
        this.toster.success(resp.responseMsg);
        this.selectedAccounts = [];
        this.closeModal('copied');
      }
      else {
        this.toster.error(resp.responseMsg);
      }
    },
      error => {
        this.loaderService.isLoading.next(false);
        this.toster.error("Failed to copying");
      });
  };
}
