<section class="c-player-details-w">
    <div class="row align-items-center">
        <div class="col-lg-8 col-md-12">
            <div class="c-player-details-top">
                <div class="c-player-image">
                    <span><img src="../../../../assets/images/player-img-1.jpg" alt="Player" title="Player" class="img-fluid"></span>
                </div>
                <div class="c-player-con">
                    <h2>Dj Bordeaux</h2>
                    <ul class="c-player-d-list">
                        <li>Grad year</li>
                        <li>GPA</li>
                        <li>CO St Adv</li>
                    </ul>
                    <ul class="c-player-d-list-2">
                        <li><strong>Position : </strong> QB</li>
                        <li><strong> HT : </strong> 6-2</li>
                        <li><strong>Weight :</strong> 187</li>
                    </ul>
                    <div class="c-player-icon">
                        <ul>
                            <li>
                                <a href="#" class="twitter-icon">
                                    <i class="fa fa-twitter" aria-hidden="true"></i>
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    
                                    <img src="../../../../assets/images/hudl.png" alt="Hudl" title="Hudl" class="img-fluid">
                                </a>
                            </li>
                            <li>
                                <a href="#" class="btn btn-air-primary btn-outline-primary"><i class="fa fa-dot-circle-o" aria-hidden="true"></i> Target</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-md-12">
            <div class="c-player-info-list">
                <ul>
                    <li>
                        <span>
                            <i class="fa fa-building-o" aria-hidden="true"></i>
                        </span>
                        HS Name    
                    </li>
                    <li>
                        <span>
                            <i class="fa fa-phone" aria-hidden="true"></i>
                        </span>
                        303-598-6229
                    </li>
                    <li>
                        <span>
                            <i class="fa fa-envelope-o" aria-hidden="true"></i>
                        </span>
                        demo@gmail.com
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="c-player-video-w">
        <owl-carousel-o [options]="owlcarousel1Options">
            <ng-template carouselSlide class="item" id="img.id" *ngFor="let img of owlcarousel1">
              <img [src]="[img.img]" alt="Pixel" title="Pixel">
            </ng-template>
        </owl-carousel-o>
    </div>
    <div class="c-player-offer-w">
        <div class="row">
            <div class="col-lg-4 col-md-12">
                <div class="c-player-con-box c-measurab-w">
                    <h2>Measurab</h2>
                    <ul>
                        <li><strong> Weight:</strong> </li>

                        <li> <strong>Height:</strong></li>
                            
                        <li><strong>Squat:</strong></li>
                            
                        <li><strong>Bench:</strong></li>
                            
                        <li><strong>Fourty:</strong></li>
                        <li><strong>Vert:</strong></li>
                        <li><strong>Shuttle:</strong></li>
                        
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="c-player-con-box d-flex justify-content-center align-items-center">
                    <h2 class="text-center">Scouting Reports</h2>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="c-player-con-box">
                    <h2>Offers and Visits</h2>
                    <ul>
                        <li>
                            <h4>Charlotte</h4>
                            <p>Charlotte 49ers offer D.J. Bordeaux</p>
                        </li>
                        <li>
                            <h4>Georgia Tech</h4>
                            <p>Georgia Tech Yellow Jackets offer D.J. Bordeaux</p>
                        </li>
                        <li>
                            <h4>Virginia Tech</h4>
                            <p>Virginia Tech Hokies offer D.J. Bordeaux</p>
                        </li>
                        
                    </ul>
                    <a href="" class="btn btn-air-primary btn-outline-primary mt-3">Read More</a>
                </div>
                
            </div>
        </div>
    </div>
    <div class="c-status-w">
        <div class="row">
              <div class="col-md-12">
                    <div class="c-status-box">
                        <div class="c-status-heading">
                            <h3>Passing </h3>
                            <span>2022-2023</span>
                        </div>
                        <div class="c-status-sub-heading d-flex justify-content-between align-items-center">
                            <p>GD: Fr</p>
                            <p class="ml-auto">TEAM: Var</p>
                        </div>
                            <div class="table-responsive">
                                <table class="table  table-styling">
                                <thead>
                                    <tr>
                                        <th scope="col">GP</th>
                                        <th scope="col">C</th>
                                        <th scope="col">ATT</th>
                                        <th scope="col">YDS</th>
                                        <th scope="col">C%</th>
                                        <th scope="col">AVG</th>
                                        <th scope="col">Y/G</th>
                                        <th scope="col">C/G</th>
                                        <th scope="col">TD</th>
                                        <th scope="col">TD/G</th>
                                        <th scope="col">INT</th>
                                        <th scope="col">LNG</th>
                                        <th scope="col">QB RATE</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>9</td>
                                        <td>58</td>
                                        <td>99</td>
                                        <td>829</td>
                                        <td>.586</td>
                                        <td>14.3</td>
                                        <td>92.1</td>
                                        <td>6.4</td>
                                        <td>7</td>
                                        <td>0.8</td>
                                        <td>4</td>
                                        <td>79</td>
                                        <td>92.5</td>
                                        
                                    </tr>
                                </tbody>
                                </table>
                          </div>
                    </div>  
              </div> 
              <div class="col-lg-6 col-md-12">
                <div class="c-status-box">
                    <div class="c-status-heading">
                        <h3>Rushing </h3>
                        <span>2022-2023</span>
                    </div>
                    <div class="c-status-sub-heading d-flex justify-content-between align-items-center">
                        <p>GD: Fr</p>
                        <p class="ml-auto">TEAM: Var</p>
                    </div>
                        <div class="table-responsive">
                            <table class="table  table-styling">
                                <thead>
                                    <tr>
                                        <th scope="col">GP</th>
                                        <th scope="col">CAR</th>
                                        <th scope="col">YDS</th>
                                        <th scope="col">AVG</th>
                                        <th scope="col">Y/G</th>
                                        <th scope="col">LNG</th>
                                        <th scope="col">100+</th>
                                        <th scope="col">TD</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>9</td>
                                        <td>26</td>
                                        <td>128</td>
                                        <td>4.9</td>
                                        <td>14.2</td>
                                        <td>15</td>
                                        <td>0</td>
                                        <td>0</td>
                                        
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                </div>  
              </div> 
              <div class="col-lg-6 col-md-12">
                <div class="c-status-box">
                    <div class="c-status-heading">
                        <h3>Total Yards</h3>
                        <span>2022-2023</span>
                    </div>
                    <div class="c-status-sub-heading d-flex justify-content-between align-items-center">
                        <p>GD: Fr</p>
                        <p class="ml-auto">TEAM: Var</p>
                    </div>
                    <div class="table-responsive">
                        <table class="table  table-styling">
                            <thead>
                                <tr>
                                    <th scope="col">GP</th>
                                    <th scope="col">CAR</th>
                                    <th scope="col">YDS</th>
                                    <th scope="col">AVG</th>
                                    <th scope="col">Y/G</th>
                                    <th scope="col">LNG</th>
                                    <th scope="col">100+</th>
                                    <th scope="col">TD</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>9</td>
                                    <td>26</td>
                                    <td>128</td>
                                    <td>4.9</td>
                                    <td>14.2</td>
                                    <td>15</td>
                                    <td>0</td>
                                    <td>0</td>
                                    
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>  
              </div>  
        </div>
    </div>
    
    <div class="c-twitter-w mb-4">
        <div class="row align-items-center">
            <div class="col-lg-4 col-md-12">
                <div class="c-map-w">
                    <img src="../../../../assets/images/map-demo.jpg" alt="Map" title="Map" class="img-fluid">
                </div>
            </div>
            <div class="col-lg-8 col-md-12">
                <div class="c-twitter-box-w">
                    <h3>Twitter</h3>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="c-twitter-box">
                                <div class="c-twitter-box-in">
                                    <blockquote class="twitter-tweet"><p lang="en" dir="ltr">Heard there’s a game tomorrow 👀<a href="https://twitter.com/JasonKervin?ref_src=twsrc%5Etfw">@JasonKervin</a> <a href="https://twitter.com/AHSFootball?ref_src=twsrc%5Etfw">@AHSFootball</a> <a href="https://t.co/CYFxcunAGT">pic.twitter.com/CYFxcunAGT</a></p>&mdash; Brannon Kersey (@Brannon_k23) <a href="https://twitter.com/Brannon_k23/status/1658507326230351876?ref_src=twsrc%5Etfw">May 16, 2023</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
                                </div>    
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="c-twitter-box">
                                <div class="c-twitter-box-in">
                                    <blockquote class="twitter-tweet"><p lang="en" dir="ltr">Thank you <a href="https://twitter.com/MattDeBary?ref_src=twsrc%5Etfw">@MattDeBary</a> &amp; <a href="https://twitter.com/PrepRedzoneGA?ref_src=twsrc%5Etfw">@PrepRedzoneGA</a> 🏴‍☠️ <a href="https://t.co/SSl7BmiIEZ">https://t.co/SSl7BmiIEZ</a></p>&mdash; DJ Bordeaux (@__GoDJ__) <a href="https://twitter.com/__GoDJ__/status/1655903432866250754?ref_src=twsrc%5Etfw">May 9, 2023</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
                                </div>    
                            </div>
                        </div>
                    </div>    
                </div>
            </div>
        </div>
    </div>
    
</section>
