<div class="modal-body p-0">
    <div class="title_of_modal">
        <button type="button" data-dismiss="modal" (click)="closeModal('dismiss')">
            <i class="icofont icofont-close"></i>
        </button>
        <h5 class="modal-title">Tag this item to</h5>
    </div>
    <div>
        <form [formGroup]="searchForm">
            <div class="form-group user_model_search_form">
                <div class="input-group" style="position: relative;">
                    <span><i class="icon-search"></i></span>
                    <input class="form-control" type="text" placeholder="Search player" formControlName="srchText">
                </div>
            </div>
        </form>
        <div class="listboxex">
            <table>
                <tr>
                  <th colspan="3">
                    <div class="checkbox checkbox-primary">
                        <input id="checkbox1" type="checkbox" [checked]="isAllSelect" (click)="checkAll()">
                        <label for="checkbox1">Select All</label>
                    </div>
                  </th>
                </tr>
                <tr *ngFor="let user of filterPlayers; let i = index;">
                    <td>
                        <div class="d-flex justify-content-start align-items-center">
                            <div class="checkbox checkbox-primary custmchkbox">
                                <input id="checkbox{{i + 2}}" type="checkbox" [(ngModel)]="user.isMediaAttached" (click)="checkBoxClick(user.userId)">
                                <label for="checkbox{{i + 2}}"></label>
                            </div>
                            <div class="helmet">
                                <img src="assets/images/players/football-helmet.png" alt="player-profile" title="player-profile">
                                <p>{{user.jerseyNumber}}</p>
                            </div>
                            <div class="nmhdng">
                                <h4>{{user.name}}</h4>
                            </div>
                        </div>
                    </td>
                </tr>
              </table>
        </div>
    </div>
</div>
<div class="modal-footer" style="padding: 0px 0px;">
    <button type="button" style="width: 100%;margin: 0;border-radius: 0;height: 50px;font-size: 18px;" class="btn d-block btn-secondary" (click)="save_player_media()"><i class="icofont icofont-tag"></i> Tag</button>
</div>