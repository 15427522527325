import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bigboard-prototype',
  templateUrl: './bigboard-prototype.component.html',
  styleUrls: ['./bigboard-prototype.component.css']
})
export class BigboardPrototypeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  owlcarousel1 = [
    { id: 1, img: "assets/images/slider/1.jpg" },
    { id: 2, img: "assets/images/slider/2.jpg" },
    { id: 3, img: "assets/images/slider/3.jpg" },
    { id: 4, img: "assets/images/slider/4.jpg" },
    { id: 5, img: "assets/images/slider/5.jpg" },
    { id: 6, img: "assets/images/slider/6.jpg" },
    { id: 7, img: "assets/images/slider/7.jpg" },
    { id: 8, img: "assets/images/slider/8.jpg" },
    { id: 9, img: "assets/images/slider/9.jpg" },
    { id: 10, img: "assets/images/slider/10.jpg" }
  ];

  //Options
  owlcarousel1Options = {
    loop: true,
    margin: 10,
    nav: false,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 3
      },
      1000: {
        items: 5
      }
    }
  };

}
