import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GameEventService } from '../data/services/media/game-events.service';

@Component({
  selector: 'app-game-count-down',
  templateUrl: './game-count-down.component.html',
  styleUrls: ['./game-count-down.component.scss']
})
export class GameCountDownComponent implements OnInit {
  currentDate: any;
  targetDate: any;
  cDateMillisecs: any;
  tDateMillisecs: any;
  difference: any;
  seconds: any;
  minutes: any;
  hours: any;
  days: any;
  gameEvent: any;

  public time: any;
  public today = new Date();
  public currentHour = this.today.getHours();
  public m = this.today.getMinutes();
  public ampm = this.currentHour >= 12 ? "PM" : "AM";

  eventDate: any;
  eventId: any;
  constructor(
    private readonly gameEventService: GameEventService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.eventId = this.route.snapshot.paramMap.get('event_id');
  }

  ngAfterViewInit() {
    if (this.eventId) {
      this.gameEventService.nextEventGet(this.eventId).subscribe(resp => {
        if (resp) {
          this.gameEvent = resp;
          this.eventDate = resp.eventDateTime;
          this.myTimer(this.eventDate);
        }
        else {
          window.close();
        }
      });
    }
    else {
      window.close();
    }
  }

  myTimer(eventDate: any) {
    this.currentDate = new Date();
    this.targetDate = new Date(eventDate);
    this.cDateMillisecs = this.currentDate.getTime();
    this.tDateMillisecs = this.targetDate.getTime();
    this.difference = this.tDateMillisecs - this.cDateMillisecs;
    this.seconds = Math.floor(this.difference / 1000);
    this.minutes = Math.floor(this.seconds / 60);
    this.hours = Math.floor(this.minutes / 60);
    this.days = Math.floor(this.hours / 24);

    this.hours %= 24;
    this.minutes %= 60;
    this.seconds %= 60;
    this.hours = this.hours < 10 ? "0" + this.hours : this.hours;
    this.minutes = this.minutes < 10 ? "0" + this.minutes : this.minutes;
    this.seconds = this.seconds < 10 ? "0" + this.seconds : this.seconds;

    if (document.getElementById("days") != undefined) {
      // @ts-ignore
      document.getElementById("days").innerText = this.days;
    }

    if (document.getElementById("hours") != undefined) {
      // @ts-ignore
      document.getElementById("hours").innerText = this.hours;
    }

    if(document.getElementById("minutes") != undefined){
      // @ts-ignore
      document.getElementById("minutes").innerText = this.minutes;
    }

    if(document.getElementById("seconds") != undefined){
      // @ts-ignore
      document.getElementById("seconds").innerText = this.seconds;
    }

    setInterval(() => {
      this.myTimer(this.eventDate)
    }, 1000);
  };
}
