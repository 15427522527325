import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { Coach, Player, PositionGroup } from 'src/app/data/models/team-management';
import { NotificationService } from 'src/app/data/services/global';
import { CoachService, PlayerService, PositionGroupService } from 'src/app/data/services/team-management';

@Component({
  selector: 'app-share-topic',
  templateUrl: './share-topic.component.html',
  styleUrls: ['./share-topic.component.css']
})
export class ShareTopicComponent implements OnInit {

  @Input() fromParent: any;
  shareForm: FormGroup;
  validate: boolean = true;
  types = [
    { 'value': 'A', 'label': 'Whole Team' },
    { 'value': 'C', 'label': 'Coach' },
    { 'value': 'P', 'label': 'Player' },
    { 'value': 'PG', 'label': 'Position Group' }];
  origin = "";
  players: Player[] = [];
  coaches: Coach[] = [];
  positionGroups: PositionGroup[] = [];
  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private coachService: CoachService,
    private playerService: PlayerService,
    private positionGroupService: PositionGroupService,
    private notificationService: NotificationService,
    public toster: ToastrService
  ) {
    this.shareForm = this.formBuilder.group({
      type: [, Validators.required],
      originIds: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.origin = this.fromParent.origin;
    this.shareForm.get("type")?.valueChanges.subscribe(value => {
      if (value && value == 'C') {
        this.getCoach();
        this.shareForm.controls['originIds'].setValidators([Validators.required]);
      }
      if (value && value == 'P') {
        this.getPlayer();
        this.shareForm.controls['originIds'].setValidators([Validators.required]);
      }
      if (value && value == 'PG') {
        this.getGroups();
        this.shareForm.controls['originIds'].setValidators([Validators.required]);
      }
      if (value && value == 'A') {
        this.shareForm.controls['originIds'].setValidators(null);
      }
      this.shareForm.controls['originIds'].setValue(null);
    });
  }
  closeModal(sendData: any) {
    this.activeModal.close(sendData);
  };
  getCoach() {
    this.coachService.allCoaches().subscribe(resp => {
      this.coaches = resp;
    });
  };
  getPlayer() {
    this.playerService.allPlayers().subscribe(resp => {
      this.players = resp;
    });
  };
  getGroups() {
    this.positionGroupService.allCoachpositionGroup().subscribe(resp => {
      this.positionGroups = resp;
    });
  };
  submit() {
    if (this.shareForm.invalid) {
      this.validate = true;
      return;
    }
    this.validate = false;
    const notificationTosave = this.shareForm.value;

    notificationTosave.originId = this.fromParent.originRefId;
    notificationTosave.description = this.fromParent.description;
    notificationTosave.eventUrl = this.fromParent.eventUrl;
    notificationTosave.fileSequence = this.fromParent.fileSequence;
    notificationTosave.originType = this.fromParent.originType;
    notificationTosave.isVideo = this.fromParent.isVideo;
    notificationTosave.users = this.shareForm.controls['originIds'].value;

    this.notificationService.save(notificationTosave)
      .pipe(first())
      .subscribe((resp: any) => {
        if (resp && resp.success) {
          this.toster.success(resp.responseMsg);
          this.closeModal('success');
        }
        else {
          this.toster.error(resp.responseMsg);
        }
      },
        error => {
          this.toster.error("Failed to share");
        });
    this.closeModal('failed');
  };
}
