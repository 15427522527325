import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ConceptService, FormationService, FormationStackService, OffensePlayService } from 'src/app/data/services/offense';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DefensivePlayService } from 'src/app/data/services/defense/defensive-play.service';
import { FrontsService } from 'src/app/data/services/defense';
import { QuizeService } from 'src/app/data/services/team-development';
import { ScoutService } from 'src/app/data/services/team-development/scout.service';

@Component({
  selector: 'app-media-upload-modal',
  templateUrl: './media-upload-modal.component.html',
  styleUrls: ['./media-upload-modal.component.css']
})
export class MediaUploadModalComponent implements OnInit {
  @Input() fromParent: any;
  public files: any[] = [];
  downloadUrl: string = '';
  constructor(
    private formationService: FormationService,
    public toster: ToastrService,
    private loaderService: LoaderService,
    public activeModal: NgbActiveModal,
    private conceptService: ConceptService,
    private formationStackService: FormationStackService,
    private playService: OffensePlayService,
    private defensivePlayService: DefensivePlayService,
    private frontsService: FrontsService,
    private quizeService: QuizeService,
    private scoutService: ScoutService,
    ) { }
 

  ngOnInit(): void {
  }

  closeModal(sendData: any) {
    this.activeModal.close(sendData);
  };

  onSelect(event: any) {
    this.files.push(...event.addedFiles);
  };

  onRemove(event: any) {
    this.files.splice(this.files.indexOf(event), 1);
  };

  uploadDoc() {
    if ((!this.files || this.files.length == 0) && this.downloadUrl === '') {
      this.toster.warning("Please select a file or enter video/image url");
      return;
    }
    let formData = new FormData();
    formData.append('isWebUpload', 'true');
    for (let i = 0; i < this.files.length; i++) {
      formData.append("files[" + i + "]", this.files[i]);
    }
    formData.append('downloadUrl', this.downloadUrl);
    this.loaderService.isLoading.next(true);
    this.loaderService.loadingMessage.next('uploading..');

    if (this.fromParent.origin == 'Formation Stack') {
      formData.append('formationSetId', this.fromParent.originId);
      this.formationStackService.uploadScreenshot(formData).subscribe((result: any) => {
        this.loaderService.isLoading.next(false);
        this.closeModal('upload done');
        if (result.success === true) {
          this.toster.success(result.responseMsg, 'Success', {
            progressBar: true,
            positionClass: 'toast-top-right'
          });
        } else {
          this.toster.error(result.responseMsg, 'Error', {
            progressBar: true,
            positionClass: 'toast-top-right'
          });
        }
      },
        error => {
          this.closeModal('upload done');
          this.loaderService.isLoading.next(false);
          this.toster.error("!Failed to upload files.");
        });
    }
    else if (this.fromParent.origin == 'Formation') {
      formData.append('formationId', this.fromParent.originId);
      this.formationService.uploadScreenshot(formData).subscribe((result: any) => {
        this.loaderService.isLoading.next(false);
        this.closeModal('upload done');
        if (result.success === true) {
          this.toster.success(result.responseMsg, 'Success', {
            progressBar: true,
            positionClass: 'toast-top-right'
          });
        } else {
          this.toster.error(result.responseMsg, 'Error', {
            progressBar: true,
            positionClass: 'toast-top-right'
          });
        }
      },
        error => {
          this.closeModal('upload done');
          this.loaderService.isLoading.next(false);
          this.toster.error("!Failed to upload files.");
        });
    }
    else if (this.fromParent.origin == 'Concept') {
      formData.append('conceptId', this.fromParent.originId);
      this.conceptService.uploadScreenshot(formData).subscribe((result: any) => {
        this.loaderService.isLoading.next(false);
        this.closeModal('upload done');
        if (result.success === true) {
          this.toster.success(result.responseMsg, 'Success', {
            progressBar: true,
            positionClass: 'toast-top-right',
          });
        } else {
          this.toster.error(result.responseMsg, 'Error', {
            progressBar: true,
            positionClass: 'toast-top-right',
          });
        }
      },
        error => {
          this.closeModal('upload done');
          this.loaderService.isLoading.next(false);
          this.toster.error("!Failed to upload files.");
        });
    }
    else if (this.fromParent.origin == 'Plays') {
      formData.append('playsId', this.fromParent.originId);
      this.playService.uploadScreenshot(formData).subscribe((result: any) => {
        this.loaderService.isLoading.next(false);
        this.closeModal('upload done');
        if (result.success === true) {
          this.toster.success(result.responseMsg, 'Success', {
            progressBar: true,
            positionClass: 'toast-top-right'
          });
        } else {
          this.toster.error(result.responseMsg, 'Error', {
            progressBar: true,
            positionClass: 'toast-top-right'
          });
        }
      },
        error => {
          this.closeModal('upload done');
          this.loaderService.isLoading.next(false);
          this.toster.error("!Failed to upload files.");
        });
    }
    else if (this.fromParent.origin == 'DPlays') {
      formData.append('defensivePlaysId', this.fromParent.originId);
      this.defensivePlayService.uploadScreenshot(formData).subscribe((result: any) => {
        this.loaderService.isLoading.next(false);
        this.closeModal('upload done');
        if (result.success === true) {
          this.toster.success(result.responseMsg, 'Success', {
            progressBar: true,
            positionClass: 'toast-top-right'
          });
        } else {
          this.toster.error(result.responseMsg, 'Error', {
            progressBar: true,
            positionClass: 'toast-top-right'
          });
        }
      },
        error => {
          this.closeModal('upload done');
          this.loaderService.isLoading.next(false);
          this.toster.error("!Failed to upload files.");
        });
    }
    else if (this.fromParent.origin == 'DFront') {
      formData.append('defensiveFrontId', this.fromParent.originId);
      this.frontsService.uploadScreenshot(formData).subscribe((result: any) => {
        this.loaderService.isLoading.next(false);
        this.closeModal('upload done');
        if (result.success === true) {
          this.toster.success(result.responseMsg, 'Success', {
            progressBar: true,
            positionClass: 'toast-top-right'
          });
        } else {
          this.toster.error(result.responseMsg, 'Error', {
            progressBar: true,
            positionClass: 'toast-top-right'
          });
        }
      },
        error => {
          this.closeModal('upload done');
          this.loaderService.isLoading.next(false);
          this.toster.error("!Failed to upload files.");
        });
    }
    else if (this.fromParent.origin == 'Quiz') {
      formData.append('quizId', this.fromParent.originId);
      this.quizeService.uploadMedia(formData).subscribe((result: any) => {
        this.loaderService.isLoading.next(false);
        this.closeModal('upload done');
        if (result.success === true) {
          this.toster.success(result.responseMsg, 'Success', {
            progressBar: true,
            positionClass: 'toast-top-right'
          });
        } else {
          this.toster.error(result.responseMsg, 'Error', {
            progressBar: true,
            positionClass: 'toast-top-right'
          });
        }
      },
        error => {
          this.closeModal('upload done');
          this.loaderService.isLoading.next(false);
          this.toster.error("!Failed to upload files.");
        });
    }
    else if (this.fromParent.origin == 'Scout') {
      formData.append('scoutId', this.fromParent.originId);
      formData.append('prefix', this.fromParent.type);
      this.scoutService.uploadScreenshot(formData).subscribe((result: any) => {
        this.loaderService.isLoading.next(false);
        this.closeModal('upload done');
        if (result.success === true) {
          this.toster.success(result.responseMsg, 'Success', {
            progressBar: true,
            positionClass: 'toast-top-right'
          });
        } else {
          this.toster.error(result.responseMsg, 'Error', {
            progressBar: true,
            positionClass: 'toast-top-right'
          });
        }
      },
        error => {
          this.closeModal('upload done');
          this.loaderService.isLoading.next(false);
          this.toster.error("!Failed to upload files.");
        });
    }
  };
}
