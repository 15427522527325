<!-- Container-fluid starts-->
<div class="container-fluid mt-3" style="background-color: #eeeeeeb5;" *ngIf="isPlayerProfileAvaiable">
	<div class="user-profile">
		<div class="row">
			<!-- user profile first-style start-->
			<div class="col-sm-12">
				<div class="card hovercard text-center">
					<div class="cardheader"
						[ngStyle]="{'background': coverUrl ? 'url('+ coverUrl +')' : 'url(assets/images/players/profile_cover_image.jpg)','background-size':'cover','background-position':'center'}">
					</div>
					<div class="user-image">
						<div class="avatar">
							<img alt="" [src]="profileUrl ? profileUrl : 'assets/images/players/player.png'"
								alt="profile" title="profile" />
							<div class="newuser">
								<h4>AP</h4>
							</div>
						</div>
					</div>
					<div class="info">
						<div class="row">
							<div class="col-sm-6 col-lg-4 order-sm-1 order-xl-0">
								<div class="row">
									<div class="col-md-6">
										<div class="ttl-info text-left">
											<h6><i class="fa fa-envelope"></i>   Email</h6>
											<span>{{ profileData.email }}</span>
										</div>
									</div>
									<div class="col-md-6">
										<div class="ttl-info text-left">
											<h6><i class="fa fa-calendar"></i>   {{profileData.isCoach ? 'DOB' :
												'Graduation Year'}}</h6>
											<span *ngIf="profileData.isCoach">{{ profileData.born | date:'dd-MM-yyyy'
												}}</span>
											<span *ngIf="!profileData.isCoach">{{ profileData.graduationYear }}</span>
										</div>
									</div>
								</div>
							</div>
							<div class="col-sm-12 col-lg-4 order-sm-0 order-xl-1">
								<div class="user-designation">
									<div class="title">
										<a>{{ profileData.name }}</a>
									</div>
									<div class="desc mt-2">{{profileData.positions ? profileData.positions + " | " :
										""}} {{ profileData.jerseyNumber ? '#' + profileData.jerseyNumber : "" }}</div>
								</div>
								<div class="social-media">
									<ul class="list-inline">
										<li class="list-inline-item" placement="bottom" ngbTooltip="Facebook">
											<a (click)="updateLink('F')"><img
													src="../../../../assets/images/players/facebook.png"
													style="width: 30px;transform: translateY(-5px); filter: grayscale(1.6);"
													alt="facebook" title="facebook" />
											</a>
										</li>
										<li class="list-inline-item" placement="bottom" ngbTooltip="Hudl">
											<a (click)="updateLink('H')"><img
													src="../../../../assets/images/players/hudl.png"
													style="transform: translateY(-5px); width: 27px; filter: grayscale(1.6);"
													alt="hudl" title="hudl" />
											</a>
										</li>
										<li class="list-inline-item" placement="bottom" ngbTooltip="Twitter">
											<a (click)="updateLink('T')"><img
													src="../../../../assets/images/twitter.png"
													style="transform: translateY(-5px); width: 27px; filter: grayscale(1.6);"
													alt="twitter" title="twitter" />
											</a>
										</li>
										<li class="list-inline-item" placement="bottom" ngbTooltip="Instagram">
											<a (click)="updateLink('I')"><img
													src="../../../../assets/images/players/instagram.png"
													style="transform: translateY(-5px); width: 27px; filter: grayscale(1.6);"
													alt="instagram" title="instagram" />
											</a>
										</li>
										<li class="list-inline-item m-0" placement="bottom" ngbTooltip="Maxpreps">
											<a (click)="updateLink('M')"><img
													src="../../../../assets/images/players/maxpreps-icon.png"
													style="transform: translateY(-5px); width: 27px; filter: grayscale(1.6);"
													alt="maxpreps" title="maxpreps" />
											</a>
										</li>
									</ul>
								</div>
							</div>
							<div class="col-sm-6 col-lg-4 order-sm-2 order-xl-2">
								<div class="row">
									<div class="col-md-6">
										<div class="ttl-info text-left">
											<h6><i class="fa fa-phone"></i>   Contact</h6>
											<span>{{ profileData.mobile }}</span>
											<!-- (+91)-9934-346-987 -->
										</div>
									</div>
									<div class="col-md-6">
										<div class="ttl-info text-left">
											<h6><i class="fa fa-location-arrow"></i>   Age</h6>
											<span>{{profileData.age}}</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-xl-1 col-lg-1 col-12"></div>
			<div class="col-xl-6 col-lg-6 col-12">
				<div class="card" *ngIf="profileData.userMedias && profileData.userMedias.length > 0">
					<!-- <div class="card-header d-flex justify-content-between">
						<h5 class="pull-left">Media</h5>
					</div> -->
					<div class="card-body">
						<div class="videodtls">
							<div class="vdbox provdbox" *ngFor="let media of profileData.userMedias; index as i">
								<div class="provdimgbx">
									<img src="{{media.thumbnail}}" alt="{{media.fileName}}"
										*ngIf="media.thumbnail && !media.isPlay && media.isVideo">
									<img src="{{media.fileSequence}}" alt="image"
										*ngIf="media.fileSequence && !media.isPlay && !media.isVideo">
									<video controls controlsList="nodownload" width="100%" class="dash-video"
										*ngIf="!media.thumbnail || media.isPlay && media.isVideo"
										(ended)="handleVideoEnded($event)">
										<source src="{{media.fileSequence}}" type="video/mp4">
									</video>
									<div class="vdicnbx" *ngIf="media.thumbnail && !media.isPlay && media.isVideo">
										<div class="mr-2">
											<i class="icofont icofont-play-alt-2" ngbTooltip="Play"
												(click)="media.isPlay = true"></i>
										</div>
									</div>
								</div>
								<div class="vdboxttl d-flex justify-content-between align-items-center">
									<a title="name">{{media.referenceType}}( {{media.referenceName}})</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-xl-4 col-lg-4 col-12">
				<div class="innerprorgtsec">
					<div class="card">
						<div class="card-header d-flex justify-content-between">
							<h5 class="pull-left">Contact Details</h5>
						</div>
						<div class="card-body">
							<div class="contactdtls">
								<h5><i class="fa fa-location-arrow"></i> Address</h5>
								<p>{{profileData.address ? profileData.address + ' ' + '.' : profileData.address}}</p>
							</div>
						</div>
					</div>
					<div class="card">
						<div class="card-header">
							<h5 class="pull-left">Recruit</h5>
						</div>
						<div class="card-body">
							<div class="tab-pane fade show">
								<div class="d-flex">
									<div style="flex: 1;">
										<p><strong>Weight:</strong> {{ profileData.weight ? profileData.weight + " lbs"
											: "" }}</p>
										<p><strong>Height:</strong> {{ profileData.heightCm }}</p>
										<p><strong>Squat:</strong> {{ profileData.squat ? profileData.squat + " lbs" :
											"" }} </p>
										<p><strong>Bench:</strong> {{ profileData.bench ? profileData.bench + " lbs" :
											"" }} </p>
										<p><strong>Fourty:</strong> {{ profileData.fourty ? profileData.fourty + " lbs"
											: "" }} </p>
									</div>
									<div style="flex: 1;">
										<p><strong>Vert:</strong> {{ profileData.vert ? profileData.vert + " inches" :
											"" }} </p>
										<p><strong>Shuttle:</strong> {{ profileData.shuttle ? profileData.shuttle + "
											seconds" : "" }} </p>
										<!-- <p><strong>GPA:</strong> {{ profileData.gpa }}</p> -->
										<p><strong>ACT Score:</strong> {{ profileData.actScore }}</p>
										<p><strong>SAT:</strong> {{ profileData.sat }}</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-xl-1 col-lg-1 col-12"></div>
		</div>
	</div>
</div>
<!-- Container-fluid Ends-->
<app-error404 *ngIf="is404Show"></app-error404>