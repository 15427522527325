<div class="container-fluid">
    <div class="row flex-row-reverse">
        <div class="col-sm-12 col-xl-9 col-12">
            <div class="card">
                <div class="card-header">
                    <div class="heading d-flex justify-content-between align-items-center">
                        <div>
                            <h4 class="text-center m-0">{{detailObj && detailObj.name ? detailObj.name : ""}}</h4>
                        </div>
                        <div>
                            <h6 class="text-right m-0">{{sectionName}}</h6>
                        </div>
                    </div>
                    <div class="btn-showcase d-flex justify-content-center align-items-center" role="group"
                        aria-label="Carousel toggle controls" *ngIf="images && images.length > 0">
                        <button type="button" class="btn btn-secondary btn-sm mr-1" (click)="pre_clk()">Prev</button>
                        <button type="button" class="btn btn-primary btn-sm" (click)="next_clk()">Next</button>
                    </div>
                </div>
                <div class="card-body">
                    
                    <div class="gallery my-gallery " *ngIf="images && images.length > 0">
                        <ngb-carousel>
                            <ng-template ngbSlide *ngFor="let img of images; let i = index;">
                                <img [src]="img.captureImg" alt="capture-image" title="capture-image" (click)="open(i)" *ngIf="img.captureImg && !img.isVideo">
                                <img [src]="img.fileSeq" alt="{{img.fileName}}" title="{{img.fileName}}" (click)="open(i)" *ngIf="img.fileSeq && !img.isVideo">
                                <img src="{{img.thumbnail}}" alt="{{img.thumbnailName}}" title="{{img.thumbnailName}}" preload="metadata" *ngIf="img.thumbnail && img.isVideo">
                                <video width="100%" class="dash-video" *ngIf="!img.thumbnail && img.isVideo">
                                    <source src="{{img.fileSeq}}" type="video/mp4">
                                </video>
                                <div class="vdicnbx">
                                    <i class="icofont icofont-play-alt-2" (click)="files_view(img.fileSeq ? img.fileSeq : img.captureImg, img.fileName ? img.fileName : '', false)" *ngIf="img.isVideo"></i>
                                    <!-- <i class="icofont icofont-eye-alt" (click)="files_view(img.fileSeq, true)" *ngIf="!img.isVideo"></i> -->
                                </div>
                            </ng-template>
                        </ngb-carousel>
                    </div>
                </div>
                <!-- button section -->
                <div class="card-body">
                    <div *ngIf="images.length == 0 || !images">
                        <h3 class="text-center c-no-data-hw">No data found.</h3>
                    </div>
                    <div class="btn-showcase mt-3 d-flex justify-content-center align-items-center" role="group"
                        aria-label="Carousel toggle controls">
                        <button type="button" class="btn btn-danger" (click)="cancel()">Back</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-xl-3 col-12">
            <div class="card presentation-menu" [ngClass]="pMenu ? 'right-0' : 'right-full'">
                <div class="presentation-menu-btn" (click)="presentionMenu()">
                    <i data-feather="arrow-left"></i>
                </div>
                <div class="card-body" style="padding: 15px 0px;">
                    <div class="leftsidenav sidebar">
                        <ul class="listmenu">
                            <li *ngFor="let section of presentationDetails; index as i; first as isFirst">
                                <h5>{{section.name}}</h5>
                                <ul *ngFor="let element of section.details; index as j; first as isFirst">
                                    <li (click)="fnLiClick(section, element, i , j)">
                                        <a [ngClass]="element.isActive ? 'active' : ''">{{element.name}}
                                            <span>{{element.imageList && element.imageList.length > 0 ?
                                                element.imageList.length : 0}}
                                            </span>
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Container-fluid Ends-->
<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Videos</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <ngb-carousel>
            <ng-template ngbSlide *ngFor="let video of videos">
                <video controls controlsList="nodownload" width="100%" (ended)="handleVideoEnded($event)">
                    <source src="{{video.fileSeq}}" type="video/mp4">
                </video>
            </ng-template>
        </ngb-carousel>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="modal.close()">Cancel</button>
    </div>
</ng-template>

<!-- quiz participate modal -->
<!-- <ng-template #contentQuizParticipate let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Participate</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-body">
                    <div class="" *ngIf="participantImages && participantImages.length > 0">
                        <ngb-carousel *ngIf="participantImages">
                            <ng-template ngbSlide *ngFor="let img of participantImages">
                                <div class="instalsldimg">
                                    <img [src]="img.imgFileSeq" *ngIf="img.imgFileSeq && !img.isVideo">
                                    <video controls controlsList="nodownload" width="100%" *ngIf="img.imgFileSeq && img.isVideo">
                                        <source src="{{img.imgFileSeq}}" type="video/mp4">
                                    </video>
                                </div>
                            </ng-template>
                        </ngb-carousel>
                    </div>
                    <form class="form-wizard" id="regForm">
                        <aw-wizard [navBarLayout]="'false'" *ngIf="quizQuestion.length > 0">
                            <aw-wizard-step  *ngFor="let question of quizQuestion; let questionIndex=index">
                                <div class="heading mb-3">
                                    <h5>{{questionIndex + 1}}. {{question.question}}</h5>
                                </div>
                                <div class="row" *ngIf="question.questionType != 'Text'">
                                    <div class="col-md-12" *ngFor="let option of question.filmQuestionOptions; let optionIndex=index">
                                        <div class="form-group">
                                            <div class="form-control input-air-primary" [ngClass]="{'radio radio-primary': question.questionType === 'Multiple Choice' || question.questionType === 'True/False'}">
                                                <input id="radio{{questionIndex}}{{optionIndex}}" type="radio" 
                                                name="{{questionIndex}}" 
                                                value="option.id" 
                                                [checked]="question.answerId === option.id"
                                                [disabled]="quizCompleted"
                                                *ngIf="question.questionType === 'Multiple Choice' || question.questionType === 'True/False'"
                                                (click)="clickRadio(questionIndex, optionIndex)">
                                                <label for="radio{{questionIndex}}{{optionIndex}}">{{option.optionText}}</label>
                                                <i class="fa fa-times" *ngIf="quizCompleted && option.isWrong && (question.questionType === 'Multiple Choice' || question.questionType === 'True/False')"></i>
                                                <i class="fa fa-check" *ngIf="quizCompleted && option.isAnswer && (question.questionType === 'Multiple Choice' || question.questionType === 'True/False')"></i>
                                            </div>
                                        </div>
                                    </div>                                        
                                </div>
                                <div class="row" *ngIf="question.questionType === 'Text'">
                                    <div class="col-md-12 mb-3">
                                        <input id="radio{{questionIndex}}" 
                                                name="{{questionIndex}}" 
                                                class="form-control input-air-primary"
                                                placeholder="Free Response"
                                                [(ngModel)]="question.answerText"
                                                [disabled]="quizCompleted"/>
                                    </div>
                                </div>                                 
                                <button class="btn btn-success pull-right" type="button" (click)="finish(modal)" *ngIf="!quizCompleted && questionCount === quizQuestion.length">Submit</button>
                                <button class="btn btn-primary pull-right" type="submit" awNextStep (click)="next()" *ngIf="questionCount != quizQuestion.length">Next</button>
                                <button class="btn btn-secondary pull-right mr-1" type="button" awPreviousStep (click)="previous()" *ngIf="questionCount > 1">Previous</button>
                            </aw-wizard-step>                           
                        </aw-wizard>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="modal.close()">Cancel</button>
    </div>
</ng-template> -->
<!-- quiz participate end -->

<!--Video View modal -->
<ng-template #videoViewcontent let-c="close" let-d="dismiss" let-modal>
    <div class="modal-body">
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')" ngbTooltip="Close">
            <span aria-hidden="true">&times;</span>
        </button>
        <!-- <owl-carousel-o [options]="owlcarouselPlayOptions">
            <ng-template carouselSlide class="item"> -->
                <div class="boxvideo">
                    <img src="{{viewFileUrl}}" alt="{{viewFileName}}" title="{{viewFileName}}" class="img-fluid" *ngIf="viewFileImg">
                    <video controls controlsList="nodownload" width="100%" *ngIf="!viewFileImg"
                     (ended)="handleVideoEnded($event)">
                        <source src="{{viewFileUrl}}" type="video/mp4">
                    </video>
                </div>
            <!-- </ng-template>
        </owl-carousel-o> -->
    </div>
</ng-template>
<!-- END -->