import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rule-book',
  templateUrl: './rule-book.component.html',
  styleUrls: ['./rule-book.component.css']
})
export class RuleBookComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
