import { Component, HostListener, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TagInputComponent } from 'ngx-chips';
import { TagService } from 'src/app/data/services/global';
import { NavService, Menu } from '../../../../services/nav.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent {

  // public menuItems: Menu[] = [];
  // public items: Menu[] = [];

  public searchResult: boolean = false;
  public searchResultEmpty: boolean = false;
  public text: string = "";

  detailForm: FormGroup;
  tags: any[] = [];
  globalResults: any[] = [];

  @ViewChildren(TagInputComponent)
  private tagInputs?: QueryList<TagInputComponent>; 
   @HostListener('click')
    onClick()
    {
      this.tagInputs?.forEach(tagInput => {
        if (!tagInput.isInputFocused() && tagInput.dropdown.isVisible) {
          tagInput.dropdown.hide();
        }
      })
    }
  constructor(
    public navServices: NavService,
    private formBuilder: FormBuilder,
    private tagService: TagService
  ) {
    //this.navServices.items.subscribe(menuItems => this.items = menuItems);
    this.detailForm = this.formBuilder.group({
      tagNames: []
    });
  }

  searchToggle() {
    this.navServices.search = false;
    this.removeFix();
    this.detailForm.controls[`tagNames`].setValue(null);
  }

  // searchTerm(term: string) {
  //   term ? this.addFix() : this.removeFix();
  //   if (!term) return this.menuItems = [];
  //   let items: any[] = [];
  //   term = term.toLowerCase();
  //   this.items.filter((menuItems: any) => {
  //     if (!menuItems?.title) return false
  //     if (menuItems.title.toLowerCase().includes(term) && menuItems.type === 'link') {
  //       items.push(menuItems);
  //     }
  //     if (!menuItems.children) return false
  //     menuItems.children.filter((subItems: any) => {
  //       if (subItems.title.toLowerCase().includes(term) && subItems.type === 'link') {
  //         subItems.icon = menuItems.icon
  //         items.push(subItems);
  //       }
  //       if (!subItems.children) return false
  //       subItems.children.filter((suSubItems: any) => {
  //         if (suSubItems.title.toLowerCase().includes(term)) {
  //           suSubItems.icon = menuItems.icon
  //           items.push(suSubItems);
  //         }
  //       })
  //     })
  //     this.checkSearchResultEmpty(items)
  //     this.menuItems = items
  //   });
  // }

  checkSearchResultEmpty(items: any) {
    if (!items.length)
      this.searchResultEmpty = true;
    else
      this.searchResultEmpty = false;
  }

  addFix() {
    this.searchResult = true;
    document.getElementsByTagName('body')[0].classList.add('offcanvas');
  }

  removeFix() {
    this.searchResult = false;
    this.text = "";
    document.getElementsByTagName('body')[0].classList.remove('offcanvas');
  }

  onTextChange(event: any) {
    var searchText: string = ""
    if (event) {
      searchText = event.toLowerCase();
    }
    this.tagService.getTagName(searchText, '').subscribe(resp => {
      this.tags = resp
    });
  };
  onItemAdded() {
    this.searchGlobalResult();
  };
  onItemRemoved() {
    this.searchGlobalResult();
  };

  searchGlobalResult() {
    let tagNames: string[] = [];
    var tagValueArry: any[] = this.detailForm.controls[`tagNames`].value;
    if (tagValueArry && tagValueArry.length) {
      tagValueArry.forEach(data => {
        tagNames.push(data.value);
      });
    }
    this.tagService.globalSearchByTag(tagNames).subscribe(resp => {
      this.globalResults = resp;
      this.checkSearchResultEmpty(resp);
      if(this.globalResults?.length === 1 && this.globalResults[0].globalTags.length === 1){
        window.open(this.globalResults[0].globalTags[0].url);
      }
    });
    this.addFix()
  };
}
