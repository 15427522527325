import { Component, OnInit } from '@angular/core';
import { Player } from '../data/models/team-management';
import { PersonnelGroupService, PlayerService } from "src/app/data/services/team-management";

@Component({
  selector: 'app-depthchart',
  templateUrl: './depthchart.component.html',
  styleUrls: ['./depthchart.component.css']
})
export class DepthchartComponent implements OnInit {
  public players: Player[] = [];
  public positions: any[] = []

  constructor(
    private playerService: PlayerService,
    private personnelGroupService: PersonnelGroupService
  ) { }

  ngOnInit(): void {
    this.playerService.getAllGlobal().subscribe(resp => {
      this.players = resp;
    });
    this.personnelGroupService.getBasePositions().subscribe(resp => {
      this.positions = resp;
    });
  }
}
