<div class="modal-header videoheader">
    <h4 class="modal-title" id="modal-basic-title">
       {{playlistName}}
    </h4>
    <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="closeModal('dismiss')"
    >
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="boxvideo">
        <video width="100%" height="340" controls (ended)="handleVideoEnded($event)">
            <source src="https://fieldvisionupload.s3.amazonaws.com/{{fileName}}" type="video/mp4" />
        </video>
    </div>
    <div class="formbox mt-3">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-12">
                <form [formGroup]="detailForm" autocomplete="off">
                    <input
                        formControlName="fileName"
                        class="form-control"
                        type="text"
                        placeholder="Clip name here"
                        value={{clipName}}
                        required />
                    <div class="cust-invalid-feedback" *ngIf="detailForm.controls.fileName.touched && detailForm.controls.fileName.errors?.required">
                        Clip name is required.
                    </div>
                </form>
            </div>

            <div class="col-xl-12 col-lg-12 col-12">
                <form [formGroup]="detailForm" autocomplete="off">
                    <tag-input
                        formControlName="tagNames"
                        placeholder="Add tag"
                        secondaryPlaceholder="Add tag"
                        (onTextChange)="onTextChange($event)"
                        (onAdd)="onItemAdded()"
                        (onRemove)="onItemRemoved()">
                        <tag-input-dropdown
                            [zIndex]="99999"
                            placeholder="Add tag"
                            secondaryPlaceholder="Add tag"
                            [showDropdownIfEmpty]="true"
                            [autocompleteItems]="tags">
                        </tag-input-dropdown>
                    </tag-input>
                </form>
            </div>

            <div class="col-xl-12 col-lg-12 col-12">
                <div class="d-flex justify-content-between w-100 align-items-center">
                    <div
                        class="btn-group d-flex chipbtn"
                        role="group"
                        aria-label="Basic example">
                        <button class="btn btn-primary" [ngClass]="{'active': detailForm.value.downValue === '1st'}" type="button" (click)="down('1st')">1st</button>
                        <button class="btn btn-primary" [ngClass]="{'active': detailForm.value.downValue === '2nd'}" type="button" (click)="down('2nd')">2nd</button>
                        <button class="btn btn-primary" [ngClass]="{'active': detailForm.value.downValue === '3rd'}" type="button" (click)="down('3rd')">3rd</button>
                        <button class="btn btn-primary" [ngClass]="{'active': detailForm.value.downValue === '4th'}" type="button" (click)="down('4th')">4th</button>
                    </div>
                    <div class="range">
                        <p>Distance</p>
                        <form [formGroup]="detailForm" autocomplete="off">
                            <ng5-slider
                                formControlName="distanceValue"
                                [options]="simpleSliderOptions">
                            </ng5-slider>
                        </form>
                    </div>
                    <div
                        class="btn-group d-flex chipbtn"
                        role="group"
                        aria-label="Basic example">
                        <button class="btn btn-primary" [ngClass]="{'active': detailForm.value.filetrValue === 'Offense'}" type="button" (click)="filterType('Offense')">Offense</button>
                        <button class="btn btn-primary" [ngClass]="{'active': detailForm.value.filetrValue === 'Defense'}" type="button" (click)="filterType('Defense')">Defense</button>
                        <button class="btn btn-primary" [ngClass]="{'active': detailForm.value.filetrValue === 'Kick'}" type="button" (click)="filterType('Kick')">Kick</button>
                    </div>
                </div>
            </div>

            <div class="col-xl-12 col-lg-12 col-12">
                <form [formGroup]="detailForm" autocomplete="off">
                    <tag-input        
                        formControlName="allTags" 
                        placeholder="More.."
                        secondaryPlaceholder="More.."
                        (onRemove)="onTagRemoved($event)">                       
                    </tag-input>
                </form>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer justify-content-start">
    <button
        type="button"
        class="btn btn-air-secondary btn-secondary"
        (click)="saveModal()">
        Save
    </button>
    <button
        type="button"
        class="btn btn-air-secondary btn-secondary"
        (click)="closeModal(null)">
        Cancel
    </button>
</div>