
<!--  =================== Counter =============== -->
<section class="countdown">
	<div class="container">
		<div id="countdown">
			<div class="maInBox">
				<img src="../../assets/images/logo/login-screen-img.png" alt="login-screen" title="login-screen"/>
				<h1 id="headline">KICKOFF</h1>
				<ul>
					<li><span id="days"></span>days</li>
					<li class="doT">:</li>
					<li><span id="hours"></span>Hours</li>
					<li class="doT">:</li>
					<li><span id="minutes"></span>Minutes</li>
					<li class="doT">:</li>
					<li><span id="seconds"></span>Seconds</li>
				</ul>

				<div class="eventBox">
					<div class="gameUserBox">
						<div class="cirCleBx">
							<h4 *ngIf="gameEvent && !gameEvent?.baseAccountImage">{{ gameEvent?.shortNameBase}}</h4>
							<img src="{{gameEvent?.baseAccountImage}}" *ngIf="gameEvent && gameEvent?.baseAccountImage" alt="{{gameEvent?.baseAccountImageName}}"
							title="{{gameEvent?.baseAccountImageName}}"/>
						</div>
						<p class="teamName">{{gameEvent?.accountName}}</p>
					</div>
					<div class="gameUserBox">
						<h3 class="vurses">VS</h3>
					</div>
					<div class="gameUserBox">
						<div class="cirCleBx">
							<h4 *ngIf="gameEvent && !gameEvent?.oppositAccountImage">{{ gameEvent?.shortNameOpposit}}</h4>
							<img src="{{gameEvent?.oppositAccountImage}}" *ngIf="gameEvent && gameEvent?.oppositAccountImage" 
							alt="{{gameEvent?.oppositAccountImageSeq}}" title="{{gameEvent?.oppositAccountImageSeq}}"/>
						</div>
						<p class="teamName">{{gameEvent?.opponentAccName ? gameEvent?.opponentAccName : 'Strongside.app'}}</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>