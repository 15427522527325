import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/data/services/auth/authentication.service';
import { ReportingIssueComponent } from 'src/app/reporting-issue/reporting-issue.component';
declare const require: any;
const Swal = require('sweetalert2');

@Component({
    selector: 'app-event-attendance-player-login',
    templateUrl: './event-attendance-player-login.component.html',
    styleUrls: ['./event-attendance-player-login.component.css']
})
export class EventAttendancePlayerLoginComponent implements OnInit {
    public loginForm: FormGroup;
    public show: boolean = false;
    public returnUrl: string = "";
    redirectUrl: any;
    google: any;

    constructor(
        private fb: FormBuilder,
        public router: Router,
        private route: ActivatedRoute,
        private authenticationService: AuthenticationService,
        public toster: ToastrService,
        public cookieService: CookieService,
        private activatedRoute: ActivatedRoute,
        private modalService: NgbModal,
    ) {
        this.loginForm = fb.group({
            email: ["", Validators.required],
            password: ["", Validators.required],
            isRemeber: []
        });
        this.google = (<any>window).google;
    }
    isLogin: string = 'Yes';

    ngOnInit() {
        this.google.accounts.id.initialize({
            //client_id: '333367936793-t45nm5b39se20qdrs4o0v57qtmhkcm1p.apps.googleusercontent.com',
            client_id: '1038458657773-f155jcvi5n1haro62luli4sg1211brk3.apps.googleusercontent.com',
            callback: (resp: any) => this.handleLogin(resp)
        });


        this.google.accounts.id.renderButton(
            document.getElementById("buttonDiv"),
            {
                theme: "filled_black",
                size: "medium",
                shape: 'rectangle',

            }  // customization attributes
        );
    }

    showPassword() {
        this.show = !this.show;
    }

    login() {
        if (this.loginForm.invalid) {
            return;
        }

        this.authenticationService.playerAttendanceEventlogin(this.loginForm.value["email"], this.loginForm.value["password"])
            .pipe(first())
            .subscribe(
                user => {
                    if (user && user.userType === 'P') {
                        this.loginForm.controls[`isRemeber`].setValue(true);
                    }
                    if (this.loginForm.controls[`isRemeber`].value && this.loginForm.controls[`isRemeber`].value === true) {
                        localStorage.setItem('__qu__remeber_User__', JSON.stringify(this.loginForm.controls[`isRemeber`].value));
                    }
                    this.returnUrl = this.route.snapshot.queryParams.returnUrl;
                    if (this.returnUrl === undefined) {
                        localStorage.setItem('isLogin', 'Yes');
                        this.router.navigate(["/dashboard/default"]);
                    }
                    else {

                        this.router.navigate([this.returnUrl]);
                    }
                },
                error => {
                    this.toster.error(error.error);
                });
    };
    imgLogoclick() {

        localStorage.removeItem("isLogin");
        this.router.navigate([""]);
    }

    private decodeToken(token: string) {
        return JSON.parse(atob(token.split(".")[1]))
    }

    handleLogin(response: any) {
        let returnUrl = this.route.snapshot.queryParams.returnUrl;
        if (response) {
            //decode the token
            const payload = this.decodeToken(response.credential)
            this.loginForm.controls['email'].setValue(payload.email);
            this.authenticationService.googlelogin(this.loginForm.controls['email'].value)
                .pipe(first())
                .subscribe(
                    user => {
                        if (this.loginForm.controls[`isRemeber`].value && this.loginForm.controls[`isRemeber`].value === true) {
                            localStorage.setItem('__qu__remeber_User__', JSON.stringify(this.loginForm.controls[`isRemeber`].value));
                        }
                        this.returnUrl = this.route.snapshot.queryParams.returnUrl;
                        if (this.returnUrl === undefined) {
                            localStorage.setItem('isLogin', 'Yes');
                            this.router.navigate(["/dashboard/default"]);
                        }
                        else {
                            this.router.navigate([this.returnUrl]);
                        }
                    },
                    error => {
                        //this.toster.error(error.error);
                        // "You have enter Wrong Email"
                        if (error.error.responseCode === 901) {
                            Swal.fire({
                                title: 'Your Google account is not registered',
                                text: "Do you want to Sign Up",
                                // type: 'warning',
                                showCancelButton: true,
                                confirmButtonText: 'Yes, Sign Up!'
                            }).then((result: any) => {
                                if (result.isConfirmed) {
                                    if (returnUrl) {
                                        this.router.navigate(["/authentication/player-attendance-reg"], { queryParams: { returnUrl: returnUrl } });
                                    }
                                    else {
                                        this.router.navigate(["/authentication/player-attendance-reg"]);
                                    }
                                }
                            });
                        }
                        else {
                            this.toster.error(error.error);
                        }
                    });

        }
    }

    goToReg() {
        let returnUrl = this.route.snapshot.queryParams.returnUrl;
        if (returnUrl) {
            this.router.navigate(["/authentication/player-attendance-reg"], { queryParams: { returnUrl: returnUrl } });
        }
        else {
            this.router.navigate(["/authentication/player-attendance-reg"]);
        }
    };

    navigateToForgotPsw() {
        let returnUrl = this.route.snapshot.queryParams.returnUrl;
        if (returnUrl) {
            this.router.navigate(["/authentication/forget-password-event-attendance"], { queryParams: { returnUrl: returnUrl } });
        }
        else {
            this.router.navigate(["/authentication/forget-password-event-attendance"]);
        }
    };

    goToReportingIssue() {
        this.modalService.open(ReportingIssueComponent, { centered: true });
    }
}
