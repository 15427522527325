import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-stripe-payments',
  templateUrl: './stripe-payments.component.html',
  styleUrls: ['./stripe-payments.component.scss']
})
export class StripePaymentsComponent implements OnInit {
  paymentHandler: any = null;
  constructor() {

  }

  ngOnInit(): void {
    this.invokeStripe();
  }
  invokeStripe() {
    if (!window.document.getElementById('stripe-script')) {
      const script = window.document.createElement('script');
      script.id = 'stripe-script';
      script.type = 'text/javascript';
      script.src = 'https://checkout.stripe.com/checkout.js';
      script.onload = () => {
        this.paymentHandler = (<any>window).StripeCheckout.configure({
          key: 'pk_test_51H7bbSE2RcKvfXD4DZhu',
          locale: 'auto',
          token: function (stripeToken: any) {
            //console.log(stripeToken);
            alert('Payment has been successfull!');
          },
        });
      };
      window.document.body.appendChild(script);
    }
  }

  makePayment(amount: any) {
    const paymentHandler = (<any>window).StripeCheckout.configure({
      key: 'pk_test_51HvRUhACqKY8jEhRJCSrL4nyTaAnyOHbicQpeqBgTmAL1unJX7hJz87lW08oAbNenXMw83TCqyNLS3TgFUX0ECrK00spKvPldY',
      locale: 'auto',
      token: function (stripeToken: any) {
        //console.log(stripeToken);
        alert('Stripe token generated!');
      },
    });
    paymentHandler.open({
      name: 'Positronx',
      description: '3 widgets',
      amount: amount * 100,
    });
  }
}
