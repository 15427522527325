import { Component, OnInit } from '@angular/core';
import { Page } from '../data/models/page';
import { InstallService } from '../data/services/offense';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-public-installs',
  templateUrl: './public-installs.component.html',
  styleUrls: ['./public-installs.component.scss']
})
export class PublicInstallsComponent implements OnInit {
  page = new Page();
  rows = new Array<any>();
  viewType: string = "List";
  public openSidebar: boolean = false;
  public listView: boolean = true;

  constructor(
    private router: Router,
    public toster: ToastrService,
    public installService: InstallService,
  ) {
    this.page.offset = 0;
    this.page.limit = 20;
   }

  ngOnInit(): void {
    //this.loadPublicInstall();
    this.loadPage(1);
  }

  loadPage(page: number) {
    if (page !== this.page.offset) {
      this.page.offset = page;
      this.loadPublicInstall();
    }
  };

  loadPublicInstall()
  {
    this.installService.getAllPublicInstall()
    .pipe(first())
    .subscribe((resp: any) => {
      this.rows = resp.result;
      this.page.count = resp.count;
      //this.viewScroller.scrollToPosition([0, 0]);
    },
    error => {
      this.toster.error("!Failed to load data.");
    });
  };

  print(id: string) {
    window.open(environment.printPath + 'print/install/' + id, '_blank');
  };

  presentation(id: string) {
    this.router.navigate(["/installs/presentation", { id: id }]);
  };
  

}
