import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { SessionUser } from 'src/app/data/models/auth/session-user';
import { AuthenticationService } from 'src/app/data/services/auth/authentication.service';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { NavService } from 'src/app/shared/services/nav.service';

@Component({
  selector: 'app-legacy-login',
  templateUrl: './legacy-login.component.html',
  styles: [],
  encapsulation: ViewEncapsulation.None
})
export class LegacyLoginComponent implements OnInit {

  constructor(
    private loaderService: LoaderService,
    private route: ActivatedRoute,
    private router: Router,
    public toster: ToastrService,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit(): void {

    var userName = this.route.snapshot.paramMap.get('userName');
    var returnUrl = this.route.snapshot.paramMap.get('returnUrl');
    if (returnUrl) {
      returnUrl = returnUrl?.toString().replace("_", "/");
    }
    this.loaderService.isLoading.next(true);
    localStorage.removeItem('__qu__');
    this.authenticationService.legacyLogin(userName?.toString())
      .pipe(first())
      .subscribe(
        user => {
          this.loaderService.isLoading.next(false);
          if (user && user.value) {
            if (returnUrl) {
              this.router.navigate(["/" + returnUrl]);
            }
            else {
              //this.router.navigate(["/dashboard/default"]);
              window.location.href = "/dashboard/default"
            }
          }
          else {
            // window.location.href = "https://localhost:44398/login";
            //window.location.href = "https://strongside-api-dev.azurewebsites.net/login";
            window.location.href = "https://strongside.app";
          }
        },
        error => {
          // window.location.href = "https://localhost:44398/login";
          //window.location.href = "https://strongside-api-dev.azurewebsites.net/login";
          window.location.href = "https://strongside.app";
        });
  }
}
