<!-- Page Header Start-->
<div class="page-header" [class.close_icon]="navServices.collapseSidebar">
	<div class="header-wrapper row m-0">
		<app-search></app-search>
		<div class="header-logo-wrapper">
			<div class="logo-wrapper">
				<a routerLink='/'>
					<img class="img-fluid for-light" src="assets/images/logo/logo.png" alt="logo" title="logo">
					<img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="logo_dark"
						title="logo_dark">
				</a>
			</div>
			<div class="toggle-sidebar" (click)="sidebarToggle()">
				<app-feather-icons [icon]="'grid'" class="status_toggle middle"></app-feather-icons>
			</div>
		</div>
		<div class="profile-media-img-w" (click)="goToOrganization()" style="cursor: pointer;">
			<!-- ngbTooltip="Organization Profile" -->
			<img class="profile-media-img" alt="{{accountData?.logoFile ? accountData?.logoFile : 'avatar'}}"
				[src]="organizationProfileUrl ? organizationProfileUrl : organizationProfileImageUrl? organizationProfileImageUrl : 'assets/images/players/player_rectangular.png'" />
		</div>
		<div class="ml-2">
			<p class="m-0 c-accoutName-width"> <strong>{{accoutName}}</strong> </p>
		</div>
		<!-- <div class="ribonaccname">
			<div class="account">
				<p class="m-0">{{accoutName}}</p>
			</div>
			<div class="ribonset">
				<p class="m-0">
					Active Package : 
					<span>{{packageName}}</span>
				</p>
			</div>
		</div> -->
		<div class="left-header col horizontal-wrapper pl-0 d-md-flex justify-content-md-around align-items-md-center">
			<div class="leftsidep h-100">
				<!-- <p class="m-0">{{accoutName}}</p> -->
			</div>
			<div class="in_mob btn-showcase">
				<button type="button" class="btn btn-air-primary btn-outline-primary windowsbtn packagebtn"
					(click)="go_to_payment_page()" *ngIf="upgradePackageBtn">
					<div class="windbtninner">
						<div>
							<span>Upgrade</span>
						</div>
						<div class="package-icn">
							<!-- <i class="icofont icofont-refresh"></i> -->
							<i class="icofont icofont-arrow-up"></i>
						</div>
					</div>
				</button>
				<button type="button" class="btn btn-air-primary btn-outline-primary windowsbtn"
					(click)="windowsDownload()">
					<div class="windbtninner">
						<div>
							<span>Download <br /><small>For Windows</small></span>
						</div>
						<div>
							<i class="fab fa-windows"></i>
						</div>
					</div>
				</button>
				<button type="button" class="btn btn-air-primary btn-outline-primary windowsbtn"
					(click)="macDownload()">
					<div class="windbtninner">
						<div>
							<span>Download <br /><small>For Mac</small></span>
						</div>
						<div>
							<i class="fab fa-apple"></i>
						</div>
					</div>
				</button>
			</div>
			<!-- <div class="cr-trail">
				<p *ngIf="currentpackageName" class="mb-0"
					style="background: yellow;padding: 0px 8px;border-radius: 2px;">
					<strong>{{currentpackageName}}</strong>
				</p>
			</div> -->
		</div>

		<div class="d-flex justify-content-center align-items-center flex-wrap cr-trail in_mob"
			*ngIf="packageEndDaysLeft && packageEndDaysLeft <= 5 && userType == 'H'">
			<div class="mr-3">
				<div style="font-size: 11px;text-align: center;">
					<p style="background: yellow;padding: 0px 8px;border-radius: 2px;margin-bottom: 6px;">
						<strong>{{packageEndDaysLeft}} days left</strong>
					</p>
					<!-- <button style="cursor: pointer;" class="btn btn-outline-danger btn-sm"
						(click)="cancelPackageSubcription()"
						*ngIf="isPackagePaid == true && packageSubscriptionCanceled == false">
						Cancel Subscription
					</button> -->
					<!-- <button class="btn btn-primary btn-sm" (click)="resumePackageSubcription()"
						*ngIf="isPackagePaid == true && packageSubscriptionCanceled == true && isUpcomingSubscription == false">
						Resume Subscription
					</button> -->
				</div>
			</div>
		</div>
		<div class="nav-right col-md-4 col-12 pull-right right-header p-0 pt-0">
			<ul class="nav-menus">
				<!-- <li class="language-nav" (click)="languageToggle()">
						<app-languages></app-languages>
					</li> -->
				<!-- <li style="width: 36px;height: 36px;padding: 4px;text-align: center;border-radius: 50% !important;border: 2px solid #7c7c7c;"
						*ngIf="isViewCoachLogin == true" ngbTooltip="Back to my login">
						<span class="header-search" (click)="goToCoachLogin()">
							<app-feather-icons [icon]="'key'"></app-feather-icons>
						</span>
					</li> -->
				<li
					style="width: 36px;height: 36px;padding: 4px;text-align: center;border-radius: 50% !important;border: 2px solid #7c7c7c;">
					<span class="header-search" (click)="searchToggle()">
						<app-feather-icons [icon]="'search'"></app-feather-icons>
					</span>
				</li>
				<!--<li class="onhover-dropdown">
						<app-bookmark></app-bookmark>
					</li>-->
				<li class="p-0">
					<div style="width: 36px;height: 36px;padding: 6px;text-align: center;border-radius: 50% !important;border: 2px solid #7c7c7c;"
						class="mode" (click)="layoutToggle()">
						<i class="fa fa-moon-o" *ngIf="!dark"></i>
						<i class="fa fa-lightbulb-o" *ngIf="dark"></i>
					</div>
				</li>
				<!--<li class="cart-nav onhover-dropdown">
						<app-cart></app-cart>
					</li>-->
				<!--<li class="onhover-dropdown">
						<app-message-box></app-message-box>
					</li>-->
				<li style="width: 36px;height: 36px;padding: 4px;text-align: center;border-radius: 50% !important;border: 2px solid #7c7c7c;"
					class="maximize">
					<!-- <span (click)="toggleFullScreen()" class="text-dark" style="cursor: pointer;">
							<app-feather-icons [icon]="'maximize'"></app-feather-icons>
						</span> -->
					<span (click)="goToHelp()" class="text-dark" style="cursor: pointer;">
						<!-- <app-feather-icons [icon]="'help-circle'"></app-feather-icons> -->
						<i class="icofont icofont-question" style="font-size: 1.6rem;"></i>
					</span>
				</li>
				<li class="p-0" *ngIf="userType !== 'F'">
					<div class="b-r-10 profile-list-img-w"
						style="width: 36px;height: 36px;padding: 7px;text-align: center;border-radius: 50% !important;border: 2px solid #7c7c7c;"
						(click)="profile()" placement="bottom" ngbTooltip="Profile">
						<img alt="profile"
							[src]="profileUrl ? profileUrl : profileImageUrl? profileImageUrl : 'assets/images/players/player_rectangular.png'" />
						<!-- <i class="fa fa-user"></i> -->
					</div>
				</li>
				<li class="p-0" *ngIf="userType === 'H' && isPackagePaid || userAccess.isDemoAccount">
					<div class="b-r-10"
						style="width: 36px;height: 36px;padding: 7px;text-align: center;border-radius: 50% !important;border: 2px solid #7c7c7c;"
						(click)="openShare()" placement="bottom" ngbTooltip="Invite Player">
						<i class="fa fa-share-alt"></i>
					</div>
				</li>
				<li class="onhover-dropdown p-0" *ngIf="userType !== 'F'">
					<app-notification></app-notification>
				</li>
				<li class="p-0 logview">
					<div class="b-r-10"
						style="width: 36px;height: 36px;padding: 7px;text-align: center;border-radius: 50% !important;border: 2px solid #7c7c7c;"
						(click)="logout()" placement="bottom" ngbTooltip="Log Out">
						<i class="fa fa-sign-out"></i>
					</div>
				</li>
				<li class="p-0 logview" *ngIf="userType == 'H'">
					<div class="b-r-10"
						style="width: 36px;height: 36px;padding: 7px;text-align: center;border-radius: 50% !important;border: 2px solid #7c7c7c;"
						(click)="openPlayerList()" placement="bottom" ngbTooltip="Players">
						<i class="fa fa-key"></i>
					</div>
				</li>
				<li class="only-mobile-Login"
					style="width: 36px;height: 36px;padding: 4px;text-align: center;border-radius: 50% !important;border: 2px solid #7c7c7c;"
					*ngIf="isViewCoachLogin == true" ngbTooltip="Back to my login">
					<span class="header-search" (click)="goToCoachLogin()">
						<app-feather-icons [icon]="'key'"></app-feather-icons>
					</span>
				</li>
				<li class="profile-nav onhover-dropdown p-0">
					<app-my-account></app-my-account>
				</li>
			</ul>
		</div>
	</div>
</div>
<!-- Page Header Ends -->

<ng-template #shareModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Share</h4>
		<button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<div class="form-row">
			<div class="col-md-12">
				<!-- <label for="clipboard1">Share Link</label> -->
				<input class="form-control" id="clipboard1" [ngModel]="shareUrl" type="text" placeholder="Share Link"
					readonly #userinput>
			</div>
			<div class="col-md-12">
				<div class="d-flex justify-content-center align-items-center">
					<div class="icnbox facebook" data-href="https://developers.facebook.com/docs/plugins/"
						data-layout="button_count" data-size="small">
						<a target="_blank"
							href="https://www.facebook.com/sharer/sharer.php?u={{shareUrl}}&amp;src=sdkpreparse"
							class="fb-xfbml-parse-ignore" ngbTooltip="Facebook" placement="bottom">
							<i class="fa fa-facebook"></i>
						</a>
					</div>
					<div class="icnbox whatsapp">
						<a target="_blank" [href]="whatsAppshareUrl" ngbTooltip="Whatsapp" placement="bottom">
							<i data-action="share/whatsapp/share" class="fa fa-whatsapp"></i>
						</a>
					</div>
					<div class="icnbox insta" ngbTooltip="Instagram" placement="bottom">
						<a target="_blank" [href]="instagramShareUrl">
							<i class="fa fa-instagram"></i>
						</a>
					</div>
					<div class="icnbox twitter">
						<a target="_blank" [href]="twitterShareUrl" ngbTooltip="Twitter" placement="bottom">
							<i class="fa-brands fa-x-twitter"></i>
						</a>
					</div>
					<div class="icnbox copy">
						<a href="javascript:void(0)" (click)="copyInputMessage(userinput)" ngbTooltip="Copy Link"
							placement="bottom">
							<i class="fa fa-clipboard" aria-hidden="true"></i>
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #playerListModal let-modal>

	<div class="modal-header">
		<h5 class="modal-title">Player List</h5>
		<button type="button" class="close" data-dismiss="modal" (click)="closeModal(modal)">
			<i class="icofont icofont-close"></i>
		</button>
	</div>
	<div class="modal-body p-3 c-searchForm-form">
		<div>
			<form [formGroup]="searchForm" (ngSubmit)="search()">
				<div class="input-group mb-3">
					<input #searchBox type="search" class="form-control" placeholder="Search.." data-original-title=""
						title="" formControlName="searchText" aria-describedby="basic-addon1">
					<button class="input-group-append c-search-btn">
						<span class="input-group-text searchBx" id="basic-addon1"><i class="fa fa-search"></i></span>
					</button>
				</div>
			</form>
			<div class="listboxex c-searchForm-list">
				<table class="table">
					<tr>
						<th>Player Name</th>
						<th>Login</th>
					</tr>
					<tr *ngFor="let player of rows; let i = index;">
						<td>{{player.name}}</td>
						<td><i class="fa fa-key" aria-hidden="true" (click)="goToPalyerLogin(player)"
								style="cursor: pointer;"></i>
						</td>
					</tr>
				</table>
			</div>
		</div>
	</div>
</ng-template>