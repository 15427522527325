<div class="container">
    <div class="row justify-content-center align-items-center">
        <div class="col col-md-8 col-12 pt-3 pb-3">
            <div class="logoBoxx">
                <img src="../../assets/images/logo/login.png" alt="looginpage" title="looginpage"
                    class="img-fluid img-responsive">
            </div>
            <!-- <h2 class="main-title" >Welcome to Strongside</h2> -->
            <div class="card mb-3">
                <div class="card-header pt-3">
                    <h4 class="m-0 card-title" style="font-size: 21px;">Welcome to ShowTime</h4>
                </div>
                <div class="card-body">
                    <form class="row" method="POST" [formGroup]="playerRegForm" autocomplete="off" novalidate
                        [ngClass]="{'was-validated': validate}">
                        <div class="col-md-12 col-12">
                            <div class="form-group mb-2">
                                <!-- <label class="form-label" >Name <span style="color: rgb(222, 11, 11);">*</span></label> -->
                                <input class="form-control" type="text" formControlName="name" placeholder="Name *"
                                    required>
                                <div class="invalid-feedback">
                                    Name is required.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 col-12">
                            <div class="form-group mb-2">
                                <!-- <label class="form-label" >Email <span style="color: rgb(222, 11, 11);">*</span></label> -->
                                <input class="form-control" type="text" formControlName="email" placeholder="Email *"
                                    required>
                                <div class="invalid-feedback">
                                    Email is required.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 col-12">
                            <div class="form-group mb-2">
                                <!-- <label class="form-label" >Mobile</label> -->
                                <input class="form-control" formControlName="phone" placeholder="Mobile No."
                                    (keyup)="onMobileChange()">
                            </div>
                        </div>
                        <div class="col-md-12 col-12">
                            <div class="form-group mb-2">
                                <!-- <label class="form-label" >Twitter&nbsp;<span style="color: rgb(222, 11, 11);">*</span></label> -->
                                <input class="form-control" type="text" formControlName="twitterLink"
                                    placeholder="Twitter Link *" required>
                                <div class="invalid-feedback">
                                    Twiiter UserName is required.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 col-12">
                            <div class="form-group mb-2">
                                <!-- <label class="form-label" >Hudl Link&nbsp;<span style="color: rgb(222, 11, 11);">*</span></label> -->
                                <input class="form-control" type="text" formControlName="hudlLink"
                                    placeholder="Hudl Link *" required>
                                <div class="invalid-feedback">
                                    Hudl Link is required.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 col-12">
                            <div class="form-group mb-2">
                                <!-- <label class="form-label" >Maxpreps Link&nbsp;<span style="color: rgb(222, 11, 11);">*</span></label> -->
                                <input class="form-control" type="text" formControlName="maxprepsLink"
                                    placeholder="Maxpreps Link *" required>
                                <div class="invalid-feedback">
                                    Maxpreps Link is required.
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4 col-12 ml-auto">
                    <div class="d-flex justify-content-end align-items-center">
                        <button class="btn btn-primary btn-sub  w-100" (click)="onSubmit()">
                            <span>Submit</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>