import { Component, OnInit, HostListener, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../../services/firebase/auth.service';
import { AuthenticationService } from 'src/app/data/services/auth/authentication.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { first } from 'rxjs/operators';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { PlayerService } from 'src/app/data/services/team-management';
import { ToastrService } from 'ngx-toastr';
import { Player } from 'src/app/data/models/team-management';
import { OffenseSearchService } from 'src/app/data/services/offense/offense-search.service';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit {
  isPackagePaid: boolean = false;
  [x: string]: any;

  userName = null;
  roleName = null;
  public customizer: boolean = false;
  public screenwidth: any = window.innerWidth;
  //public customizer: boolean = false;
  public layoutType: string = 'ltr';
  public sidebarType: string = 'compact-wrapper';
  public sidebarSetting: string = 'default-sidebar';
  public MIXLayout: string = 'default';

  public primary_color: string = '#7366ff';
  public secondary_color: string = '#f73164';

  accoutName: string = "";
  packageName: string = "";
  isLifeTime: any;
  name: string = "";
  role: string = "";
  userType: string = "";
  @ViewChild('playerListModal', { static: true })
  playerListModal!: ElementRef<HTMLElement>;
  searchForm: FormGroup;
  loginForm: FormGroup;
  rows: Array<Player> = [];
  isViewCoachLogin: boolean = false;

  constructor(
    private router: Router,
    public authService: AuthService,
    private authenticationService: AuthenticationService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private loader: LoaderService,
    private playerService: PlayerService,
    public toster: ToastrService,
    private route: ActivatedRoute,
    private offensiveSearch: OffenseSearchService
  ) {
    this.isPackagePaid = this.authenticationService.packagePaid;

    const user = localStorage.getItem('__qu__');
    if (user) {
      this.accoutName = JSON.parse(user).accountName;
      this.userType = JSON.parse(user).userType;
      this.name = JSON.parse(user).name;
      this.role = JSON.parse(user).role;
    }

    const headCoachUser = sessionStorage.getItem('__qu_HeadCoach__')
    if (headCoachUser) {
      this.headCoachUserName = JSON.parse(headCoachUser).headCoachUserName;
      this.isViewCoachLogin = JSON.parse(headCoachUser).isViewPlayerLogin;
    }

    this.loginForm = this.formBuilder.group({
      headCoachUserName: [],
      isViewPlayerLogin: [false],
      playerUserName: [],
    });

    this.searchForm = this.formBuilder.group({
      searchText: [''],
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.screenwidth = event.target.innerWidth;
  }

  ngOnInit() {
    //const currentUser = sessionStorage.getItem('__qu__');
    const currentUser = localStorage.getItem('__qu__');
    if (currentUser) {
      this.userName = JSON.parse(currentUser).userName;
      this.roleName = JSON.parse(currentUser).role;
    }

    this.authenticationService.getPackageDetailsByLogin().subscribe(resp => {
      this.packageName = resp.name;
      this.isLifeTime = resp.isLifeTime;
      if (this.userType === "H" && this.packageName != "Varsity Package") {
        this.upgradePackageBtn = true;
      }
    });
  }

  changePass() {
    this.router.navigate(['/authentication/reset-password']);
  };

  logout() {
    //sessionStorage.removeItem('__qu__');
    this.offensiveSearch.setSearch('');
    localStorage.removeItem('__qu__remeber_User__');
    localStorage.removeItem('__qu__');
    sessionStorage.removeItem('__qu_HeadCoach__');
    this.router.navigate(['/auth/login']);
  };

  Customizer() {
    this.customizer = !this.customizer;
  }

  accSetting() {
    this.router.navigate(['/account-settings']);
  };

  // Open Modal
  openModal(popup: any) {
    this.modalService.open(popup, { backdropClass: 'dark-modal', centered: true });
  }

  // Open customizer
  // Customizer() {
  //   this.customizer = !this.customizer;
  // }

  // Customize Layout Type
  customizeLayoutType(val: any) {
    this.layoutType = val;
    this.layout.config.settings.layout_type = val;
    if (val == 'rtl') {
      document.getElementsByTagName('html')[0].setAttribute('dir', val);
    } else {
      document.getElementsByTagName('html')[0].removeAttribute('dir');
    }
  }

  // Customize Sidebar Type
  customizeSidebarType(val: any) {
    this.sidebarType = val;
    this.layout.config.settings.sidebar.type = val;
    this.layout.config.settings.sidebar.body_type = val == 'compact-wrapper' ? 'sidebar-icon' : 'horizontal-menu';
  }

  // Customize Sidebar Setting
  customizeSidebarSetting(val: any) {
    this.sidebarSetting = val;
    this.layout.config.settings.sidebar_type = val;
  }

  // Customize Mix Layout
  customizeMixLayout(val: any) {
    this.MIXLayout = val;
    this.layout.config.settings.layout_version = val;
  }

  applyColor() {
    document.documentElement.style.setProperty('--theme-deafult', this.primary_color);
    document.documentElement.style.setProperty('--theme-secondary', this.secondary_color);
    this.layout.config.color.primary_color = this.primary_color;
    this.layout.config.color.secondary_color = this.secondary_color;
  }

  resetColor() {
    document.documentElement.style.setProperty('--theme-deafult', '#7366ff');
    document.documentElement.style.setProperty('--theme-secondary', '#f73164');
    (<HTMLInputElement>document.getElementById('ColorPicker1')).value = '#7366ff';
    (<HTMLInputElement>document.getElementById('ColorPicker2')).value = '#f73164';
    this.layout.config.color.primary_color = '#7366ff';
    this.layout.config.color.secondary_color = '#f73164';
  }

  openPlayerList() {
    this.modalService.open(this.playerListModal, { centered: true, size: 'lg' });
    this.loadPlayer();
  }

  loadPlayer() {
    const searchValue = this.searchForm.controls['searchText'].value;
    this.loader.isLoading.next(true);
    this.playerService.getAllPlayer(searchValue)
      .pipe(first())
      .subscribe((resp: any) => {
        this.loader.isLoading.next(false);
        if (resp) {
          this.rows = resp;
        }
      },
        (error: any) => {
          this.loader.isLoading.next(false);
          this.toster.error("!Failed to load data.");
        });
  };

  closeModal(modal: any) {
    modal.dismiss();
  };

  goToPalyerLogin(playerData: any) {
    this.loginForm.controls['headCoachUserName'].setValue(this.userName);
    this.loginForm.controls['isViewPlayerLogin'].setValue(true);
    this.loginForm.controls['playerUserName'].setValue(playerData.email ? playerData.email : playerData.userName);
    if (!this.loginForm.controls['playerUserName'].value) {
      this.toster.error('Player username or email is not found')
      return;
    }
    localStorage.removeItem('__qu__remeber_User__');
    localStorage.removeItem('__qu__');
    this.loader.isLoading.next(true);
    this.authenticationService.legacyLogin(this.loginForm.controls['playerUserName'].value)
      .pipe(first())
      .subscribe(
        user => {
          this.loader.isLoading.next(false);
          if (this.loginForm.controls[`isViewPlayerLogin`].value && this.loginForm.controls[`isViewPlayerLogin`].value === true) {
            sessionStorage.setItem('__qu_HeadCoach__', JSON.stringify(this.loginForm.value));
          }
          this.returnUrl = this.route.snapshot.queryParams.returnUrl;
          if (this.returnUrl === undefined) {
            localStorage.setItem('isLogin', 'Yes');
            this.router.navigate(["/dashboard/default"]).then(() => {
              window.location.reload();
            });
          }
          else {
            this.router.navigate([this.returnUrl]).then(() => {
              window.location.reload();
            });
          }
        },
        error => {
          this.loader.isLoading.next(false);
          this.toster.error(error.error);
        });
  };

  search() {
    this.loadPlayer();
  };

  goToCoachLogin() {
    localStorage.removeItem('__qu__remeber_User__');
    localStorage.removeItem('__qu__');
    sessionStorage.removeItem('__qu_HeadCoach__');
    this.authenticationService.legacyLogin(this.headCoachUserName)
      .pipe(first())
      .subscribe(
        user => {
          this.returnUrl = this.route.snapshot.queryParams.returnUrl;
          if (this.returnUrl === undefined) {
            localStorage.setItem('isLogin', 'Yes');
            this.router.navigate(["/dashboard/default"]).then(() => {
              window.location.reload();
            });
          }
          else {
            this.router.navigate([this.returnUrl]).then(() => {
              window.location.reload();
            });
          }
        },
        error => {
          this.toster.error(error.error);
        });
  };
}
