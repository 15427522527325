import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgbCarouselConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InstallService } from '../data/services/offense';
import { ActivatedRoute, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoaderService } from '../shared/services/loader.service';
import { Lightbox } from 'ngx-lightbox';
import { AuthenticationService } from '../data/services/auth/authentication.service';
import { FeedService } from '../data/services/global';
import { first } from 'rxjs/operators';
import { Feed } from '../data/models/global';

@Component({
  selector: 'app-public-install-presentations',
  templateUrl: './public-install-presentations.component.html',
  styleUrls: ['./public-install-presentations.component.css'],
  providers: [NgbCarouselConfig],
	styles: [],
	encapsulation: ViewEncapsulation.None,
})
export class PublicInstallPresentationsComponent implements OnInit {
	showNavigationArrows = false;
	showNavigationIndicators = false;
	images: any[] = []; //['assets/print_img/right(formation).PNG', 'assets/print_img/dart(formation).PNG'];
	videos: any[] = [];

	id: any;
	presentationDetails: any[] = [];
	detailObj: any = {};
	sectionName: any;
	playsAssignments: any[] = [];
	quizdetails: any[] = [];

	@ViewChild('content', { static: true })
	content!: ElementRef<HTMLElement>;
	@ViewChild('contentQuizParticipate', { static: true })
	contentQuizParticipate!: ElementRef<HTMLElement>


	parrent = 0;
	child = 0;
	pMenu = false;

	oldDetailId: string = '';

	buttonText: string = "Quiz Participate";
	filetype: string = "";
	filesequence: any;
	quizCompleted: boolean = false;
	quizQuestion: any[] = [];
	questionCount = 1;
	quizParticipateid = "";
	participantImages: any;
	participantVideo: any;

	viewFileUrl?: string = "";
	viewFileImg?: boolean = true;
	viewFileName?: string = "";
	@ViewChild('videoViewcontent', { static: true })
	videoViewcontent!: ElementRef<HTMLElement>;

  constructor(
	config: NgbCarouselConfig,
	private installService: InstallService,
	private route: ActivatedRoute,
	private router: Router,
	public toster: ToastrService,
	private loaderService: LoaderService,
	private modalService: NgbModal,
	public _lightbox: Lightbox,
	private toaster: ToastrService,
	private authenticationService: AuthenticationService,
	private feedService: FeedService
  ) {
		// customize default values of carousels used by this component tree
		config.showNavigationArrows = true;
		config.showNavigationIndicators = true;
		// customize default values of carousels used by this component tree
		config.interval = 10000;
		config.wrap = false;
		config.keyboard = false;
		config.pauseOnHover = false;

		this.router.events.subscribe((event: any) => {
			switch (true) {
				case event instanceof NavigationStart:
				case event instanceof NavigationEnd: {
					if (this.oldDetailId) {
						this.update_activity(this.oldDetailId);
					}
					break;
				}
				case event instanceof NavigationCancel:
				case event instanceof NavigationError:
				default: {
					break;
				}
			}
		});   
    }

  presentionMenu() {
	this.pMenu = !this.pMenu;
  }


  ngOnInit(): void {
	this.id = this.route.snapshot.paramMap.get('id');
		this.loaderService.isLoading.next(true);
		this.loaderService.loadingMessage.next('loading...');
		this.installService.getPublicPresentation(this.id).subscribe((data) => {
			if (data) {
				this.presentationDetails = data;
				let i = 1;
				this.presentationDetails.forEach((elem) => {
					if (elem.details && elem.details.length > 0) {
						elem.details.forEach((e: any) => {
							if (i == 1) {
								e.isActive = true;
								this.detailObj = e;
								this.images = e.imageList;
								this.playsAssignments = e.playsAssignments;
								this.quizdetails = e.quiz;
								this.videos = e.videoList;
								this.sectionName = elem.name;
								this.save_activity(e.id);
								this.oldDetailId = e.id;
							}
							i++;
						});
					}
				});
			}
			this.loaderService.isLoading.next(false);
		});
    };

	fnLiClick(section: any, element: any, index1: number, index2: number) {
		this.parrent = index1;
		this.child = index2;
		this.presentationDetails.forEach((elem) => {
			if (elem.details && elem.details.length > 0) {
				elem.details.forEach((e: any) => {
					e.isActive = undefined;
				});
			}
		});
		element.isActive = true;
		this.detailObj = element;
		this.images = element.imageList;
		this.videos = element.videoList;
		this.playsAssignments = element.playsAssignments;
		this.quizdetails = element.quiz;
		this.sectionName = section.name;

		this.save_activity(element.id);
		if (this.oldDetailId && this.oldDetailId != element.id) {
			this.update_activity(this.oldDetailId);
			this.oldDetailId = element.id;
		} else {
			this.oldDetailId = element.id;
		}
	};

	async save_activity(detailId: string) {
	this.installService
		.saveInstallActivity(this.id, detailId)
		.pipe(first())
		.subscribe((resp: any) => {
			if (resp && resp.success) {
				//this.toster.success(resp.responseMsg);
			} else {
				// this.toster.error(resp.responseMsg);
			}
		});
	};

	async update_activity(detailId: string) {
		this.installService.updateInstallActivity(this.id, detailId)
			.pipe(first())
			.subscribe((resp: any) => {
				if (resp && resp.success) {
					//this.toster.success(resp.responseMsg);
				}
				else {
					// this.toster.error(resp.responseMsg);
				}
			});
	};

	show_video() {
		this.modalService.open(this.content, { centered: true, size: 'xl' });
	};

	owlcarouselPlayOptions = {
		loop: true,
		margin: 10,
		items: 1,
		nav: true,
		dots: false,
		autoHeight: true,
		navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
		responsive: {
			576: {
				items: 1
			},
			768: {
				items: 1
			},
			992: {
				items: 1
			}
		}
	};

	files_view(fileUrl?: string, fileName?: string, isImg?: boolean) {
		this.viewFileUrl = fileUrl;
		this.viewFileImg = isImg;
		this.viewFileName = fileName;
		this.modalService.open(this.videoViewcontent, { centered: true, size: 'xl' });
	};

	next_clk() {
		if (this.presentationDetails.length > 0) {
			if (this.presentationDetails.length - 1 > this.parrent) {
				this.presentationDetails.forEach((elem) => {
					if (elem.details && elem.details.length > 0) {
						elem.details.forEach((e: any) => {
							e.isActive = undefined;
						});
					}
				});
				if (
					this.presentationDetails[this.parrent].details.length - 1 <=
					this.child
				) {
					this.parrent += 1;
					this.child = 0;

					this.presentationDetails[this.parrent].details[
						this.child
					].isActive = true;
					this.detailObj =
						this.presentationDetails[this.parrent].details[
						this.child
						];
					this.images =
						this.presentationDetails[this.parrent].details[
							this.child
						].imageList;
					this.playsAssignments =
						this.presentationDetails[this.parrent].details[
							this.child
						].playsAssignments;
					this.quizdetails = this.presentationDetails[this.parrent].details[this.child].quiz;
					this.sectionName =
						this.presentationDetails[this.parrent].name;

					this.save_activity(
						this.presentationDetails[this.parrent].details[
							this.child
						].id
					);
					if (
						this.oldDetailId &&
						this.oldDetailId !=
						this.presentationDetails[this.parrent].details[
							this.child
						].id
					) {
						this.update_activity(this.oldDetailId);
						this.oldDetailId =
							this.presentationDetails[this.parrent].details[
								this.child
							].id;
					} else {
						this.oldDetailId =
							this.presentationDetails[this.parrent].details[
								this.child
							].id;
					}
				} else {
					this.child += 1;

					this.presentationDetails[this.parrent].details[
						this.child
					].isActive = true;
					this.detailObj =
						this.presentationDetails[this.parrent].details[
						this.child
						];
					this.images =
						this.presentationDetails[this.parrent].details[
							this.child
						].imageList;
					this.playsAssignments =
						this.presentationDetails[this.parrent].details[
							this.child
						].playsAssignments;
					this.quizdetails = this.presentationDetails[this.parrent].details[this.child].quiz;
					this.sectionName =
						this.presentationDetails[this.parrent].name;

					this.save_activity(
						this.presentationDetails[this.parrent].details[
							this.child
						].id
					);
					if (
						this.oldDetailId &&
						this.oldDetailId !=
						this.presentationDetails[this.parrent].details[
							this.child
						].id
					) {
						this.update_activity(this.oldDetailId);
						this.oldDetailId =
							this.presentationDetails[this.parrent].details[
								this.child
							].id;
					} else {
						this.oldDetailId =
							this.presentationDetails[this.parrent].details[
								this.child
							].id;
					}
				}
			} else if (
				this.presentationDetails.length - 1 == this.parrent &&
				this.presentationDetails[this.parrent].details.length - 1 >=
				this.child
			) {
				this.child += 1;
				if (
					this.presentationDetails[this.parrent].details[this.child]
				) {
					this.presentationDetails.forEach((elem) => {
						if (elem.details && elem.details.length > 0) {
							elem.details.forEach((e: any) => {
								e.isActive = undefined;
							});
						}
					});
					this.presentationDetails[this.parrent].details[
						this.child
					].isActive = true;
					this.detailObj =
						this.presentationDetails[this.parrent].details[
						this.child
						];
					this.images =
						this.presentationDetails[this.parrent].details[
							this.child
						].imageList;
					this.playsAssignments =
						this.presentationDetails[this.parrent].details[
							this.child
						].playsAssignments;
					this.quizdetails = this.presentationDetails[this.parrent].details[this.child].quiz;
					this.sectionName =
						this.presentationDetails[this.parrent].name;

					this.save_activity(
						this.presentationDetails[this.parrent].details[
							this.child
						].id
					);
					if (
						this.oldDetailId &&
						this.oldDetailId !=
						this.presentationDetails[this.parrent].details[
							this.child
						].id
					) {
						this.update_activity(this.oldDetailId);
						this.oldDetailId =
							this.presentationDetails[this.parrent].details[
								this.child
							].id;
					} else {
						this.oldDetailId =
							this.presentationDetails[this.parrent].details[
								this.child
							].id;
					}
				}
			}
		}
	};

	pre_clk() {
		if (this.parrent == 0 && this.child == 0) {
			return;
		} else {
			this.presentationDetails.forEach((elem) => {
				if (elem.details && elem.details.length > 0) {
					elem.details.forEach((e: any) => {
						e.isActive = undefined;
					});
				}
			});
			if (this.child <= 0) {
				this.parrent -= 1;
				this.child =
					this.presentationDetails[this.parrent].details.length - 1;

				this.presentationDetails[this.parrent].details[
					this.child
				].isActive = true;
				this.detailObj =
					this.presentationDetails[this.parrent].details[this.child];
				this.images =
					this.presentationDetails[this.parrent].details[
						this.child
					].imageList;
				this.playsAssignments =
					this.presentationDetails[this.parrent].details[
						this.child
					].playsAssignments;
				this.quizdetails = this.presentationDetails[this.parrent].details[this.child].quiz;
				this.sectionName = this.presentationDetails[this.parrent].name;

				this.save_activity(
					this.presentationDetails[this.parrent].details[this.child]
						.id
				);
				if (
					this.oldDetailId &&
					this.oldDetailId !=
					this.presentationDetails[this.parrent].details[
						this.child
					].id
				) {
					this.update_activity(this.oldDetailId);
					this.oldDetailId =
						this.presentationDetails[this.parrent].details[
							this.child
						].id;
				} else {
					this.oldDetailId =
						this.presentationDetails[this.parrent].details[
							this.child
						].id;
				}
			} else {
				this.child -= 1;

				this.presentationDetails[this.parrent].details[
					this.child
				].isActive = true;
				this.detailObj =
					this.presentationDetails[this.parrent].details[this.child];
				this.images =
					this.presentationDetails[this.parrent].details[
						this.child
					].imageList;
				this.playsAssignments =
					this.presentationDetails[this.parrent].details[
						this.child
					].playsAssignments;
				this.quizdetails = this.presentationDetails[this.parrent].details[this.child].quiz;
				this.sectionName = this.presentationDetails[this.parrent].name;

				this.save_activity(
					this.presentationDetails[this.parrent].details[this.child]
						.id
				);
				if (
					this.oldDetailId &&
					this.oldDetailId !=
					this.presentationDetails[this.parrent].details[
						this.child
					].id
				) {
					this.update_activity(this.oldDetailId);
					this.oldDetailId =
						this.presentationDetails[this.parrent].details[
							this.child
						].id;
				} else {
					this.oldDetailId =
						this.presentationDetails[this.parrent].details[
							this.child
						].id;
				}
			}
		}
	};

	cancel() {
		this.router.navigate(['/installs']);
	};

	open(index: number): void {
		this._lightbox.open(this.images, index);
	};

	// Quiz Particiapnt start//
	Quiz_participateModal(id: any) {
		this.quizCompleted = false;
		this.quizQuestion = [];
		this.questionCount = 1;
		this.quizParticipateid = id;
		this.installService.getParticipateQuiz(id).subscribe(data => {
			this.participantImages = data.quizFiles;
			this.quizCompleted = data.quizCompleted;
			this.quizQuestion = data.filmQuestions
		});
		this.modalService.open(this.contentQuizParticipate, { centered: true, size: 'lg' });
	};

	clickRadio(questionIndex: number, optionIndex: number) {
		this.quizQuestion[questionIndex].answerId = this.quizQuestion[questionIndex].filmQuestionOptions[optionIndex].id;
	};

	next() {
		this.questionCount += 1;
	};

	previous() {
		this.questionCount -= 1;
	};

	finish(modal: any) {
		const quizsaveData: any = {};
		quizsaveData.quizId = this.quizParticipateid;

		let answerCount = 0;

		this.quizQuestion.forEach(value => {
			if (value.questionType === "Explanation" || value.questionType === "Text") {
				value.answerId = value.filmQuestionOptions[0].id;
			}
			else if (value.answerId == null || value.answerId == undefined) {
				answerCount += 1;
			}
		});
		if (answerCount > 0) {
			this.toaster.warning("you must give all quizes answer");
			return;
		}
		quizsaveData.quizDetails = this.quizQuestion;
		this.installService.saveParticipateQuiz(quizsaveData)
			.pipe(first())
			.subscribe((resp: any) => {
				if (resp && resp.success) {
					this.toaster.success(resp.responseMsg);
					this.cancel();
				}
				else {
					this.toaster.error(resp.responseMsg);
				}
			},
				error => {
					this.toaster.error("!Failed");
				});
		modal.close('Save click');
	};

	handleVideoEnded(event: Event) {
		const videoElement: HTMLVideoElement = event.target as HTMLVideoElement;
		if(videoElement){
		  videoElement.playbackRate = 0.25;
		  videoElement.play();
		}
	}
}
