<header class="main-header">
	<div class="container">
		<div class="row">
			<div class="col-12 p-0">
				<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
					<a class="navbar-brand" href="index.html"><img src="../../assets/images/home-logo-ss.png"
							alt="STRONGSIDE" title="STRONGSIDE" height="40" /></a>
					<button class="navbar-toggler" type="button" data-toggle="collapse"
						data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
						aria-expanded="false" aria-label="Toggle navigation">
						<span class="navbar-toggler-icon"></span>
					</button>
					<div class="collapse navbar-collapse" id="navbarSupportedContent">
						<ul class="navbar-nav ml-auto">
							<li class="nav-item">
								<a class="nav-link" href="#">HOME</a>
							</li>
							<li class="nav-item">
								<a class="nav-link" href="#prices">PRICE</a>
							</li>
							<li class="nav-item">
								<a class="nav-link" href="/docs">FAQ</a>
							</li>
							<li class="nav-item">
								<a class="nav-link" href="/about">ABOUT US</a>
							</li>
							<li class="nav-item">
								<a class="nav-link" href="/careers">CAREERS</a>
							</li>
						</ul>
						<ul class="navbar-nav ml-auto">
							<div class="btn-grid m-0">
								<a href="#" class="primary-link-btn" title="Start">Sign Up</a> &nbsp;
								<a href="auth/login" class="default-link-btn" title="Sign In">sign in</a>
							</div>
						</ul>
					</div>
				</nav>
			</div>
		</div>
	</div>
</header>

<div class="whyus-sec">
	<div class="container" style="padding: 30px; border: 2px solid #08eaba; border-radius: 10px">
		<div class="row justify-content-center">
			<div class="col">
				<div class="site-main-title">
					<h1 class="default-title">Terms & Conditions</h1>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col text-white">
				<h4 class="text-center">OVERVIEW</h4>
				<p>
					Strongside LLC allows its customers to purchase different ranges of web and
					desktop software from its website www.strongside.app.
				</p>
				<h4 class="text-center">ACCEPTANCE OF TERMS</h4>
				<p>
					1. By placing an order on www.strongside.app you will be deemed to have
					read, understood & agreed to these Terms & Conditions. By using this
					Website or any utility in this web site directly or indirectly you abide by
					all terms and conditions mentioned herein. If you do not agree to any of
					the terms and conditions mentioned herein, please exit this site
					immediately.
				</p>
				<p>
					2. Any right or obligation contained in this Agreement shall be distinct
					and severable and if one or more of the same are declared invalid, illegal,
					void or unenforceable then the remainder shall continue in force and effect
					to the fullest extent permissible by law.
				</p>
				<h4 class="text-center">GOVERNING LAW AND JURISDICTION</h4>
				<p>
					These terms and conditions are shall be governed by and construed and
					interpreted in accordance with United States law. You hereby consent to the
					exclusive jurisdiction and venue of Colorado courts, US, in all disputes
					arising out of or relating to the use of this website. Use of this website
					is unauthorized in any jurisdiction that does not give effect to all
					provisions of these terms and conditions.
				</p>
				<h4 class="text-center">CANCELLING AN ORDER</h4>
				<p>
					You may cancel the order up to the point before payment has been processed
					on your system.
				</p>
				<h4 class="text-center">PAYMENT AND TAXES</h4>
				<p>
					We accept payment through multiple payment processors. It is the
					responsibility of the visitor to check the domestic conversion rate with
					their credit or debit card company or payment providers, before placing the
					order. Strongside will not be liable for any extra cost incurred in a
					transaction performed without consulting the debit/credit card company or
					payment providers for domestic conversion.
				</p>
				<h4 class="text-center">SECURITY & CONFIDENTIALITY MEASURES</h4>
				<p>
					All transactions made on our websites are performed through a secure
					server. In no whatsoever way our staff members can access your card
					details. All personal information and credit card details are transmitted
					via Secure Socket Layer(SSL) technology and then encrypted into our Payment
					gateway providers database only to be accessible by those authorized with
					special access rights to such systems.
				</p>
				<h4 class="text-center">UNAUTHORIZED USE OF THIS WEBSITE</h4>
				<p>
					Unauthorized use of this Website and systems, including, but not limited
					to, unauthorized entry into Strongside’s systems, online accounts, misuse
					of passwords, or misuse of any other information, is strictly prohibited.
					You may not use this Website in any manner that could damage, disable,
					overburden, or impair this Website or service or interfere with any other
					party's use and enjoyment of this Website or service. You agree that you
					will not engage in any activities related to this web site that are
					contrary to the applicable laws or regulations in force.
				</p>
				<h4 class="text-center">LINKS TO THIRD PARTY SITES</h4>
				<p>
					There may be links on the Site that lead to third-party sites, including
					those of advertisers. The company is not responsible for the privacy
					policies of those sites or the cookies those sites use. In addition,
					because the company has no control over such sites and resources, the user
					acknowledges and agrees that the company is not responsible for the
					availability of such external sites or resources, and does not endorse and
					is not responsible or liable for any content, advertising, products, or
					other materials on or available from such sites or resources.
				</p>
				<h4 class="text-center">DISCLAIMER</h4>
				<p style="text-transform: uppercase">
					YOUR USE OF THE SERVICE AND PRODUCT IS AT YOUR SOLE RISK. THE PRODUCTS AND
					SERVICES ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. STRONGSIDE
					MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, WHETHER EXPRESS OR
					IMPLIED, AS TO THE OPERATION OF THE SERVICES OR ANY CONTENT, PRODUCTS OR
					SERVICES INCLUDED ON, OR OTHERWISE MADE AVAILABLE TO YOU THROUGH, THE
					SERVICES, UNLESS OTHERWISE SPECIFIED IN WRITING, INCLUDING WITHOUT
					LIMITATION ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR USE
					OR PURPOSE, NON-INFRINGEMENT, QUIET ENJOYMENT, AND ACCURACY. WE
					SPECIFICALLY DISCLAIM ANY LIABILITY ASSOCIATED WITH THE USE OF THE PRODUCTS
					AND YOU AGREE THAT YOU WILL NOT SUE STRONGSIDE FOR ANY CLAIM RELATED TO ANY
					PRODUCTS PURCHASED THROUGH THE SERVICES. YOU AGREE TO INDEMNIFY, DEFEND AND
					HOLD HARMLESS STRONGSIDE, ITS OFFICERS, DIRECTORS, EMPLOYEES, AGENTS,
					DISTRIBUTORS, VENDORS AND AFFILIATES FROM AND AGAINST ANY AND ALL THIRD
					PARTY CLAIMS, DEMANDS, LIABILITIES, COSTS OR EXPENSES, INCLUDING REASONABLE
					ATTORNEYS' FEES, RESULTING OR ARISING OUT OF YOUR BREACH OF ANY OF THESE
					TERMS AND CONDITIONS. YOU UNDERSTAND AND AGREE THAT YOU WILL BE SOLELY
					RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER OR LOSS OF DATA THAT RESULTS
					FROM THE DOWNLOAD OF ANY MATERIAL IN ANY WAY RELATED TO THE SERVICES AND/OR
					OUR CONTENT. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY
					YOU FROM US THROUGH THE SERVICES, OR OTHERWISE WILL CREATE ANY WARRANTY,
					REPRESENTATION OR GUARANTEE NOT EXPRESSLY STATED IN TERMS AND CONDITIONS.
				</p>
				<p>
					www.strongside.app does not make any representations or warranties about
					any website you may access through this website. Any such website is
					independent from www.strongside.app, and www.strongside.app has no control
					over, or responsibility with respect to, the information provided or
					activities undertaken by any such website. A link between
					www.strongside.app and another website does not mean that
					www.strongside.app endorses that website. You need to make your own
					independent decisions regarding your interactions or communications with
					any other website.
				</p>
				<h4 class="text-center">CODE OF ETHICS AND CONDUCT</h4>
				<strong>Ownership of copyright</strong>
				<p>
					The copyright in this website and the material on this website (including
					without limitation the text, artwork, photographs, images, music, audio
					material, video material, and audio-visual material on this website) is
					owned by Strongside.
				</p>
				<strong>Copyright license</strong>
				<p>
					Strongside grants to you a non-exclusive royalty-free revocable license to:
				</p>
				<ul>
					<li>
						View this website and the material on this website on a computer or mobile
						device via a web browser.
					</li>
					<li>
						Copy and store this website and the material on this website in your web
						browser cache memory.
					</li>
					<li>
						Print pages from this website for your own personal non-commercial use.
					</li>
				</ul>
				<p>
					Strongside does not grant you any other rights in relation to this website
					or the material on this website. In other words, all other rights are
					reserved.
				</p>
				<p>
					For the avoidance of doubt, you must not adapt, edit, change, transform,
					republish, redistribute, broadcast, or show or play in public this website
					or the material on this website (in any form or media) without Strongside’s
					prior written permission. You may request permission by writing to “support
					address”.
				</p>
				<strong>Data mining</strong>
				<p>
					The automated and/or systematic collection of data from this website is
					prohibited.
				</p>
				<strong>Enforcement of copyright</strong>
				<p>
					Strongside takes the protection of its copyright materials very seriously.
				</p>
				<p>
					If Strongside discovers that you have used our copyright materials in
					contravention of the license above, Strongside may bring legal proceedings
					against you for an injunction to stop you using those materials and
					monetary damages. You could also be ordered to pay our legal costs.
				</p>
				<p>
					If you become aware of any use of Strongside ‘s copyright materials that
					contravenes or may contravene the license above, please let us know.
				</p>
			</div>
		</div>
	</div>
</div>

<div class="footer-sec" id="contact">
	<div class="container">
		<div class="row">
			<div class="col-lg-4 col-md-6 col-sm-12">
				<div class="footer-grid">
					<h1 class="title">QUICK LINKS</h1>
					<ul>
						<li><a href="#">HOME</a></li>
						<li><a href="#features">FEATURES</a></li>
						<li><a href="#prices">PRICE</a></li>
						<li><a href="#contact">CONTACT</a></li>
						<li><a href="#">CAREERS</a></li>
					</ul>
				</div>
			</div>
			<div class="col-lg-4 col-md-6 col-sm-12">
				<div class="footer-grid">
					<h1 class="title">POLICIES</h1>
					<ul>
						<li><a href="/terms-condition">Terms & Conditions</a></li>
						<li><a href="/privacy-policy">Privacy Policy</a></li>
						<li><a href="#">Cookie Policy</a></li>
						<li><a href="/affiliate">Affiliate Program</a></li>
					</ul>
				</div>
			</div>
			<div class="col-lg-4 col-md-6 col-sm-12">
				<div class="footer-grid">
					<h1 class="title">CONTACT US</h1>
					<form>
						<div class="form-grid">
							<div class="form-group">
								<input type="text" class="foot-form-control" placeholder="example@example.com"
									required />
							</div>
							<div class="form-group">
								<textarea name="" class="foot-form-control" required></textarea>
							</div>
							<div class="form-group">
								<input type="submit" class="input-btn" value="SEND" />
							</div>
						</div>
					</form>
				</div>
			</div>
			<div class="col-lg-12">
				<p class="my-3 text-white">© Strongside 2020 All Rights Reserved.</p>
			</div>
		</div>
	</div>
</div>