import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/data/services/auth/authentication.service';

@Component({
  selector: 'app-playbook-reg-depthchart',
  templateUrl: './playbook-reg-depthchart.component.html',
  styleUrls: ['./playbook-reg-depthchart.component.scss']
})
export class PlaybookRegDepthchartComponent implements OnInit {

  public regForm: FormGroup;
  public show: boolean = false;
  public returnUrl: string = "";
  public isRegFormShow: boolean = true;
  public isOTPFormShow: boolean = false;
  public isPasswordFormShow: boolean = false;
  public otpForm: FormGroup;
  public passForm: FormGroup;
  constructor(
    private fb: FormBuilder,
    public router: Router,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    public toster: ToastrService,
  ) {
    this.regForm = fb.group({
      email: ["", [Validators.required, Validators.email]]
    });

    this.otpForm = fb.group({
      otp1: ['', Validators.required],
      otp2: ['', Validators.required],
      otp3: ['', Validators.required],
      otp4: ['', Validators.required],
    });

    this.passForm = fb.group({
      id: [''],
      email: [''],
      password: ['', [Validators.required, Validators.minLength(8), Validators.pattern("(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}")]],
      confirmPassword: ['', Validators.required],

    }, { validators: this.checkPasswords });
  }

  ngOnInit(): void {
  }

  checkPasswords: ValidatorFn = (group: AbstractControl): ValidationErrors | null => {
    let pass = group.get('password')?.value;
    let confirmPass = group.get('confirmPassword')?.value
    return pass === confirmPass ? null : { notSame: true }
  }

  get f() {
    return this.passForm.controls;
  };

  showPassword() {
    this.show = !this.show;
  };

  onEmailSubmit() {
    if (this.regForm.invalid) {
      this.toster.error("Please! enter a valid E-mail.");
      return;
    }
    const emailTosend = this.regForm.controls['email'].value;
    this.authenticationService.sendMarketingUserSignupOTP(emailTosend, '').pipe(first())
      .subscribe((resp: any) => {
        if (!resp.success) {
          this.toster.error(resp.responseMsg);
          this.router.navigate(["/"]);
        }
        else if (resp.success && resp.response.isEmailVerified) {
          this.toster.success(resp.responseMsg);
          this.isRegFormShow = false;
          this.isOTPFormShow = false;
          this.isPasswordFormShow = true;
          this.passForm.controls['id'].setValue(resp.response.id);
          this.passForm.controls['email'].setValue(this.regForm.controls['email'].value);
        }
        else {
          this.toster.success(resp.responseMsg);
          this.isRegFormShow = false;
          this.isOTPFormShow = true;
          this.isPasswordFormShow = false;
          this.passForm.controls['id'].setValue(resp.response.id);
        }
      },
        error => {
          this.toster.error(error.error)
        });
  };

  onOTPsubmit() {
    if (this.otpForm.invalid) {
      this.toster.error("Please! enter 4 digit OTP.");
      return;
    }
    const otpToVerify = this.otpForm.controls['otp1'].value.trim()
      + this.otpForm.controls['otp2'].value.trim()
      + this.otpForm.controls['otp3'].value.trim()
      + this.otpForm.controls['otp4'].value.trim();
    this.authenticationService.emailVerifyOtp(otpToVerify, this.regForm.controls['email'].value).pipe(first())
      .subscribe((resp: any) => {
        if (!resp.success) {
          this.toster.error(resp.responseMsg);
          return;
        }
        this.toster.success(resp.responseMsg);
        this.isRegFormShow = false;
        this.isOTPFormShow = false;
        this.isPasswordFormShow = true;
        this.passForm.controls['email'].setValue(this.regForm.controls['email'].value);
      },
        error => {
          this.toster.error(error.error)
        });
  };

  onResend() {
    if (this.regForm.controls['email'].valid) {
      this.onEmailSubmit();
    }
  };

  onNewPassSubmit() {
    if (this.passForm.invalid) {
      return;
    }
    this.authenticationService.savePassword(this.passForm.controls['id'].value, this.passForm.controls['confirmPassword'].value).pipe(first())
      .subscribe((resp: any) => {
        if (!resp.success) {
          this.toster.error(resp.responseMsg);
          return;
        }
        this.login();
      },
        error => {
          this.toster.error(error.error)
        });
  };

  login() {
    this.authenticationService.login(this.passForm.controls['email'].value, this.passForm.controls['confirmPassword'].value)
      .pipe(first())
      .subscribe(
        user => {
          localStorage.setItem('isLogin', 'Yes');
          this.router.navigate(["/team-management/depth-chart"]);
        },
        error => {
          //this.toster.error(error.error);
          // "You have enter Wrong Email or Password."
        });
  };

}
