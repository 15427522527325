import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { filter, first } from "rxjs/operators";
import { Faq } from "src/app/data/models/global/faq";
import { FaqService } from "src/app/data/services/global/faq.service";
import { LoaderService } from "src/app/shared/services/loader.service";

@Component({
  selector: "app-public-faq",
  templateUrl: "./public-faq.component.html",
  styleUrls: ["./public-faq.component.css"],
})
export class PublicFaqComponent implements OnInit {
  faqs: Faq[] = [];
  activeId: string = "";
  searchForm: FormGroup;
  afterVerifiationAccess: boolean = false;
  constructor(
    public toster: ToastrService,
    private loaderService: LoaderService,
    public faqService: FaqService,
    private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.searchForm = this.formBuilder.group({
      searchText: ['']
    });
  }

  ngOnInit() {
    const url = window.location.href;
    if (url.includes('global/faq')) {
      this.afterVerifiationAccess = true;
      this.loadFaqData();
    }
    else {
      this.afterVerifiationAccess = false;
      this.loadFaqData();
    }
  }

  loadFaqData() {
    const searchParam = this.searchForm.value;
    searchParam.searchText = this.searchForm.controls["searchText"].value;
    this.loaderService.isLoading.next(true);
    this.faqService
      .getAllPublic(searchParam)
      .pipe(first())
      .subscribe(
        (resp: any) => {
          this.loaderService.isLoading.next(false);
          if (resp && resp.success) {
            let faqArray = resp.result;
            faqArray.forEach((val: any) => {
              if (val.faqLink) {
                val.faqSantizeUrl = this.getSanitizeUrl(val.faqLink);
              }
            });
            this.faqs = faqArray;
          } else {
            this.toster.error("!Failed to load data.");
          }
        },
        (error) => {
          this.loaderService.isLoading.next(false);
          this.toster.error("!Failed to load data.");
        }
      );
  }

  getSanitizeUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  toggleAccordian(event: any) {
    // If it is already open you will close it and if it is closed open it
    this.activeId = this.activeId == event.panelId ? "" : event.panelId;
  }

  search() {
    this.loadFaqData();
  };

  imgLogoclick() {
    this.router.navigate(['/auth/login']);
  }
}
