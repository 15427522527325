<!-- page-wrapper Start-->
<div [ngClass]="layout.config.settings.layout_type" [class]="layout.config.settings.layout_version">
	<div class="page-wrapper" id="canvas-bookmark" [ngClass]="layout.config.settings.sidebar.type">
		<app-header></app-header>
		<!-- Page Body Start-->
		<div class="page-body-wrapper" [ngClass]="layout.config.settings.sidebar.body_type">
			<div class="sidebar-wrapper" [attr.sidebar-layout]="layout.config.settings.sidebar_type"
				[class.close_icon]="navServices.collapseSidebar">
				<app-sidebar></app-sidebar>
			</div>
			<div class="page-body">
				<main [@fadeInAnimation]="getRouterOutletState(o)" class="pt-4">
					<router-outlet #o="outlet"></router-outlet>
				</main>
				<div class="cr-msgCircle cr-fixedBtn" placement="left" (click)="goToReportingIssue()"
					style="cursor: pointer;">
					<!-- <span>25</span> -->
					<i class="fa fa-envelope"></i>
				</div>
				<!-- ngbTooltip="Repoting Issue" -->
			</div>
			<!-- footer start-->
			<footer class="footer">
				<app-footer></app-footer>
			</footer>
			<!-- footer End-->
		</div>
		<!-- Page Body End-->
	</div>
</div>
<app-customizer></app-customizer>
<!-- page-wrapper End-->