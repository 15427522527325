<header class="main-header">
	<div class="container">
		<div class="row">
			<div class="col-12 p-0">
				<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
					<a class="navbar-brand" href="index.html"><img src="../../assets/images/home-logo-ss.png"
							alt="STRONGSIDE" height="40" title="STRONGSIDE" /></a>
					<button class="navbar-toggler" type="button" data-toggle="collapse"
						data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
						aria-expanded="false" aria-label="Toggle navigation">
						<span class="navbar-toggler-icon"></span>
					</button>
					<div class="collapse navbar-collapse" id="navbarSupportedContent">
						<ul class="navbar-nav ml-auto">
							<li class="nav-item">
								<a class="nav-link" href="#">HOME</a>
							</li>
							<li class="nav-item">
								<a class="nav-link" href="#prices">PRICE</a>
							</li>
							<li class="nav-item">
								<a class="nav-link" href="/docs">FAQ</a>
							</li>
							<li class="nav-item">
								<a class="nav-link" href="/about">ABOUT US</a>
							</li>
							<li class="nav-item">
								<a class="nav-link" href="/careers">CAREERS</a>
							</li>
						</ul>
						<ul class="navbar-nav ml-auto">
							<div class="btn-grid m-0">
								<a href="#" class="primary-link-btn" title="Start">Sign Up</a> &nbsp;
								<a href="auth/login" class="default-link-btn" title="Sign In">sign in</a>
							</div>
						</ul>
					</div>
				</nav>
			</div>
		</div>
	</div>
</header>

<div class="whyus-sec">
	<div class="container" style="padding: 30px; border: 2px solid #08eaba; border-radius: 10px">
		<div class="row justify-content-center">
			<div class="col">
				<div class="site-main-title">
					<h1 class="default-title">PRIVACY POLICY</h1>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col text-white">
				<p>
					This policy describes the processing of personal data by Strongside LLC in
					connection with how you use and interact with our website
					www.strongside.app.
				</p>
				<p>
					This Privacy Policy should be read in conjunction with the terms that
					describe the conditions under which you may use this site.
				</p>
				<p>
					If you have any further complaints or questions about this Privacy Policy,
					please contact us.
				</p>
				<strong>This document describes:</strong>
				<ol>
					<li>Important information and who we are</li>
					<li>Personal Information we collect</li>
					<li>How we get this information</li>
					<li>How we use this information</li>
					<li>How long we store this information</li>
					<li>With whom do we share this information?</li>
					<li>
						To what other location or countries do we transfer this information?
					</li>
					<li>How we protect your information</li>
					<li>Minor Policy</li>
					<li>Privacy FAQs</li>
				</ol>
				<h4 class="text-center">IMPORTANT INFORMATION AND WHO WE ARE</h4>
				<strong>Purpose of this privacy policy</strong>
				<p>
					This Privacy Policy sets out information about how we collect and process
					your personal information when using this site, including information that
					you may provide through this site when you subscribe to our newsletter,
					when you purchase any product or service or participate in a competition or
					survey.
				</p>
				<strong>Controller</strong>
				<p>
					This Privacy Policy is issued on behalf of Strongside LLC, so any use of
					“Strongside”, “we” or “us” in this Privacy Policy refers to Strongside LLC.
				</p>
				<strong>Third Party Links</strong>
				<p>
					This site may contain links to third-party websites, plugins and
					applications. Clicking these links, may allow third parties to collect data
					about you. We do not control these third-party websites and are not
					responsible for their privacy policies. When you leave our site, we
					recommend that you read the privacy policy of each site you visit.
				</p>
				<h4 class="text-center">PERSONAL INFORMATION WE COLLECT</h4>
				<p>
					Personal information means any information relating to an identified or
					identifiable natural person.
				</p>
				<p>
					Because of our wide range of work and for other different reasons, we need
					to use your personal information. Data collected consists of:
				</p>
				<ol>
					<li>
						<strong>Identity and contact information:</strong> including name, email
						address, date of birth, phone number;
					</li>
					<li>
						<strong>Preference information:</strong> information on the type of
						product/service purchased on the website and the type of product/service
						of your interest;
					</li>
					<li>
						<strong>Financial and transaction data:</strong> including bank account
						information, card number, email address, postal address, phone number,
						passport information;
					</li>
					<li>
						<strong>Technical and Usage Information:</strong> Contains information
						about how visitors use our site.
					</li>
					<li>
						<strong>Marketing data-</strong> includes the individual's choice to
						receive marketing material from us, including the consent you have given
						us;
					</li>
					<li>
						<strong>Information used to provide the services:</strong> this includes
						information that user has provided to us or generated by us while
						providing the product or service to our customers.
					</li>
					<li>
						We may also record communications if you contact us by email or other
						means.
					</li>
				</ol>
				<h4 class="text-center">HOW WE GET THIS INFORMATION</h4>
				<p>1. We collect this information in a variety of ways, including:</p>
				<p>
					a. <strong>Direct Contact:</strong> While individuals may provide personal
					information by contacting us by email, phone or other means, we may collect
					personal information, such as financial information, from payment providers
					for processing a transaction.
				</p>
				<p>
					b. <strong>Our Website:</strong> automatically collected information
					includes your browser type and version, the operating system you use, your
					IP address, Mac ID, device ID, browser settings, date and time of your
					interaction. We use cookies to provide us insights about how to provide you
					with the best experience on our site when you browse our site. More
					information about how we use cookies and how we gather information through
					cookies can be found in our Cookie Policy. However, users can block
					cookies.
				</p>
				<p>
					c. <strong>Third Party or publicly available sources:</strong> We may
					obtain personal information about you from a variety of third parties and
					public sources, such as:
				</p>
				<ul>
					<li>
						Technical data from the following parties:
						<ul>
							<li>Analytics providers such as Google;</li>
							<li>Advertising networks like Google,</li>
							<li>Search information providers such as Google.</li>
						</ul>
					</li>
				</ul>
				<h4 class="text-center">HOW WE USE YOUR PERSONAL INFORMATION</h4>
				<ol>
					<li>
						We use personal information in a variety of ways, including:
						<ol type="A">
							<li>
								<strong>Contractual necessity:</strong> for fulfilling the contract with
								you, e.g. you purchase services/products from us;
							</li>
							<li>
								<strong>Legitimate Interests:</strong> In accordance with our
								requirements to pursue our legitimate interests such as:
								<ol type="I">
									<li>
										Manage and improve accounts registered on the website to enhance the
										customer experience;
									</li>
									<li>Managing and securing registered online accounts;</li>
									<li>
										For monitoring all registered online accounts to investigate and/or
										report; terrorism, misrepresentation, fraud, and crime;
									</li>
									<li>For providing advertisements (via Facebook and Google ads);</li>
									<li>
										We may use personal data in case of legal claims against the company,
										partners and for the purpose of investigation including disclosure of
										information in case of litigation;
									</li>
									<li>
										Providing customer/user insights based on his/her behavior to drive
										targeted direct marketing.
									</li>
									<li>In communicating with you</li>
									<li>
										Investigate any complaints you may have received about our services,
										website and products.
									</li>
								</ol>
							</li>
							<li>For providing services to customers / customers;</li>
							<li>For recruiting employees and members;</li>
							<li>For supervising and managing our partners or employees;</li>
							<li>To promote our products or services;</li>
							<li>To fulfill our audit or insurance obligations;</li>
							<li>
								Legal Compliance: to comply with applicable laws and legal procedures,
								including legal claims, investigations and litigation;
							</li>
							<li>
								<strong>Consent:</strong> We send direct marketing messages via
								SMS/email/phone/online about our products/services that we believe may
								be of interest to you. It is only sent if you have provided your
								personal information during the registration process or through an
								online account on the website or directly at the office. You can opt out
								of online marketing messages by clicking on the unsubscribe link in
								email sent, update your account preferences on our website, or contact.
							</li>
						</ol>
					</li>
				</ol>
				<h4 class="text-center">WE USE YOUR PERSONAL INFORMATION BASED ON</h4>
				<p>We will use your personal information on the basis:</p>
				<ol>
					<li>If it is in our legitimate interests.</li>
					<li>
						When it is necessary to comply with a regulatory or legal obligation.
					</li>
				</ol>
				<h4 class="text-center">HOW LONG WE KEEP YOUR INFORMATION</h4>
				<p>
					We store personal information in accordance to category of personal
					information. When determining the retention period, we take into account
					the purpose for which personal data was collected.
				</p>
				<h4 class="text-center">WITH WHOM WE YOUR INFORMATION</h4>
				<ol>
					<li>
						We may share the collected personal information.
						<ol type="a">
							<li>
								When outsourcing certain services (for example, photocopying or IT
								services;
							</li>
							<li>
								With our professional advisors: for example, our bankers, insurers;
							</li>
							<li>
								With regulatory authorities, law enforcement agencies, if mandated by
								law;
							</li>
							<li>
								With trusted third parties who helps us in operating our site, conducts
								our business, or provide services to you (if that party maintains the
								confidentiality of this information);
							</li>
							<li>
								Third party service providers who process personal data on our behalf.
							</li>
							<li>
								Prospective purchasers of all or part of our assets or operations, and
								professional advisors related to purchases.
							</li>
							<li></li>
						</ol>
					</li>
					<li>
						We do not sell personal data to third parties. Third parties with whom we
						share your personal information, in order to improve the user experience,
						are required to use that information in accordance with law and has the
						responsibility of its security;
					</li>
				</ol>
				<h4 class="text-center">
					WHICH COUNTRIES WE TRANSFER YOUR PERSONAL INFORMATION TO
				</h4>
				<p>
					We do not share your personal information outside of the United States. If
					we choose to transfer your personal information outside of the United
					States for any reason in the future, there are procedures in place to
					ensure that these third parties process your personal data with the same
					level of security as it was processed inside the United States. We do not
					sell your information to third parties.
				</p>
				<h4 class="text-center">HOW WE PROTECT YOUR INFORMATION</h4>
				<p>
					Fraud Prevention: If you are buying from our website. We can use automated
					tools as part of counter fraud prevention measures. Your purchase may be
					denied if your bank rejected your payment or if other factors conclude that
					the transaction is fraudulent.
				</p>
				<h4 class="text-center">MINORS</h4>
				<p>
					We comply with Children's Online Privacy Protection Act 1988 (COPPA) and do
					not collect information from anyone under the age of 16. Our websites,
					products and services are intended for people who are 16-year-old or older.
				</p>
				<p>
					Visitors under the age of 16 are not allowed to subscribe to our services
					or provide personal information in any form on our website or application.
					We encourage parents to monitor their children's online activities.
				</p>
				<h4 class="text-center">PRIVACY FAQS</h4>
				<p><strong>1. What rights do you have over your personal data?</strong></p>
				<p>You have the right to request:</p>
				<ul>
					<li>Copy of your personal information.</li>
					<li>
						to correct your personal information if your personal information is
						inaccurate, incomplete or outdated.
					</li>
					<li>To delete your personal information</li>
					<li>
						Stop processing of your personal information (processing will temporarily
						stop).
					</li>
				</ul>
				<p><strong>2. How long do we keep your information?</strong></p>
				<p>
					We only retain your personal information for as long as necessary for the
					purposes for which we collect it, including meeting legal, accounting, or
					reporting requirements. When we process your personal and transaction data,
					we may keep your information as long as we deem necessary.
				</p>
				<p>
					If we process your information for marketing purposes, we do so until you
					ask us to stop it.
				</p>
			</div>
		</div>
	</div>
</div>

<div class="footer-sec" id="contact">
	<div class="container">
		<div class="row">
			<div class="col-lg-4 col-md-6 col-sm-12">
				<div class="footer-grid">
					<h1 class="title">QUICK LINKS</h1>
					<ul>
						<li><a href="#">HOME</a></li>
						<li><a href="#features">FEATURES</a></li>
						<li><a href="#prices">PRICE</a></li>
						<li><a href="#contact">CONTACT</a></li>
						<li><a href="#">CAREERS</a></li>
					</ul>
				</div>
			</div>
			<div class="col-lg-4 col-md-6 col-sm-12">
				<div class="footer-grid">
					<h1 class="title">POLICIES</h1>
					<ul>
						<li><a href="/terms-condition">Terms & Conditions</a></li>
						<li><a href="/privacy-policy">Privacy Policy</a></li>
						<li><a href="#">Cookie Policy</a></li>
						<li><a href="/affiliate">Affiliate Program</a></li>
					</ul>
				</div>
			</div>
			<div class="col-lg-4 col-md-6 col-sm-12">
				<div class="footer-grid">
					<h1 class="title">CONTACT US</h1>
					<form>
						<div class="form-grid">
							<div class="form-group">
								<input type="text" class="foot-form-control" placeholder="example@example.com"
									required />
							</div>
							<div class="form-group">
								<textarea name="" class="foot-form-control" required></textarea>
							</div>
							<div class="form-group">
								<input type="submit" class="input-btn" value="SEND" />
							</div>
						</div>
					</form>
				</div>
			</div>
			<div class="col-lg-12">
				<p class="my-3 text-white">© Strongside 2020 All Rights Reserved.</p>
			</div>
		</div>
	</div>
</div>