import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { PlayerService } from '../data/services/team-management';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-showtime-profile',
  templateUrl: './showtime-profile.component.html',
  styleUrls: ['./showtime-profile.component.css']
})
export class ShowtimeProfileComponent implements OnInit {
  data: any;
  id: any;
  public isCjProfile: any;
  public userName: any = '';
  public imageUrl = "";

  // changeText: any;

  @ViewChild('twitterDiv1') twitterDiv1!: ElementRef;
  @ViewChild('twitterDiv2') twitterDiv2!: ElementRef;
  @ViewChild('twitterDiv3') twitterDiv3!: ElementRef;

  constructor( private playerService: PlayerService,
    private route: ActivatedRoute,
    private router: Router,
    ) {
      // this.changeText = false;
     }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    if(this.id){
      this.playerService.getShowTimePlayerData(this.id).subscribe(resp => {
        this.data = resp;
        this.imageUrl = resp.imageUrl;
        if(resp.twitterView1){
          this.twitterDiv1.nativeElement.innerHTML = resp.twitterView1;
        }
        if(resp.twitterView2){
          this.twitterDiv2.nativeElement.innerHTML = resp.twitterView2;
        }
        if(resp.twitterView3){
          this.twitterDiv3.nativeElement.innerHTML = resp.twitterView3;
        }
      })
    }
    
    this.userName = this.route.snapshot.paramMap.get('_user');
    // if(this.userName === 'cj' && !this.id){
    //   this.isCjProfile = true;
    // }
    // else if(this.userName === 'dj' && !this.id){
    //   this.isCjProfile = false;
    // }
  }

  getVideoUrl(url: string) {
    return url.trim();
  }

  gotoShowTimeProfileCreation()
  {
    window.open('https://strongside.app/showtime-welcome');
  }

  gotoCollegeUserRegistration()
  {
    window.open('https://strongside.app/authentication/co-usr-reg');
  }

}
