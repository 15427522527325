<!-- <header class="main-header">
	<div class="container">
		<div class="row">
			<div class="col-12 p-0">
				<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
					<a class="navbar-brand" href="index.html"
						><img
							src="../../assets/images/home-logo-ss.png"
							alt="STRONGSIDE"
							height="40"
					/></a>
					<button
						class="navbar-toggler"
						type="button"
						data-toggle="collapse"
						data-target="#navbarSupportedContent"
						aria-controls="navbarSupportedContent"
						aria-expanded="false"
						aria-label="Toggle navigation"
					>
						<span class="navbar-toggler-icon"></span>
					</button>
					<div class="collapse navbar-collapse" id="navbarSupportedContent">
						<ul class="navbar-nav ml-auto">
							<li class="nav-item">
								<a class="nav-link" href="#">HOME</a>
							</li>
							<li class="nav-item">
								<a class="nav-link" href="#prices">PRICE</a>
							</li>
							<li class="nav-item">
								<a class="nav-link" href="#faqs">FAQ</a>
							</li>
							<li class="nav-item">
								<a class="nav-link" href="/about">ABOUT US</a>
							</li>
							<li class="nav-item">
								<a class="nav-link" href="/careers">CAREERS</a>
							</li>
						</ul>
						<ul class="navbar-nav ml-auto">
							<div class="btn-grid m-0">
								<a href="#" class="primary-link-btn" title="Start">Sign Up</a> &nbsp;
								<a href="auth/login" class="default-link-btn" title="Sign In"
									>sign in</a
								>
							</div>
						</ul>
					</div>
				</nav>
			</div>
		</div>
	</div>
</header> -->

<!-- <div class="whyus-sec">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col">
                <div class="site-main-title">
                    <h1 class="primary-title">WHY STRONGSIDE</h1>
                    <h1 class="default-title">Save Time While Creating a Great Team</h1>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col text-white">
                <p>
                    When you're planning… I mean when you're really zeroed in on your playbook, gameplan and film ...you probably know what it's like to be frustrated by the software you have. Hello, my name is Mark. You don't know me yet but I am a real person. I coach in Colorado and a year ago I chose to
                    build a great playbook software and never hate what my team uses.
                </p>
                <p>
                    I always had many ideas in my head but when I opened my word playbook and started to work, I began to cringe. I felt scammed and I didn't even pay for the thing... I felt like my my playbook was out of scale and ugly and I couldn't show it to players and teach. In my mind we were paying too much for broken junk. I was constantly looking for something with a real football field scale and a way to teach from it and visualize it until I found something better, I knew I was wasting time and none of our team would benefit. I can't tell you the amount of software I tried to find to "fit" my vision.
                </p>
                <p>
                    Until one day, I told my head coach. I'm going to build something better. And our coaches started to plan something better. It can't be just another “drawing”. We needed a unique new way of teaching and I needed other feedback to make it – specialists, lifelong coaches. The guys who REALLY DID everything I wanted to make easier. This was built with feedback from coaches who had done things I can only dream of. A QB coach who has put his last 6 starters in D1 schools. Coaches with multiple state titles. Coaches who had been all over the country and seen what was needed anywhere. Then I started to steal their genius and add in my skills. It changed the way I perceive football software forever...
                </p>
                <p>
                    In the start of 2020 I started to build. Within the first weeks, I crafted my own software. But I couldn’t nail it down. It wasn't great yet. During the months that followed, my work began to revolve around a
                    software that would change everything we do. What if I could make all my plays at once. What if I could use my film to teach rather than tell players to "go watch film". What if I could take the exact perspective each person in a game sees and simply live it.
                </p>
                <p>
                    Being a a coach and developer and having ideas of what could exist, I continued to prototype …and to me, using what I
                    was making felt like a miracle. Ever since, my playbook has never been better.

                    The reason my idea worked was simple: An effective playbook makes a clear picture of what is required by each person
                    (including coaches), and the software I was building allowed me to show everything. See each moment of a play
                    progressing like I was there.
                </p>
                <p>
                    <strong>How do YOU really coach your plays?</strong>
                </p>
                <p>
                    See, creating a play is not just a bunch of lines on templates. That will never really work out. The secret is to
                    translate a plan into a real world execution. Only then you can prepare a team to perform. We start with lines however
                    we NEED a field.

                    And that’s exactly what my software does. It reminds me (and you) on what really happens on a field. And it allows all
                    of us to share a plan. Practice on the field is proven. You know standing there with other players in front of you, just
                    seeing and explaining works. It's not weird or untested coaching, it let's you use a playbook based on what works.

                    Up until now, it’s the only solution that allows you to diagram and teach what we see.

                    And it has only been introduced to our team. No one else. This is first time I'm getting the completed version out to
                    the world.
                </p>
                <p>
                    <strong>Just to name a few of the benefits you’re getting…</strong>
                </p>
                <ul>
                    <li>Never again will you Draw in a "fake" space - a limited world based on a "feeling" of Scale.</li>
                    <li>Never again will you waste money on playbook software that doesn't help you coach.</li>
                    <li>Never again will you waste time trying to rebuild every single play and write down every possibility.</li>
                    <li>You’ll have access to making a playbook that works for players and coaches in in the same way you do on the field.</li>
                    <li>
                        You’ll have access to a constantly evolving platform, none of this was here a year ago and you'll want to have what we
                        do this year.
                    </li>
                    <li>You’ll be reminded on what you need to focus on when game planning and install.?</li>
                    <li>
                        You'll get access to a platform that does WAY more than just draw plays because I haven't even touched on all the other
                        parts of the platform.
                    </li>
                </ul>
                <p>
                    You can get the playbook software as a 1 year license for only $199.

                    I’m 100% sure you’ll love it. In fact, I’m so confident you might think I’m nuts.

                    Try the playbooks AND website free for 30 days and if you don’t love it, let me know. You can keep the playbook
                    software. I make this thing better everyday. I’ll give that specific version to you forever.

                    So, the worst-case scenario is you get free access for 30 days and the software for life.

                    The best case is having a secret weapon for creating great players and not just praying a strong safety that's 6'2"
                    weighs 195 and can run a 4.6 40 randomly falls in your lap (that happened to me once - ONCE).

                    If that sounds good to you, visit the link below and get the software. I think you’ll love it as well.

                    <a href="https://fieldvision.strongside.app/" target="_blank">www.fieldvision.strongside.app</a> and signup.
                </p>
            </div>
        </div>
    </div>
</div>-->

<!-- <div class="packages-sec" id="packages">
	<div class="container">
		<div class="row justify-content-center">
			<div class="col">
				<div class="site-main-title">
					<h1 class="primary-title" style="margin-top: revert">PACKAGES</h1>
					<h1 class="default-title">Full packages details</h1>
					<p>
						Experience The Closest Thing There Is To Being A Real Football Manager.
					</p>
				</div>
			</div>
		</div>
		<div class="row justify-content-center">
			<div class="table-responsive">
				<table class="table table-bordered">
					<tbody>
						<tr>
							<th></th>
							<th></th>
							<th colspan="2">PeeWee</th>
							<th>Freshman</th>
							<th>Varsity</th>
						</tr>
						<tr>
							<th colspan="6">Fieldvision Playbook</th>
						</tr>
						<tr>
							<th colspan="3" rowspan="2">Formation</th>
							<td>30</td>
							<td rowspan="2">Unlimited</td>
							<td rowspan="2">Unlimited</td>
						</tr>
						<tr>
							<td>Additional 5 cent per formation</td>
						</tr>
						<tr>
							<th colspan="3" rowspan="2">Concepts</th>
							<td>10</td>
							<td rowspan="2">Unlimited</td>
							<td rowspan="2">Unlimited</td>
						</tr>
						<tr>
							<td>Additional 10 cent per formation</td>
						</tr>
						<tr>
							<th colspan="6">Strongside</th>
						</tr>
						<tr>
							<th colspan="3" rowspan="2">Player Account</th>
							<td>25</td>
							<td>80</td>
							<td rowspan="2">Unlimited</td>
						</tr>
						<tr>
							<td>Add additional player for $3 per year</td>
							<td>Add additional player for $1 per year</td>
						</tr>
						<tr>
							<th colspan="3" rowspan="2">Coaches Account</th>
							<td>3</td>
							<td>10</td>
							<td rowspan="2">Unlimited</td>
						</tr>
						<tr>
							<td>Additional coaches for $10 per year</td>
							<td>Additional coaches for $3 per year</td>
						</tr>
						<tr>
							<th colspan="3" rowspan="2">Quizzes</th>
							<td>30</td>
							<td rowspan="2">Unlimited</td>
							<td rowspan="2">Unlimited</td>
						</tr>
						<tr>
							<td>Additional for 30 cent per quiz</td>
						</tr>
						<tr>
							<th colspan="3" rowspan="2">Storage</th>
							<td>10 GBs/Year</td>
							<td>50 GBs/Year</td>
							<td rowspan="2">Unlimited</td>
						</tr>
						<tr>
							<td>Additional for $3 per year</td>
							<td>Additional 50 GB per year for $100</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</div> -->

<!----FAQS -->
<!-- <div class="faq-sec" id="faqs">
	<div class="container">
		<div class="row justify-content-center">
			<div class="col">
				<div class="site-main-title">
					<h1 class="default-title">frequently asked questions</h1>
					<h3 class="text-white">or 'why constant improvement matters'</h3>
				</div>
			</div>
		</div>
		<div class="row my-5">
			<div class="col-lg-6 col-sm-12">
				<img class="img-fluid" src="../../assets/images/faq-img.png" alt="FAQS" />
			</div>
			<div class="col-lg-6 col-sm-12">
				<div class="faq-grid">
					<div id="accordion">
						<h3>Lifetime Access * (asterisk)?</h3>
						<div>
							<p>
								Yeah, I don't like to see astrisks and sports mixed. This is a cheat you
								benefit from. Whatever version of Fieldvision is out when you're
								finished with us that's your version. Forever. Anything you diagrammed
								it'll be there for you. If we don't improve - you shouldn't pay for it.
							</p>
						</div>
						<h3>What if I can't afford Helmets for my team?</h3>
						<div>
							<p>
								If your school is less than 200 students then that Freshman option is
								yours free. If that's not you and you're on a tight budget then we're
								builing a pay for what you use option. Either way - message us. The
								sport improves when all of us improve.
							</p>
						</div>
						<h3>4k film</h3>
						<div>
							<p>
								There is an associated cost based on use for 4k film. Getting film to
								you is the expensive part. There is a reason web video shows you ads.
								Every football software makes more money when your teams DO NOT watch
								film. They don't improve if it got better, they pay for it.
							</p>
						</div>
						<h3>Early Access to New Features?</h3>
						<div>
							<p>
								Check out the About Us page - this was built for one team. The one I
								coach. If you're reading this you're already looking at 'old features'.
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="footer-sec" id="contact">
	<div class="container">
		<div class="row">
			<div class="col-lg-4 col-md-6 col-sm-12">
				<div class="footer-grid">
					<h1 class="title">QUICK LINKS</h1>
					<ul>
						<li><a href="#">HOME</a></li>
						<li><a href="#features">FEATURES</a></li>
						<li><a href="#prices">PRICE</a></li>
						<li><a href="#contact">CONTACT</a></li>
						<li><a href="#">CAREERS</a></li>
					</ul>
				</div>
			</div>
			<div class="col-lg-4 col-md-6 col-sm-12">
				<div class="footer-grid">
					<h1 class="title">POLICIES</h1>
					<ul>
						<li><a href="/terms-condition">Terms & Conditions</a></li>
						<li><a href="/privacy-policy">Privacy Policy</a></li>
						<li><a href="#">Cookie Policy</a></li>
						<li><a href="/affiliate">Affiliate Program</a></li>
					</ul>
				</div>
			</div>
			<div class="col-lg-4 col-md-6 col-sm-12">
				<div class="footer-grid">
					<h1 class="title">CONTACT US</h1>
					<form>
						<div class="form-grid">
							<div class="form-group">
								<input
									type="text"
									class="foot-form-control"
									placeholder="example@example.com"
									required
								/>
							</div>
							<div class="form-group">
								<textarea name="" class="foot-form-control" required></textarea>
							</div>
							<div class="form-group">
								<input type="submit" class="input-btn" value="SEND" />
							</div>
						</div>
					</form>
				</div>
			</div>
			<div class="col-lg-12">
				<p class="my-3 text-white">© Strongside 2020 All Rights Reserved.</p>
			</div>
		</div>
	</div>
</div> -->


<!-- ========== Packages screen ============ -->
<section class="c-price-w" *ngIf="activeStatusArray[0] === true">
	<div class="container">
		<div class="row">
			<!-- === ITEM =====-->
			<div class="col-lg-4 col-md-6" *ngFor="let package of packages">
				<div class="c-price-box">
					<div class="c-price-header-w">
						<div class="c-price-header-in">
							<h2>{{package.name}}</h2>
							<h3 *ngIf="package.isLifeTime">LIfetime</h3>
							<div class="c-price-number">
								${{package.packagePricePlans[0].price}}
							</div>
						</div>
					</div>
					<div class="c-price-feature-list">
						<ul>
							<li>
								<h4>User Limits</h4>
								<p *ngIf="package.administratorUserCount || package.normalUserCount">
									{{!package.administratorUserCount ?'Unlimited' : package.administratorUserCount}}
									coaches,
									{{ package.normalUserCount == 0 ? 0 : !package.normalUserCount ? 'Unlimited' :
									package.normalUserCount }} players</p>
								<p *ngIf="!package.administratorUserCount && !package.normalUserCount">Unlimited
									Coaches, Players, and Fans</p>
							</li>
							<li>
								<h4>Film</h4>
								<p>Pay what you use film.
									Unlmited storage
									4k / streamed</p>
								<ol>
									<li *ngIf="package.freeStorageSpace">unlimited storage HD only -
										{{package.freeStorageSpace}} Gbs of free</li>
									<li *ngIf="package.storageChargePerGb">{{package.storageChargePerGb}} per GB storage
									</li>
									<li *ngIf="package.freeStreamingSpace">{{package.freeStreamingSpace}} GB streamed
										Free</li>
									<li *ngIf="package.streamingChargePerGb">{{package.streamingChargePerGb}} per GB
										streamed</li>
								</ol>
							</li>
							<li>
								<h4>Tools</h4>
								<p>LIst all of them and add as included</p>
							</li>
							<li>
								<h4>Playbooks</h4>
								<p *ngIf="package.canPlaybookCreate === true">All Current Playbooks</p>
								<ol *ngIf="package.canPlaybookCreate === false">
									<li>Cannot create or Add new playbooks</li>
									<li>All Current Playbooks</li>
								</ol>
								<ol *ngIf="package.canPlaybookCreate !== true && package.canPlaybookCreate !== false">
									<li>Can only buy Varsity package after that for $799 per year</li>
									<li>In Dashboard can always see others and buy individual</li>
								</ol>
							</li>
							<!-- <li>
								<h4>Extras</h4>
								<p>Playbook Import $200</p>
							</li> -->
						</ul>
					</div>
					<div class="c-price-footer-w">
						<a class="c-btn-1" (click)="p_buy(package.id)"><span>Buy now</span></a>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<!-- ========== Register screen ============ -->
<section class="c-rgister-w" id="c-rgister-w" *ngIf="activeStatusArray[1] === true">
	<div class="container">
		<div class="row align-items-center">
			<div class="col-lg-4 col-md-6">
				<div class="c-price-box">
					<div class="c-price-header-w">
						<div class="c-price-header-in">
							<h2>{{package.name}}</h2>
							<h3 *ngIf="package.isLifeTime">LIfetime</h3>
							<div class="c-price-number">
								${{package.packagePricePlans[0].price}}
							</div>
						</div>
					</div>
					<div class="c-price-feature-list">
						<ul>
							<li>
								<h4>User Limits</h4>
								<p *ngIf="package.administratorUserCount || package.normalUserCount">
									{{!package.administratorUserCount ?'Unlimited' : package.administratorUserCount}}
									coaches,
									{{ package.normalUserCount == 0 ? 0 : !package.normalUserCount ? 'Unlimited' :
									package.normalUserCount }} players</p>
								<p *ngIf="!package.administratorUserCount && !package.normalUserCount">Unlimited
									Coaches, Players, and Fans</p>
							</li>
							<li>
								<h4>Film</h4>
								<p>Pay what you use film.
									Unlmited storage
									4k / streamed</p>
								<ol>
									<li *ngIf="package.freeStorageSpace">unlimited storage HD only -
										{{package.freeStorageSpace}} Gbs of free</li>
									<li *ngIf="package.storageChargePerGb">{{package.storageChargePerGb}} per GB storage
									</li>
									<li *ngIf="package.freeStreamingSpace">{{package.freeStreamingSpace}} GB streamed
										Free</li>
									<li *ngIf="package.streamingChargePerGb">{{package.streamingChargePerGb}} per GB
										streamed</li>
								</ol>
							</li>
							<li>
								<h4>Tools</h4>
								<p>LIst all of them and add as included</p>
							</li>
							<li>
								<h4>Playbooks</h4>
								<p *ngIf="package.canPlaybookCreate === true">All Current Playbooks</p>
								<ol *ngIf="package.canPlaybookCreate === false">
									<li>Cannot create or Add new playbooks</li>
									<li>All Current Playbooks</li>
								</ol>
								<ol *ngIf="package.canPlaybookCreate !== true && package.canPlaybookCreate !== false">
									<li>Can only buy Varsity package after that for $799 per year</li>
									<li>In Dashboard can always see others and buy individual</li>
								</ol>
							</li>
							<!-- <li>
								<h4>Extras</h4>
								<p>Playbook Import $200</p>
							</li>
							<li>
								<h4>15 Coach Accounts Unlimited Players</h4>
							</li> -->
						</ul>
					</div>
					<div class="c-price-footer-w">

					</div>
				</div>
			</div>
			<div class="col-lg-5 col-md-6">
				<div class="c-rgister-box">
					<h3>Register</h3>
					<div class="c-rgister-box-in">
						<form action="package-detail#c-thanks-w" [class.was-validated]="!registerForm.valid"
							novalidate="" [formGroup]="registerForm" autocomplete="off">
							<div class="form-group">
								<i class="fas fa-address-book"></i>
								<input class="form-control" type="text" required="" placeholder="Enter your Team Name"
									formControlName="organizationName">
								<div class="invalid-feedback"
									*ngIf="registerForm.controls.organizationName.touched && registerForm.controls.organizationName.errors?.required">
									Team Name is required.
								</div>
							</div>
							<div class="form-group">
								<i class="fas fa-user"></i>
								<input class="form-control" type="text" required="" placeholder="Coach First name"
									formControlName="name">
								<div class="invalid-feedback"
									*ngIf="registerForm.controls.name.touched && registerForm.controls.name.errors?.required">
									Coach First Name is required.
								</div>
							</div>
							<div class="form-group">
								<i class="fas fa-user"></i>
								<input class="form-control" type="text" required="" placeholder="Coach Last name"
									formControlName="lastName">
								<div class="invalid-feedback"
									*ngIf="registerForm.controls.lastName.touched && registerForm.controls.lastName.errors?.required">
									Coach Last Name is required.
								</div>
							</div>
							<div class="form-group">
								<i class="fas fa-envelope"></i>
								<input class="form-control" type="email" required="" placeholder="Email Address"
									formControlName="email" autocomplete="nope">
								<div class="invalid-feedback"
									*ngIf="registerForm.controls.email.touched && registerForm.controls.email.errors?.required">
									Email is required.
								</div>
								<div class="invalid-feedback"
									*ngIf="registerForm.controls.email.touched && registerForm.controls.email.errors?.email">
									Invalid Email Address.
								</div>
							</div>
							<div class="form-group">
								<i class="fas fa-phone-alt"></i>
								<input class="form-control" placeholder="Mobile no." formControlName="mobile"
									autocomplete="nope" (keyup)="onMobileChange()">
								<div class="invalid-feedback"
									*ngIf="registerForm.controls.mobile.touched && registerForm.controls.mobile.errors?.required">
									Coach Mobile Number is required.
								</div>
							</div>
							<div class="form-group">
								<i class="fas fa-key"></i>
								<input class="form-control" [type]="show ? 'text' : 'password'" name="login[password]"
									required placeholder="Password" formControlName="password" autocomplete="nope">
								<div class="invalid-feedback"
									*ngIf="registerForm.controls.password.touched && registerForm.controls.password.errors?.required">
									Password is required.
								</div>
								<div class="cust-invalid-feedback"
									*ngIf="registerForm.controls.password.touched  && registerForm.controls.password.errors?.minlength">
									Password must 8 characters long.
								</div>
								<div class="cust-invalid-feedback"
									*ngIf="registerForm.get('password')?.hasError('pattern')">
									(Contain min one Capital Letter [A-Z], at lest one [a-z], contain spacial character
									like [! @@ # $ % ^ & *] & at least one number [0-9]).
								</div>
								<div class="show-hide" (click)="showPassword()" *ngIf="!show"><i class="far fa-eye"></i>
								</div>
								<div class="show-hide" (click)="showPassword()" *ngIf="show"><i
										class="far fa-eye-slash"></i></div>
							</div>
							<div class="form-group">
								<i class="fas fa-key"></i>
								<input class="form-control" [type]="showConfirm ? 'text' : 'password'"
									name="login[confirmPassword]" required="" placeholder="Confirm Password"
									formControlName="confirmPassword" autocomplete="nope">
								<div class="invalid-feedback"
									*ngIf="registerForm.controls.confirmPassword.touched && registerForm.controls.confirmPassword.errors?.required">
									Confirm Password is required.
								</div>
								<div class="cust-invalid-feedback"
									*ngIf="!registerForm.controls.confirmPassword.errors?.required &&  registerForm.hasError('notSame')">
									Password and confirm not match.
								</div>
								<div class="show-hide" (click)="showConfirmPassword()" *ngIf="!showConfirm"><i
										class="far fa-eye"></i></div>
								<div class="show-hide" (click)="showConfirmPassword()" *ngIf="showConfirm"><i
										class="far fa-eye-slash"></i></div>
							</div>
							<input type="submit" name="" class="c-btn-1" value="Submit" [disabled]="!registerForm.valid"
								(click)="register()">
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<!-- ========= Thank You ========= -->
<section class="c-thanks-w" id="c-thanks-w" *ngIf="activeStatusArray[2] === true">
	<div class="c-thanks-in">
		<div class="container" *ngIf="success === true">
			<img src="../../../assets/images/logo/logo.png" alt="logo" title="logo">
			<h1>Thank You</h1>
			<h3>Your Purchase has Successful.</h3>
			<a class="c-btn-1" (click)="go_login()"><span>Go To Login</span></a>
		</div>
		<div class="container" *ngIf="success === false">
			<img src="../../../assets/images/logo/logo.png" alt="logo" title="logo">
			<h1>Sorry</h1>
			<h3>Your Purchase was UnSuccessful.</h3>
			<a class="c-btn-1" (click)="go_login()"><span>Go To Login</span></a>
		</div>
	</div>
</section>