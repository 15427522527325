import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ValidationComponent } from "./pages/authentication/login/validation/validation.component";
import { ContentComponent } from "./shared/components/layout/content/content.component";
import { FullComponent } from "./shared/components/layout/full/full.component";
import { full } from "./shared/routes/full.routes";
import { content } from "./shared/routes/routes";
import { LegacyLoginComponent } from "./shared/components/layout/legacy-login/legacy-login.component";
import { HomeComponent } from "./home/home.component";
import { AboutComponent } from "./about/about.component";
import { CareersComponent } from "./careers/careers.component";
import { PackageDetailComponent } from "./package-detail/package-detail.component";
import { TermsconditionComponent } from "./termscondition/termscondition.component";
import { PrivacypolicyComponent } from "./privacypolicy/privacypolicy.component";
import { AffiliateprogramComponent } from "./affiliateprogram/affiliateprogram.component";
import { PublicProfileComponent } from "./public-profile/public-profile.component";
import { DepthchartComponent } from "./depthchart/depthchart.component";
import { MarketingRegisterComponent } from "./pages/authentication/marketing-register/marketing-register.component";
import { PlaybookRegDepthchartComponent } from "./pages/authentication/playbook-reg-depthchart/playbook-reg-depthchart.component";
import { StripePaymentsComponent } from "./stripe-payments/stripe-payments.component";
import { GameCountDownComponent } from "./game-count-down/game-count-down.component";
import { PlaybookPackagePurchaseComponent } from "./playbook-package-purchase/playbook-package-purchase.component";
import { ShowtimeProfileComponent } from "./showtime-profile/showtime-profile.component";
import { PlayerRegComponent } from "./player-reg/player-reg.component";
import { AppDownloadComponent } from "./app-download/app-download.component";
import { BigboardPrototypeComponent } from "./bigboard-prototype/bigboard-prototype.component";
import { PlayerInvitationComponent } from "./player-invitation/player-invitation.component";
import { PublicInstallsComponent } from "./public-installs/public-installs.component";
import { PublicInstallPresentationsComponent } from "./public-install-presentations/public-install-presentations.component";
import { RuleBookComponent } from "./rule-book/rule-book.component";
import { PublicFootballMindProfileComponent } from "./public-football-mind-profile/public-football-mind-profile.component";
import { EventAttendancePlayerLoginComponent } from "./pages/authentication/event-attendance-player-login/event-attendance-player-login.component";
import { Error404Component } from "./pages/error/error404/error404.component";
import { PublicFaqComponent } from "./public-faq/public-faq.component";
import { ReportingIssueComponent } from "./reporting-issue/reporting-issue.component";
import { UrlSharingComponent } from "./url-sharing/url-sharing.component";
import { AuthGuard } from "./core/guards/auth.guard";

const routes: Routes = [
    // {
    //     path: "",
    //     redirectTo: "auth/login",
    //     pathMatch: "full",
    // },
    {
        path: "",
        component: HomeComponent
    },
    {
        path: "rule-book",
        component: RuleBookComponent
    },
    {
        path: "about",
        component: AboutComponent
    },
    {
        path: "careers",
        component: CareersComponent
    },
    {
        path: "package-detail",
        component: PackageDetailComponent
    },
    {
        path: "terms-condition",
        component: TermsconditionComponent
    },
    {
        path: "privacy-policy",
        component: PrivacypolicyComponent
    },
    {
        path: "affiliate",
        component: AffiliateprogramComponent
    },
    {
        path: "showtime-profile",
        component: ShowtimeProfileComponent,
    },
    {
        path: "player-invitation",
        component: PlayerInvitationComponent,
    },
    {
        path: "auth/login",
        component: ValidationComponent,
    },
    {
        path: 'auth/player-attendance-login',
        component: EventAttendancePlayerLoginComponent,
    },
    {
        path: "showtime-welcome",
        component: PlayerRegComponent
    },
    {
        path: "installs",
        component: PublicInstallsComponent
    },
    {
        path: "installs/presentation",
        component: PublicInstallPresentationsComponent
    },
    {
        path: "football-mind-profile",
        component: PublicFootballMindProfileComponent
    },
    {
        path: "reporting-issue",
        component: ReportingIssueComponent
    },
    {
        path: "offense-public",
        loadChildren: () =>
            import(
                "./offense-public/offense-public.module"
            ).then((m) => m.OffensePublicModule),
    },
    {
        path: '404',
        component: Error404Component
    },
    {
        path: "docs",
        component: PublicFaqComponent,
    },
    {
        path: 'download/:_type',
        component: AppDownloadComponent,
    },
    {
        path: 'bigboard-prototype',
        component: BigboardPrototypeComponent,
    },
    {
        path: 'shared/:_code',
        component: UrlSharingComponent,
        //canActivate: [AuthGuard]
    },
    {
        path: "",
        component: ContentComponent,
        //canActivate: [AdminGuard],
        //canActivate: [AuthGuard],
        children: content,
    },
    {
        path: "",
        component: FullComponent,
        //canActivate: [AdminGuard],
        //canActivate: [AuthGuard],
        children: full,
    },
    {
        path: "game-count-down",
        component: GameCountDownComponent,
    },
    {
        path: "package-payment",
        component: StripePaymentsComponent,
    },
    {
        path: 'playbook-package-purchase',
        component: PlaybookPackagePurchaseComponent,
    },
    {
        path: "legacy-login",
        component: LegacyLoginComponent,
    },
    {
        path: "depthchart",
        component: DepthchartComponent,
    },
    {
        path: 'playbook-register',
        component: MarketingRegisterComponent,
    },
    {
        path: 'playbook-register/depth-chrt',
        component: PlaybookRegDepthchartComponent,
    },
    {
        path: 'playbook-register/:_accCode',
        component: MarketingRegisterComponent,
    },
    {
        path: ":_user",
        component: PublicProfileComponent
    },
    {
        path: '**',
        component: Error404Component
    }
    // {
    //     path: "**",
    //     redirectTo: "",
    // },
];

@NgModule({
    imports: [
        [
            RouterModule.forRoot(routes, {
                anchorScrolling: "enabled",
                scrollPositionRestoration: "enabled",
                relativeLinkResolution: "legacy",
            }),
        ],
    ],
    exports: [RouterModule],
})
export class AppRoutingModule { }
