import { Component, HostListener, Input, OnInit, QueryList, ViewChildren, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Options } from 'ng5-slider';
import { TagInputComponent } from 'ngx-chips';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { DashboardService } from 'src/app/data/services/dashboard';
import { TagService } from 'src/app/data/services/global';

@Component({
  selector: 'app-video-modal',
  templateUrl: './video-modal.component.html',
  styles: ['.modal-dialog-scrollable .modal-content { overflow: visible !important; } ngb-modal-window .component-host-scrollable { overflow: visible !important; } .modal-dialog-scrollable .modal-body { overflow-y: visible !important; } ng2-dropdown-menu div { z-index: 99999 !important; }'
  ],
  styleUrls: ['./video-modal.component.scss'],
})
export class VideoModalComponent implements OnInit {
  @Input() fromParent: any;
  detailForm: FormGroup;
  tags: any[] = [];
  public validate = false;
  fileName: string = "";
  playlistName: string = "";
  clipName: string = "";
  clipId: string = "";
  fileExtension: string = "";
  isDiabled: boolean = true;
  //simpleSliderControl: FormControl = new FormControl(100);

  @ViewChildren(TagInputComponent)
  private tagInputs?: QueryList<TagInputComponent>;
  @HostListener('click')
  onClick() {
    this.tagInputs?.forEach(tagInput => {
      if (tagInput.dropdown && !tagInput.isInputFocused() && tagInput.dropdown.isVisible) {
        tagInput.dropdown.hide();
      }
    })
  }
  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private tagService: TagService,
    private dashboardService: DashboardService,
    public toster: ToastrService,
  ) {
    this.detailForm = this.formBuilder.group({
      fileName: ['', Validators.required],
      downValue: String,
      filetrValue: String,
      distanceValue: 0,
      tagNames: [],
      allTags: []
    });
  }

  ngOnInit(): void {
    this.fileName = this.fromParent.fileName;
    this.playlistName = this.fromParent.playlistName;
    this.clipId = this.fromParent.clipId;
    this.fileExtension = this.fromParent.clipName.substring(this.fromParent.clipName.lastIndexOf('.'));
    this.clipName = this.fromParent.clipName.substr(0, this.fromParent.clipName.lastIndexOf('.'));;

    this.detailForm.controls[`fileName`].setValue(this.clipName)

    if (this.clipId) {
      this.getData();
    }

    this.detailForm.get("distanceValue")?.valueChanges.subscribe(value => {
      this.distance(value);
    });
  }

  getData() {
    this.dashboardService.getVideoModalData(this.clipId).subscribe(resp => {
      if (resp) {
        this.detailForm.controls[`downValue`].setValue(resp.downValue);
        this.detailForm.controls[`filetrValue`].setValue(resp.filetrValue);
        this.detailForm.controls[`distanceValue`].setValue(resp.distanceValue);
        this.detailForm.controls[`allTags`].setValue(resp.tagNames);
      }
    });
  };

  simpleSliderOptions: Options = {
    floor: 0.5,
    ceil: 20
  };

  onTextChange(event: any) {
    var searchText: string = ""
    if (event) {
      searchText = event.toLowerCase();
    }
    this.tagService.getTagName(searchText, '').subscribe(resp => {
      this.tags = resp
    });
  };
  onItemAdded() {
    this.tagModify();
  };
  onItemRemoved() {
    this.tagModify();
  };

  tagModify() {
    const saveData = this.detailForm.value;
    saveData.associateName = saveData.name;
    saveData.type = 'pv';
    saveData.prefix = 'pv';
    saveData.originRefId = this.clipId;

    var tagValueArry: any[] = saveData.tagNames;
    if (tagValueArry && tagValueArry.length) {
      var searchTagValueArry: any[] = [];
      tagValueArry.forEach(data => {
        searchTagValueArry.push(data.value);
      });
      saveData.tagList = searchTagValueArry;
    }

    this.tagService.modify(saveData).subscribe((resp) => {
      if (!resp) {
        //console.log(resp);
      }
      this.getData();
    });
  };

  saveModal() {
    if (this.detailForm.invalid) {
      return;
    }
    var saveData = this.detailForm.value;
    saveData.playlistVideoId = this.clipId;
    saveData.fileName = saveData.fileName + this.fileExtension;

    var tagValueArry: any[] = saveData.tagNames;
    if (tagValueArry && tagValueArry.length) {
      var searchTagValueArry: any[] = [];
      tagValueArry.forEach(data => {
        searchTagValueArry.push(data.value);
      });
      saveData.tagNames = searchTagValueArry;
    }

    this.dashboardService.updateVideoModal(saveData)
      .pipe(first())
      .subscribe((resp: any) => {
        if (resp && resp.success) {
          this.closeModal('ok');
        }
        else {
          this.toster.error(resp.responseMsg);
        }
      },
        error => {
          this.toster.error("!Failed to Save.");
        });
  };

  closeModal(referenceId: any) {
    this.activeModal.close(referenceId);
  };

  down(value: string) {
    let array = this.detailForm.controls[`allTags`].value;
    if (array == null) {
      array = [];
    }
    array = array.filter((value: string) => {
      let index = value.indexOf("DN");
      return index <= -1;
    });
    array.push("DN:" + value);
    this.detailForm.controls[`allTags`].setValue(array);
    this.detailForm.controls[`downValue`].setValue(value);
  };
  filterType(value: string) {
    let array = this.detailForm.controls[`allTags`].value;
    if (array == null) {
      array = [];
    }
    array = array.filter((value: string) => {
      let index = value.indexOf("Offense");
      return index <= -1;
    });
    array = array.filter((value: string) => {
      let index = value.indexOf("Defense");
      return index <= -1;
    });
    array = array.filter((value: string) => {
      let index = value.indexOf("Kick");
      return index <= -1;
    });
    array.push(value);
    this.detailForm.controls[`allTags`].setValue(array);
    this.detailForm.controls[`filetrValue`].setValue(value);
  };
  distance(value: number) {
    let array = this.detailForm.controls[`allTags`].value;
    if (array == null) {
      array = [];
    }
    array = array.filter((value: string) => {
      let index = value.indexOf("DIST");
      return index <= -1;
    });
    if(value > 0.5){
      array.push("DIST:" + value);
    }
    this.detailForm.controls[`allTags`].setValue(array);
    //this.detailForm.controls[`downValue`].setValue(value);
  };
  onTagRemoved(event: any) {   
    this.dashboardService.removeVideoTagData(this.clipId, event, false)
      .pipe(first())
      .subscribe((resp: any) => {
        if (resp && resp == true) {
          this.getData();
        }
        else {
          this.toster.error("Failed to Remove Tag");
        }
      },
        error => {
          this.toster.error("Failed to Remove Tag");
        });
  };

  handleVideoEnded(event: Event) {
    const videoElement: HTMLVideoElement = event.target as HTMLVideoElement;
    if(videoElement){
      videoElement.playbackRate = 0.25;
      videoElement.play();
    }
  }
}
