import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-player-invitation',
  templateUrl: './player-invitation.component.html',
  styleUrls: ['./player-invitation.component.css']
})
export class PlayerInvitationComponent implements OnInit {
step =1;
  constructor() { }

  ngOnInit(): void {
  }

  nxtStep(){
    this.step +=1;
  };
  preStep(){
    this.step -=1;
  };
}
