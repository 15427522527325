<header class="main-header">
	<div class="container">
		<div class="row">
			<div class="col-12 p-0">
				<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
					<a class="navbar-brand" href="index.html"><img src="../../assets/images/home-logo-ss.png"
							alt="STRONGSIDE" height="40" title="STRONGSIDE" /></a>
					<button class="navbar-toggler" type="button" data-toggle="collapse"
						data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
						aria-expanded="false" aria-label="Toggle navigation">
						<span class="navbar-toggler-icon"></span>
					</button>
					<div class="collapse navbar-collapse" id="navbarSupportedContent">
						<ul class="navbar-nav ml-auto">
							<li class="nav-item">
								<a class="nav-link" href="/">HOME</a>
							</li>
							<li class="nav-item">
								<a class="nav-link" href="#prices">PRICE</a>
							</li>
							<li class="nav-item">
								<a class="nav-link" href="/docs">FAQ</a>
							</li>
							<li class="nav-item">
								<a class="nav-link" href="#">ABOUT US</a>
							</li>
							<li class="nav-item">
								<a class="nav-link" href="/careers">CAREERS</a>
							</li>
						</ul>
						<ul class="navbar-nav ml-auto">
							<div class="btn-grid m-0">
								<a href="authentication/register" class="primary-link-btn" title="Start">Sign Up</a>
								&nbsp;
								<a href="auth/login" class="default-link-btn" title="Sign In">sign in</a>
							</div>
						</ul>
					</div>
				</nav>
			</div>
		</div>
	</div>
</header>

<div class="page-main-banner-section" id="home">
	<div class="container-fluid">
		<div class="row align-items-center">
			<div class="col-lg">
				<div class="banner-information">
					<h1 style="padding-top: 40px" class="wow fadeInDown" data-wow-delay="1s">
						<span>STRONG</span>SIDE
					</h1>
					<h3 class="wow fadeInDown" data-wow-delay="1s">
						Welcome to <span> Our Football Program</span>
					</h3>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="features-wraper" id="features">
	<div class="container-fluid">
		<div class="row mt-2">
			<div class="col-md-3 col-12">
				<div class="card listbox">
					<div class="card-heder">
						<h4>Player Lists</h4>
					</div>
					<hr />
					<div style="max-height: 500px; overflow-y: auto;" [dragula]="'DRAGULA_PLAYER_LIST'">
						<div class="lists" *ngFor="let player of players; let i = index;">
							#{{i + 1}}
							<!-- <span style="float: right;">{{ player.jerseyNumber ? player.jerseyNumber : '##'}}</span> -->
						</div>
					</div>
				</div>
			</div>

			<div class="col-md-9 col-12">
				<div class="row align-item-center justify-content-center">
					<div class="col-md-3 col-12" *ngFor="let pos of positions">
						<div class="card box listbox">
							<h4>{{pos.positionName}}({{pos.positionAlias}})</h4>
							<hr />
							<div style="min-height: 40px;" [dragula]="'DRAGULA_PLAYER_LIST'">

							</div>
						</div>
					</div>
					<!-- <div class="col-md-4 col-12">
                        <div class="card box listbox">
                            <h4>PositionName</h4>
                            <hr/>
                            <div [dragula]="'DRAGULA_PLAYER_LIST'">
                                <div class="lists">#25</div>
                                <div class="lists">#33</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-12">
                        <div class="card box listbox">
                            <h4>PositionName</h4>
                            <hr/>
                            <div [dragula]="'DRAGULA_PLAYER_LIST'">
                                <div class="lists">#25</div>
                                <div class="lists">#33</div>
                            </div>
                        </div>
                    </div> -->
				</div>
			</div>

			<!-- <div class="col-md-3 col-12">
                <div class="card listbox" [dragula]="'DRAGULA_PLAYER_LIST'">
                    <div class="card-heder">
                        <h4>Personnel Group Positions</h4>
                    </div>
                    <hr/>
                    <div class="lists">#7</div>
                    <div class="lists">#10</div>
                    <div class="lists">#12</div>
                    <div class="lists">#15</div>
                    <div class="lists">#19</div>
                    <div class="lists">#20</div>
                    <div class="lists">#7</div>
                    <div class="lists">#10</div>
                    <div class="lists">#12</div>
                    <div class="lists">#15</div>
                    <div class="lists">#19</div>
                    <div class="lists">#20</div>
                </div>
            </div> -->
		</div>
	</div>
</div>

<div class="footer-sec" id="contact">
	<div class="container">
		<div class="row">
			<div class="col-lg-4 col-md-6 col-sm-12">
				<div class="footer-grid">
					<h1 class="title">QUICK LINKS</h1>
					<ul>
						<li><a href="#">HOME</a></li>
						<li><a href="#features">FEATURES</a></li>
						<li><a href="#prices">PRICE</a></li>
						<li><a href="#contact">CONTACT</a></li>
						<li><a href="#">CAREERS</a></li>
					</ul>
				</div>
			</div>
			<div class="col-lg-4 col-md-6 col-sm-12">
				<div class="footer-grid">
					<h1 class="title">POLICIES</h1>
					<ul>
						<li><a href="/terms-condition">Terms & Conditions</a></li>
						<li><a href="/privacy-policy">Privacy Policy</a></li>
						<li><a href="#">Cookie Policy</a></li>
						<li><a href="/affiliate">Affiliate Program</a></li>
					</ul>
				</div>
			</div>
			<div class="col-lg-4 col-md-6 col-sm-12">
				<div class="footer-grid">
					<h1 class="title">CONTACT US</h1>
					<form>
						<div class="form-grid">
							<div class="form-group">
								<input type="text" class="foot-form-control" placeholder="example@example.com"
									required />
							</div>
							<div class="form-group">
								<textarea name="" class="foot-form-control" required></textarea>
							</div>
							<div class="form-group">
								<input type="submit" class="input-btn" value="SEND" />
							</div>
						</div>
					</form>
				</div>
			</div>
			<div class="col-lg-12">
				<p class="my-3 text-white">© Strongside 2020 All Rights Reserved.</p>
			</div>
		</div>
	</div>
</div>