import { Component, OnInit, Inject, AfterViewInit, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { NavService } from '../../services/nav.service';
import { LayoutService } from '../../services/layout.service';
import { AppDetailService } from 'src/app/data/services/global/app-detail.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { CoachService, PlayerService, ProfileService } from 'src/app/data/services/team-management';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from 'src/app/data/services/auth/authentication.service';
import { first } from 'rxjs/operators';
import { LoaderService } from '../../services/loader.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Player } from 'src/app/data/models/team-management';
import { OffenseSearchService } from 'src/app/data/services/offense/offense-search.service';
import { UserAccess } from 'src/app/data/models/auth/session-user';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewInit {
  isPackagePaid: boolean = false;

  public elem: any;
  public dark: boolean = this.layout.config.settings.layout_version == 'dark-only' ? true : false;
  macUrl: string = "";
  desktopUrl: string = "";
  accoutName: string = "";
  userType: string = "";
  packageName: string = "";
  upgradePackageBtn: boolean = false;

  @ViewChild('shareModal', { static: true })
  shareModal!: ElementRef<HTMLElement>
  public shareUrl: string = "";
  public whatsAppshareUrl: string = "";
  public instagramShareUrl: string = "";
  public twitterShareUrl: string = "";
  organizationProfileUrl: any;
  organizationProfileImageUrl: any;
  accountData: any;
  public profileUrl: any;
  profileImageUrl: any;
  headCoachUserName: string = "";
  isViewCoachLogin: boolean = false;
  public returnUrl: string = "";
  @ViewChild('playerListModal', { static: true })
  playerListModal!: ElementRef<HTMLElement>;
  searchForm: FormGroup;
  loginForm: FormGroup;
  userName = null;
  rows: Array<Player> = [];
  packageEndDaysLeft: any;
  currentpackageName: string = "";
  // packageSubscriptionId: string = "";
  // packageSubscriptionCanceled: boolean = false;
  userAccess: UserAccess = {};
  // isUpcomingSubscription: boolean = false;

  constructor(public layout: LayoutService,
    public navServices: NavService,
    @Inject(DOCUMENT) private document: any,
    private appDetailService: AppDetailService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private modalService: NgbModal,
    public toster: ToastrService,
    private coachService: CoachService,
    private authenticationService: AuthenticationService,
    private profileService: ProfileService,
    private route: ActivatedRoute,
    private loader: LoaderService,
    private formBuilder: FormBuilder,
    private playerService: PlayerService,
    private offensiveSearch: OffenseSearchService
  ) {
    this.isPackagePaid = this.authenticationService.packagePaid;
    const user = localStorage.getItem('__qu__');
    if (user) {
      this.accoutName = JSON.parse(user).accountName;
      this.userType = JSON.parse(user).userType;
      this.packageEndDaysLeft = JSON.parse(user).packageEndDaysLeft;
      this.currentpackageName = JSON.parse(user).packageName;
      // this.packageSubscriptionId = JSON.parse(user).packageSubscriptionId;
      // this.packageSubscriptionCanceled = JSON.parse(user).packageSubscriptionCanceled;
      // this.packageSubscriptionCanceled = JSON.parse(user).packageSubscriptionCanceled;
      // this.isUpcomingSubscription = JSON.parse(user).isUpcomingSubscription;
    }

    const headCoachUser = sessionStorage.getItem('__qu_HeadCoach__')
    if (headCoachUser) {
      this.headCoachUserName = JSON.parse(headCoachUser).headCoachUserName;
      this.isViewCoachLogin = JSON.parse(headCoachUser).isViewPlayerLogin;
    }

    this.loginForm = this.formBuilder.group({
      headCoachUserName: [],
      isViewPlayerLogin: [false],
      playerUserName: [],
    });

    this.searchForm = this.formBuilder.group({
      searchText: [''],
    });
  }

  ngOnInit() {
    this.userAccess = this.authenticationService.checkUserAccess(this.router.url);

    this.elem = document.documentElement;

    const currentUser = localStorage.getItem('__qu__');
    if (currentUser) {
      this.userName = JSON.parse(currentUser).userName;
    }

    this.appDetailService.getCurrentVersion().subscribe(resp => {
      this.macUrl = resp.macUrl;
      this.desktopUrl = resp.desktopUrl;
    });

    this.authenticationService.getPackageDetailsByLogin().subscribe(resp => {
      this.packageName = resp.name;
      if (this.userType === "H" && this.packageName != "Varsity Package") {
        this.upgradePackageBtn = true;
      }
    });

    this.profileService.getOrg().subscribe(resp => {
      if (resp) {
        this.accountData = resp;
        if (resp.logoFileSequence) {
          this.organizationProfileUrl = "https://fieldvisionupload.s3.amazonaws.com/" + resp.logoFileSequence;
        }
        this.organizationProfileImageUrl = resp.organizationProfileImageUrl;
      }
    });

    this.profileService.get().subscribe(data => {
      //this.profileData = data;
      if (data && data.pro_Seq) {
        this.profileUrl = "https://fieldvisionupload.s3.amazonaws.com/" + data.pro_Seq;
      }

      this.profileImageUrl = data.profileImageUrl;

    });
  }

  ngAfterViewInit() {
    let themeColor = localStorage.getItem('__APP-THEME__');
    if (themeColor) {
      setTimeout(() => {
        this.layout.config.settings.layout_version = themeColor ?? 'light';

        if (this.layout.config.settings.layout_version == 'dark-only') {
          this.dark = true;
        }
      }, 0);
    }
  };

  sidebarToggle() {
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
    this.navServices.megaMenu = false;
    this.navServices.levelMenu = false;
  }

  layoutToggle() {
    localStorage.removeItem('__APP-THEME__');
    this.dark = !this.dark;
    this.layout.config.settings.layout_version = this.dark ? 'dark-only' : 'light';
    localStorage.setItem('__APP-THEME__', this.dark ? 'dark-only' : 'light');
  }

  searchToggle() {
    this.router.navigate(["/global/advanced-search"]);
    //this.navServices.search = true;
  }

  languageToggle() {
    this.navServices.language = !this.navServices.language;
  }

  // toggleFullScreen() {
  //   this.navServices.fullScreen = !this.navServices.fullScreen;
  //   if (this.navServices.fullScreen) {
  //     if (this.elem.requestFullscreen) {
  //       this.elem.requestFullscreen();
  //     } else if (this.elem.mozRequestFullScreen) {
  //       /* Firefox */
  //       this.elem.mozRequestFullScreen();
  //     } else if (this.elem.webkitRequestFullscreen) {
  //       /* Chrome, Safari and Opera */
  //       this.elem.webkitRequestFullscreen();
  //     } else if (this.elem.msRequestFullscreen) {
  //       /* IE/Edge */
  //       this.elem.msRequestFullscreen();
  //     }
  //   } else {
  //     if (!this.document.exitFullscreen) {
  //       this.document.exitFullscreen();
  //     } else if (this.document.mozCancelFullScreen) {
  //       /* Firefox */
  //       this.document.mozCancelFullScreen();
  //     } else if (this.document.webkitExitFullscreen) {
  //       /* Chrome, Safari and Opera */
  //       this.document.webkitExitFullscreen();
  //     } else if (this.document.msExitFullscreen) {
  //       /* IE/Edge */
  //       this.document.msExitFullscreen();
  //     }
  //   }
  // }

  go_to_payment_page() {
    this.router.navigate(["/playbook-package-purchase"]);
  }

  windowsDownload() {
    this.router.navigate(["/download/windows"]);
    //window.location.href = this.desktopUrl;
  };

  macDownload() {
    this.router.navigate(["/download/mac"]);
    //window.location.href = this.macUrl;
  };

  profile() {
    this.router.navigate(["/team-management/profile"]);
  };

  openShare() {
    this.coachService.getOrgPromocode().subscribe(resp => {
      if (resp.success) {
        this.shareUrl = resp.response
        this.shareUrl = environment.siteUrl + "authentication/register/player/" + resp.response;
        this.whatsAppshareUrl = "https://web.whatsapp.com/send?text=" + "https://strongside.app. Hey! this is my StrongSide profile link, Please Click to View. " + this.shareUrl;
        this.instagramShareUrl = "https://api.instagram.com/oembed?url=" + "https://strongside.app. Hey! this is my StrongSide profile link, Please Click to View " + this.shareUrl;
        this.twitterShareUrl = "https://twitter.com/intent/tweet?url=" + "https://strongside.app. Hey! this is my StrongSide profile link, Please Click to View " + this.shareUrl;

        this.modalService.open(this.shareModal, { centered: true });
      }
      else {
        this.toster.error("Something went wrong");
      }
    });
  };

  copyInputMessage(inputElement: any) {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
    this.toster.info("link copied to clipboard");
  };

  logout() {
    //sessionStorage.removeItem('__qu__');
    this.offensiveSearch.setSearch('');
    localStorage.removeItem('__qu__remeber_User__');
    localStorage.removeItem('__qu__');
    sessionStorage.removeItem('__qu_HeadCoach__');
    this.router.navigate(['/auth/login']);
  };

  goToOrganization() {
    this.router.navigate(["/account-settings"]);
  };

  goToCoachLogin() {
    localStorage.removeItem('__qu__remeber_User__');
    localStorage.removeItem('__qu__');
    sessionStorage.removeItem('__qu_HeadCoach__');
    this.authenticationService.legacyLogin(this.headCoachUserName)
      .pipe(first())
      .subscribe(
        user => {
          this.returnUrl = this.route.snapshot.queryParams.returnUrl;
          if (this.returnUrl === undefined) {
            localStorage.setItem('isLogin', 'Yes');
            this.router.navigate(["/dashboard/default"]).then(() => {
              window.location.reload();
            });
          }
          else {
            this.router.navigate([this.returnUrl]).then(() => {
              window.location.reload();
            });
          }
        },
        error => {
          this.toster.error(error.error);
        });
  };

  openPlayerList() {
    this.modalService.open(this.playerListModal, { centered: true, size: 'lg' });
    this.loadPlayer();
  }

  loadPlayer() {
    const searchValue = this.searchForm.controls['searchText'].value;
    this.loader.isLoading.next(true);
    this.playerService.getAllPlayer(searchValue)
      .pipe(first())
      .subscribe((resp: any) => {
        this.loader.isLoading.next(false);
        if (resp) {
          this.rows = resp;
        }
      },
        (error: any) => {
          this.loader.isLoading.next(false);
          this.toster.error("!Failed to load data.");
        });
  };

  closeModal(modal: any) {
    modal.dismiss();
  };

  goToPalyerLogin(playerData: any) {
    this.loginForm.controls['headCoachUserName'].setValue(this.userName);
    this.loginForm.controls['isViewPlayerLogin'].setValue(true);
    this.loginForm.controls['playerUserName'].setValue(playerData.email ? playerData.email : playerData.userName);
    if (!this.loginForm.controls['playerUserName'].value) {
      this.toster.error('Player username or email is not found')
      return;
    }
    localStorage.removeItem('__qu__remeber_User__');
    localStorage.removeItem('__qu__');
    this.loader.isLoading.next(true);
    this.authenticationService.legacyLogin(this.loginForm.controls['playerUserName'].value)
      .pipe(first())
      .subscribe(
        user => {
          this.loader.isLoading.next(false);
          if (this.loginForm.controls[`isViewPlayerLogin`].value && this.loginForm.controls[`isViewPlayerLogin`].value === true) {
            sessionStorage.setItem('__qu_HeadCoach__', JSON.stringify(this.loginForm.value));
          }
          this.returnUrl = this.route.snapshot.queryParams.returnUrl;
          if (this.returnUrl === undefined) {
            localStorage.setItem('isLogin', 'Yes');
            this.router.navigate(["/dashboard/default"]).then(() => {
              window.location.reload();
            });
          }
          else {
            this.router.navigate([this.returnUrl]).then(() => {
              window.location.reload();
            });
          }
        },
        error => {
          this.loader.isLoading.next(false);
          this.toster.error(error.error);
        });
  };

  search() {
    this.loadPlayer();
  };

  goToHelp() {
    this.router.navigate(["/global/faq"]);
  };

  // cancelPackageSubcription() {
  //   this.loader.isLoading.next(true);
  //   this.authenticationService.cancelPackageSubcription(this.packageSubscriptionId)
  //     .pipe(first())
  //     .subscribe((resp: any) => {
  //       this.loader.isLoading.next(false);
  //       if (resp && resp.success) {
  //         this.toster.success(resp.responseMsg);
  //         this.router.navigate(["/auth/login"]);
  //       }
  //     },
  //       (error: any) => {
  //         this.loader.isLoading.next(false);
  //         this.toster.error("!Failed to cancel subscription.");
  //       });
  // };

  // resumePackageSubcription() {
  //   this.router.navigate(["/playbook-package-purchase"]);
  // };
}
