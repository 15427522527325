<div  data-spy="scroll" data-target=".navbar" class="c-shotime-w" *ngIf="userName === 'dj'">
    <!-- START: Preloader -->
    <div id="preloader" class="preloader">
        <div class="spinner-grow text-light" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
    <!-- END: Preloader -->

    <header>
        <div class="container">
            <div class="d-flex justify-content-between align-items-center">
                <a href="showtime-profile;_user=dj#HOME" class="header-logo" >DJ Bordeaux</a>

                <div class="menu d-flex justify-content-center align-items-center">

                    <!-- <a href="#CONTACT" class="head-contact btn btn-small btn-gradient mr-4">Contact</a> -->

                    <button class="btn btn-small collapsed navbar-toggler" data-toggle="collapse" data-target="#" aria-controls="" aria-expanded="false" aria-label="Toggle navigation"> 
                        <svg class="ham hamRotate ham1" viewBox="0 0 100 100" width="40"><path class="line top" d="m 30,33 h 40 c 0,0 9.044436,-0.654587 9.044436,-8.508902 0,-7.854315 -8.024349,-11.958003 -14.89975,-10.85914 -6.875401,1.098863 -13.637059,4.171617 -13.637059,16.368042 v 40"></path><path class="line middle" d="m 30,50 h 40"></path><path class="line bottom" d="m 30,67 h 40 c 12.796276,0 15.357889,-11.717785 15.357889,-26.851538 0,-15.133752 -4.786586,-27.274118 -16.667516,-27.274118 -11.88093,0 -18.499247,6.994427 -18.435284,17.125656 l 0.252538,40"></path></svg>
                    </button>

                    <div class="navbar-collapse collapse" id="">
                        <div class="container">
                            <div class="navbar-content">
                                <div class="navbar-links h-100vh d-flex align-items-center">
                                    <ul>
                                        <li>
                                            <span><i class="lni lni-cloud"></i></span>
                                            <a href="showtime-profile;_user=dj#HIGHLIGHTS">Highlights</a>
                                        </li>
                                        <li>
                                            <span><i class="lni lni-book"></i></span>
                                            <a href="showtime-profile;_user=dj#ABOUT">ABOUT</a>
                                        </li>			
                                        <li>
                                            <span><i class="lni lni-offer"></i></span>
                                            <a href="showtime-profile;_user=dj#OFFERS">OFFERS</a>
                                        </li>
                                        <li>
                                            <span><i class="lni lni-bar-chart"></i></span>
                                            <a href="showtime-profile;_user=dj#STATS">STATS</a>
                                        </li>
                                    </ul>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>	
            </div>
        </div>
    </header>

    <div id="pagepiling">

        <!-- START: Home -->
        <div class="section section-home" id="HOME">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-md-7 col-12">
                        <div class="hero-content text-center">
                            <span class="hello mb-4 d-block"></span>
                            <h1><span>DJ Bordeaux</span></h1>

                            <div id="autoplay" class="my-4">
                            <span>QuaterBack 2026</span>
                            <span>6'2", 185lbs, 40: 4.58 </span>
                            <span>Alpharetta, GA</span>
                            </div>   
                            <!-- <div class="smallcontent">
                                <h4 class="mt-2 mb-3">QuaterBack 2026</h4>
                                <h5 class="mb-2">6'2", 185lbs, #19</h5>
                                <p>
                                    <i class="lni lni-twitter"></i>
                                    <a href="https://twitter.com/__GoDJ__?ref_src=twsrc%5Etfw%7Ctwcamp%5Etweetembed%7Ctwterm%5E1641791684215177219%7Ctwgr%5Ec495381aefaca1beb1d0df49dcf723d0351bb502%7Ctwcon%5Es1_&ref_url=https%3A%2F%2Fpublish.twitter.com%2F%3Fquery%3Dhttps3A2F2Ftwitter.com2F__GoDJ__2Fstatus2F1641791684215177219widget%3DTweet" target="_blank">@_GoDJ_</a>
                                </p>
                            </div> -->

                        </div>
                    </div>
                    <div class="col-md-5 col-12">
                        <div class="rigtBox">
                            <img src="assets/images/dj-bordeaux.png" alt="player-profile" title="player-profile" class="img-fluid">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- END: Home -->

        <!-- START: PORTFOLIO -->
        <div class="section" id="PORTFOLIO">
            <div class="container">
                <!-- <div class="section-head text-center">
                    <span>TAKE A LOOK AT</span>
                    <h2>Portfolio</h2>
                </div>	 -->
                <div class="container swiper-container swiper-portfolio wow fadeInUp mt-5 pt-5" data-wow-duration="1.5s">
                    <div class="swiper-wrapper">
                        <div class="portfolio-item swiper-slide " data-groups="all slideshow">
                            <a href="assets/images/p-2.jpg" class="portfolio-item--content simple-ajax-popup">
                                <div class="portfolio-item--img b-box">
                                    <img src="assets/images/p-2.jpg" class="img-fluid mx-auto" alt="portfolio" title="portfolio">
                                </div>
                            </a>
                        </div>

                        <div class="portfolio-item swiper-slide image" data-groups="all image">
                            <a href="assets/images/ytv2.mp4" class="portfolio-item--content simple-ajax-video-popup">
                                <div class="portfolio-item--img b-box">
                                    <!-- <img src="assets/images/ytv2.mp4" class="img-fluid mx-auto" alt="portfolio" title="portfolio"> -->
                                    <video width="100%" height="400" controls="false" autoplay="flase" muted loop>
                                        <source src="assets/images/ytv2.mp4" type="video/mp4">
                                    </video>
                                </div>
                                <!-- <div class="portfolio-item--desc d-flex justify-content-center align-items-center">
                                    <div class="portfolio-item--desc-in">
                                        <h3 class="mb-2 text-white">The wall</h3>
                                        <p class="text-white">Slideshow</p>
                                    </div>
                                </div> -->
                            </a>
                        </div>

                        <div class="portfolio-item swiper-slide slideshow" data-groups="all slideshow">
                            <a href="assets/images/ytv1.mp4" class="portfolio-item--content simple-ajax-video-popup">
                                <div class="portfolio-item--img b-box">
                                    <!-- <img src="assets/images/p1-1.jpg" class="img-fluid mx-auto" alt="portfolio" title="portfolio"> -->
                                    <video width="100%" height="400" controls="false" autoplay="autoplay" muted loop>
                                        <source src="assets/images/ytv1.mp4" type="video/mp4">
                                    </video>
                                </div>
                                <!-- <div class="portfolio-item--desc d-flex justify-content-center align-items-center">
                                    <div class="portfolio-item--desc-in">
                                        <h3 class="mb-2 text-white">The wall</h3>
                                        <p class="text-white">Slideshow</p>
                                    </div>
                                </div> -->
                            </a>
                        </div>

                        <div class="portfolio-item swiper-slide video" data-groups="all video">
                            <a href="assets/images/ytv2.mp4" class="portfolio-item--content simple-ajax-video-popup">
                                <div class="portfolio-item--img b-box">
                                    <!-- <img src="assets/images/ytv2.mp4" class="img-fluid mx-auto" alt="portfolio" title="portfolio"> -->
                                    <video width="100%" height="400" controls="false" autoplay="autoplay" muted loop>
                                        <source src="assets/images/ytv2.mp4" type="video/mp4">
                                    </video>
                                </div>
                                <!-- <div class="portfolio-item--desc d-flex justify-content-center align-items-center">
                                    <div class="portfolio-item--desc-in">
                                        <h3 class="mb-2 text-white">The wall</h3>
                                        <p class="text-white">Slideshow</p>
                                    </div>
                                </div> -->
                            </a>
                        </div>

                        <div class="portfolio-item swiper-slide  slideshow" data-groups="all slideshow">
                            <a href="assets/images/p-4.jpg" class="portfolio-item--content simple-ajax-popup">
                                <div class="portfolio-item--img b-box">
                                    <img src="assets/images/p-4.jpg" class="img-fluid mx-auto" alt="portfolio" title="portfolio">
                                </div>
                                <!-- <div class="portfolio-item--desc d-flex justify-content-center align-items-center">
                                    <div class="portfolio-item--desc-in">
                                        <h3 class="mb-2 text-white">The wall</h3>
                                        <p class="text-white">Slideshow</p>
                                    </div>
                                </div> -->
                            </a>
                        </div>

                    </div>
                    <div class="portfolio-pagination">
                        
                    </div>
                </div>
            </div>
        </div>
        <!-- END: PORTFOLIO -->

        <!-- START: ABOUT -->
        <div class="section" id="ABT">
            <div class="container">

                <div class="row justify-content-center align-items-center">
                    <div class="col-md-5">
                        <img class="about-img about-showtime-profile img-fluid wow fadeInUp" data-wow-duration="1s" src="assets/images/about-img.jpg" alt="about-picture" title="about-picture">
                    </div>
                    <div class="col-md-7">

                        <div class="about-desc wow fadeInUp" data-wow-duration="1s"  data-wow-delay="500ms">
                            <div class="about-desc-content">
                                <!-- <h1 class="font-weight-light mb-5">DJ Bordeaux</h1> -->
                                <p class="font-weight-light my-3">DJ Bordeaux accounted for nearly 1,000 yards as a freshman on varsity at Highlands Ranch (Colo.) ThunderRidge last fall, with a
                                    bulk of those yards coming in the final three.</p>
                                    <p>The 2026 quarterback prospect is now anxious to test his skills against Peach State
                                        competition. Bordeaux moved to Georgia and enrolled at Alpharetta High School.</p>
                            </div>

                            <div class="about-desc-more text-left mt-5">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="about-desc-info">
                                            <div class="about-desc-info-content d-flex align-items-center hover-state b-box">
                                                <div class="about-desc-icon">
                                                    <i class="lni lni-calendar"></i>
                                                </div>
                                                <div class="about-desc-info-desc">
                                                    <span>Freshman • 2026</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="about-desc-info">
                                            <div class="about-desc-info-content d-flex align-items-center hover-state b-box">
                                                <div class="about-desc-icon">
                                                    <i class="lni lni-pin"></i>
                                                </div>
                                                <div class="about-desc-info-desc">
                                                    <span>Alpharetta, GA</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="about-desc-info">
                                            <div class="about-desc-info-content d-flex align-items-center hover-state b-box">
                                                <div class="about-desc-icon">
                                                    <i class="lni lni-graduation"></i>
                                                </div>
                                                <div class="about-desc-info-desc">
                                                    <span>Alpharetta HS</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="about-desc-info">
                                            <div class="about-desc-info-content d-flex align-items-center hover-state b-box">
                                                <div class="about-desc-icon">
                                                    <i class="lni lni-youtube"></i>
                                                </div>
                                                <div class="about-desc-info-desc">
                                                    <a href="https://www.youtube.com/@__GoDJ__" target="_blank">youtube.com/@__GoDJ__</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>			
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- END: ABOUT -->

        <!-- START: TESTIMONY -->
        <div class="section feed-section" id="TESTIMONY">
            <div class="container">
                <!-- <div class="section-head text-center">
                    <span>Kind Words</span>
                    <h2>testimonial</h2>
                </div> -->
                <div class="row">
                    <div class="col-md-4 col-12">
                        <blockquote class="twitter-tweet">
                            <p lang="en" dir="ltr">Thank you <a
                                    href="https://twitter.com/simon_villanoz?ref_src=twsrc%5Etfw">@simon_villanoz</a> &amp; <a
                                    href="https://twitter.com/PlaymakerCorner?ref_src=twsrc%5Etfw">@PlaymakerCorner</a> <a
                                    href="https://twitter.com/hashtag/pmcFam?src=hash&amp;ref_src=twsrc%5Etfw">#pmcFam</a> <br>📸: <a
                                    href="https://twitter.com/CFravel247?ref_src=twsrc%5Etfw">@CFravel247</a> <br>Edit by: <a
                                    href="https://twitter.com/longeyegraphics?ref_src=twsrc%5Etfw">@longeyegraphics</a> <br>.<br>.<br>.<a
                                    href="https://twitter.com/CoachWheatNCSA?ref_src=twsrc%5Etfw">@CoachWheatNCSA</a> <a
                                    href="https://twitter.com/AHSFootball?ref_src=twsrc%5Etfw">@AHSFootball</a> <a
                                    href="https://twitter.com/JasonKervin?ref_src=twsrc%5Etfw">@JasonKervin</a> <a
                                    href="https://twitter.com/AlphaProspects?ref_src=twsrc%5Etfw">@AlphaProspects</a> <a
                                    href="https://twitter.com/CamNewton7v7?ref_src=twsrc%5Etfw">@CamNewton7v7</a> <a
                                    href="https://twitter.com/EPO_Football?ref_src=twsrc%5Etfw">@EPO_Football</a> <a
                                    href="https://twitter.com/On3sports?ref_src=twsrc%5Etfw">@On3sports</a> <a
                                    href="https://twitter.com/deucerecruiting?ref_src=twsrc%5Etfw">@deucerecruiting</a> <a
                                    href="https://twitter.com/QBHitList?ref_src=twsrc%5Etfw">@QBHitList</a> <a
                                    href="https://twitter.com/DPostSports?ref_src=twsrc%5Etfw">@DPostSports</a> <a
                                    href="https://twitter.com/Stumpf_Brian?ref_src=twsrc%5Etfw">@Stumpf_Brian</a> <a
                                    href="https://t.co/xuF442wAzj">pic.twitter.com/xuF442wAzj</a></p>&mdash; DJ Bordeaux (@__GoDJ__) <a
                                href="https://twitter.com/__GoDJ__/status/1642692662347149313?ref_src=twsrc%5Etfw">April 3, 2023</a>
                        </blockquote>
                        <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
                    </div>
                    <div class="col-md-4 col-12">
                        <blockquote class="twitter-tweet">
                            <p lang="en" dir="ltr">Check out my interview with <a
                                    href="https://twitter.com/PlaymakerCorner?ref_src=twsrc%5Etfw">@PlaymakerCorner</a> Thank y’all for having
                                me <a href="https://twitter.com/hashtag/pmcfam?src=hash&amp;ref_src=twsrc%5Etfw">#pmcfam</a> <a
                                    href="https://t.co/SYjBkFM4MV">https://t.co/SYjBkFM4MV</a><a
                                    href="https://t.co/y2T4trDL76">https://t.co/y2T4trDL76</a> <a
                                    href="https://t.co/Z43mCyQFsp">pic.twitter.com/Z43mCyQFsp</a></p>&mdash; DJ Bordeaux (@__GoDJ__) <a
                                href="https://twitter.com/__GoDJ__/status/1641791684215177219?ref_src=twsrc%5Etfw">March 31, 2023</a>
                        </blockquote>
                        <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
                    </div>
                    <div class="col-md-4 col-12">
                        <blockquote class="twitter-tweet">
                            <p lang="en" dir="ltr">Thank you <a
                                    href="https://twitter.com/longeyegraphics?ref_src=twsrc%5Etfw">@longeyegraphics</a> for the 🔥
                                <br>.<br>.<br>.<br>.<a href="https://twitter.com/AHSFootball?ref_src=twsrc%5Etfw">@AHSFootball</a> <a
                                    href="https://twitter.com/JasonKervin?ref_src=twsrc%5Etfw">@JasonKervin</a> <a
                                    href="https://twitter.com/CoachWheatNCSA?ref_src=twsrc%5Etfw">@CoachWheatNCSA</a> <a
                                    href="https://twitter.com/HokiesFB?ref_src=twsrc%5Etfw">@HokiesFB</a> <a
                                    href="https://twitter.com/PennStateFball?ref_src=twsrc%5Etfw">@PennStateFball</a> <a
                                    href="https://twitter.com/CUBuffsFootball?ref_src=twsrc%5Etfw">@CUBuffsFootball</a> <a
                                    href="https://twitter.com/Pitt_FB?ref_src=twsrc%5Etfw">@Pitt_FB</a> <a
                                    href="https://twitter.com/On3sports?ref_src=twsrc%5Etfw">@On3sports</a> <a
                                    href="https://twitter.com/CamNewton7v7?ref_src=twsrc%5Etfw">@CamNewton7v7</a> <a
                                    href="https://twitter.com/brandofachamp?ref_src=twsrc%5Etfw">@brandofachamp</a> <a
                                    href="https://twitter.com/PlaymakerCorner?ref_src=twsrc%5Etfw">@PlaymakerCorner</a> <a
                                    href="https://twitter.com/EPO_Football?ref_src=twsrc%5Etfw">@EPO_Football</a> <a
                                    href="https://twitter.com/JeremyO_Johnson?ref_src=twsrc%5Etfw">@JeremyO_Johnson</a> <a
                                    href="https://twitter.com/CoachRich681?ref_src=twsrc%5Etfw">@CoachRich681</a> <a
                                    href="https://twitter.com/B_1egacy?ref_src=twsrc%5Etfw">@B_1egacy</a> <a
                                    href="https://t.co/lYE2IWrWai">pic.twitter.com/lYE2IWrWai</a></p>&mdash; DJ Bordeaux (@__GoDJ__) <a
                                href="https://twitter.com/__GoDJ__/status/1641781458007207937?ref_src=twsrc%5Etfw">March 31, 2023</a>
                        </blockquote>
                        <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
                    </div>
                </div>
            </div>
        </div>
        <!-- END: TESTIMONY -->

        <!-- START: SERVICES -->
        <div class="section" id="SERVICES">
            <div class="container">
                <!-- <div class="section-head text-center">
                    <span>WHAT I DO</span>
                    <h2>My Services</h2>
                </div> -->

                <div class="row mt-4 justify-content-center align-content-center">
                    <div class="col-md-8 col-12">
                        <div class="row">
                            <div class="col-sm-6 col-lg-6">
                                <div class="services-list mt-4 wow fadeInUp" data-wow-duration="1.5s">
                                    <div class="services-list--content d-flex align-items-center b-box hover-state p-4">
                                        <!-- <div class="services-list-icon">
                                            <i class="lni-color-pallet"></i>
                                            <span>01</span>
                                        </div> -->
                                        <div class="services-list-desc">
                                            <!-- <h5 class="mb-0">Graphic Design</h5> -->
                                            <img src="assets/images/logo-1.png" alt="offer" title="offer" class="img-fluid">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 col-lg-6">
                                <div class="services-list mt-4 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay=".2s">
                                    <div class="services-list--content d-flex align-items-center b-box hover-state p-4">
                                        <!-- <div class="services-list-icon">
                                            <i class="lni-laptop-phone"></i>
                                            <span>02</span>
                                        </div> -->
                                        <div class="services-list-desc">
                                            <!-- <h5 class="mb-0">Web Development</h5> -->
                                            <img src="assets/images/logo-2.png" alt="offer" title="offer" class="img-fluid">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 col-lg-6">
                                <div class="services-list mt-4 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay=".3s">
                                    <div class="services-list--content d-flex align-items-center b-box hover-state p-4">
                                        <!-- <div class="services-list-icon">
                                            <i class="lni-video"></i>
                                            <span>03</span>
                                        </div> -->
                                        <div class="services-list-desc">
                                            <!-- <h5 class="mb-0">Media Marketing</h5> -->
                                            <img src="assets/images/logo-3.png" alt="offer" title="offer" class="img-fluid">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 col-lg-6">
                                <div class="services-list mt-4 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay=".4s">
                                    <div class="services-list--content d-flex align-items-center b-box hover-state p-4">
                                        <!-- <div class="services-list-icon">
                                            <i class="lni-network"></i>
                                            <span>04</span>
                                        </div> -->
                                        <div class="services-list-desc">
                                            <!-- <h5 class="mb-0">Advetising</h5> -->
                                            <img src="assets/images/logo-4.png" alt="offer" title="offer" class="img-fluid">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- END: SERVICES -->

        <!-- START: PRICE -->
        <div class="section" id="PRICE">
            <!-- <div class="section-head text-center">
                <span>WHAT I DO</span>
                <h2>My Pricing</h2>
            </div> -->
            <div class="section-skills-wrap">
                <div class="container">
                    <div class="row ">
                        <div class="col-md-12">
                            <div class="resume-list statslists b-box p-4 mb-4">
                                <div>
                                    <h5>PASSING <span class="badge badge-light d-inline-block my-3">2022-2023</span></h5>
                                    <h4 class="d-flex justify-content-between align-items-center">
                                        <p>GD: Fr</p>
                                        <p>TEAM: Var</p>
                                    </h4>
                                    <div class="yearly-stats stats-listed">
                                        <div class="stat">
                                            <div class="name">GP</div>
                                            <div class="value">9</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">C</div>
                                            <div class="value">58</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">ATT</div>
                                            <div class="value">99</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">YDS</div>
                                            <div class="value">829</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">C%</div>
                                            <div class="value">.586</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">AVG</div>
                                            <div class="value">14.3</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">Y/G</div>
                                            <div class="value">92.1</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">C/G</div>
                                            <div class="value">6.4</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">TD</div>
                                            <div class="value"> </div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">TD/G</div>
                                            <div class="value">0.8</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">INT</div>
                                            <div class="value">4</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">LNG</div>
                                            <div class="value">79</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">QB RATE</div>
                                            <div class="value">92.5</div>
                                        </div>
                                        <div class="gradient"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="resume-list statslists b-box p-4 mb-4">
                                <div>
                                    <h5>RUSHING <span class="badge badge-light d-inline-block my-3">2022-2023</span></h5>
                                    <h4 class="d-flex justify-content-between align-items-center">
                                        <p>GD: Fr</p>
                                        <p>TEAM: Var</p>
                                    </h4>
                                    <div class="yearly-stats statsrushing">
                                        <div class="stat">
                                            <div class="name">GP</div>
                                            <div class="value">9</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">CAR</div>
                                            <div class="value">26</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">YDS</div>
                                            <div class="value">128</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">AVG</div>
                                            <div class="value">4.9</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">Y/G</div>
                                            <div class="value">14.2</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">LNG</div>
                                            <div class="value">15</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">100+</div>
                                            <div class="value">0</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">TD</div>
                                            <div class="value">0</div>
                                        </div>
                                        <div class="gradient"></div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="col-md-6">
                            <div class="resume-list statslists b-box p-4 mb-4">
                                <div>
                                    <h5>TOTAL YARDS <span class="badge badge-light d-inline-block my-3">2022-2023</span></h5>
                                    <h4 class="d-flex justify-content-between align-items-center">
                                        <p>GD: Fr</p>
                                        <p>TEAM: Var</p>
                                    </h4>
                                    <div class="yearly-stats">
                                        <div class="stat">
                                            <div class="name">GP</div>
                                            <div class="value">9</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">RUSH</div>
                                            <div class="value">128</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">PASS</div>
                                            <div class="value">829</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">REC</div>
                                            <div class="value">0</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">TOTAL</div>
                                            <div class="value">957</div>
                                        </div>
                                        <div class="stat bdrrgt">
                                            <div class="name">Y/G</div>
                                            <div class="value">106.3</div>
                                        </div>
                                        <div class="gradient"></div>
                                    </div>
                                </div>
                            </div>
                        </div>	
                    </div>
                </div>
            </div>
        </div>
        <!-- END: PRICE -->

        <!-- START: RESUME -->
        <!-- <div class="section" id="RESUME">
            <div class="container">
                <div class="row justify-content-center align-items-center">
                    <div class="col-md-3">

                        <div class="section-head">
                            <span>MY RESUME</span>
                            <h2>MY EXPERTISES</h2>
                        </div>

                        <ul class="nav nav-tabs">
                            <li class="nav-item">
                                <a class="nav-link active" data-toggle="tab" href="#tab2">Teams</a>
                            </li>
                        </ul>
                        

                    </div>

                    <div class="col-md-9">
                        <div class="tab-content">
                            <div class="tab-pane active" id="tab1">
                                <div class="ExperienceList pb-3">
                                    <div class="resume-list b-box p-4">
                                        <div class="row align-items-center">
                                            <div class="col-3 col-md-3 pb-3 pb-md-0">
                                                Google Inc.	
                                            </div>
                                            <div class="col-md-8 pb-2 pb-md-0">
                                                <h5 class="mb-3">Alpharetta High School</h5>
                                                <p>QB</p>
                                            </div>
                                            <div class="col-md-4 text-md-right"><h6 class="badge badge-light">2023 - </h6></div>
                                        </div>
                                    </div>
                                    <div class="resume-list b-box p-4">
                                        <div class="row align-items-center">
                                            <div class="col-3 col-md-3 pb-3 pb-md-0">
                                                Google Inc.	
                                            </div>
                                            <div class="col-md-8 pb-2 pb-md-0">
                                                <h5 class="mb-3">ThunderRidge High School</h5>
                                                <p>QB</p>
                                            </div>
                                            <div class="col-md-4 text-md-right"><h6 class="badge badge-light">2022 - 2023</h6></div>
                                        </div>
                                    </div>
                                    <div class="resume-list b-box p-4">
                                        <div class="row align-items-center">
                                            <div class="col-3 col-md-3 pb-3 pb-md-0">
                                                Facebook.	
                                            </div>
                                            <div class="col-md-8 pb-2 pb-md-0">
                                                <h5 class="mb-3">Raptors Athletics- AYL</h5>
                                                <p>Raptors - White - Aragon (QB)</p>
                                            </div>
                                            <div class="col-md-4 text-md-right"><h6 class="badge badge-light">2019 - 2022</h6></div>
                                            
                                        </div>
                                    </div>
                                    <div class="resume-list b-box p-4">
                                        <div class="row align-items-center">
                                            <div class="col-3 col-md-3 pb-3 pb-md-0">
                                                Envato.	
                                            </div>
                                            <div class="col-md-8 pb-2 pb-md-0">
                                                <h5 class="mb-3">Jeremy Dufour Youth Teams</h5>
                                                <p>QB</p>
                                            </div>
                                            <div class="col-md-4 text-md-right"><h6 class="badge badge-light">2020 - 2021</h6></div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane" id="tab2">
                                <div class="EducationList">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <div class="resume-list b-box p-4 mb-4">
                                                <div>
                                                    <h5>ThunderRidge High School - Boys Varsity Football</h5>
                                                    <h6 class="badge badge-light d-inline-block my-3">2022</h6>
                                                    <p>Collaborate with creative and development teams on the execution of ideas.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane" id="tab3">
                                <div class="section-skills-wrap">
                                    <div class="row ">
                                        <div class="col-md-10">
                                            <div class="resume-list b-box p-4 mb-4">
                                                <div>
                                                    <h5>Football Stats</h5>
                                                    <h6 class="badge badge-light d-inline-block my-3">2022-2023</h6>
                                                    <div class="yearly-stats">
                                                        <div class="stat">
                                                            <div class="name">Yds</div>
                                                            <div class="value">829</div>
                                                        </div>
                                                        <div class="stat">
                                                            <div class="name">TD Passes</div>
                                                            <div class="value">7</div>
                                                        </div>
                                                        <div class="stat">
                                                            <div class="name">Y/G</div>
                                                            <div class="value">92.1</div>
                                                        </div>
                                                        <div class="stat">
                                                            <div class="name">Cmp</div>
                                                            <div class="value">58</div>
                                                        </div>
                                                        <div class="stat">
                                                            <div class="name">Att</div>
                                                            <div class="value">99</div>
                                                        </div>
                                                        <div class="stat bdrrgt">
                                                            <div class="name">Int</div>
                                                            <div class="value">4</div>
                                                        </div>
                                                        <div class="gradient"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>	
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- END: RESUME -->


        <!-- START: CONTACT -->
        <!-- <div class="section" id="CONTACT">
            <div class="container">
                <div class="row justify-content-center align-items-center">
                    <div class="col-md-4">
                        <div class="section-head">
                            <span>say Hello</span>
                            <h2>Contact </h2>
                        </div>
                    </div>

                    <div class="col-md-8">
                        <div class="contact-details">
                            <h5 class="section-subHead mb-4"> Get in Touch</h5>
                            <div class="row">
                                <div class="col-lg-6">

                                    <div class="about-desc-info">
                                        <div class="about-desc-info-content d-flex align-items-center hover-state b-box">
                                            <div class="about-desc-icon">
                                                <i class="lni-mobile"></i>
                                            </div>
                                            <div class="about-desc-info-desc">
                                                <a href="tel:9876543210" class="">+987 654 3210</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    
                                    <div class="about-desc-info">
                                        <div class="about-desc-info-content d-flex align-items-center hover-state b-box">
                                            <div class="about-desc-icon">
                                                <i class="lni-envelope"></i>
                                            </div>
                                            <div class="about-desc-info-desc">
                                                <a href="mailto:gtomdesign@gmail.com">gtomdesign@gmail.com</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="contact-form mt-4">
                            <form method="post" action="php/contact.php" name="contact-form" id="contactForm">
                                <h5 class="section-subHead mb-4"> Contact Form</h5>
                                <div id="formMessage" style="display: none;"><h3>Email Sending</h3></div>
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="form-group mb-3">
                                            <input name="name" id="name" type="text" class="form-control b-box" placeholder="Your Name *" required>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group mb-3">
                                            <input name="email" id="email" type="email" class="form-control b-box" placeholder="Your Email *" required>
                                        </div>
                                    </div>                                
                                </div>
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="form-group mb-3">
                                            <input type="text" class="form-control b-box" id="subject" placeholder="Your Subject.." required>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="form-group mb-3">
                                            <textarea name="comments" id="comments" rows="4" class="form-control b-box" placeholder="Your message..."></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-12 mt-4 mb-5">
                                        <input type="submit" id="submit" name="send" class="submitBnt btn btn-outline text-uppercase" value="Submit">
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- END: CONTACT -->

        <!-- START: RESUME -->
        <div class="section" id="RECRUITERS">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-4 col-12">
                        <div class="showTime_Logo" (click)="gotoShowTimeProfileCreation()">
                            <img src="assets/images/logo_showtime.jpg" alt="resume" title="resume">
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-md-4 col-12">
                        <!-- <div class="scroll-prompt" scroll-prompt="" ng-show="showPrompt" style="opacity: 1;">
                            <div class="scroll-prompt-arrow-container">
                                <div class="scroll-prompt-arrow"><div></div></div>
                                <div class="scroll-prompt-arrow"><div></div></div>
                            </div>
                        </div> -->
                        <div class="recruiterBtn">
                            <div class="animation">
                                <div class="scroll-prompt" scroll-prompt="" ng-show="showPrompt" style="opacity: 1;">
                                    <div class="scroll-prompt-arrow-container">
                                        <div class="scroll-prompt-arrow"><div></div></div>
                                        <div class="scroll-prompt-arrow"><div></div></div>
                                    </div>
                                </div>
                            </div>
                            <div class="animation-text">
                                <h4>Recruiters</h4>
                            </div>
                            <div class="animation">
                                <div class="scroll-prompt" scroll-prompt="" ng-show="showPrompt" style="opacity: 1;">
                                    <div class="scroll-prompt-arrow-container">
                                        <div class="scroll-prompt-arrow"><div></div></div>
                                        <div class="scroll-prompt-arrow"><div></div></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- START: RESUME -->
        <div class="section" id="RESUME">
            <div class="container">
                <!-- <div class="row justify-content-center align-items-center">
                    <div class="col-md-3">


                        <ul class="nav nav-tabs">
                            <li class="nav-item">
                                <a class="nav-link active" data-toggle="tab" href="#tab2">Teams</a>
                            </li>
                        </ul>
                        

                    </div>

                    <div class="col-md-9">
                        <div class="tab-content">
                            <div class="tab-pane active" id="tab1">
                                <div class="ExperienceList pb-3">
                                    <div class="resume-list b-box p-4">
                                        <div class="row align-items-center">
                                            
                                            <div class="col-md-8 pb-2 pb-md-0">
                                                <h5 class="mb-3">ThunderRidge Varsity Football</h5>
                                                <p>WR, Junior</p>
                                            </div>
                                            <div class="col-md-4 text-md-right"><h6 class="badge badge-light">2022 - 2023 </h6></div>
                                        </div>
                                    </div>
                                    <div class="resume-list b-box p-4">
                                        <div class="row align-items-center">
                                           
                                            <div class="col-md-8 pb-2 pb-md-0">
                                                <h5 class="mb-3">ThunderRidge High School</h5>
                                                <p>WR, Sophomore</p>
                                            </div>
                                            <div class="col-md-4 text-md-right"><h6 class="badge badge-light">2021 - 2022</h6></div>
                                        </div>
                                    </div>
                                    <div class="resume-list b-box p-4">
                                        <div class="row align-items-center">
                                            
                                            <div class="col-md-8 pb-2 pb-md-0">
                                                <h5 class="mb-3">ThunderRidge Varsity Football</h5>
                                                <p>WR, Freshman</p>
                                            </div>
                                            <div class="col-md-4 text-md-right"><h6 class="badge badge-light">2020 - 2021</h6></div>
                                            
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>

                            <div class="tab-pane" id="tab2">
                                <div class="EducationList">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <div class="resume-list b-box p-4 mb-4">
                                                <div>
                                                    <h5>ThunderRidge High School - Boys Varsity Football</h5>
                                                    <h6 class="badge badge-light d-inline-block my-3">2022</h6>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane" id="tab3">
                                <div class="section-skills-wrap">
                                    <div class="row ">
                                        <div class="col-md-10">
                                            <div class="resume-list b-box p-4 mb-4">
                                                <div>
                                                    <h5>Football Stats</h5>
                                                    <h6 class="badge badge-light d-inline-block my-3">2022-2023</h6>
                                                    <div class="yearly-stats">
                                                        <div class="stat">
                                                            <div class="name">Yds</div>
                                                            <div class="value">829</div>
                                                        </div>
                                                        <div class="stat">
                                                            <div class="name">TD Passes</div>
                                                            <div class="value">7</div>
                                                        </div>
                                                        <div class="stat">
                                                            <div class="name">Y/G</div>
                                                            <div class="value">92.1</div>
                                                        </div>
                                                        <div class="stat">
                                                            <div class="name">Cmp</div>
                                                            <div class="value">58</div>
                                                        </div>
                                                        <div class="stat">
                                                            <div class="name">Att</div>
                                                            <div class="value">99</div>
                                                        </div>
                                                        <div class="stat bdrrgt">
                                                            <div class="name">Int</div>
                                                            <div class="value">4</div>
                                                        </div>
                                                        <div class="gradient"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>	
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
                <div class="c-show-time-bottom">
                    <!-- <div class="c-show-time-bottom-btn">
                        <button class="c-btn-1" (click)="gotoShowTimeProfileCreation()">
                            Create My Show Time Profile
                        </button>
                    </div> -->
                    <div class="c-show-time-bottom-con">
                        <div class="row  justify-content-center">
                            <div class="col-lg-7 col-md-12">
                                <h4>Recruiters Get 5,000 prospects and <br> twitter profile</h4>
                                <video  height="280" width="100%" loop  muted controls>
                                    <source src="assets/images/cg-video.mp4" type="video/mp4">
                                </video>
                                <div class="c-show-time-bottom-btn-2">
                                    <button class="c-btn-1" (click)="gotoCollegeUserRegistration()">
                                        Sign Up
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="waves"></div>


    <div class="mobileView">
        <div class="mobileView-wrap">
            <div class="mobileView-content">
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
                <defs>
                    <linearGradient id="grad1" x1="100%" y1="100%" x2="0%" y2="60%">
                        <stop offset="40%" style="stop-color:#ff1ba6;stop-opacity:1"></stop>
                        <stop offset="100%" style="stop-color:#ed2a4a;stop-opacity:1"></stop>
                    </linearGradient>
                </defs>
                <text fill="url(#grad1)" font-size="60" x="0" y="100" style="
                font-weight: 700;
            ">Too small!</text>
                </svg>
                <h4 class="mb-3">MAKE YOUR BROWSER WINDOW WIDER</h4>
                <p> Please resize the browser window wider, you are not in mobile device. Switch to mobile device to see the mobile view.</p>
            </div>
        </div>
    </div>
</div>

<div  data-spy="scroll" data-target=".navbar" class="c-shotime-w" *ngIf="userName === 'cj'" >
    <!-- START: Preloader -->
    <div id="preloader" class="preloader">
        <div class="spinner-grow text-light" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
    <!-- END: Preloader -->

    <header>
        <div class="container">
            <div class="d-flex justify-content-between align-items-center">
                <a href="showtime-profile;_user=cj#HOME" class="header-logo">CJ REESE</a>

                <div class="menu d-flex justify-content-center align-items-center">

                    <!-- <a href="#CONTACT" class="head-contact btn btn-small btn-gradient mr-4">Contact</a> -->

                    <button class="btn btn-small collapsed navbar-toggler" data-toggle="collapse" data-target="#" aria-controls="" aria-expanded="false" aria-label="Toggle navigation"> 
                        <svg class="ham hamRotate ham1" viewBox="0 0 100 100" width="40"><path class="line top" d="m 30,33 h 40 c 0,0 9.044436,-0.654587 9.044436,-8.508902 0,-7.854315 -8.024349,-11.958003 -14.89975,-10.85914 -6.875401,1.098863 -13.637059,4.171617 -13.637059,16.368042 v 40"></path><path class="line middle" d="m 30,50 h 40"></path><path class="line bottom" d="m 30,67 h 40 c 12.796276,0 15.357889,-11.717785 15.357889,-26.851538 0,-15.133752 -4.786586,-27.274118 -16.667516,-27.274118 -11.88093,0 -18.499247,6.994427 -18.435284,17.125656 l 0.252538,40"></path></svg>
                    </button>

                    <div class="navbar-collapse collapse" id="">
                        <div class="container">
                            <div class="navbar-content">
                                <div class="navbar-links h-100vh d-flex align-items-center">
                                    <ul>
                                        <li>
                                            <span><i class="lni lni-cloud"></i></span>
                                            <a href="showtime-profile;_user=cj#HIGHLIGHTS">Highlights</a>
                                        </li>	
                                        <li>
                                            <span><i class="lni lni-book"></i></span>
                                            <a href="showtime-profile;_user=cj#ABOUT">ABOUT</a>
                                        </li>		
                                        <li>
                                            <span><i class="lni lni-offer"></i></span>
                                            <a href="showtime-profile;_user=cj#OFFERS">OFFERS</a>
                                        </li>
                                        <li>
                                            <span><i class="lni lni-bar-chart"></i></span>
                                            <a href="showtime-profile;_user=cj#STATS">STATS</a>
                                        </li>
                                    </ul>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>	
            </div>
        </div>
    </header>

    <div id="pagepiling">

        <!-- START: Home -->
        <div class="section section-home" id="HOME">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-md-7 col-12">
                        <div class="hero-content text-center">
                            <span class="hello mb-4 d-block"></span>
                            <h1><span>CJ REESE</span></h1>

                            <div id="autoplay" class="my-4">
                            <span>WR, TE</span>
                            <span>6'3", 190 lbs, 4.6, 33.6  </span>
                            <span>Highlands Ranch, CO</span>
                            </div>   
                            <!-- <div class="smallcontent">
                                <h4 class="mt-2 mb-3">QuaterBack 2026</h4>
                                <h5 class="mb-2">6'2", 185lbs, #19</h5>
                                <p>
                                    <i class="lni lni-twitter"></i>
                                    <a href="https://twitter.com/__GoDJ__?ref_src=twsrc%5Etfw%7Ctwcamp%5Etweetembed%7Ctwterm%5E1641791684215177219%7Ctwgr%5Ec495381aefaca1beb1d0df49dcf723d0351bb502%7Ctwcon%5Es1_&ref_url=https%3A%2F%2Fpublish.twitter.com%2F%3Fquery%3Dhttps3A2F2Ftwitter.com2F__GoDJ__2Fstatus2F1641791684215177219widget%3DTweet" target="_blank">@_GoDJ_</a>
                                </p>
                            </div> -->

                        </div>
                    </div>
                    <div class="col-md-5 col-12">
                        <div class="rigtBox">
                            <img src="assets/images/CJ-Reese.png" alt="player-profile" title="player-profile" class="img-fluid">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- END: Home -->

        <!-- START: PORTFOLIO -->
        <div class="section" id="PORTFOLIO">
            <div class="container">
                <!-- <div class="section-head text-center">
                    <span>TAKE A LOOK AT</span>
                    <h2>Portfolio</h2>
                </div>	 -->
                <div class="container swiper-container swiper-portfolio wow fadeInUp mt-5 pt-5" data-wow-duration="1.5s">
                    <div class="swiper-wrapper">
                        <div class="portfolio-item swiper-slide " data-groups="all slideshow">
                            <a href="assets/images/cg-img-1.jpg" class="portfolio-item--content simple-ajax-popup">
                                <div class="portfolio-item--img b-box">
                                    <img src="assets/images/cg-img-1.jpg" class="img-fluid mx-auto" alt="portfolio" title="portfolio">
                                </div>
                            </a>
                        </div>

                        <div class="portfolio-item swiper-slide image" data-groups="all image">
                            <a href="https://vf.hudl.com/p-highlights/User/17007776/63389c1615f59007441f17be/50ed9cee_720.mp4?v=ED851243F7DBDA08" class="portfolio-item--content simple-ajax-video-popup">
                                <div class="portfolio-item--img b-box">
                                    <!-- <img src="assets/images/ytv2.mp4" class="img-fluid mx-auto" alt="work-img"> -->
                                    <video width="100%" height="400" controls="false" autoplay="flase" muted loop>
                                        <source src="https://vf.hudl.com/p-highlights/User/17007776/63389c1615f59007441f17be/50ed9cee_720.mp4?v=ED851243F7DBDA08" type="video/mp4">
                                    </video>
                                </div>
                                <!-- <div class="portfolio-item--desc d-flex justify-content-center align-items-center">
                                    <div class="portfolio-item--desc-in">
                                        <h3 class="mb-2 text-white">The wall</h3>
                                        <p class="text-white">Slideshow</p>
                                    </div>
                                </div> -->
                            </a>
                        </div>

                        <div class="portfolio-item swiper-slide video" data-groups="all video">
                            <a href="https://vf.hudl.com/p-highlights/User/17007776/637aa2344deaef0a343878d9/8b437f67_720.mp4?v=D8B660D745CBDA08" class="portfolio-item--content simple-ajax-video-popup">
                                <div class="portfolio-item--img b-box">
                                    <!-- <img src="assets/images/ytv2.mp4" class="img-fluid mx-auto" alt="work-img"> -->
                                    <video width="100%" height="400" controls="false" autoplay="autoplay" muted loop>
                                        <source src="https://vf.hudl.com/p-highlights/User/17007776/637aa2344deaef0a343878d9/8b437f67_720.mp4?v=D8B660D745CBDA08" type="video/mp4">
                                    </video>
                                </div>
                                <!-- <div class="portfolio-item--desc d-flex justify-content-center align-items-center">
                                    <div class="portfolio-item--desc-in">
                                        <h3 class="mb-2 text-white">The wall</h3>
                                        <p class="text-white">Slideshow</p>
                                    </div>
                                </div> -->
                            </a>
                        </div>

                        <div class="portfolio-item swiper-slide slideshow" data-groups="all slideshow">
                            <a href="https://vf.hudl.com/p-highlights/User/17007776/6396aa547bdd20090ceb9465/34c8dedc_720.mp4?v=F18A1943F7DBDA08" class="portfolio-item--content simple-ajax-video-popup">
                                <div class="portfolio-item--img b-box">
                                    <!-- <img src="assets/images/p1-1.jpg" class="img-fluid mx-auto" alt="work-img"> -->
                                    <video width="100%" height="400" controls="false" autoplay="autoplay" muted loop>
                                        <source src="https://vf.hudl.com/p-highlights/User/17007776/6396aa547bdd20090ceb9465/34c8dedc_720.mp4?v=F18A1943F7DBDA08" type="video/mp4">
                                    </video>
                                </div>
                                <!-- <div class="portfolio-item--desc d-flex justify-content-center align-items-center">
                                    <div class="portfolio-item--desc-in">
                                        <h3 class="mb-2 text-white">The wall</h3>
                                        <p class="text-white">Slideshow</p>
                                    </div>
                                </div> -->
                            </a>
                        </div>

                        <div class="portfolio-item swiper-slide video" data-groups="all video">
                            <a href="https://vf.hudl.com/p-highlights/User/17007776/6340442ef327200a2060c411/509fd38d_720.mp4?v=B7C72A565BB1DA08" class="portfolio-item--content simple-ajax-video-popup">
                                <div class="portfolio-item--img b-box">
                                    <!-- <img src="assets/images/ytv2.mp4" class="img-fluid mx-auto" alt="work-img"> -->
                                    <video width="100%" height="400" controls="false" autoplay="autoplay" muted loop>
                                        <source src="https://vf.hudl.com/p-highlights/User/17007776/6340442ef327200a2060c411/509fd38d_720.mp4?v=B7C72A565BB1DA08" type="video/mp4">
                                    </video>
                                </div>
                                <!-- <div class="portfolio-item--desc d-flex justify-content-center align-items-center">
                                    <div class="portfolio-item--desc-in">
                                        <h3 class="mb-2 text-white">The wall</h3>
                                        <p class="text-white">Slideshow</p>
                                    </div>
                                </div> -->
                            </a>
                        </div>

                    </div>
                    <div class="portfolio-pagination">
                        
                    </div>
                </div>
            </div>
        </div>
        <!-- END: PORTFOLIO -->

        <!-- START: ABOUT -->
        <div class="section" id="ABT">
            <div class="container">

                <div class="row justify-content-center align-items-center">
                    <div class="col-md-5">
                        <img class="about-img about-showtime-profile img-fluid wow fadeInUp" data-wow-duration="1s" src="assets/images/cj_bio_bg.jpg" alt="about-picture" title="about-picture">
                    </div>
                    <div class="col-md-7">

                        <div class="about-desc wow fadeInUp" data-wow-duration="1s"  data-wow-delay="500ms">
                            <div class="about-desc-content">
                                <!-- <h1 class="font-weight-light mb-5">DJ Bordeaux</h1> -->
                                <p>Cj reese is a WR at Thunderridge Highschool Colorado.</p>
                                <p>As of May 23 he has 3 D1 offers.  He's been highlighted as one of the top WR prospects in Colorado.</p>
                                <P>He's poised for a break out year after seeing limited playing time due to a deep roster of WRs for his team last season.  
                                    You can get detailed information by reaching out to his Head Coach <a href="https://twitter.com/SamCampfield?s=09" target="_blank"> Sam Campfield </a> or 
                                    trainers at <a href="https://twitter.com/glvwrktraining?t=lhRQ6pMUgj8oPpdDrcbpyQ&s=09" target="_blank">GLOVEWORK</a>

                                </P>
                            </div>

                            <div class="about-desc-more text-left mt-5">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="about-desc-info">
                                            <div class="about-desc-info-content d-flex align-items-center hover-state b-box">
                                                <div class="about-desc-icon">
                                                    <i class="lni lni-calendar"></i>
                                                </div>
                                                <div class="about-desc-info-desc">
                                                    <span>Junior • 2024</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="about-desc-info">
                                            <div class="about-desc-info-content d-flex align-items-center hover-state b-box">
                                                <div class="about-desc-icon">
                                                    <i class="lni lni-pin"></i>
                                                </div>
                                                <div class="about-desc-info-desc">
                                                    <span>ThunderRidge</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="about-desc-info">
                                            <div class="about-desc-info-content d-flex align-items-center hover-state b-box">
                                                <div class="about-desc-icon">
                                                    <i class="lni lni-graduation"></i>
                                                </div>
                                                <div class="about-desc-info-desc">
                                                    <span>Varsity Football</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="about-desc-info">
                                            <div class="about-desc-info-content d-flex align-items-center hover-state b-box">
                                                <div class="about-desc-icon">
                                                    <i class="lni lni-twitter"></i>
                                                </div>
                                                <div class="about-desc-info-desc">
                                                    <a href="https://twitter.com/CJREESE20?t=Ptw0oUujvaWdxDy3puT71A&s=08" title="https://twitter.com/CJREESE20?t=Ptw0oUujvaWdxDy3puT71A&s=08" target="_blank">https://twitter.com/CJREESE20?t=Ptw0oUujvaWdxDy3puT71A&s=08</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>			
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- END: ABOUT -->

        <!-- START: TESTIMONY -->
        <div class="section feed-section" id="TESTIMONY">
            <div class="container">
                <!-- <div class="section-head text-center">
                    <span>Kind Words</span>
                    <h2>testimonial</h2>
                </div> -->
                <div class="row">
                    <div class="col-md-4 col-12">
                        <blockquote class="twitter-tweet">
                            <p lang="en" dir="ltr">Blessed to receive my 3rd D1 offer from The University of Wyoming🟡🟤! Thank you <a
                                    href="https://twitter.com/CoachMikeGrant?ref_src=twsrc%5Etfw" target="_blank">@CoachMikeGrant</a> <a
                                    href="https://twitter.com/CoachBHen?ref_src=twsrc%5Etfw" target="_blank">@CoachBHen</a> <a
                                    href="https://twitter.com/wyo_football?ref_src=twsrc%5Etfw" target="_blank">@wyo_football</a> for this amazing opportunity!
                                <a href="https://twitter.com/CoachThomas04?ref_src=twsrc%5Etfw" target="_blank">@CoachThomas04</a> <a
                                    href="https://twitter.com/glvwrktraining?ref_src=twsrc%5Etfw" target="_blank">@glvwrktraining</a> <a
                                    href="https://twitter.com/TeamFullGorilla?ref_src=twsrc%5Etfw" target="_blank">@TeamFullGorilla</a> <a
                                    href="https://t.co/8SYk1jbmgz" target="_blank">pic.twitter.com/8SYk1jbmgz</a></p>&mdash; CJ REESE (@CJREESE20) <a
                                href="https://twitter.com/CJREESE20/status/1639331589640556545?ref_src=twsrc%5Etfw" target="_blank">March 24, 2023</a>
                        </blockquote>
                        <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
                    </div>
                    <div class="col-md-4 col-12">
                        <blockquote class="twitter-tweet">
                            <p lang="en" dir="ltr">Blessed to receive my first D1 offer from the University of Nevada! Thank you <a
                                    href="https://twitter.com/CoachJ_Ortiz?ref_src=twsrc%5Etfw" target="_blank">@CoachJ_Ortiz</a> <a
                                    href="https://twitter.com/CoachKWils?ref_src=twsrc%5Etfw" target="_blank">@CoachKWils</a> <a
                                    href="https://twitter.com/CoachAArceneaux?ref_src=twsrc%5Etfw" target="_blank">@CoachAArceneaux</a> and <a
                                    href="https://twitter.com/NevadaFootball?ref_src=twsrc%5Etfw" target="_blank">@NevadaFootball</a> for this amazing
                                opportunity!@CoachThomas04 <a href="https://twitter.com/glvwrktraining?ref_src=twsrc%5Etfw" target="_blank">@glvwrktraining</a>
                                <a href="https://twitter.com/TeamFullGorilla?ref_src=twsrc%5Etfw" target="_blank">@TeamFullGorilla</a> <a
                                    href="https://t.co/x9Wo688kvy" target="_blank">pic.twitter.com/x9Wo688kvy</a></p>&mdash; CJ REESE (@CJREESE20) <a
                                href="https://twitter.com/CJREESE20/status/1631109853816188929?ref_src=twsrc%5Etfw" target="_blank">March 2, 2023</a>
                        </blockquote>
                        <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
                    </div>
                    <div class="col-md-4 col-12">
                        <blockquote class="twitter-tweet">
                            <p lang="en" dir="ltr">How we comin for the Pylon Mecca tourney this week <a
                                    href="https://twitter.com/TeamFullGorilla?ref_src=twsrc%5Etfw" target="_blank">@TeamFullGorilla</a>⁩ ⁦<a
                                    href="https://twitter.com/glvwrktraining?ref_src=twsrc%5Etfw" target="_blank">@glvwrktraining</a>⁩ ⁦<a
                                    href="https://twitter.com/CoachThomas04?ref_src=twsrc%5Etfw" target="_blank">@CoachThomas04</a>⁩ <a
                                    href="https://t.co/bIrjuTiAnJ" target="_blank">pic.twitter.com/bIrjuTiAnJ</a></p>&mdash; CJ REESE (@CJREESE20) <a
                                href="https://twitter.com/CJREESE20/status/1628226754023137280?ref_src=twsrc%5Etfw" target="_blank">February 22, 2023</a>
                        </blockquote>
                        <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
                    </div>
                </div>
            </div>
        </div>
        <!-- END: TESTIMONY -->

        <!-- START: SERVICES -->
        <div class="section" id="SERVICES">
            <div class="container">
                <!-- <div class="section-head text-center">
                    <span>WHAT I DO</span>
                    <h2>My Services</h2>
                </div> -->

                <div class="row mt-4 justify-content-center align-content-center">
                    <div class="col-md-8 col-12">
                        <div class="row justify-content-center">
                            <div class="col-sm-6 col-lg-6">
                                <div class="services-list mt-4 wow fadeInUp" data-wow-duration="1.5s">
                                    <div class="services-list--content d-flex align-items-center b-box hover-state p-4">
                                        <!-- <div class="services-list-icon">
                                            <i class="lni-color-pallet"></i>
                                            <span>01</span>
                                        </div> -->
                                        <div class="services-list-desc">
                                            <!-- <h5 class="mb-0">Graphic Design</h5> -->
                                            <img src="assets/images/offer-logo-5.png" alt="offer" title="offer" class="img-fluid">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 col-lg-6">
                                <div class="services-list mt-4 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay=".2s">
                                    <div class="services-list--content d-flex align-items-center b-box hover-state p-4">
                                        <!-- <div class="services-list-icon">
                                            <i class="lni-laptop-phone"></i>
                                            <span>02</span>
                                        </div> -->
                                        <div class="services-list-desc">
                                            <!-- <h5 class="mb-0">Web Development</h5> -->
                                            <img src="assets/images/offer-logo-6.png" alt="offer" title="offer" class="img-fluid">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 col-lg-6">
                                <div class="services-list mt-4 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay=".3s">
                                    <div class="services-list--content d-flex align-items-center b-box hover-state p-4">
                                        <!-- <div class="services-list-icon">
                                            <i class="lni-video"></i>
                                            <span>03</span>
                                        </div> -->
                                        <div class="services-list-desc">
                                            <!-- <h5 class="mb-0">Media Marketing</h5> -->
                                            <img src="assets/images/offer-logo-7.png" alt="offer" title="offer" class="img-fluid">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col-sm-6 col-lg-6">
                                <div class="services-list mt-4 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay=".4s">
                                    <div class="services-list--content d-flex align-items-center b-box hover-state p-4">
                                        <div class="services-list-desc">
                                            <img src="assets/images/offer-logo-8.png" alt="" class="img-fluid">
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- END: SERVICES -->

        <!-- START: PRICE -->
        <div class="section" id="PRICE">
            <!-- <div class="section-head text-center">
                <span>WHAT I DO</span>
                <h2>My Pricing</h2>
            </div> -->
            <div class="section-skills-wrap">
                <div class="container">
                    <div class="row ">
                        <div class="col-md-12">
                            <div class="resume-list statslists b-box p-4 mb-4">
                                <div>
                                    <h5>RUSHING <span class="badge badge-light d-inline-block my-3">2022-2023</span></h5>
                                    <h4 class="d-flex justify-content-between align-items-center">
                                        <p>GD: Jr.</p>
                                        <p>TEAM: Var</p>
                                    </h4>
                                    <div class="yearly-stats rushing-stats">
                                        <div class="stat">
                                            <div class="name">GP</div>
                                            <div class="value">13</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">CAR</div>
                                            <div class="value">2</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">YAD</div>
                                            <div class="value">15</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">AVG</div>
                                            <div class="value">7.5</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">Y/G</div>
                                            <div class="value">1.2</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">LNG</div>
                                            <div class="value">12</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">100+</div>
                                            <div class="value">0</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">TD</div>
                                            <div class="value">0</div>
                                        </div>
                                        <div class="gradient"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="resume-list statslists b-box p-4 mb-4">
                                <div>
                                    <h5>RECEIVING <span class="badge badge-light d-inline-block my-3">2021 - 2022</span></h5>
                                    <h4 class="d-flex justify-content-between align-items-center">
                                        <p>GD: So.</p>
                                        <p>TEAM: Var</p>
                                    </h4>
                                    <div class="yearly-stats receiving-stats">
                                        <div class="stat">
                                            <div class="name">GP</div>
                                            <div class="value">3</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">REC</div>
                                            <div class="value">1</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">YDS</div>
                                            <div class="value">12</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">AVG</div>
                                            <div class="value">12.0</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">Y/G</div>
                                            <div class="value">4.0</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">LNG</div>
                                            <div class="value">12</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">TD</div>
                                            <div class="value">0</div>
                                        </div>
                                        <div class="gradient"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="resume-list statslists b-box p-4 mb-4">
                                <div>
                                    <h5>TOTAL YARDS <span class="badge badge-light d-inline-block my-3">2022-2023</span></h5>
                                    <h4 class="d-flex justify-content-between align-items-center">
                                        <p>GD: Jr</p>
                                        <p>TEAM: Var</p>
                                    </h4>
                                    <div class="yearly-stats">
                                        <div class="stat">
                                            <div class="name">GP</div>
                                            <div class="value">13</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">RUSH</div>
                                            <div class="value">15</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">PASS</div>
                                            <div class="value">0</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">REC</div>
                                            <div class="value">734</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">TOTAL</div>
                                            <div class="value">749</div>
                                        </div>
                                        <div class="stat bdrrgt">
                                            <div class="name">Y/G</div>
                                            <div class="value">57.6</div>
                                        </div>
                                        <div class="gradient"></div>
                                    </div>
                                </div>
                            </div>
                        </div>	

                    </div>
                </div>
            </div>
        </div>
        <!-- END: PRICE -->

        <!-- START: RESUME -->
        <div class="section" id="RECRUITERS">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-4 col-12">
                        <div class="showTime_Logo" (click)="gotoShowTimeProfileCreation()">
                            <img src="assets/images/logo_showtime.jpg" alt="resume" title="resume">
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-md-4 col-12">
                        <!-- <div class="scroll-prompt" scroll-prompt="" ng-show="showPrompt" style="opacity: 1;">
                            <div class="scroll-prompt-arrow-container">
                                <div class="scroll-prompt-arrow"><div></div></div>
                                <div class="scroll-prompt-arrow"><div></div></div>
                            </div>
                        </div> -->
                        <div class="recruiterBtn">
                            <div class="animation">
                                <div class="scroll-prompt" scroll-prompt="" ng-show="showPrompt" style="opacity: 1;">
                                    <div class="scroll-prompt-arrow-container">
                                        <div class="scroll-prompt-arrow"><div></div></div>
                                        <div class="scroll-prompt-arrow"><div></div></div>
                                    </div>
                                </div>
                            </div>
                            <div class="animation-text">
                                <h4>Recruiters</h4>
                            </div>
                            <div class="animation">
                                <div class="scroll-prompt" scroll-prompt="" ng-show="showPrompt" style="opacity: 1;">
                                    <div class="scroll-prompt-arrow-container">
                                        <div class="scroll-prompt-arrow"><div></div></div>
                                        <div class="scroll-prompt-arrow"><div></div></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- START: RESUME -->
        <div class="section" id="RESUME">
            <div class="container">
                <!-- <div class="row justify-content-center align-items-center">
                    <div class="col-md-3">


                        <ul class="nav nav-tabs">
                            <li class="nav-item">
                                <a class="nav-link active" data-toggle="tab" href="#tab2">Teams</a>
                            </li>
                        </ul>
                        

                    </div>

                    <div class="col-md-9">
                        <div class="tab-content">
                            <div class="tab-pane active" id="tab1">
                                <div class="ExperienceList pb-3">
                                    <div class="resume-list b-box p-4">
                                        <div class="row align-items-center">
                                            
                                            <div class="col-md-8 pb-2 pb-md-0">
                                                <h5 class="mb-3">ThunderRidge Varsity Football</h5>
                                                <p>WR, Junior</p>
                                            </div>
                                            <div class="col-md-4 text-md-right"><h6 class="badge badge-light">2022 - 2023 </h6></div>
                                        </div>
                                    </div>
                                    <div class="resume-list b-box p-4">
                                        <div class="row align-items-center">
                                           
                                            <div class="col-md-8 pb-2 pb-md-0">
                                                <h5 class="mb-3">ThunderRidge High School</h5>
                                                <p>WR, Sophomore</p>
                                            </div>
                                            <div class="col-md-4 text-md-right"><h6 class="badge badge-light">2021 - 2022</h6></div>
                                        </div>
                                    </div>
                                    <div class="resume-list b-box p-4">
                                        <div class="row align-items-center">
                                            
                                            <div class="col-md-8 pb-2 pb-md-0">
                                                <h5 class="mb-3">ThunderRidge Varsity Football</h5>
                                                <p>WR, Freshman</p>
                                            </div>
                                            <div class="col-md-4 text-md-right"><h6 class="badge badge-light">2020 - 2021</h6></div>
                                            
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>

                            <div class="tab-pane" id="tab2">
                                <div class="EducationList">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <div class="resume-list b-box p-4 mb-4">
                                                <div>
                                                    <h5>ThunderRidge High School - Boys Varsity Football</h5>
                                                    <h6 class="badge badge-light d-inline-block my-3">2022</h6>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane" id="tab3">
                                <div class="section-skills-wrap">
                                    <div class="row ">
                                        <div class="col-md-10">
                                            <div class="resume-list b-box p-4 mb-4">
                                                <div>
                                                    <h5>Football Stats</h5>
                                                    <h6 class="badge badge-light d-inline-block my-3">2022-2023</h6>
                                                    <div class="yearly-stats">
                                                        <div class="stat">
                                                            <div class="name">Yds</div>
                                                            <div class="value">829</div>
                                                        </div>
                                                        <div class="stat">
                                                            <div class="name">TD Passes</div>
                                                            <div class="value">7</div>
                                                        </div>
                                                        <div class="stat">
                                                            <div class="name">Y/G</div>
                                                            <div class="value">92.1</div>
                                                        </div>
                                                        <div class="stat">
                                                            <div class="name">Cmp</div>
                                                            <div class="value">58</div>
                                                        </div>
                                                        <div class="stat">
                                                            <div class="name">Att</div>
                                                            <div class="value">99</div>
                                                        </div>
                                                        <div class="stat bdrrgt">
                                                            <div class="name">Int</div>
                                                            <div class="value">4</div>
                                                        </div>
                                                        <div class="gradient"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>	
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
                <div class="c-show-time-bottom">
                    <!-- <div class="c-show-time-bottom-btn">
                        <button class="c-btn-1" (click)="gotoShowTimeProfileCreation()">
                            Create My Show Time Profile
                        </button>
                    </div> -->
                    <div class="c-show-time-bottom-con">
                        <div class="row  justify-content-center">
                            <div class="col-lg-7 col-md-12">
                                <h4>Recruiters Get 5,000 prospects and <br> twitter profile</h4>
                                <video  height="280" width="100%" loop  muted controls>
                                    <source src="assets/images/cg-video.mp4" type="video/mp4">
                                </video>
                                <div class="c-show-time-bottom-btn-2">
                                    <button class="c-btn-1" (click)="gotoCollegeUserRegistration()">
                                        Sign Up
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="waves"></div> -->

    <!-- background Animation -->
    <div class="cj_AnimationBG">
        <div class="column sw-col-1"><span>&#10483;</span><span>&#10270;</span><span>&#10267;</span><span>&#10248;</span><span>&#10362;</span><span>&#10381;</span><span>&#10249;</span><span>&#10352;</span><span>&#10292;</span><span>&#10450;</span><span>&#10439;</span><span>&#10325;</span><span>&#10279;</span><span>&#10414;</span><span>&#10360;</span><span>&#10270;</span><span>&#10420;</span><span>&#10288;</span><span>&#10434;</span><span>&#10329;</span><span>&#10243;</span><span>&#10467;</span><span>&#10299;</span><span>&#10415;</span><span>&#10244;</span><span>&#10321;</span><span>&#10358;</span><span>&#10410;</span><span>&#10372;</span><span>&#10261;</span><span>&#10312;</span><span>&#10475;</span><span>&#10275;</span><span>&#10315;</span><span>&#10453;</span><span>&#10471;</span><span>&#10401;</span><span>&#10390;</span><span>&#10343;</span><span>&#10292;</span><span>&#10352;</span><span>&#10397;</span><span>&#10301;</span><span>&#10376;</span><span>&#10340;</span><span>&#10347;</span><span>&#10408;</span><span>&#10436;</span><span>&#10335;</span><span>&#10439;</span>
        </div>
        <div class="column sw-col-2"><span>&#10276;</span><span>&#10387;</span><span>&#10441;</span><span>&#10322;</span><span>&#10385;</span><span>&#10352;</span><span>&#10240;</span><span>&#10484;</span><span>&#10461;</span><span>&#10455;</span><span>&#10466;</span><span>&#10344;</span><span>&#10432;</span><span>&#10370;</span><span>&#10300;</span><span>&#10243;</span><span>&#10296;</span><span>&#10413;</span><span>&#10467;</span><span>&#10331;</span><span>&#10440;</span><span>&#10289;</span><span>&#10492;</span><span>&#10412;</span><span>&#10243;</span><span>&#10272;</span><span>&#10452;</span><span>&#10253;</span><span>&#10342;</span><span>&#10274;</span><span>&#10289;</span><span>&#10384;</span><span>&#10396;</span><span>&#10469;</span><span>&#10382;</span><span>&#10374;</span><span>&#10337;</span><span>&#10481;</span><span>&#10368;</span><span>&#10339;</span><span>&#10484;</span><span>&#10426;</span><span>&#10462;</span><span>&#10352;</span><span>&#10432;</span><span>&#10248;</span><span>&#10451;</span><span>&#10457;</span><span>&#10488;</span><span>&#10392;</span>
        </div>
        <div class="column sw-col-3"><span>&#10454;</span><span>&#10379;</span><span>&#10260;</span><span>&#10272;</span><span>&#10479;</span><span>&#10462;</span><span>&#10311;</span><span>&#10392;</span><span>&#10317;</span><span>&#10391;</span><span>&#10485;</span><span>&#10435;</span><span>&#10270;</span><span>&#10280;</span><span>&#10453;</span><span>&#10449;</span><span>&#10309;</span><span>&#10478;</span><span>&#10443;</span><span>&#10370;</span><span>&#10446;</span><span>&#10242;</span><span>&#10353;</span><span>&#10245;</span><span>&#10485;</span><span>&#10325;</span><span>&#10466;</span><span>&#10286;</span><span>&#10294;</span><span>&#10302;</span><span>&#10307;</span><span>&#10423;</span><span>&#10360;</span><span>&#10483;</span><span>&#10442;</span><span>&#10456;</span><span>&#10399;</span><span>&#10451;</span><span>&#10384;</span><span>&#10413;</span><span>&#10316;</span><span>&#10374;</span><span>&#10291;</span><span>&#10380;</span><span>&#10419;</span><span>&#10305;</span><span>&#10261;</span><span>&#10267;</span><span>&#10485;</span><span>&#10301;</span>
        </div>
        <div class="column sw-col-4"><span>&#10342;</span><span>&#10242;</span><span>&#10363;</span><span>&#10279;</span><span>&#10397;</span><span>&#10274;</span><span>&#10319;</span><span>&#10243;</span><span>&#10321;</span><span>&#10311;</span><span>&#10326;</span><span>&#10368;</span><span>&#10337;</span><span>&#10423;</span><span>&#10348;</span><span>&#10463;</span><span>&#10297;</span><span>&#10367;</span><span>&#10382;</span><span>&#10251;</span><span>&#10318;</span><span>&#10461;</span><span>&#10314;</span><span>&#10243;</span><span>&#10320;</span><span>&#10388;</span><span>&#10340;</span><span>&#10291;</span><span>&#10251;</span><span>&#10307;</span><span>&#10273;</span><span>&#10364;</span><span>&#10311;</span><span>&#10337;</span><span>&#10422;</span><span>&#10340;</span><span>&#10487;</span><span>&#10399;</span><span>&#10434;</span><span>&#10450;</span><span>&#10286;</span><span>&#10451;</span><span>&#10313;</span><span>&#10322;</span><span>&#10281;</span><span>&#10438;</span><span>&#10486;</span><span>&#10305;</span><span>&#10490;</span><span>&#10344;</span>
        </div>
        <div class="column sw-col-5"><span>&#10267;</span><span>&#10446;</span><span>&#10360;</span><span>&#10456;</span><span>&#10292;</span><span>&#10362;</span><span>&#10365;</span><span>&#10244;</span><span>&#10430;</span><span>&#10487;</span><span>&#10354;</span><span>&#10294;</span><span>&#10255;</span><span>&#10456;</span><span>&#10466;</span><span>&#10477;</span><span>&#10274;</span><span>&#10427;</span><span>&#10402;</span><span>&#10395;</span><span>&#10474;</span><span>&#10331;</span><span>&#10383;</span><span>&#10481;</span><span>&#10376;</span><span>&#10287;</span><span>&#10340;</span><span>&#10440;</span><span>&#10241;</span><span>&#10333;</span><span>&#10377;</span><span>&#10351;</span><span>&#10291;</span><span>&#10380;</span><span>&#10393;</span><span>&#10341;</span><span>&#10476;</span><span>&#10328;</span><span>&#10380;</span><span>&#10369;</span><span>&#10314;</span><span>&#10327;</span><span>&#10294;</span><span>&#10367;</span><span>&#10356;</span><span>&#10241;</span><span>&#10405;</span><span>&#10289;</span><span>&#10390;</span><span>&#10351;</span>
        </div>
        <div class="column sw-col-6"><span>&#10346;</span><span>&#10263;</span><span>&#10287;</span><span>&#10356;</span><span>&#10432;</span><span>&#10448;</span><span>&#10344;</span><span>&#10295;</span><span>&#10394;</span><span>&#10455;</span><span>&#10489;</span><span>&#10331;</span><span>&#10485;</span><span>&#10318;</span><span>&#10483;</span><span>&#10455;</span><span>&#10447;</span><span>&#10245;</span><span>&#10277;</span><span>&#10270;</span><span>&#10328;</span><span>&#10293;</span><span>&#10295;</span><span>&#10310;</span><span>&#10326;</span><span>&#10356;</span><span>&#10463;</span><span>&#10278;</span><span>&#10398;</span><span>&#10325;</span><span>&#10423;</span><span>&#10474;</span><span>&#10425;</span><span>&#10249;</span><span>&#10416;</span><span>&#10414;</span><span>&#10373;</span><span>&#10452;</span><span>&#10425;</span><span>&#10351;</span><span>&#10259;</span><span>&#10392;</span><span>&#10352;</span><span>&#10267;</span><span>&#10435;</span><span>&#10373;</span><span>&#10320;</span><span>&#10334;</span><span>&#10459;</span><span>&#10390;</span>
        </div>
        <div class="column sw-col-7"><span>&#10251;</span><span>&#10336;</span><span>&#10319;</span><span>&#10359;</span><span>&#10486;</span><span>&#10444;</span><span>&#10241;</span><span>&#10340;</span><span>&#10276;</span><span>&#10478;</span><span>&#10486;</span><span>&#10469;</span><span>&#10410;</span><span>&#10254;</span><span>&#10248;</span><span>&#10276;</span><span>&#10400;</span><span>&#10463;</span><span>&#10399;</span><span>&#10365;</span><span>&#10387;</span><span>&#10255;</span><span>&#10384;</span><span>&#10290;</span><span>&#10346;</span><span>&#10407;</span><span>&#10280;</span><span>&#10343;</span><span>&#10403;</span><span>&#10294;</span><span>&#10373;</span><span>&#10290;</span><span>&#10301;</span><span>&#10334;</span><span>&#10297;</span><span>&#10410;</span><span>&#10340;</span><span>&#10445;</span><span>&#10264;</span><span>&#10440;</span><span>&#10460;</span><span>&#10259;</span><span>&#10295;</span><span>&#10397;</span><span>&#10342;</span><span>&#10433;</span><span>&#10417;</span><span>&#10361;</span><span>&#10272;</span><span>&#10414;</span>
        </div>
        <div class="column sw-col-8"><span>&#10245;</span><span>&#10291;</span><span>&#10475;</span><span>&#10461;</span><span>&#10295;</span><span>&#10387;</span><span>&#10357;</span><span>&#10433;</span><span>&#10491;</span><span>&#10403;</span><span>&#10432;</span><span>&#10296;</span><span>&#10490;</span><span>&#10351;</span><span>&#10264;</span><span>&#10490;</span><span>&#10472;</span><span>&#10483;</span><span>&#10394;</span><span>&#10316;</span><span>&#10482;</span><span>&#10487;</span><span>&#10345;</span><span>&#10263;</span><span>&#10277;</span><span>&#10249;</span><span>&#10413;</span><span>&#10428;</span><span>&#10375;</span><span>&#10433;</span><span>&#10444;</span><span>&#10247;</span><span>&#10398;</span><span>&#10368;</span><span>&#10242;</span><span>&#10439;</span><span>&#10417;</span><span>&#10376;</span><span>&#10373;</span><span>&#10485;</span><span>&#10406;</span><span>&#10457;</span><span>&#10481;</span><span>&#10287;</span><span>&#10345;</span><span>&#10350;</span><span>&#10273;</span><span>&#10473;</span><span>&#10359;</span><span>&#10272;</span>
        </div>
        <div class="column sw-col-9"><span>&#10324;</span><span>&#10388;</span><span>&#10313;</span><span>&#10261;</span><span>&#10294;</span><span>&#10433;</span><span>&#10330;</span><span>&#10336;</span><span>&#10361;</span><span>&#10441;</span><span>&#10427;</span><span>&#10367;</span><span>&#10412;</span><span>&#10431;</span><span>&#10330;</span><span>&#10318;</span><span>&#10389;</span><span>&#10273;</span><span>&#10443;</span><span>&#10409;</span><span>&#10270;</span><span>&#10361;</span><span>&#10405;</span><span>&#10446;</span><span>&#10486;</span><span>&#10455;</span><span>&#10427;</span><span>&#10398;</span><span>&#10394;</span><span>&#10476;</span><span>&#10395;</span><span>&#10356;</span><span>&#10374;</span><span>&#10332;</span><span>&#10376;</span><span>&#10345;</span><span>&#10399;</span><span>&#10318;</span><span>&#10263;</span><span>&#10379;</span><span>&#10435;</span><span>&#10254;</span><span>&#10415;</span><span>&#10493;</span><span>&#10437;</span><span>&#10326;</span><span>&#10405;</span><span>&#10346;</span><span>&#10374;</span><span>&#10485;</span>
        </div>
        <div class="column sw-col-10"><span>&#10243;</span><span>&#10347;</span><span>&#10276;</span><span>&#10313;</span><span>&#10493;</span><span>&#10425;</span><span>&#10490;</span><span>&#10461;</span><span>&#10470;</span><span>&#10369;</span><span>&#10339;</span><span>&#10485;</span><span>&#10267;</span><span>&#10426;</span><span>&#10424;</span><span>&#10247;</span><span>&#10329;</span><span>&#10394;</span><span>&#10312;</span><span>&#10374;</span><span>&#10345;</span><span>&#10339;</span><span>&#10456;</span><span>&#10267;</span><span>&#10345;</span><span>&#10436;</span><span>&#10269;</span><span>&#10341;</span><span>&#10448;</span><span>&#10457;</span><span>&#10431;</span><span>&#10343;</span><span>&#10406;</span><span>&#10323;</span><span>&#10447;</span><span>&#10362;</span><span>&#10353;</span><span>&#10256;</span><span>&#10454;</span><span>&#10395;</span><span>&#10347;</span><span>&#10321;</span><span>&#10435;</span><span>&#10454;</span><span>&#10442;</span><span>&#10308;</span><span>&#10343;</span><span>&#10480;</span><span>&#10371;</span><span>&#10466;</span>
        </div>
        <div class="column sw-col-11"><span>&#10493;</span><span>&#10314;</span><span>&#10443;</span><span>&#10425;</span><span>&#10418;</span><span>&#10260;</span><span>&#10308;</span><span>&#10391;</span><span>&#10358;</span><span>&#10313;</span><span>&#10257;</span><span>&#10363;</span><span>&#10348;</span><span>&#10411;</span><span>&#10308;</span><span>&#10319;</span><span>&#10341;</span><span>&#10407;</span><span>&#10389;</span><span>&#10259;</span><span>&#10333;</span><span>&#10331;</span><span>&#10406;</span><span>&#10490;</span><span>&#10373;</span><span>&#10269;</span><span>&#10487;</span><span>&#10316;</span><span>&#10455;</span><span>&#10269;</span><span>&#10441;</span><span>&#10304;</span><span>&#10278;</span><span>&#10340;</span><span>&#10266;</span><span>&#10284;</span><span>&#10459;</span><span>&#10289;</span><span>&#10437;</span><span>&#10452;</span><span>&#10374;</span><span>&#10447;</span><span>&#10433;</span><span>&#10274;</span><span>&#10477;</span><span>&#10294;</span><span>&#10491;</span><span>&#10379;</span><span>&#10455;</span><span>&#10361;</span>
        </div>
        <div class="column sw-col-12"><span>&#10304;</span><span>&#10358;</span><span>&#10341;</span><span>&#10319;</span><span>&#10282;</span><span>&#10348;</span><span>&#10317;</span><span>&#10277;</span><span>&#10416;</span><span>&#10465;</span><span>&#10392;</span><span>&#10331;</span><span>&#10380;</span><span>&#10397;</span><span>&#10485;</span><span>&#10352;</span><span>&#10273;</span><span>&#10481;</span><span>&#10337;</span><span>&#10484;</span><span>&#10421;</span><span>&#10274;</span><span>&#10290;</span><span>&#10310;</span><span>&#10405;</span><span>&#10322;</span><span>&#10434;</span><span>&#10250;</span><span>&#10486;</span><span>&#10425;</span><span>&#10251;</span><span>&#10351;</span><span>&#10489;</span><span>&#10261;</span><span>&#10258;</span><span>&#10418;</span><span>&#10414;</span><span>&#10429;</span><span>&#10479;</span><span>&#10256;</span><span>&#10297;</span><span>&#10284;</span><span>&#10290;</span><span>&#10290;</span><span>&#10301;</span><span>&#10347;</span><span>&#10437;</span><span>&#10280;</span><span>&#10392;</span><span>&#10327;</span>
        </div>
        <div class="column sw-col-13"><span>&#10392;</span><span>&#10256;</span><span>&#10247;</span><span>&#10489;</span><span>&#10334;</span><span>&#10402;</span><span>&#10367;</span><span>&#10253;</span><span>&#10465;</span><span>&#10280;</span><span>&#10409;</span><span>&#10376;</span><span>&#10471;</span><span>&#10388;</span><span>&#10260;</span><span>&#10277;</span><span>&#10415;</span><span>&#10343;</span><span>&#10337;</span><span>&#10362;</span><span>&#10286;</span><span>&#10341;</span><span>&#10249;</span><span>&#10457;</span><span>&#10264;</span><span>&#10308;</span><span>&#10279;</span><span>&#10477;</span><span>&#10307;</span><span>&#10351;</span><span>&#10245;</span><span>&#10260;</span><span>&#10457;</span><span>&#10320;</span><span>&#10274;</span><span>&#10455;</span><span>&#10377;</span><span>&#10383;</span><span>&#10308;</span><span>&#10257;</span><span>&#10319;</span><span>&#10430;</span><span>&#10370;</span><span>&#10429;</span><span>&#10305;</span><span>&#10421;</span><span>&#10338;</span><span>&#10297;</span><span>&#10350;</span><span>&#10260;</span>
        </div>
        <div class="column sw-col-14"><span>&#10389;</span><span>&#10424;</span><span>&#10311;</span><span>&#10343;</span><span>&#10416;</span><span>&#10263;</span><span>&#10300;</span><span>&#10331;</span><span>&#10443;</span><span>&#10294;</span><span>&#10256;</span><span>&#10244;</span><span>&#10479;</span><span>&#10267;</span><span>&#10483;</span><span>&#10296;</span><span>&#10435;</span><span>&#10462;</span><span>&#10258;</span><span>&#10295;</span><span>&#10487;</span><span>&#10389;</span><span>&#10347;</span><span>&#10435;</span><span>&#10437;</span><span>&#10479;</span><span>&#10248;</span><span>&#10434;</span><span>&#10387;</span><span>&#10413;</span><span>&#10423;</span><span>&#10374;</span><span>&#10279;</span><span>&#10408;</span><span>&#10457;</span><span>&#10472;</span><span>&#10460;</span><span>&#10257;</span><span>&#10334;</span><span>&#10293;</span><span>&#10307;</span><span>&#10353;</span><span>&#10258;</span><span>&#10349;</span><span>&#10408;</span><span>&#10402;</span><span>&#10279;</span><span>&#10451;</span><span>&#10397;</span><span>&#10479;</span>
        </div>
        <div class="column sw-col-15"><span>&#10352;</span><span>&#10473;</span><span>&#10304;</span><span>&#10331;</span><span>&#10494;</span><span>&#10327;</span><span>&#10373;</span><span>&#10477;</span><span>&#10480;</span><span>&#10467;</span><span>&#10348;</span><span>&#10435;</span><span>&#10260;</span><span>&#10391;</span><span>&#10254;</span><span>&#10302;</span><span>&#10410;</span><span>&#10368;</span><span>&#10474;</span><span>&#10413;</span><span>&#10471;</span><span>&#10241;</span><span>&#10331;</span><span>&#10363;</span><span>&#10440;</span><span>&#10433;</span><span>&#10438;</span><span>&#10249;</span><span>&#10313;</span><span>&#10345;</span><span>&#10424;</span><span>&#10472;</span><span>&#10400;</span><span>&#10367;</span><span>&#10430;</span><span>&#10411;</span><span>&#10268;</span><span>&#10364;</span><span>&#10350;</span><span>&#10441;</span><span>&#10271;</span><span>&#10405;</span><span>&#10429;</span><span>&#10436;</span><span>&#10394;</span><span>&#10476;</span><span>&#10354;</span><span>&#10395;</span><span>&#10479;</span><span>&#10247;</span>
        </div>
        <div class="column sw-col-16"><span>&#10413;</span><span>&#10441;</span><span>&#10421;</span><span>&#10409;</span><span>&#10436;</span><span>&#10375;</span><span>&#10276;</span><span>&#10397;</span><span>&#10412;</span><span>&#10243;</span><span>&#10356;</span><span>&#10357;</span><span>&#10352;</span><span>&#10284;</span><span>&#10325;</span><span>&#10273;</span><span>&#10438;</span><span>&#10278;</span><span>&#10410;</span><span>&#10483;</span><span>&#10473;</span><span>&#10366;</span><span>&#10401;</span><span>&#10485;</span><span>&#10417;</span><span>&#10274;</span><span>&#10441;</span><span>&#10472;</span><span>&#10250;</span><span>&#10307;</span><span>&#10405;</span><span>&#10330;</span><span>&#10361;</span><span>&#10258;</span><span>&#10262;</span><span>&#10271;</span><span>&#10247;</span><span>&#10330;</span><span>&#10327;</span><span>&#10365;</span><span>&#10334;</span><span>&#10410;</span><span>&#10337;</span><span>&#10491;</span><span>&#10309;</span><span>&#10324;</span><span>&#10408;</span><span>&#10269;</span><span>&#10420;</span><span>&#10245;</span>
        </div>
        <div class="column sw-col-17"><span>&#10475;</span><span>&#10419;</span><span>&#10417;</span><span>&#10359;</span><span>&#10485;</span><span>&#10353;</span><span>&#10483;</span><span>&#10307;</span><span>&#10373;</span><span>&#10449;</span><span>&#10418;</span><span>&#10333;</span><span>&#10297;</span><span>&#10362;</span><span>&#10477;</span><span>&#10354;</span><span>&#10285;</span><span>&#10394;</span><span>&#10382;</span><span>&#10432;</span><span>&#10251;</span><span>&#10407;</span><span>&#10481;</span><span>&#10445;</span><span>&#10329;</span><span>&#10409;</span><span>&#10417;</span><span>&#10250;</span><span>&#10309;</span><span>&#10348;</span><span>&#10436;</span><span>&#10411;</span><span>&#10295;</span><span>&#10263;</span><span>&#10442;</span><span>&#10415;</span><span>&#10252;</span><span>&#10281;</span><span>&#10369;</span><span>&#10370;</span><span>&#10290;</span><span>&#10325;</span><span>&#10394;</span><span>&#10263;</span><span>&#10385;</span><span>&#10286;</span><span>&#10485;</span><span>&#10305;</span><span>&#10301;</span><span>&#10356;</span>
        </div>
        <div class="column sw-col-18"><span>&#10271;</span><span>&#10466;</span><span>&#10290;</span><span>&#10458;</span><span>&#10243;</span><span>&#10398;</span><span>&#10468;</span><span>&#10427;</span><span>&#10252;</span><span>&#10279;</span><span>&#10359;</span><span>&#10266;</span><span>&#10414;</span><span>&#10355;</span><span>&#10440;</span><span>&#10268;</span><span>&#10323;</span><span>&#10369;</span><span>&#10395;</span><span>&#10381;</span><span>&#10458;</span><span>&#10426;</span><span>&#10294;</span><span>&#10297;</span><span>&#10287;</span><span>&#10401;</span><span>&#10431;</span><span>&#10488;</span><span>&#10447;</span><span>&#10493;</span><span>&#10247;</span><span>&#10488;</span><span>&#10301;</span><span>&#10387;</span><span>&#10482;</span><span>&#10379;</span><span>&#10457;</span><span>&#10315;</span><span>&#10375;</span><span>&#10252;</span><span>&#10319;</span><span>&#10392;</span><span>&#10491;</span><span>&#10324;</span><span>&#10385;</span><span>&#10392;</span><span>&#10397;</span><span>&#10337;</span><span>&#10315;</span><span>&#10465;</span>
        </div>
        <div class="column sw-col-19"><span>&#10377;</span><span>&#10445;</span><span>&#10365;</span><span>&#10407;</span><span>&#10340;</span><span>&#10279;</span><span>&#10292;</span><span>&#10266;</span><span>&#10273;</span><span>&#10340;</span><span>&#10275;</span><span>&#10371;</span><span>&#10412;</span><span>&#10473;</span><span>&#10357;</span><span>&#10338;</span><span>&#10360;</span><span>&#10446;</span><span>&#10409;</span><span>&#10260;</span><span>&#10444;</span><span>&#10363;</span><span>&#10416;</span><span>&#10467;</span><span>&#10489;</span><span>&#10314;</span><span>&#10433;</span><span>&#10248;</span><span>&#10334;</span><span>&#10421;</span><span>&#10258;</span><span>&#10252;</span><span>&#10467;</span><span>&#10393;</span><span>&#10414;</span><span>&#10307;</span><span>&#10418;</span><span>&#10413;</span><span>&#10485;</span><span>&#10268;</span><span>&#10243;</span><span>&#10290;</span><span>&#10347;</span><span>&#10407;</span><span>&#10285;</span><span>&#10452;</span><span>&#10387;</span><span>&#10269;</span><span>&#10314;</span><span>&#10279;</span>
        </div>
        <div class="column sw-col-20"><span>&#10242;</span><span>&#10278;</span><span>&#10454;</span><span>&#10442;</span><span>&#10321;</span><span>&#10365;</span><span>&#10475;</span><span>&#10430;</span><span>&#10426;</span><span>&#10477;</span><span>&#10285;</span><span>&#10439;</span><span>&#10348;</span><span>&#10265;</span><span>&#10321;</span><span>&#10402;</span><span>&#10271;</span><span>&#10476;</span><span>&#10313;</span><span>&#10364;</span><span>&#10287;</span><span>&#10450;</span><span>&#10283;</span><span>&#10345;</span><span>&#10481;</span><span>&#10490;</span><span>&#10375;</span><span>&#10292;</span><span>&#10342;</span><span>&#10418;</span><span>&#10451;</span><span>&#10341;</span><span>&#10430;</span><span>&#10451;</span><span>&#10293;</span><span>&#10258;</span><span>&#10244;</span><span>&#10452;</span><span>&#10400;</span><span>&#10304;</span><span>&#10401;</span><span>&#10335;</span><span>&#10407;</span><span>&#10438;</span><span>&#10401;</span><span>&#10456;</span><span>&#10340;</span><span>&#10280;</span><span>&#10309;</span><span>&#10405;</span>
        </div>
        <div class="column sw-col-21"><span>&#10326;</span><span>&#10322;</span><span>&#10414;</span><span>&#10491;</span><span>&#10359;</span><span>&#10406;</span><span>&#10396;</span><span>&#10246;</span><span>&#10305;</span><span>&#10377;</span><span>&#10388;</span><span>&#10380;</span><span>&#10417;</span><span>&#10302;</span><span>&#10431;</span><span>&#10393;</span><span>&#10273;</span><span>&#10250;</span><span>&#10457;</span><span>&#10411;</span><span>&#10337;</span><span>&#10314;</span><span>&#10453;</span><span>&#10375;</span><span>&#10408;</span><span>&#10439;</span><span>&#10470;</span><span>&#10467;</span><span>&#10362;</span><span>&#10431;</span><span>&#10493;</span><span>&#10395;</span><span>&#10424;</span><span>&#10363;</span><span>&#10425;</span><span>&#10494;</span><span>&#10465;</span><span>&#10274;</span><span>&#10387;</span><span>&#10338;</span><span>&#10297;</span><span>&#10249;</span><span>&#10350;</span><span>&#10404;</span><span>&#10453;</span><span>&#10340;</span><span>&#10249;</span><span>&#10327;</span><span>&#10463;</span><span>&#10407;</span>
        </div>
        <div class="column sw-col-22"><span>&#10400;</span><span>&#10245;</span><span>&#10350;</span><span>&#10351;</span><span>&#10337;</span><span>&#10377;</span><span>&#10465;</span><span>&#10409;</span><span>&#10477;</span><span>&#10393;</span><span>&#10458;</span><span>&#10269;</span><span>&#10306;</span><span>&#10257;</span><span>&#10474;</span><span>&#10282;</span><span>&#10328;</span><span>&#10419;</span><span>&#10373;</span><span>&#10285;</span><span>&#10286;</span><span>&#10253;</span><span>&#10260;</span><span>&#10248;</span><span>&#10437;</span><span>&#10432;</span><span>&#10329;</span><span>&#10494;</span><span>&#10439;</span><span>&#10333;</span><span>&#10348;</span><span>&#10387;</span><span>&#10289;</span><span>&#10386;</span><span>&#10441;</span><span>&#10414;</span><span>&#10491;</span><span>&#10482;</span><span>&#10341;</span><span>&#10402;</span><span>&#10243;</span><span>&#10366;</span><span>&#10438;</span><span>&#10410;</span><span>&#10489;</span><span>&#10446;</span><span>&#10331;</span><span>&#10391;</span><span>&#10284;</span><span>&#10405;</span>
        </div>
        <div class="column sw-col-23"><span>&#10491;</span><span>&#10400;</span><span>&#10267;</span><span>&#10406;</span><span>&#10297;</span><span>&#10355;</span><span>&#10463;</span><span>&#10265;</span><span>&#10443;</span><span>&#10394;</span><span>&#10391;</span><span>&#10261;</span><span>&#10445;</span><span>&#10456;</span><span>&#10356;</span><span>&#10324;</span><span>&#10422;</span><span>&#10424;</span><span>&#10419;</span><span>&#10294;</span><span>&#10289;</span><span>&#10272;</span><span>&#10271;</span><span>&#10339;</span><span>&#10307;</span><span>&#10263;</span><span>&#10337;</span><span>&#10390;</span><span>&#10414;</span><span>&#10432;</span><span>&#10405;</span><span>&#10267;</span><span>&#10409;</span><span>&#10406;</span><span>&#10439;</span><span>&#10438;</span><span>&#10314;</span><span>&#10474;</span><span>&#10309;</span><span>&#10323;</span><span>&#10323;</span><span>&#10405;</span><span>&#10473;</span><span>&#10265;</span><span>&#10266;</span><span>&#10393;</span><span>&#10312;</span><span>&#10285;</span><span>&#10256;</span><span>&#10342;</span>
        </div>
        <div class="column sw-col-24"><span>&#10435;</span><span>&#10300;</span><span>&#10295;</span><span>&#10394;</span><span>&#10384;</span><span>&#10390;</span><span>&#10287;</span><span>&#10396;</span><span>&#10338;</span><span>&#10341;</span><span>&#10350;</span><span>&#10290;</span><span>&#10353;</span><span>&#10493;</span><span>&#10253;</span><span>&#10386;</span><span>&#10380;</span><span>&#10247;</span><span>&#10301;</span><span>&#10311;</span><span>&#10325;</span><span>&#10358;</span><span>&#10372;</span><span>&#10433;</span><span>&#10429;</span><span>&#10298;</span><span>&#10399;</span><span>&#10435;</span><span>&#10431;</span><span>&#10285;</span><span>&#10372;</span><span>&#10249;</span><span>&#10285;</span><span>&#10451;</span><span>&#10324;</span><span>&#10368;</span><span>&#10481;</span><span>&#10350;</span><span>&#10479;</span><span>&#10374;</span><span>&#10444;</span><span>&#10284;</span><span>&#10446;</span><span>&#10290;</span><span>&#10335;</span><span>&#10354;</span><span>&#10335;</span><span>&#10247;</span><span>&#10404;</span><span>&#10486;</span>
        </div>
        <div class="column sw-col-25"><span>&#10259;</span><span>&#10348;</span><span>&#10330;</span><span>&#10384;</span><span>&#10293;</span><span>&#10327;</span><span>&#10245;</span><span>&#10245;</span><span>&#10305;</span><span>&#10316;</span><span>&#10301;</span><span>&#10400;</span><span>&#10361;</span><span>&#10413;</span><span>&#10486;</span><span>&#10243;</span><span>&#10399;</span><span>&#10458;</span><span>&#10414;</span><span>&#10257;</span><span>&#10416;</span><span>&#10264;</span><span>&#10438;</span><span>&#10320;</span><span>&#10432;</span><span>&#10428;</span><span>&#10265;</span><span>&#10433;</span><span>&#10400;</span><span>&#10329;</span><span>&#10490;</span><span>&#10330;</span><span>&#10397;</span><span>&#10478;</span><span>&#10362;</span><span>&#10330;</span><span>&#10396;</span><span>&#10350;</span><span>&#10464;</span><span>&#10491;</span><span>&#10399;</span><span>&#10324;</span><span>&#10435;</span><span>&#10329;</span><span>&#10478;</span><span>&#10372;</span><span>&#10442;</span><span>&#10381;</span><span>&#10351;</span><span>&#10485;</span>
        </div>
        <div class="column sw-col-26"><span>&#10289;</span><span>&#10474;</span><span>&#10288;</span><span>&#10283;</span><span>&#10259;</span><span>&#10248;</span><span>&#10317;</span><span>&#10383;</span><span>&#10471;</span><span>&#10326;</span><span>&#10424;</span><span>&#10446;</span><span>&#10335;</span><span>&#10414;</span><span>&#10436;</span><span>&#10266;</span><span>&#10282;</span><span>&#10285;</span><span>&#10475;</span><span>&#10444;</span><span>&#10469;</span><span>&#10457;</span><span>&#10481;</span><span>&#10446;</span><span>&#10375;</span><span>&#10366;</span><span>&#10461;</span><span>&#10297;</span><span>&#10387;</span><span>&#10456;</span><span>&#10346;</span><span>&#10313;</span><span>&#10352;</span><span>&#10255;</span><span>&#10254;</span><span>&#10314;</span><span>&#10296;</span><span>&#10350;</span><span>&#10388;</span><span>&#10351;</span><span>&#10276;</span><span>&#10453;</span><span>&#10373;</span><span>&#10359;</span><span>&#10332;</span><span>&#10278;</span><span>&#10484;</span><span>&#10470;</span><span>&#10471;</span><span>&#10382;</span>
        </div>
        <div class="column sw-col-27"><span>&#10460;</span><span>&#10368;</span><span>&#10485;</span><span>&#10470;</span><span>&#10360;</span><span>&#10316;</span><span>&#10473;</span><span>&#10252;</span><span>&#10441;</span><span>&#10329;</span><span>&#10424;</span><span>&#10423;</span><span>&#10322;</span><span>&#10334;</span><span>&#10351;</span><span>&#10413;</span><span>&#10445;</span><span>&#10363;</span><span>&#10304;</span><span>&#10412;</span><span>&#10395;</span><span>&#10329;</span><span>&#10282;</span><span>&#10381;</span><span>&#10247;</span><span>&#10362;</span><span>&#10257;</span><span>&#10409;</span><span>&#10324;</span><span>&#10287;</span><span>&#10305;</span><span>&#10474;</span><span>&#10253;</span><span>&#10270;</span><span>&#10406;</span><span>&#10450;</span><span>&#10274;</span><span>&#10422;</span><span>&#10473;</span><span>&#10407;</span><span>&#10473;</span><span>&#10272;</span><span>&#10290;</span><span>&#10380;</span><span>&#10468;</span><span>&#10377;</span><span>&#10301;</span><span>&#10428;</span><span>&#10428;</span><span>&#10309;</span>
        </div>
        <div class="column sw-col-28"><span>&#10274;</span><span>&#10385;</span><span>&#10422;</span><span>&#10372;</span><span>&#10405;</span><span>&#10370;</span><span>&#10472;</span><span>&#10276;</span><span>&#10316;</span><span>&#10440;</span><span>&#10385;</span><span>&#10484;</span><span>&#10278;</span><span>&#10422;</span><span>&#10319;</span><span>&#10414;</span><span>&#10380;</span><span>&#10259;</span><span>&#10469;</span><span>&#10293;</span><span>&#10450;</span><span>&#10484;</span><span>&#10317;</span><span>&#10248;</span><span>&#10305;</span><span>&#10318;</span><span>&#10362;</span><span>&#10485;</span><span>&#10461;</span><span>&#10343;</span><span>&#10267;</span><span>&#10473;</span><span>&#10293;</span><span>&#10412;</span><span>&#10349;</span><span>&#10258;</span><span>&#10336;</span><span>&#10294;</span><span>&#10259;</span><span>&#10400;</span><span>&#10300;</span><span>&#10430;</span><span>&#10351;</span><span>&#10394;</span><span>&#10315;</span><span>&#10449;</span><span>&#10287;</span><span>&#10350;</span><span>&#10338;</span><span>&#10406;</span>
        </div>
        <div class="column sw-col-29"><span>&#10411;</span><span>&#10252;</span><span>&#10281;</span><span>&#10362;</span><span>&#10426;</span><span>&#10403;</span><span>&#10257;</span><span>&#10458;</span><span>&#10259;</span><span>&#10331;</span><span>&#10469;</span><span>&#10363;</span><span>&#10412;</span><span>&#10373;</span><span>&#10437;</span><span>&#10371;</span><span>&#10337;</span><span>&#10271;</span><span>&#10370;</span><span>&#10247;</span><span>&#10319;</span><span>&#10351;</span><span>&#10455;</span><span>&#10453;</span><span>&#10301;</span><span>&#10447;</span><span>&#10339;</span><span>&#10331;</span><span>&#10381;</span><span>&#10350;</span><span>&#10483;</span><span>&#10435;</span><span>&#10303;</span><span>&#10427;</span><span>&#10471;</span><span>&#10294;</span><span>&#10329;</span><span>&#10333;</span><span>&#10344;</span><span>&#10438;</span><span>&#10424;</span><span>&#10370;</span><span>&#10404;</span><span>&#10348;</span><span>&#10304;</span><span>&#10456;</span><span>&#10423;</span><span>&#10445;</span><span>&#10398;</span><span>&#10385;</span>
        </div>
        <div class="column sw-col-30"><span>&#10382;</span><span>&#10433;</span><span>&#10311;</span><span>&#10352;</span><span>&#10293;</span><span>&#10467;</span><span>&#10300;</span><span>&#10421;</span><span>&#10331;</span><span>&#10345;</span><span>&#10314;</span><span>&#10274;</span><span>&#10429;</span><span>&#10345;</span><span>&#10272;</span><span>&#10418;</span><span>&#10408;</span><span>&#10369;</span><span>&#10310;</span><span>&#10399;</span><span>&#10267;</span><span>&#10351;</span><span>&#10455;</span><span>&#10340;</span><span>&#10425;</span><span>&#10462;</span><span>&#10334;</span><span>&#10242;</span><span>&#10407;</span><span>&#10377;</span><span>&#10343;</span><span>&#10275;</span><span>&#10295;</span><span>&#10448;</span><span>&#10410;</span><span>&#10258;</span><span>&#10440;</span><span>&#10268;</span><span>&#10444;</span><span>&#10317;</span><span>&#10242;</span><span>&#10247;</span><span>&#10441;</span><span>&#10317;</span><span>&#10461;</span><span>&#10298;</span><span>&#10477;</span><span>&#10436;</span><span>&#10481;</span><span>&#10420;</span>
        </div>
        <div class="column sw-col-31"><span>&#10461;</span><span>&#10250;</span><span>&#10350;</span><span>&#10282;</span><span>&#10488;</span><span>&#10387;</span><span>&#10308;</span><span>&#10351;</span><span>&#10456;</span><span>&#10409;</span><span>&#10402;</span><span>&#10344;</span><span>&#10293;</span><span>&#10397;</span><span>&#10409;</span><span>&#10400;</span><span>&#10448;</span><span>&#10249;</span><span>&#10288;</span><span>&#10454;</span><span>&#10462;</span><span>&#10265;</span><span>&#10255;</span><span>&#10383;</span><span>&#10336;</span><span>&#10319;</span><span>&#10271;</span><span>&#10429;</span><span>&#10400;</span><span>&#10455;</span><span>&#10258;</span><span>&#10454;</span><span>&#10393;</span><span>&#10442;</span><span>&#10424;</span><span>&#10492;</span><span>&#10341;</span><span>&#10411;</span><span>&#10351;</span><span>&#10375;</span><span>&#10483;</span><span>&#10318;</span><span>&#10401;</span><span>&#10364;</span><span>&#10386;</span><span>&#10278;</span><span>&#10440;</span><span>&#10443;</span><span>&#10463;</span><span>&#10356;</span>
        </div>
        <div class="column sw-col-32"><span>&#10327;</span><span>&#10328;</span><span>&#10255;</span><span>&#10308;</span><span>&#10393;</span><span>&#10274;</span><span>&#10257;</span><span>&#10443;</span><span>&#10452;</span><span>&#10494;</span><span>&#10422;</span><span>&#10368;</span><span>&#10265;</span><span>&#10365;</span><span>&#10324;</span><span>&#10292;</span><span>&#10285;</span><span>&#10285;</span><span>&#10281;</span><span>&#10365;</span><span>&#10286;</span><span>&#10261;</span><span>&#10389;</span><span>&#10366;</span><span>&#10452;</span><span>&#10346;</span><span>&#10409;</span><span>&#10340;</span><span>&#10455;</span><span>&#10406;</span><span>&#10287;</span><span>&#10294;</span><span>&#10285;</span><span>&#10324;</span><span>&#10300;</span><span>&#10248;</span><span>&#10243;</span><span>&#10455;</span><span>&#10246;</span><span>&#10262;</span><span>&#10318;</span><span>&#10380;</span><span>&#10390;</span><span>&#10282;</span><span>&#10457;</span><span>&#10404;</span><span>&#10412;</span><span>&#10373;</span><span>&#10325;</span><span>&#10434;</span>
        </div>
        <div class="column sw-col-33"><span>&#10273;</span><span>&#10388;</span><span>&#10303;</span><span>&#10341;</span><span>&#10489;</span><span>&#10402;</span><span>&#10321;</span><span>&#10408;</span><span>&#10381;</span><span>&#10490;</span><span>&#10475;</span><span>&#10429;</span><span>&#10346;</span><span>&#10388;</span><span>&#10478;</span><span>&#10449;</span><span>&#10485;</span><span>&#10470;</span><span>&#10431;</span><span>&#10240;</span><span>&#10256;</span><span>&#10257;</span><span>&#10421;</span><span>&#10301;</span><span>&#10282;</span><span>&#10281;</span><span>&#10452;</span><span>&#10446;</span><span>&#10256;</span><span>&#10295;</span><span>&#10340;</span><span>&#10285;</span><span>&#10453;</span><span>&#10323;</span><span>&#10473;</span><span>&#10481;</span><span>&#10297;</span><span>&#10407;</span><span>&#10276;</span><span>&#10428;</span><span>&#10248;</span><span>&#10283;</span><span>&#10316;</span><span>&#10251;</span><span>&#10442;</span><span>&#10240;</span><span>&#10473;</span><span>&#10342;</span><span>&#10456;</span><span>&#10374;</span>
        </div>
        <div class="column sw-col-34"><span>&#10383;</span><span>&#10280;</span><span>&#10320;</span><span>&#10344;</span><span>&#10265;</span><span>&#10409;</span><span>&#10400;</span><span>&#10278;</span><span>&#10443;</span><span>&#10433;</span><span>&#10388;</span><span>&#10341;</span><span>&#10331;</span><span>&#10382;</span><span>&#10343;</span><span>&#10470;</span><span>&#10334;</span><span>&#10414;</span><span>&#10301;</span><span>&#10296;</span><span>&#10354;</span><span>&#10371;</span><span>&#10243;</span><span>&#10401;</span><span>&#10452;</span><span>&#10316;</span><span>&#10265;</span><span>&#10329;</span><span>&#10292;</span><span>&#10477;</span><span>&#10409;</span><span>&#10394;</span><span>&#10287;</span><span>&#10440;</span><span>&#10493;</span><span>&#10334;</span><span>&#10482;</span><span>&#10374;</span><span>&#10454;</span><span>&#10409;</span><span>&#10454;</span><span>&#10282;</span><span>&#10264;</span><span>&#10458;</span><span>&#10401;</span><span>&#10426;</span><span>&#10274;</span><span>&#10410;</span><span>&#10411;</span><span>&#10347;</span>
        </div>
        <div class="column sw-col-35"><span>&#10282;</span><span>&#10327;</span><span>&#10415;</span><span>&#10277;</span><span>&#10290;</span><span>&#10282;</span><span>&#10445;</span><span>&#10256;</span><span>&#10407;</span><span>&#10399;</span><span>&#10470;</span><span>&#10361;</span><span>&#10455;</span><span>&#10425;</span><span>&#10463;</span><span>&#10326;</span><span>&#10349;</span><span>&#10419;</span><span>&#10320;</span><span>&#10247;</span><span>&#10297;</span><span>&#10454;</span><span>&#10308;</span><span>&#10488;</span><span>&#10416;</span><span>&#10250;</span><span>&#10354;</span><span>&#10461;</span><span>&#10399;</span><span>&#10275;</span><span>&#10263;</span><span>&#10381;</span><span>&#10418;</span><span>&#10431;</span><span>&#10308;</span><span>&#10349;</span><span>&#10409;</span><span>&#10326;</span><span>&#10276;</span><span>&#10335;</span><span>&#10255;</span><span>&#10321;</span><span>&#10346;</span><span>&#10385;</span><span>&#10400;</span><span>&#10443;</span><span>&#10460;</span><span>&#10249;</span><span>&#10290;</span><span>&#10392;</span>
        </div>
        <div class="column sw-col-36"><span>&#10420;</span><span>&#10432;</span><span>&#10373;</span><span>&#10426;</span><span>&#10382;</span><span>&#10287;</span><span>&#10467;</span><span>&#10488;</span><span>&#10317;</span><span>&#10311;</span><span>&#10331;</span><span>&#10245;</span><span>&#10469;</span><span>&#10491;</span><span>&#10439;</span><span>&#10429;</span><span>&#10454;</span><span>&#10425;</span><span>&#10303;</span><span>&#10269;</span><span>&#10435;</span><span>&#10446;</span><span>&#10312;</span><span>&#10407;</span><span>&#10375;</span><span>&#10494;</span><span>&#10466;</span><span>&#10482;</span><span>&#10335;</span><span>&#10402;</span><span>&#10392;</span><span>&#10332;</span><span>&#10264;</span><span>&#10384;</span><span>&#10328;</span><span>&#10460;</span><span>&#10275;</span><span>&#10261;</span><span>&#10453;</span><span>&#10420;</span><span>&#10486;</span><span>&#10464;</span><span>&#10254;</span><span>&#10353;</span><span>&#10394;</span><span>&#10353;</span><span>&#10451;</span><span>&#10471;</span><span>&#10252;</span><span>&#10484;</span>
        </div>
        <div class="column sw-col-37"><span>&#10491;</span><span>&#10362;</span><span>&#10365;</span><span>&#10280;</span><span>&#10345;</span><span>&#10420;</span><span>&#10296;</span><span>&#10328;</span><span>&#10268;</span><span>&#10249;</span><span>&#10355;</span><span>&#10393;</span><span>&#10281;</span><span>&#10445;</span><span>&#10379;</span><span>&#10300;</span><span>&#10280;</span><span>&#10301;</span><span>&#10338;</span><span>&#10306;</span><span>&#10358;</span><span>&#10416;</span><span>&#10355;</span><span>&#10492;</span><span>&#10449;</span><span>&#10315;</span><span>&#10310;</span><span>&#10416;</span><span>&#10382;</span><span>&#10260;</span><span>&#10483;</span><span>&#10437;</span><span>&#10269;</span><span>&#10255;</span><span>&#10259;</span><span>&#10482;</span><span>&#10358;</span><span>&#10322;</span><span>&#10328;</span><span>&#10329;</span><span>&#10241;</span><span>&#10394;</span><span>&#10445;</span><span>&#10253;</span><span>&#10448;</span><span>&#10367;</span><span>&#10491;</span><span>&#10346;</span><span>&#10486;</span><span>&#10399;</span>
        </div>
        <div class="column sw-col-38"><span>&#10281;</span><span>&#10386;</span><span>&#10294;</span><span>&#10444;</span><span>&#10342;</span><span>&#10271;</span><span>&#10400;</span><span>&#10438;</span><span>&#10420;</span><span>&#10450;</span><span>&#10434;</span><span>&#10450;</span><span>&#10395;</span><span>&#10397;</span><span>&#10355;</span><span>&#10385;</span><span>&#10312;</span><span>&#10313;</span><span>&#10282;</span><span>&#10334;</span><span>&#10477;</span><span>&#10338;</span><span>&#10304;</span><span>&#10485;</span><span>&#10244;</span><span>&#10337;</span><span>&#10474;</span><span>&#10399;</span><span>&#10344;</span><span>&#10463;</span><span>&#10353;</span><span>&#10460;</span><span>&#10478;</span><span>&#10374;</span><span>&#10282;</span><span>&#10334;</span><span>&#10449;</span><span>&#10317;</span><span>&#10279;</span><span>&#10452;</span><span>&#10384;</span><span>&#10380;</span><span>&#10475;</span><span>&#10343;</span><span>&#10420;</span><span>&#10269;</span><span>&#10293;</span><span>&#10321;</span><span>&#10293;</span><span>&#10283;</span>
        </div>
        <div class="column sw-col-39"><span>&#10306;</span><span>&#10337;</span><span>&#10472;</span><span>&#10394;</span><span>&#10400;</span><span>&#10468;</span><span>&#10256;</span><span>&#10280;</span><span>&#10469;</span><span>&#10399;</span><span>&#10317;</span><span>&#10399;</span><span>&#10463;</span><span>&#10290;</span><span>&#10355;</span><span>&#10362;</span><span>&#10431;</span><span>&#10321;</span><span>&#10343;</span><span>&#10401;</span><span>&#10330;</span><span>&#10334;</span><span>&#10361;</span><span>&#10283;</span><span>&#10241;</span><span>&#10416;</span><span>&#10407;</span><span>&#10492;</span><span>&#10281;</span><span>&#10314;</span><span>&#10485;</span><span>&#10257;</span><span>&#10447;</span><span>&#10441;</span><span>&#10342;</span><span>&#10321;</span><span>&#10418;</span><span>&#10300;</span><span>&#10469;</span><span>&#10272;</span><span>&#10306;</span><span>&#10263;</span><span>&#10345;</span><span>&#10348;</span><span>&#10381;</span><span>&#10266;</span><span>&#10259;</span><span>&#10364;</span><span>&#10272;</span><span>&#10268;</span>
        </div>
        <div class="column sw-col-40"><span>&#10479;</span><span>&#10414;</span><span>&#10393;</span><span>&#10270;</span><span>&#10313;</span><span>&#10441;</span><span>&#10364;</span><span>&#10259;</span><span>&#10485;</span><span>&#10366;</span><span>&#10437;</span><span>&#10479;</span><span>&#10411;</span><span>&#10453;</span><span>&#10433;</span><span>&#10385;</span><span>&#10374;</span><span>&#10459;</span><span>&#10489;</span><span>&#10299;</span><span>&#10337;</span><span>&#10258;</span><span>&#10341;</span><span>&#10285;</span><span>&#10432;</span><span>&#10487;</span><span>&#10341;</span><span>&#10270;</span><span>&#10401;</span><span>&#10478;</span><span>&#10257;</span><span>&#10410;</span><span>&#10417;</span><span>&#10453;</span><span>&#10332;</span><span>&#10345;</span><span>&#10279;</span><span>&#10252;</span><span>&#10415;</span><span>&#10484;</span><span>&#10248;</span><span>&#10439;</span><span>&#10380;</span><span>&#10381;</span><span>&#10302;</span><span>&#10338;</span><span>&#10457;</span><span>&#10304;</span><span>&#10257;</span><span>&#10346;</span>
        </div>
        <div class="column sw-col-41"><span>&#10452;</span><span>&#10243;</span><span>&#10406;</span><span>&#10300;</span><span>&#10475;</span><span>&#10250;</span><span>&#10302;</span><span>&#10404;</span><span>&#10399;</span><span>&#10406;</span><span>&#10443;</span><span>&#10256;</span><span>&#10456;</span><span>&#10350;</span><span>&#10449;</span><span>&#10268;</span><span>&#10311;</span><span>&#10423;</span><span>&#10399;</span><span>&#10439;</span><span>&#10367;</span><span>&#10336;</span><span>&#10272;</span><span>&#10450;</span><span>&#10350;</span><span>&#10471;</span><span>&#10432;</span><span>&#10331;</span><span>&#10424;</span><span>&#10327;</span><span>&#10349;</span><span>&#10489;</span><span>&#10374;</span><span>&#10379;</span><span>&#10273;</span><span>&#10338;</span><span>&#10335;</span><span>&#10418;</span><span>&#10304;</span><span>&#10323;</span><span>&#10341;</span><span>&#10298;</span><span>&#10360;</span><span>&#10348;</span><span>&#10240;</span><span>&#10422;</span><span>&#10304;</span><span>&#10336;</span><span>&#10363;</span><span>&#10466;</span>
        </div>
        <div class="column sw-col-42"><span>&#10299;</span><span>&#10255;</span><span>&#10477;</span><span>&#10279;</span><span>&#10462;</span><span>&#10301;</span><span>&#10466;</span><span>&#10416;</span><span>&#10347;</span><span>&#10402;</span><span>&#10312;</span><span>&#10400;</span><span>&#10471;</span><span>&#10481;</span><span>&#10272;</span><span>&#10472;</span><span>&#10339;</span><span>&#10313;</span><span>&#10347;</span><span>&#10361;</span><span>&#10344;</span><span>&#10433;</span><span>&#10285;</span><span>&#10306;</span><span>&#10343;</span><span>&#10391;</span><span>&#10243;</span><span>&#10278;</span><span>&#10288;</span><span>&#10278;</span><span>&#10312;</span><span>&#10245;</span><span>&#10259;</span><span>&#10292;</span><span>&#10304;</span><span>&#10374;</span><span>&#10433;</span><span>&#10432;</span><span>&#10338;</span><span>&#10386;</span><span>&#10246;</span><span>&#10319;</span><span>&#10334;</span><span>&#10450;</span><span>&#10379;</span><span>&#10457;</span><span>&#10355;</span><span>&#10339;</span><span>&#10282;</span><span>&#10355;</span>
        </div>
        <div class="column sw-col-43"><span>&#10307;</span><span>&#10300;</span><span>&#10454;</span><span>&#10313;</span><span>&#10480;</span><span>&#10311;</span><span>&#10248;</span><span>&#10451;</span><span>&#10455;</span><span>&#10387;</span><span>&#10321;</span><span>&#10436;</span><span>&#10425;</span><span>&#10252;</span><span>&#10392;</span><span>&#10302;</span><span>&#10403;</span><span>&#10269;</span><span>&#10480;</span><span>&#10311;</span><span>&#10288;</span><span>&#10371;</span><span>&#10357;</span><span>&#10467;</span><span>&#10419;</span><span>&#10391;</span><span>&#10282;</span><span>&#10332;</span><span>&#10455;</span><span>&#10408;</span><span>&#10464;</span><span>&#10392;</span><span>&#10475;</span><span>&#10332;</span><span>&#10341;</span><span>&#10454;</span><span>&#10350;</span><span>&#10370;</span><span>&#10297;</span><span>&#10324;</span><span>&#10439;</span><span>&#10488;</span><span>&#10404;</span><span>&#10473;</span><span>&#10428;</span><span>&#10290;</span><span>&#10283;</span><span>&#10319;</span><span>&#10289;</span><span>&#10482;</span>
        </div>
        <div class="column sw-col-44"><span>&#10373;</span><span>&#10379;</span><span>&#10386;</span><span>&#10408;</span><span>&#10437;</span><span>&#10425;</span><span>&#10268;</span><span>&#10289;</span><span>&#10365;</span><span>&#10240;</span><span>&#10452;</span><span>&#10318;</span><span>&#10410;</span><span>&#10342;</span><span>&#10324;</span><span>&#10482;</span><span>&#10434;</span><span>&#10242;</span><span>&#10254;</span><span>&#10256;</span><span>&#10379;</span><span>&#10259;</span><span>&#10281;</span><span>&#10341;</span><span>&#10456;</span><span>&#10324;</span><span>&#10345;</span><span>&#10472;</span><span>&#10366;</span><span>&#10278;</span><span>&#10437;</span><span>&#10405;</span><span>&#10324;</span><span>&#10324;</span><span>&#10381;</span><span>&#10280;</span><span>&#10322;</span><span>&#10272;</span><span>&#10347;</span><span>&#10297;</span><span>&#10340;</span><span>&#10340;</span><span>&#10403;</span><span>&#10408;</span><span>&#10297;</span><span>&#10242;</span><span>&#10472;</span><span>&#10376;</span><span>&#10288;</span><span>&#10283;</span>
        </div>
        <div class="column sw-col-45"><span>&#10241;</span><span>&#10253;</span><span>&#10430;</span><span>&#10426;</span><span>&#10419;</span><span>&#10440;</span><span>&#10344;</span><span>&#10369;</span><span>&#10368;</span><span>&#10479;</span><span>&#10389;</span><span>&#10482;</span><span>&#10403;</span><span>&#10477;</span><span>&#10318;</span><span>&#10332;</span><span>&#10306;</span><span>&#10452;</span><span>&#10333;</span><span>&#10357;</span><span>&#10421;</span><span>&#10486;</span><span>&#10371;</span><span>&#10332;</span><span>&#10265;</span><span>&#10444;</span><span>&#10408;</span><span>&#10360;</span><span>&#10426;</span><span>&#10257;</span><span>&#10261;</span><span>&#10492;</span><span>&#10470;</span><span>&#10455;</span><span>&#10367;</span><span>&#10470;</span><span>&#10430;</span><span>&#10243;</span><span>&#10249;</span><span>&#10491;</span><span>&#10382;</span><span>&#10399;</span><span>&#10283;</span><span>&#10480;</span><span>&#10341;</span><span>&#10245;</span><span>&#10315;</span><span>&#10331;</span><span>&#10298;</span><span>&#10436;</span>
        </div>
        <div class="column sw-col-46"><span>&#10298;</span><span>&#10279;</span><span>&#10345;</span><span>&#10303;</span><span>&#10438;</span><span>&#10415;</span><span>&#10303;</span><span>&#10458;</span><span>&#10409;</span><span>&#10254;</span><span>&#10471;</span><span>&#10248;</span><span>&#10480;</span><span>&#10472;</span><span>&#10486;</span><span>&#10246;</span><span>&#10299;</span><span>&#10327;</span><span>&#10464;</span><span>&#10360;</span><span>&#10377;</span><span>&#10277;</span><span>&#10396;</span><span>&#10320;</span><span>&#10430;</span><span>&#10320;</span><span>&#10359;</span><span>&#10442;</span><span>&#10330;</span><span>&#10384;</span><span>&#10252;</span><span>&#10469;</span><span>&#10438;</span><span>&#10289;</span><span>&#10244;</span><span>&#10269;</span><span>&#10271;</span><span>&#10447;</span><span>&#10330;</span><span>&#10339;</span><span>&#10385;</span><span>&#10398;</span><span>&#10357;</span><span>&#10369;</span><span>&#10429;</span><span>&#10449;</span><span>&#10310;</span><span>&#10340;</span><span>&#10405;</span><span>&#10423;</span>
        </div>
        <div class="column sw-col-47"><span>&#10363;</span><span>&#10429;</span><span>&#10448;</span><span>&#10284;</span><span>&#10371;</span><span>&#10251;</span><span>&#10297;</span><span>&#10365;</span><span>&#10423;</span><span>&#10283;</span><span>&#10390;</span><span>&#10325;</span><span>&#10463;</span><span>&#10419;</span><span>&#10365;</span><span>&#10435;</span><span>&#10419;</span><span>&#10360;</span><span>&#10417;</span><span>&#10289;</span><span>&#10429;</span><span>&#10493;</span><span>&#10431;</span><span>&#10462;</span><span>&#10285;</span><span>&#10446;</span><span>&#10339;</span><span>&#10300;</span><span>&#10374;</span><span>&#10288;</span><span>&#10388;</span><span>&#10284;</span><span>&#10246;</span><span>&#10252;</span><span>&#10268;</span><span>&#10274;</span><span>&#10449;</span><span>&#10476;</span><span>&#10415;</span><span>&#10473;</span><span>&#10314;</span><span>&#10456;</span><span>&#10267;</span><span>&#10473;</span><span>&#10444;</span><span>&#10358;</span><span>&#10401;</span><span>&#10300;</span><span>&#10477;</span><span>&#10376;</span>
        </div>
        <div class="column sw-col-48"><span>&#10388;</span><span>&#10371;</span><span>&#10348;</span><span>&#10478;</span><span>&#10378;</span><span>&#10304;</span><span>&#10463;</span><span>&#10461;</span><span>&#10461;</span><span>&#10424;</span><span>&#10262;</span><span>&#10420;</span><span>&#10255;</span><span>&#10453;</span><span>&#10277;</span><span>&#10277;</span><span>&#10368;</span><span>&#10349;</span><span>&#10485;</span><span>&#10332;</span><span>&#10282;</span><span>&#10421;</span><span>&#10415;</span><span>&#10396;</span><span>&#10268;</span><span>&#10433;</span><span>&#10484;</span><span>&#10247;</span><span>&#10391;</span><span>&#10339;</span><span>&#10354;</span><span>&#10481;</span><span>&#10446;</span><span>&#10375;</span><span>&#10332;</span><span>&#10319;</span><span>&#10327;</span><span>&#10431;</span><span>&#10291;</span><span>&#10331;</span><span>&#10494;</span><span>&#10379;</span><span>&#10341;</span><span>&#10322;</span><span>&#10267;</span><span>&#10390;</span><span>&#10265;</span><span>&#10278;</span><span>&#10298;</span><span>&#10289;</span>
        </div>
        <div class="column sw-col-49"><span>&#10383;</span><span>&#10470;</span><span>&#10321;</span><span>&#10365;</span><span>&#10432;</span><span>&#10242;</span><span>&#10482;</span><span>&#10299;</span><span>&#10396;</span><span>&#10351;</span><span>&#10269;</span><span>&#10302;</span><span>&#10491;</span><span>&#10337;</span><span>&#10484;</span><span>&#10314;</span><span>&#10402;</span><span>&#10461;</span><span>&#10325;</span><span>&#10324;</span><span>&#10379;</span><span>&#10409;</span><span>&#10469;</span><span>&#10328;</span><span>&#10473;</span><span>&#10318;</span><span>&#10405;</span><span>&#10272;</span><span>&#10349;</span><span>&#10271;</span><span>&#10355;</span><span>&#10245;</span><span>&#10244;</span><span>&#10300;</span><span>&#10303;</span><span>&#10345;</span><span>&#10314;</span><span>&#10296;</span><span>&#10266;</span><span>&#10321;</span><span>&#10300;</span><span>&#10275;</span><span>&#10385;</span><span>&#10389;</span><span>&#10399;</span><span>&#10412;</span><span>&#10324;</span><span>&#10268;</span><span>&#10485;</span><span>&#10490;</span>
        </div>
        <div class="column sw-col-50"><span>&#10294;</span><span>&#10317;</span><span>&#10454;</span><span>&#10437;</span><span>&#10368;</span><span>&#10388;</span><span>&#10278;</span><span>&#10353;</span><span>&#10305;</span><span>&#10365;</span><span>&#10491;</span><span>&#10242;</span><span>&#10429;</span><span>&#10366;</span><span>&#10301;</span><span>&#10405;</span><span>&#10363;</span><span>&#10268;</span><span>&#10291;</span><span>&#10429;</span><span>&#10433;</span><span>&#10280;</span><span>&#10290;</span><span>&#10439;</span><span>&#10300;</span><span>&#10244;</span><span>&#10424;</span><span>&#10349;</span><span>&#10330;</span><span>&#10412;</span><span>&#10363;</span><span>&#10412;</span><span>&#10286;</span><span>&#10493;</span><span>&#10263;</span><span>&#10410;</span><span>&#10400;</span><span>&#10380;</span><span>&#10259;</span><span>&#10389;</span><span>&#10318;</span><span>&#10378;</span><span>&#10280;</span><span>&#10472;</span><span>&#10328;</span><span>&#10287;</span><span>&#10391;</span><span>&#10278;</span><span>&#10370;</span><span>&#10343;</span>
        </div>
        <div class="column sw-col-51"><span>&#10414;</span><span>&#10431;</span><span>&#10383;</span><span>&#10358;</span><span>&#10304;</span><span>&#10395;</span><span>&#10359;</span><span>&#10291;</span><span>&#10458;</span><span>&#10289;</span><span>&#10407;</span><span>&#10253;</span><span>&#10472;</span><span>&#10459;</span><span>&#10434;</span><span>&#10461;</span><span>&#10311;</span><span>&#10266;</span><span>&#10291;</span><span>&#10279;</span><span>&#10438;</span><span>&#10489;</span><span>&#10332;</span><span>&#10474;</span><span>&#10439;</span><span>&#10281;</span><span>&#10401;</span><span>&#10359;</span><span>&#10261;</span><span>&#10476;</span><span>&#10242;</span><span>&#10388;</span><span>&#10453;</span><span>&#10375;</span><span>&#10306;</span><span>&#10360;</span><span>&#10288;</span><span>&#10373;</span><span>&#10426;</span><span>&#10416;</span><span>&#10281;</span><span>&#10486;</span><span>&#10422;</span><span>&#10347;</span><span>&#10469;</span><span>&#10442;</span><span>&#10470;</span><span>&#10469;</span><span>&#10494;</span><span>&#10297;</span>
        </div>
        <div class="column sw-col-52"><span>&#10275;</span><span>&#10340;</span><span>&#10269;</span><span>&#10426;</span><span>&#10318;</span><span>&#10306;</span><span>&#10346;</span><span>&#10418;</span><span>&#10351;</span><span>&#10402;</span><span>&#10337;</span><span>&#10494;</span><span>&#10445;</span><span>&#10247;</span><span>&#10430;</span><span>&#10437;</span><span>&#10404;</span><span>&#10275;</span><span>&#10285;</span><span>&#10352;</span><span>&#10352;</span><span>&#10404;</span><span>&#10266;</span><span>&#10404;</span><span>&#10380;</span><span>&#10381;</span><span>&#10428;</span><span>&#10368;</span><span>&#10416;</span><span>&#10284;</span><span>&#10331;</span><span>&#10408;</span><span>&#10322;</span><span>&#10366;</span><span>&#10290;</span><span>&#10315;</span><span>&#10376;</span><span>&#10310;</span><span>&#10482;</span><span>&#10299;</span><span>&#10348;</span><span>&#10366;</span><span>&#10296;</span><span>&#10287;</span><span>&#10395;</span><span>&#10427;</span><span>&#10326;</span><span>&#10456;</span><span>&#10451;</span><span>&#10381;</span>
        </div>
        <div class="column sw-col-53"><span>&#10304;</span><span>&#10333;</span><span>&#10260;</span><span>&#10248;</span><span>&#10371;</span><span>&#10314;</span><span>&#10457;</span><span>&#10360;</span><span>&#10286;</span><span>&#10249;</span><span>&#10244;</span><span>&#10341;</span><span>&#10350;</span><span>&#10297;</span><span>&#10485;</span><span>&#10374;</span><span>&#10332;</span><span>&#10339;</span><span>&#10381;</span><span>&#10327;</span><span>&#10303;</span><span>&#10460;</span><span>&#10460;</span><span>&#10328;</span><span>&#10290;</span><span>&#10460;</span><span>&#10459;</span><span>&#10291;</span><span>&#10464;</span><span>&#10462;</span><span>&#10415;</span><span>&#10391;</span><span>&#10405;</span><span>&#10256;</span><span>&#10293;</span><span>&#10395;</span><span>&#10293;</span><span>&#10429;</span><span>&#10262;</span><span>&#10409;</span><span>&#10316;</span><span>&#10463;</span><span>&#10302;</span><span>&#10461;</span><span>&#10444;</span><span>&#10270;</span><span>&#10356;</span><span>&#10435;</span><span>&#10468;</span><span>&#10311;</span>
        </div>
        <div class="column sw-col-54"><span>&#10463;</span><span>&#10295;</span><span>&#10381;</span><span>&#10282;</span><span>&#10476;</span><span>&#10297;</span><span>&#10422;</span><span>&#10408;</span><span>&#10459;</span><span>&#10271;</span><span>&#10453;</span><span>&#10321;</span><span>&#10355;</span><span>&#10307;</span><span>&#10254;</span><span>&#10275;</span><span>&#10392;</span><span>&#10413;</span><span>&#10358;</span><span>&#10479;</span><span>&#10253;</span><span>&#10319;</span><span>&#10241;</span><span>&#10418;</span><span>&#10323;</span><span>&#10289;</span><span>&#10325;</span><span>&#10428;</span><span>&#10355;</span><span>&#10249;</span><span>&#10494;</span><span>&#10312;</span><span>&#10368;</span><span>&#10281;</span><span>&#10356;</span><span>&#10314;</span><span>&#10297;</span><span>&#10483;</span><span>&#10309;</span><span>&#10244;</span><span>&#10354;</span><span>&#10437;</span><span>&#10446;</span><span>&#10287;</span><span>&#10381;</span><span>&#10410;</span><span>&#10340;</span><span>&#10371;</span><span>&#10277;</span><span>&#10372;</span>
        </div>
        <div class="column sw-col-55"><span>&#10265;</span><span>&#10341;</span><span>&#10447;</span><span>&#10296;</span><span>&#10362;</span><span>&#10452;</span><span>&#10349;</span><span>&#10446;</span><span>&#10481;</span><span>&#10386;</span><span>&#10380;</span><span>&#10425;</span><span>&#10471;</span><span>&#10417;</span><span>&#10330;</span><span>&#10322;</span><span>&#10422;</span><span>&#10351;</span><span>&#10256;</span><span>&#10445;</span><span>&#10299;</span><span>&#10466;</span><span>&#10284;</span><span>&#10457;</span><span>&#10428;</span><span>&#10368;</span><span>&#10444;</span><span>&#10346;</span><span>&#10454;</span><span>&#10313;</span><span>&#10275;</span><span>&#10466;</span><span>&#10258;</span><span>&#10310;</span><span>&#10428;</span><span>&#10381;</span><span>&#10273;</span><span>&#10342;</span><span>&#10485;</span><span>&#10404;</span><span>&#10405;</span><span>&#10358;</span><span>&#10279;</span><span>&#10422;</span><span>&#10328;</span><span>&#10382;</span><span>&#10465;</span><span>&#10293;</span><span>&#10476;</span><span>&#10442;</span>
        </div>
        <div class="column sw-col-56"><span>&#10349;</span><span>&#10326;</span><span>&#10405;</span><span>&#10321;</span><span>&#10357;</span><span>&#10397;</span><span>&#10312;</span><span>&#10493;</span><span>&#10437;</span><span>&#10284;</span><span>&#10331;</span><span>&#10358;</span><span>&#10319;</span><span>&#10480;</span><span>&#10390;</span><span>&#10460;</span><span>&#10358;</span><span>&#10323;</span><span>&#10322;</span><span>&#10471;</span><span>&#10443;</span><span>&#10296;</span><span>&#10419;</span><span>&#10241;</span><span>&#10403;</span><span>&#10483;</span><span>&#10471;</span><span>&#10395;</span><span>&#10242;</span><span>&#10248;</span><span>&#10430;</span><span>&#10376;</span><span>&#10419;</span><span>&#10318;</span><span>&#10376;</span><span>&#10340;</span><span>&#10407;</span><span>&#10384;</span><span>&#10249;</span><span>&#10307;</span><span>&#10304;</span><span>&#10479;</span><span>&#10309;</span><span>&#10325;</span><span>&#10336;</span><span>&#10480;</span><span>&#10489;</span><span>&#10278;</span><span>&#10469;</span><span>&#10279;</span>
        </div>
        <div class="column sw-col-57"><span>&#10346;</span><span>&#10279;</span><span>&#10404;</span><span>&#10451;</span><span>&#10493;</span><span>&#10473;</span><span>&#10274;</span><span>&#10268;</span><span>&#10305;</span><span>&#10326;</span><span>&#10369;</span><span>&#10373;</span><span>&#10465;</span><span>&#10267;</span><span>&#10424;</span><span>&#10465;</span><span>&#10398;</span><span>&#10368;</span><span>&#10390;</span><span>&#10268;</span><span>&#10313;</span><span>&#10329;</span><span>&#10425;</span><span>&#10305;</span><span>&#10311;</span><span>&#10329;</span><span>&#10370;</span><span>&#10487;</span><span>&#10373;</span><span>&#10315;</span><span>&#10300;</span><span>&#10415;</span><span>&#10247;</span><span>&#10261;</span><span>&#10418;</span><span>&#10478;</span><span>&#10328;</span><span>&#10380;</span><span>&#10347;</span><span>&#10241;</span><span>&#10376;</span><span>&#10303;</span><span>&#10466;</span><span>&#10456;</span><span>&#10424;</span><span>&#10249;</span><span>&#10371;</span><span>&#10278;</span><span>&#10310;</span><span>&#10252;</span>
        </div>
        <div class="column sw-col-58"><span>&#10240;</span><span>&#10424;</span><span>&#10433;</span><span>&#10269;</span><span>&#10448;</span><span>&#10399;</span><span>&#10345;</span><span>&#10400;</span><span>&#10254;</span><span>&#10249;</span><span>&#10380;</span><span>&#10315;</span><span>&#10338;</span><span>&#10296;</span><span>&#10393;</span><span>&#10416;</span><span>&#10291;</span><span>&#10261;</span><span>&#10453;</span><span>&#10487;</span><span>&#10474;</span><span>&#10413;</span><span>&#10320;</span><span>&#10414;</span><span>&#10251;</span><span>&#10493;</span><span>&#10396;</span><span>&#10406;</span><span>&#10341;</span><span>&#10392;</span><span>&#10272;</span><span>&#10445;</span><span>&#10435;</span><span>&#10399;</span><span>&#10487;</span><span>&#10478;</span><span>&#10449;</span><span>&#10448;</span><span>&#10451;</span><span>&#10395;</span><span>&#10426;</span><span>&#10270;</span><span>&#10325;</span><span>&#10262;</span><span>&#10488;</span><span>&#10301;</span><span>&#10247;</span><span>&#10406;</span><span>&#10476;</span><span>&#10266;</span>
        </div>
        <div class="column sw-col-59"><span>&#10370;</span><span>&#10440;</span><span>&#10435;</span><span>&#10464;</span><span>&#10397;</span><span>&#10448;</span><span>&#10244;</span><span>&#10477;</span><span>&#10300;</span><span>&#10294;</span><span>&#10338;</span><span>&#10361;</span><span>&#10245;</span><span>&#10295;</span><span>&#10354;</span><span>&#10396;</span><span>&#10355;</span><span>&#10415;</span><span>&#10355;</span><span>&#10257;</span><span>&#10290;</span><span>&#10333;</span><span>&#10478;</span><span>&#10316;</span><span>&#10345;</span><span>&#10412;</span><span>&#10396;</span><span>&#10469;</span><span>&#10331;</span><span>&#10470;</span><span>&#10362;</span><span>&#10251;</span><span>&#10264;</span><span>&#10382;</span><span>&#10448;</span><span>&#10471;</span><span>&#10303;</span><span>&#10249;</span><span>&#10411;</span><span>&#10290;</span><span>&#10311;</span><span>&#10253;</span><span>&#10303;</span><span>&#10394;</span><span>&#10474;</span><span>&#10439;</span><span>&#10254;</span><span>&#10243;</span><span>&#10374;</span><span>&#10299;</span>
        </div>
        <div class="column sw-col-60"><span>&#10413;</span><span>&#10402;</span><span>&#10299;</span><span>&#10454;</span><span>&#10494;</span><span>&#10414;</span><span>&#10432;</span><span>&#10343;</span><span>&#10378;</span><span>&#10263;</span><span>&#10296;</span><span>&#10428;</span><span>&#10399;</span><span>&#10420;</span><span>&#10412;</span><span>&#10355;</span><span>&#10354;</span><span>&#10364;</span><span>&#10288;</span><span>&#10487;</span><span>&#10407;</span><span>&#10276;</span><span>&#10314;</span><span>&#10385;</span><span>&#10379;</span><span>&#10331;</span><span>&#10479;</span><span>&#10351;</span><span>&#10340;</span><span>&#10251;</span><span>&#10390;</span><span>&#10464;</span><span>&#10418;</span><span>&#10474;</span><span>&#10376;</span><span>&#10375;</span><span>&#10465;</span><span>&#10389;</span><span>&#10383;</span><span>&#10272;</span><span>&#10259;</span><span>&#10337;</span><span>&#10248;</span><span>&#10252;</span><span>&#10252;</span><span>&#10478;</span><span>&#10427;</span><span>&#10308;</span><span>&#10481;</span><span>&#10387;</span>
        </div>
        <div class="column sw-col-61"><span>&#10336;</span><span>&#10254;</span><span>&#10439;</span><span>&#10350;</span><span>&#10255;</span><span>&#10260;</span><span>&#10413;</span><span>&#10394;</span><span>&#10320;</span><span>&#10489;</span><span>&#10288;</span><span>&#10407;</span><span>&#10362;</span><span>&#10443;</span><span>&#10316;</span><span>&#10470;</span><span>&#10314;</span><span>&#10276;</span><span>&#10255;</span><span>&#10270;</span><span>&#10282;</span><span>&#10300;</span><span>&#10470;</span><span>&#10363;</span><span>&#10388;</span><span>&#10416;</span><span>&#10377;</span><span>&#10384;</span><span>&#10281;</span><span>&#10277;</span><span>&#10472;</span><span>&#10374;</span><span>&#10467;</span><span>&#10487;</span><span>&#10248;</span><span>&#10284;</span><span>&#10447;</span><span>&#10460;</span><span>&#10399;</span><span>&#10363;</span><span>&#10284;</span><span>&#10252;</span><span>&#10383;</span><span>&#10470;</span><span>&#10453;</span><span>&#10393;</span><span>&#10358;</span><span>&#10451;</span><span>&#10401;</span><span>&#10483;</span>
        </div>
        <div class="column sw-col-62"><span>&#10394;</span><span>&#10354;</span><span>&#10480;</span><span>&#10435;</span><span>&#10286;</span><span>&#10449;</span><span>&#10409;</span><span>&#10245;</span><span>&#10353;</span><span>&#10361;</span><span>&#10268;</span><span>&#10482;</span><span>&#10485;</span><span>&#10358;</span><span>&#10428;</span><span>&#10244;</span><span>&#10347;</span><span>&#10279;</span><span>&#10359;</span><span>&#10318;</span><span>&#10384;</span><span>&#10257;</span><span>&#10353;</span><span>&#10258;</span><span>&#10450;</span><span>&#10441;</span><span>&#10449;</span><span>&#10410;</span><span>&#10451;</span><span>&#10294;</span><span>&#10398;</span><span>&#10387;</span><span>&#10295;</span><span>&#10333;</span><span>&#10467;</span><span>&#10268;</span><span>&#10359;</span><span>&#10360;</span><span>&#10378;</span><span>&#10352;</span><span>&#10248;</span><span>&#10360;</span><span>&#10490;</span><span>&#10320;</span><span>&#10277;</span><span>&#10255;</span><span>&#10446;</span><span>&#10267;</span><span>&#10325;</span><span>&#10273;</span>
        </div>
        <div class="column sw-col-63"><span>&#10270;</span><span>&#10310;</span><span>&#10375;</span><span>&#10333;</span><span>&#10482;</span><span>&#10266;</span><span>&#10416;</span><span>&#10366;</span><span>&#10295;</span><span>&#10434;</span><span>&#10467;</span><span>&#10492;</span><span>&#10280;</span><span>&#10412;</span><span>&#10474;</span><span>&#10420;</span><span>&#10243;</span><span>&#10365;</span><span>&#10317;</span><span>&#10405;</span><span>&#10418;</span><span>&#10464;</span><span>&#10389;</span><span>&#10383;</span><span>&#10358;</span><span>&#10286;</span><span>&#10317;</span><span>&#10370;</span><span>&#10407;</span><span>&#10304;</span><span>&#10440;</span><span>&#10410;</span><span>&#10380;</span><span>&#10315;</span><span>&#10372;</span><span>&#10349;</span><span>&#10493;</span><span>&#10488;</span><span>&#10492;</span><span>&#10367;</span><span>&#10359;</span><span>&#10299;</span><span>&#10477;</span><span>&#10253;</span><span>&#10355;</span><span>&#10428;</span><span>&#10307;</span><span>&#10324;</span><span>&#10347;</span><span>&#10297;</span>
        </div>
        <div class="column sw-col-64"><span>&#10338;</span><span>&#10416;</span><span>&#10386;</span><span>&#10354;</span><span>&#10381;</span><span>&#10463;</span><span>&#10455;</span><span>&#10340;</span><span>&#10491;</span><span>&#10350;</span><span>&#10469;</span><span>&#10320;</span><span>&#10251;</span><span>&#10240;</span><span>&#10261;</span><span>&#10488;</span><span>&#10325;</span><span>&#10287;</span><span>&#10422;</span><span>&#10481;</span><span>&#10405;</span><span>&#10481;</span><span>&#10339;</span><span>&#10365;</span><span>&#10314;</span><span>&#10251;</span><span>&#10289;</span><span>&#10368;</span><span>&#10310;</span><span>&#10349;</span><span>&#10254;</span><span>&#10461;</span><span>&#10355;</span><span>&#10367;</span><span>&#10308;</span><span>&#10247;</span><span>&#10289;</span><span>&#10299;</span><span>&#10423;</span><span>&#10362;</span><span>&#10378;</span><span>&#10416;</span><span>&#10342;</span><span>&#10308;</span><span>&#10414;</span><span>&#10414;</span><span>&#10418;</span><span>&#10427;</span><span>&#10252;</span><span>&#10266;</span>
        </div>
        <div class="column sw-col-65"><span>&#10372;</span><span>&#10290;</span><span>&#10358;</span><span>&#10386;</span><span>&#10300;</span><span>&#10275;</span><span>&#10343;</span><span>&#10351;</span><span>&#10485;</span><span>&#10321;</span><span>&#10335;</span><span>&#10381;</span><span>&#10474;</span><span>&#10304;</span><span>&#10482;</span><span>&#10377;</span><span>&#10342;</span><span>&#10270;</span><span>&#10451;</span><span>&#10416;</span><span>&#10294;</span><span>&#10387;</span><span>&#10489;</span><span>&#10326;</span><span>&#10421;</span><span>&#10320;</span><span>&#10376;</span><span>&#10315;</span><span>&#10493;</span><span>&#10450;</span><span>&#10460;</span><span>&#10450;</span><span>&#10397;</span><span>&#10272;</span><span>&#10398;</span><span>&#10417;</span><span>&#10398;</span><span>&#10431;</span><span>&#10338;</span><span>&#10440;</span><span>&#10427;</span><span>&#10399;</span><span>&#10245;</span><span>&#10279;</span><span>&#10295;</span><span>&#10294;</span><span>&#10268;</span><span>&#10322;</span><span>&#10396;</span><span>&#10377;</span>
        </div>
        <div class="column sw-col-66"><span>&#10259;</span><span>&#10242;</span><span>&#10368;</span><span>&#10408;</span><span>&#10311;</span><span>&#10311;</span><span>&#10324;</span><span>&#10377;</span><span>&#10333;</span><span>&#10268;</span><span>&#10274;</span><span>&#10462;</span><span>&#10320;</span><span>&#10303;</span><span>&#10336;</span><span>&#10283;</span><span>&#10309;</span><span>&#10468;</span><span>&#10348;</span><span>&#10399;</span><span>&#10371;</span><span>&#10469;</span><span>&#10445;</span><span>&#10321;</span><span>&#10257;</span><span>&#10447;</span><span>&#10414;</span><span>&#10274;</span><span>&#10492;</span><span>&#10302;</span><span>&#10430;</span><span>&#10347;</span><span>&#10384;</span><span>&#10347;</span><span>&#10487;</span><span>&#10430;</span><span>&#10373;</span><span>&#10270;</span><span>&#10467;</span><span>&#10278;</span><span>&#10457;</span><span>&#10287;</span><span>&#10321;</span><span>&#10299;</span><span>&#10314;</span><span>&#10280;</span><span>&#10440;</span><span>&#10313;</span><span>&#10372;</span><span>&#10483;</span>
        </div>
        <div class="column sw-col-67"><span>&#10256;</span><span>&#10371;</span><span>&#10471;</span><span>&#10453;</span><span>&#10460;</span><span>&#10442;</span><span>&#10327;</span><span>&#10292;</span><span>&#10468;</span><span>&#10458;</span><span>&#10445;</span><span>&#10342;</span><span>&#10341;</span><span>&#10367;</span><span>&#10269;</span><span>&#10451;</span><span>&#10311;</span><span>&#10298;</span><span>&#10409;</span><span>&#10444;</span><span>&#10457;</span><span>&#10245;</span><span>&#10372;</span><span>&#10381;</span><span>&#10487;</span><span>&#10444;</span><span>&#10284;</span><span>&#10357;</span><span>&#10342;</span><span>&#10477;</span><span>&#10440;</span><span>&#10429;</span><span>&#10416;</span><span>&#10407;</span><span>&#10381;</span><span>&#10314;</span><span>&#10420;</span><span>&#10247;</span><span>&#10415;</span><span>&#10307;</span><span>&#10281;</span><span>&#10347;</span><span>&#10426;</span><span>&#10383;</span><span>&#10404;</span><span>&#10316;</span><span>&#10404;</span><span>&#10333;</span><span>&#10486;</span><span>&#10342;</span>
        </div>
        <div class="column sw-col-68"><span>&#10426;</span><span>&#10352;</span><span>&#10438;</span><span>&#10431;</span><span>&#10286;</span><span>&#10277;</span><span>&#10437;</span><span>&#10419;</span><span>&#10370;</span><span>&#10269;</span><span>&#10401;</span><span>&#10406;</span><span>&#10422;</span><span>&#10254;</span><span>&#10286;</span><span>&#10305;</span><span>&#10423;</span><span>&#10245;</span><span>&#10267;</span><span>&#10330;</span><span>&#10241;</span><span>&#10269;</span><span>&#10305;</span><span>&#10284;</span><span>&#10310;</span><span>&#10382;</span><span>&#10418;</span><span>&#10389;</span><span>&#10385;</span><span>&#10261;</span><span>&#10362;</span><span>&#10267;</span><span>&#10416;</span><span>&#10309;</span><span>&#10387;</span><span>&#10288;</span><span>&#10342;</span><span>&#10477;</span><span>&#10474;</span><span>&#10292;</span><span>&#10367;</span><span>&#10360;</span><span>&#10399;</span><span>&#10388;</span><span>&#10381;</span><span>&#10374;</span><span>&#10426;</span><span>&#10257;</span><span>&#10372;</span><span>&#10438;</span>
        </div>
        <div class="column sw-col-69"><span>&#10245;</span><span>&#10417;</span><span>&#10475;</span><span>&#10346;</span><span>&#10443;</span><span>&#10372;</span><span>&#10403;</span><span>&#10493;</span><span>&#10295;</span><span>&#10354;</span><span>&#10431;</span><span>&#10297;</span><span>&#10368;</span><span>&#10484;</span><span>&#10449;</span><span>&#10375;</span><span>&#10491;</span><span>&#10261;</span><span>&#10333;</span><span>&#10452;</span><span>&#10339;</span><span>&#10245;</span><span>&#10328;</span><span>&#10303;</span><span>&#10429;</span><span>&#10307;</span><span>&#10245;</span><span>&#10267;</span><span>&#10297;</span><span>&#10323;</span><span>&#10401;</span><span>&#10378;</span><span>&#10476;</span><span>&#10339;</span><span>&#10445;</span><span>&#10449;</span><span>&#10473;</span><span>&#10405;</span><span>&#10367;</span><span>&#10311;</span><span>&#10409;</span><span>&#10381;</span><span>&#10348;</span><span>&#10314;</span><span>&#10334;</span><span>&#10424;</span><span>&#10478;</span><span>&#10326;</span><span>&#10488;</span><span>&#10249;</span>
        </div>
        <div class="column sw-col-70"><span>&#10307;</span><span>&#10464;</span><span>&#10410;</span><span>&#10368;</span><span>&#10376;</span><span>&#10249;</span><span>&#10475;</span><span>&#10316;</span><span>&#10455;</span><span>&#10353;</span><span>&#10371;</span><span>&#10328;</span><span>&#10258;</span><span>&#10323;</span><span>&#10414;</span><span>&#10310;</span><span>&#10263;</span><span>&#10382;</span><span>&#10393;</span><span>&#10365;</span><span>&#10243;</span><span>&#10321;</span><span>&#10373;</span><span>&#10484;</span><span>&#10357;</span><span>&#10461;</span><span>&#10474;</span><span>&#10293;</span><span>&#10367;</span><span>&#10362;</span><span>&#10345;</span><span>&#10257;</span><span>&#10318;</span><span>&#10321;</span><span>&#10281;</span><span>&#10383;</span><span>&#10336;</span><span>&#10257;</span><span>&#10253;</span><span>&#10283;</span><span>&#10353;</span><span>&#10481;</span><span>&#10387;</span><span>&#10367;</span><span>&#10357;</span><span>&#10484;</span><span>&#10423;</span><span>&#10376;</span><span>&#10310;</span><span>&#10330;</span>
        </div>
        <div class="column sw-col-71"><span>&#10305;</span><span>&#10285;</span><span>&#10415;</span><span>&#10337;</span><span>&#10280;</span><span>&#10402;</span><span>&#10317;</span><span>&#10363;</span><span>&#10493;</span><span>&#10290;</span><span>&#10429;</span><span>&#10274;</span><span>&#10455;</span><span>&#10282;</span><span>&#10384;</span><span>&#10338;</span><span>&#10317;</span><span>&#10337;</span><span>&#10306;</span><span>&#10490;</span><span>&#10492;</span><span>&#10248;</span><span>&#10345;</span><span>&#10335;</span><span>&#10434;</span><span>&#10302;</span><span>&#10437;</span><span>&#10474;</span><span>&#10385;</span><span>&#10394;</span><span>&#10348;</span><span>&#10247;</span><span>&#10293;</span><span>&#10458;</span><span>&#10279;</span><span>&#10241;</span><span>&#10291;</span><span>&#10376;</span><span>&#10464;</span><span>&#10300;</span><span>&#10383;</span><span>&#10339;</span><span>&#10246;</span><span>&#10243;</span><span>&#10267;</span><span>&#10281;</span><span>&#10259;</span><span>&#10321;</span><span>&#10244;</span><span>&#10342;</span>
        </div>
        <div class="column sw-col-72"><span>&#10385;</span><span>&#10435;</span><span>&#10379;</span><span>&#10301;</span><span>&#10377;</span><span>&#10394;</span><span>&#10280;</span><span>&#10281;</span><span>&#10316;</span><span>&#10414;</span><span>&#10384;</span><span>&#10442;</span><span>&#10406;</span><span>&#10273;</span><span>&#10466;</span><span>&#10461;</span><span>&#10374;</span><span>&#10293;</span><span>&#10318;</span><span>&#10489;</span><span>&#10297;</span><span>&#10267;</span><span>&#10415;</span><span>&#10407;</span><span>&#10330;</span><span>&#10413;</span><span>&#10255;</span><span>&#10412;</span><span>&#10443;</span><span>&#10281;</span><span>&#10411;</span><span>&#10240;</span><span>&#10432;</span><span>&#10348;</span><span>&#10403;</span><span>&#10366;</span><span>&#10284;</span><span>&#10309;</span><span>&#10351;</span><span>&#10462;</span><span>&#10433;</span><span>&#10395;</span><span>&#10426;</span><span>&#10464;</span><span>&#10490;</span><span>&#10241;</span><span>&#10302;</span><span>&#10303;</span><span>&#10352;</span><span>&#10363;</span>
        </div>
        <div class="column sw-col-73"><span>&#10405;</span><span>&#10313;</span><span>&#10477;</span><span>&#10244;</span><span>&#10269;</span><span>&#10417;</span><span>&#10392;</span><span>&#10410;</span><span>&#10323;</span><span>&#10491;</span><span>&#10331;</span><span>&#10477;</span><span>&#10335;</span><span>&#10307;</span><span>&#10452;</span><span>&#10421;</span><span>&#10388;</span><span>&#10411;</span><span>&#10291;</span><span>&#10404;</span><span>&#10265;</span><span>&#10431;</span><span>&#10300;</span><span>&#10361;</span><span>&#10472;</span><span>&#10366;</span><span>&#10437;</span><span>&#10403;</span><span>&#10250;</span><span>&#10343;</span><span>&#10478;</span><span>&#10442;</span><span>&#10383;</span><span>&#10477;</span><span>&#10304;</span><span>&#10413;</span><span>&#10480;</span><span>&#10375;</span><span>&#10430;</span><span>&#10293;</span><span>&#10477;</span><span>&#10325;</span><span>&#10392;</span><span>&#10481;</span><span>&#10471;</span><span>&#10342;</span><span>&#10353;</span><span>&#10377;</span><span>&#10459;</span><span>&#10321;</span>
        </div>
        <div class="column sw-col-74"><span>&#10329;</span><span>&#10368;</span><span>&#10329;</span><span>&#10322;</span><span>&#10408;</span><span>&#10315;</span><span>&#10296;</span><span>&#10296;</span><span>&#10433;</span><span>&#10445;</span><span>&#10437;</span><span>&#10267;</span><span>&#10284;</span><span>&#10453;</span><span>&#10313;</span><span>&#10319;</span><span>&#10432;</span><span>&#10459;</span><span>&#10437;</span><span>&#10369;</span><span>&#10364;</span><span>&#10297;</span><span>&#10387;</span><span>&#10447;</span><span>&#10349;</span><span>&#10426;</span><span>&#10324;</span><span>&#10325;</span><span>&#10441;</span><span>&#10282;</span><span>&#10327;</span><span>&#10435;</span><span>&#10425;</span><span>&#10363;</span><span>&#10313;</span><span>&#10352;</span><span>&#10286;</span><span>&#10264;</span><span>&#10245;</span><span>&#10301;</span><span>&#10352;</span><span>&#10463;</span><span>&#10447;</span><span>&#10244;</span><span>&#10381;</span><span>&#10325;</span><span>&#10313;</span><span>&#10252;</span><span>&#10259;</span><span>&#10386;</span>
        </div>
        <div class="column sw-col-75"><span>&#10475;</span><span>&#10336;</span><span>&#10333;</span><span>&#10299;</span><span>&#10350;</span><span>&#10381;</span><span>&#10255;</span><span>&#10339;</span><span>&#10442;</span><span>&#10340;</span><span>&#10284;</span><span>&#10460;</span><span>&#10317;</span><span>&#10478;</span><span>&#10456;</span><span>&#10292;</span><span>&#10488;</span><span>&#10281;</span><span>&#10415;</span><span>&#10480;</span><span>&#10349;</span><span>&#10299;</span><span>&#10302;</span><span>&#10465;</span><span>&#10454;</span><span>&#10447;</span><span>&#10361;</span><span>&#10476;</span><span>&#10287;</span><span>&#10410;</span><span>&#10312;</span><span>&#10358;</span><span>&#10332;</span><span>&#10434;</span><span>&#10389;</span><span>&#10335;</span><span>&#10340;</span><span>&#10277;</span><span>&#10263;</span><span>&#10386;</span><span>&#10360;</span><span>&#10464;</span><span>&#10262;</span><span>&#10485;</span><span>&#10265;</span><span>&#10344;</span><span>&#10243;</span><span>&#10275;</span><span>&#10467;</span><span>&#10411;</span>
        </div>
        <div class="column sw-col-76"><span>&#10331;</span><span>&#10270;</span><span>&#10293;</span><span>&#10289;</span><span>&#10405;</span><span>&#10356;</span><span>&#10426;</span><span>&#10409;</span><span>&#10241;</span><span>&#10293;</span><span>&#10453;</span><span>&#10426;</span><span>&#10317;</span><span>&#10318;</span><span>&#10357;</span><span>&#10299;</span><span>&#10431;</span><span>&#10355;</span><span>&#10371;</span><span>&#10454;</span><span>&#10436;</span><span>&#10377;</span><span>&#10445;</span><span>&#10246;</span><span>&#10399;</span><span>&#10363;</span><span>&#10403;</span><span>&#10314;</span><span>&#10440;</span><span>&#10483;</span><span>&#10314;</span><span>&#10373;</span><span>&#10290;</span><span>&#10338;</span><span>&#10460;</span><span>&#10334;</span><span>&#10368;</span><span>&#10391;</span><span>&#10330;</span><span>&#10307;</span><span>&#10315;</span><span>&#10357;</span><span>&#10380;</span><span>&#10402;</span><span>&#10369;</span><span>&#10483;</span><span>&#10412;</span><span>&#10393;</span><span>&#10371;</span><span>&#10314;</span>
        </div>
        <div class="column sw-col-77"><span>&#10247;</span><span>&#10427;</span><span>&#10363;</span><span>&#10265;</span><span>&#10455;</span><span>&#10494;</span><span>&#10278;</span><span>&#10415;</span><span>&#10272;</span><span>&#10411;</span><span>&#10444;</span><span>&#10489;</span><span>&#10336;</span><span>&#10294;</span><span>&#10273;</span><span>&#10416;</span><span>&#10318;</span><span>&#10384;</span><span>&#10274;</span><span>&#10286;</span><span>&#10293;</span><span>&#10333;</span><span>&#10458;</span><span>&#10403;</span><span>&#10420;</span><span>&#10379;</span><span>&#10261;</span><span>&#10285;</span><span>&#10330;</span><span>&#10427;</span><span>&#10341;</span><span>&#10392;</span><span>&#10407;</span><span>&#10425;</span><span>&#10272;</span><span>&#10339;</span><span>&#10353;</span><span>&#10483;</span><span>&#10462;</span><span>&#10253;</span><span>&#10294;</span><span>&#10433;</span><span>&#10459;</span><span>&#10295;</span><span>&#10427;</span><span>&#10353;</span><span>&#10485;</span><span>&#10453;</span><span>&#10422;</span><span>&#10268;</span>
        </div>
        <div class="column sw-col-78"><span>&#10303;</span><span>&#10252;</span><span>&#10347;</span><span>&#10438;</span><span>&#10433;</span><span>&#10419;</span><span>&#10426;</span><span>&#10256;</span><span>&#10412;</span><span>&#10359;</span><span>&#10419;</span><span>&#10248;</span><span>&#10400;</span><span>&#10403;</span><span>&#10385;</span><span>&#10412;</span><span>&#10406;</span><span>&#10402;</span><span>&#10434;</span><span>&#10356;</span><span>&#10474;</span><span>&#10493;</span><span>&#10301;</span><span>&#10252;</span><span>&#10471;</span><span>&#10271;</span><span>&#10317;</span><span>&#10304;</span><span>&#10243;</span><span>&#10320;</span><span>&#10399;</span><span>&#10483;</span><span>&#10485;</span><span>&#10482;</span><span>&#10297;</span><span>&#10481;</span><span>&#10378;</span><span>&#10426;</span><span>&#10246;</span><span>&#10468;</span><span>&#10442;</span><span>&#10254;</span><span>&#10272;</span><span>&#10265;</span><span>&#10465;</span><span>&#10441;</span><span>&#10436;</span><span>&#10259;</span><span>&#10324;</span><span>&#10298;</span>
        </div>
        <div class="column sw-col-79"><span>&#10412;</span><span>&#10315;</span><span>&#10275;</span><span>&#10459;</span><span>&#10318;</span><span>&#10296;</span><span>&#10372;</span><span>&#10452;</span><span>&#10383;</span><span>&#10275;</span><span>&#10345;</span><span>&#10274;</span><span>&#10434;</span><span>&#10261;</span><span>&#10264;</span><span>&#10443;</span><span>&#10369;</span><span>&#10356;</span><span>&#10455;</span><span>&#10430;</span><span>&#10321;</span><span>&#10298;</span><span>&#10449;</span><span>&#10482;</span><span>&#10463;</span><span>&#10306;</span><span>&#10397;</span><span>&#10348;</span><span>&#10377;</span><span>&#10274;</span><span>&#10354;</span><span>&#10311;</span><span>&#10385;</span><span>&#10268;</span><span>&#10480;</span><span>&#10325;</span><span>&#10247;</span><span>&#10438;</span><span>&#10460;</span><span>&#10474;</span><span>&#10361;</span><span>&#10355;</span><span>&#10379;</span><span>&#10392;</span><span>&#10246;</span><span>&#10370;</span><span>&#10459;</span><span>&#10475;</span><span>&#10421;</span><span>&#10329;</span>
        </div>
        <div class="column sw-col-80"><span>&#10273;</span><span>&#10250;</span><span>&#10282;</span><span>&#10320;</span><span>&#10391;</span><span>&#10408;</span><span>&#10402;</span><span>&#10460;</span><span>&#10306;</span><span>&#10363;</span><span>&#10288;</span><span>&#10343;</span><span>&#10296;</span><span>&#10243;</span><span>&#10425;</span><span>&#10344;</span><span>&#10493;</span><span>&#10483;</span><span>&#10391;</span><span>&#10408;</span><span>&#10341;</span><span>&#10431;</span><span>&#10354;</span><span>&#10315;</span><span>&#10444;</span><span>&#10480;</span><span>&#10325;</span><span>&#10454;</span><span>&#10401;</span><span>&#10312;</span><span>&#10292;</span><span>&#10456;</span><span>&#10278;</span><span>&#10312;</span><span>&#10470;</span><span>&#10493;</span><span>&#10247;</span><span>&#10247;</span><span>&#10385;</span><span>&#10252;</span><span>&#10419;</span><span>&#10389;</span><span>&#10437;</span><span>&#10420;</span><span>&#10407;</span><span>&#10439;</span><span>&#10340;</span><span>&#10395;</span><span>&#10476;</span><span>&#10268;</span>
        </div>
        <div class="column sw-col-81"><span>&#10303;</span><span>&#10364;</span><span>&#10384;</span><span>&#10379;</span><span>&#10476;</span><span>&#10360;</span><span>&#10256;</span><span>&#10242;</span><span>&#10406;</span><span>&#10319;</span><span>&#10363;</span><span>&#10395;</span><span>&#10261;</span><span>&#10427;</span><span>&#10399;</span><span>&#10448;</span><span>&#10301;</span><span>&#10454;</span><span>&#10340;</span><span>&#10369;</span><span>&#10477;</span><span>&#10321;</span><span>&#10358;</span><span>&#10331;</span><span>&#10375;</span><span>&#10341;</span><span>&#10428;</span><span>&#10291;</span><span>&#10336;</span><span>&#10490;</span><span>&#10304;</span><span>&#10480;</span><span>&#10452;</span><span>&#10425;</span><span>&#10304;</span><span>&#10423;</span><span>&#10422;</span><span>&#10343;</span><span>&#10307;</span><span>&#10426;</span><span>&#10301;</span><span>&#10252;</span><span>&#10313;</span><span>&#10406;</span><span>&#10312;</span><span>&#10386;</span><span>&#10458;</span><span>&#10488;</span><span>&#10474;</span><span>&#10284;</span>
        </div>
      </div>

    <div class="mobileView">
        <div class="mobileView-wrap">
            <div class="mobileView-content">
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
                <defs>
                    <linearGradient id="grad1" x1="100%" y1="100%" x2="0%" y2="60%">
                        <stop offset="40%" style="stop-color:#ff1ba6;stop-opacity:1"></stop>
                        <stop offset="100%" style="stop-color:#ed2a4a;stop-opacity:1"></stop>
                    </linearGradient>
                </defs>
                <text fill="url(#grad1)" font-size="60" x="0" y="100" style="
                font-weight: 700;
            ">Too small!</text>
                </svg>
                <h4 class="mb-3">MAKE YOUR BROWSER WINDOW WIDER</h4>
                <p> Please resize the browser window wider, you are not in mobile device. Switch to mobile device to see the mobile view.</p>
            </div>
        </div>
    </div>
</div>

<div  data-spy="scroll" data-target=".navbar" class="c-shotime-w" *ngIf="userName === 'zs'">
    <!-- START: Preloader -->
    <div id="preloader" class="preloader">
        <div class="spinner-grow text-light" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
    <!-- END: Preloader -->

    <header>
        <div class="container">
            <div class="d-flex justify-content-between align-items-center">
                <a href="showtime-profile;_user=zs#HOME" class="header-logo" >Zach Smith</a>

                <div class="menu d-flex justify-content-center align-items-center">

                    <!-- <a href="#CONTACT" class="head-contact btn btn-small btn-gradient mr-4">Contact</a> -->

                    <button class="btn btn-small collapsed navbar-toggler" data-toggle="collapse" data-target="#" aria-controls="" aria-expanded="false" aria-label="Toggle navigation"> 
                        <svg class="ham hamRotate ham1" viewBox="0 0 100 100" width="40"><path class="line top" d="m 30,33 h 40 c 0,0 9.044436,-0.654587 9.044436,-8.508902 0,-7.854315 -8.024349,-11.958003 -14.89975,-10.85914 -6.875401,1.098863 -13.637059,4.171617 -13.637059,16.368042 v 40"></path><path class="line middle" d="m 30,50 h 40"></path><path class="line bottom" d="m 30,67 h 40 c 12.796276,0 15.357889,-11.717785 15.357889,-26.851538 0,-15.133752 -4.786586,-27.274118 -16.667516,-27.274118 -11.88093,0 -18.499247,6.994427 -18.435284,17.125656 l 0.252538,40"></path></svg>
                    </button>

                    <div class="navbar-collapse collapse" id="">
                        <div class="container">
                            <div class="navbar-content">
                                <div class="navbar-links h-100vh d-flex align-items-center">
                                    <ul>
                                        <li>
                                            <span><i class="lni lni-cloud"></i></span>
                                            <a href="showtime-profile;_user=zs#HIGHLIGHTS">Highlights</a>
                                        </li>
                                        <li>
                                            <span><i class="lni lni-book"></i></span>
                                            <a href="showtime-profile;_user=zs#ABOUT">ABOUT</a>
                                        </li>			
                                        <li>
                                            <span><i class="lni lni-offer"></i></span>
                                            <a href="showtime-profile;_user=zs#OFFERS">OFFERS</a>
                                        </li>
                                        <li>
                                            <span><i class="lni lni-bar-chart"></i></span>
                                            <a href="showtime-profile;_user=zs#STATS">STATS</a>
                                        </li>                                        
                                    </ul>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>	
            </div>
        </div>
    </header>

    <div id="pagepiling">

        <!-- START: Home -->
        <div class="section section-home" id="HOME">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-md-7 col-12">
                        <div class="hero-content text-center">
                            <span class="hello mb-4 d-block"></span>
                            <h1><span>Zach Smith</span></h1>

                            <div id="autoplay" class="my-4">
                            <span>TE 2024</span>
                            <span>6'7", 250lbs, 84, 4.9</span>
                            <span>Highlands Ranch, CO</span>
                            </div>   

                        </div>
                    </div>
                    <div class="col-md-5 col-12">
                        <div class="rigtBox">
                            <img src="assets/images/zach-smith.png" alt="player-profile" title="player-profile" class="img-fluid">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- END: Home -->

        <!-- START: PORTFOLIO -->
        <div class="section" id="PORTFOLIO">
            <div class="container">
                <div class="container swiper-container swiper-portfolio wow fadeInUp mt-5 pt-5" data-wow-duration="1.5s">
                    <div class="swiper-wrapper">
                        <div class="portfolio-item swiper-slide " data-groups="all slideshow">
                            <a href="https://static.hudl.com/users/prod/11589817_41ebc2e257084d5897e6382dd7e85046.jpg" class="portfolio-item--content simple-ajax-popup">
                                <div class="portfolio-item--img b-box">
                                    <img src="https://static.hudl.com/users/prod/11589817_41ebc2e257084d5897e6382dd7e85046.jpg" class="img-fluid mx-auto" alt="portfolio" title="portfolio">
                                </div>
                            </a>
                        </div>

                        <div class="portfolio-item swiper-slide image" data-groups="all image">
                            <a href="https://vd.hudl.com/p-highlights/Team/383321/602efd4d42071a06f8f43c5d/e7065201_720.mp4?v=F97CE81968D4D808" class="portfolio-item--content simple-ajax-video-popup">
                                <div class="portfolio-item--img b-box">
                                    <!-- <img src="assets/images/ytv2.mp4" class="img-fluid mx-auto" alt="portfolio" title="portfolio"> -->
                                    <video width="100%" height="400" controls="false" autoplay="flase" muted loop>
                                        <source src="https://vd.hudl.com/p-highlights/Team/383321/602efd4d42071a06f8f43c5d/e7065201_720.mp4?v=F97CE81968D4D808" type="video/mp4">
                                    </video>
                                </div>
                            </a>
                        </div>

                        <div class="portfolio-item swiper-slide slideshow" data-groups="all slideshow">
                            <a href="https://vd.hudl.com/p-highlights/Team/383321/603f6d47b3d29c135c565bee/1fbad3be_720.mp4?v=8F7A78754EDED808" class="portfolio-item--content simple-ajax-video-popup">
                                <div class="portfolio-item--img b-box">
                                    <!-- <img src="assets/images/p1-1.jpg" class="img-fluid mx-auto" alt="portfolio" title="portfolio"> -->
                                    <video width="100%" height="400" controls="false" autoplay="autoplay" muted loop>
                                        <source src="https://vd.hudl.com/p-highlights/Team/383321/603f6d47b3d29c135c565bee/1fbad3be_720.mp4?v=8F7A78754EDED808" type="video/mp4">
                                    </video>
                                </div>
                            </a>
                        </div>

                        <div class="portfolio-item swiper-slide video" data-groups="all video">
                            <a href="https://vd.hudl.com/p-highlights/Team/383321/604368a50dca9c15dc570fa9/a77b9e22_720.mp4?v=93AA0AF9C6E0D808" class="portfolio-item--content simple-ajax-video-popup">
                                <div class="portfolio-item--img b-box">
                                    <!-- <img src="assets/images/ytv2.mp4" class="img-fluid mx-auto" alt="portfolio" title="portfolio"> -->
                                    <video width="100%" height="400" controls="false" autoplay="autoplay" muted loop>
                                        <source src="https://vd.hudl.com/p-highlights/Team/383321/604368a50dca9c15dc570fa9/a77b9e22_720.mp4?v=93AA0AF9C6E0D808" type="video/mp4">
                                    </video>
                                </div>
                            </a>
                        </div>

                        <div class="portfolio-item swiper-slide  slideshow" data-groups="all slideshow">
                            <a href="https://pbs.twimg.com/media/FriTgLuaEAEZYIC?format=jpg&name=large" class="portfolio-item--content simple-ajax-popup">
                                <div class="portfolio-item--img b-box">
                                    <img src="https://pbs.twimg.com/media/FriTgLuaEAEZYIC?format=jpg&name=large" class="img-fluid mx-auto" alt="portfolio" title="portfolio">
                                </div>
                            </a>
                        </div>

                    </div>
                    <div class="portfolio-pagination">
                        
                    </div>
                </div>
            </div>
        </div>
        <!-- END: PORTFOLIO -->

        <!-- START: ABOUT -->
        <div class="section" id="ABT">
            <div class="container">

                <div class="row justify-content-center align-items-center">
                    <div class="col-md-5">
                        <img class="about-img about-showtime-profile img-fluid wow fadeInUp" data-wow-duration="1s" src="https://static.hudl.com/users/prod/18938738_90883ef2c5ca4d77bbb907d52de1b11d.jpg" alt="about-picture" title="about-picture">
                    </div>
                    <div class="col-md-7">

                        <div class="about-desc wow fadeInUp" data-wow-duration="1s"  data-wow-delay="500ms">
                            <div class="about-desc-content">
                                <!-- <h1 class="font-weight-light mb-5">DJ Bordeaux</h1> -->
                                <!-- <p class="font-weight-light my-3">Zach Smith athletic achievements -
                                    <li>All Conference Honorable Mention Varsity Basketball 2022</li>
                                    <li>All Star Basketball team 2022</li>
                                    <li>Newcomer (Rookie) of the Year 2021 Varsity Football - Highlands Ranch, CO</li></p> -->
                                    <p>Working hard every day to be better than the last one and live up to all that God made me to be, as a man, student & athlete!</p>
                            </div>

                            <div class="about-desc-more text-left mt-5">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="about-desc-info">
                                            <div class="about-desc-info-content d-flex align-items-center hover-state b-box">
                                                <div class="about-desc-icon">
                                                    <i class="lni lni-calendar"></i>
                                                </div>
                                                <div class="about-desc-info-desc">
                                                    <span>Junior • 2024</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="about-desc-info">
                                            <div class="about-desc-info-content d-flex align-items-center hover-state b-box">
                                                <div class="about-desc-icon">
                                                    <i class="lni lni-pin"></i>
                                                </div>
                                                <div class="about-desc-info-desc">
                                                    <span>Highlands Ranch, CO</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="about-desc-info">
                                            <div class="about-desc-info-content d-flex align-items-center hover-state b-box">
                                                <div class="about-desc-icon">
                                                    <i class="lni lni-graduation"></i>
                                                </div>
                                                <div class="about-desc-info-desc">
                                                    <span>Thunderridge High School</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="about-desc-info">
                                            <div class="about-desc-info-content d-flex align-items-center hover-state b-box">
                                                <div class="about-desc-icon">
                                                    <i class="lni lni-twitter"></i>
                                                </div>
                                                <div class="about-desc-info-desc">
                                                    <a href="https://twitter.com/Zachari68893966?s=08" title="https://twitter.com/Zachari68893966?s=08" target="_blank">https://twitter.com/Zachari68893966?s=08</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>			
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- END: ABOUT -->

        <!-- START: TESTIMONY -->
        <div class="section feed-section" id="TESTIMONY">
            <div class="container">
                <div class="row">
                    <div class="col-md-4 col-12">
                        <blockquote class="twitter-tweet">
                            <p lang="en" dir="ltr">After a great conversation with <a
                                    href="https://twitter.com/CoachJ_Ortiz?ref_src=twsrc%5Etfw">@CoachJ_Ortiz</a> I’m blessed to say I’ve
                                received my 6th d1 offer from the University of Nevada! Huge thanks to <a
                                    href="https://twitter.com/CoachDSage?ref_src=twsrc%5Etfw">@CoachDSage</a> and the rest of the staff for the
                                opportunity! <a href="https://twitter.com/TR_GrizzliesFB?ref_src=twsrc%5Etfw">@TR_GrizzliesFB</a> <a
                                    href="https://twitter.com/SamCampfield?ref_src=twsrc%5Etfw">@SamCampfield</a> <a
                                    href="https://t.co/qXTWXy5AF6">pic.twitter.com/qXTWXy5AF6</a></p>&mdash; Zach Smith (@Zachari68893966) <a
                                href="https://twitter.com/Zachari68893966/status/1653913883344510976?ref_src=twsrc%5Etfw">May 4, 2023</a>
                        </blockquote>
                    </div>
                    <div class="col-md-4 col-12">
                        <blockquote class="twitter-tweet"><p lang="en" dir="ltr">Huge thanks to <a href="https://twitter.com/KU_Football?ref_src=twsrc%5Etfw">@KU_Football</a> for having me out for an amazing visit! <a href="https://t.co/gW86BLgfIa">pic.twitter.com/gW86BLgfIa</a></p>&mdash; Zach Smith (@Zachari68893966) <a href="https://twitter.com/Zachari68893966/status/1632250580570611712?ref_src=twsrc%5Etfw">March 5, 2023</a></blockquote>                      
                    </div>
                    <div class="col-md-4 col-12">
                        <blockquote class="twitter-tweet"><p lang="en" dir="ltr">Blessed to receive my 4th D1 offer from the university of Memphis! Huge thanks to <a href="https://twitter.com/CoachBradSalem?ref_src=twsrc%5Etfw">@CoachBradSalem</a> and the rest of the staff for the opportunity!<a href="https://twitter.com/TR_GrizzliesFB?ref_src=twsrc%5Etfw">@TR_GrizzliesFB</a> <a href="https://twitter.com/SamCampfield?ref_src=twsrc%5Etfw">@SamCampfield</a> <a href="https://twitter.com/ThunderRidge_AD?ref_src=twsrc%5Etfw">@ThunderRidge_AD</a> <a href="https://t.co/OtWSTClNw9">pic.twitter.com/OtWSTClNw9</a></p>&mdash; Zach Smith (@Zachari68893966) <a href="https://twitter.com/Zachari68893966/status/1647329153086005248?ref_src=twsrc%5Etfw">April 15, 2023</a></blockquote>
                    </div>
                </div>
            </div>
        </div>
        <!-- END: TESTIMONY -->

         <!-- START: SERVICES -->
        <div class="section" id="SERVICES">
            <div class="container">
                <!-- <div class="section-head text-center">
                    <span>WHAT I DO</span>
                    <h2>My Services</h2>
                </div> -->

                <div class="row mt-4 justify-content-center align-content-center">
                    <div class="col-md-8 col-12">
                        <div class="row">
                            <div class="col-sm-6 col-lg-6">
                                <div class="services-list mt-4 wow fadeInUp" data-wow-duration="1.5s">
                                    <div class="services-list--content d-flex align-items-center b-box hover-state p-4">
                                        <!-- <div class="services-list-icon">
                                            <i class="lni-color-pallet"></i>
                                            <span>01</span>
                                        </div> -->
                                        <div class="services-list-desc">
                                            <!-- <h5 class="mb-0">Graphic Design</h5> -->
                                            <img src="assets/images/zack-offer_1.png" alt="offer" title="offer" class="img-fluid">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 col-lg-6">
                                <div class="services-list mt-4 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay=".2s">
                                    <div class="services-list--content d-flex align-items-center b-box hover-state p-4">
                                        <!-- <div class="services-list-icon">
                                            <i class="lni-laptop-phone"></i>
                                            <span>02</span>
                                        </div> -->
                                        <div class="services-list-desc">
                                            <!-- <h5 class="mb-0">Web Development</h5> -->
                                            <img src="assets/images/zack-offer1.png" alt="offer" title="offer" class="img-fluid">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 col-lg-6">
                                <div class="services-list mt-4 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay=".3s">
                                    <div class="services-list--content d-flex align-items-center b-box hover-state p-4">
                                        <!-- <div class="services-list-icon">
                                            <i class="lni-video"></i>
                                            <span>03</span>
                                        </div> -->
                                        <div class="services-list-desc">
                                            <!-- <h5 class="mb-0">Media Marketing</h5> -->
                                            <img src="assets/images/zack-offer2.png" alt="offer" title="offer" class="img-fluid">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 col-lg-6">
                                <div class="services-list mt-4 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay=".4s">
                                    <div class="services-list--content d-flex align-items-center b-box hover-state p-4">
                                        <!-- <div class="services-list-icon">
                                            <i class="lni-network"></i>
                                            <span>04</span>
                                        </div> -->
                                        <div class="services-list-desc">
                                            <!-- <h5 class="mb-0">Advetising</h5> -->
                                            <img src="assets/images/zack-offer3.png" alt="offer" title="offer" class="img-fluid">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- END: SERVICES -->

        <!-- START: PRICE -->
        <div class="section" id="PRICE">
            <!-- <div class="section-head text-center">
                <span>WHAT I DO</span>
                <h2>My Pricing</h2>
            </div> -->
            <div class="section-skills-wrap">
                <div class="container">
                    <div class="row ">
                        <div class="col-md-12">
                            <div class="resume-list statslists b-box p-4 mb-4">
                                <div>
                                    <h5>RUSHING <span class="badge badge-light d-inline-block my-3">2022-2023</span></h5>
                                    <h4 class="d-flex justify-content-between align-items-center">
                                        <p>GD: Jr.</p>
                                        <p>TEAM: Var</p>
                                    </h4>
                                    <div class="yearly-stats statsrushing">
                                        <div class="stat">
                                            <div class="name">GP</div>
                                            <div class="value">13</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">CAR</div>
                                            <div class="value">1</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">YDS</div>
                                            <div class="value">14</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">AVG</div>
                                            <div class="value">14.0</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">Y/G</div>
                                            <div class="value">1.1</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">LNG</div>
                                            <div class="value">14</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">100+</div>
                                            <div class="value">0</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">TD</div>
                                            <div class="value">0</div>
                                        </div>
                                        <div class="gradient"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="resume-list statslists b-box p-4 mb-4">
                                <div>
                                    <h5>RECEIVING <span class="badge badge-light d-inline-block my-3">2022-2023</span></h5>
                                    <h4 class="d-flex justify-content-between align-items-center">
                                        <p>GD: Jr.</p>
                                        <p>TEAM: Var</p>
                                    </h4>
                                    <div class="yearly-stats statsrushing">
                                        <div class="stat">
                                            <div class="name">GP</div>
                                            <div class="value">13</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">REC</div>
                                            <div class="value">11</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">YDS</div>
                                            <div class="value">108</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">AVG</div>
                                            <div class="value">9.8</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">Y/G</div>
                                            <div class="value">8.3</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">LNG</div>
                                            <div class="value">20</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">TD</div>
                                            <div class="value">1</div>
                                        </div>
                                        <div class="gradient"></div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="col-md-6">
                            <div class="resume-list statslists b-box p-4 mb-4">
                                <div>
                                    <h5>TOTAL YARDS <span class="badge badge-light d-inline-block my-3">2022-2023</span></h5>
                                    <h4 class="d-flex justify-content-between align-items-center">
                                        <p>GD: Jr.</p>
                                        <p>TEAM: Var</p>
                                    </h4>
                                    <div class="yearly-stats">
                                        <div class="stat">
                                            <div class="name">GP</div>
                                            <div class="value">13</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">RUSH</div>
                                            <div class="value">14</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">PASS</div>
                                            <div class="value">0</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">REC</div>
                                            <div class="value">108</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">TOTAL</div>
                                            <div class="value">122</div>
                                        </div>
                                        <div class="stat bdrrgt">
                                            <div class="name">Y/G</div>
                                            <div class="value">9.4</div>
                                        </div>
                                        <div class="gradient"></div>
                                    </div>
                                </div>
                            </div>
                        </div>	
                    </div>
                </div>
            </div>
        </div>
        <!-- END: PRICE -->

        <!-- START: RESUME -->
        <!-- <div class="section" id="RESUME">
            <div class="container">
                <div class="row justify-content-center align-items-center">
                    <div class="col-md-3">

                        <ul class="nav nav-tabs">
                            <li class="nav-item">
                                <a class="nav-link active" data-toggle="tab" href="#tab2">Teams</a>
                            </li>
                        </ul>
                        

                    </div>

                    <div class="col-md-9">
                        <div class="tab-content">
                            <div class="tab-pane active" id="tab1">
                                <div class="ExperienceList pb-3">
                                    <div class="resume-list b-box p-4">
                                        <div class="row align-items-center">
                                            <div class="col-md-8 pb-2 pb-md-0">
                                                <h5 class="mb-3">Alpharetta High School</h5>
                                                <p>QB</p>
                                            </div>
                                            <div class="col-md-4 text-md-right"><h6 class="badge badge-light">2023 - </h6></div>
                                        </div>
                                    </div>
                                    <div class="resume-list b-box p-4">
                                        <div class="row align-items-center">
                                            <div class="col-md-8 pb-2 pb-md-0">
                                                <h5 class="mb-3">ThunderRidge High School</h5>
                                                <p>QB</p>
                                            </div>
                                            <div class="col-md-4 text-md-right"><h6 class="badge badge-light">2022 - 2023</h6></div>
                                        </div>
                                    </div>
                                    <div class="resume-list b-box p-4">
                                        <div class="row align-items-center">
                                            <div class="col-md-8 pb-2 pb-md-0">
                                                <h5 class="mb-3">Raptors Athletics- AYL</h5>
                                                <p>Raptors - White - Aragon (QB)</p>
                                            </div>
                                            <div class="col-md-4 text-md-right"><h6 class="badge badge-light">2019 - 2022</h6></div>
                                            
                                        </div>
                                    </div>
                                    <div class="resume-list b-box p-4">
                                        <div class="row align-items-center">
                                            <div class="col-md-8 pb-2 pb-md-0">
                                                <h5 class="mb-3">Jeremy Dufour Youth Teams</h5>
                                                <p>QB</p>
                                            </div>
                                            <div class="col-md-4 text-md-right"><h6 class="badge badge-light">2020 - 2021</h6></div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane" id="tab2">
                                <div class="EducationList">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <div class="resume-list b-box p-4 mb-4">
                                                <div>
                                                    <h5>ThunderRidge High School - Boys Varsity Football</h5>
                                                    <h6 class="badge badge-light d-inline-block my-3">2022</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane" id="tab3">
                                <div class="section-skills-wrap">
                                    <div class="row ">
                                        <div class="col-md-10">
                                            <div class="resume-list b-box p-4 mb-4">
                                                <div>
                                                    <h5>Football Stats</h5>
                                                    <h6 class="badge badge-light d-inline-block my-3">2022-2023</h6>
                                                    <div class="yearly-stats">
                                                        <div class="stat">
                                                            <div class="name">Yds</div>
                                                            <div class="value">829</div>
                                                        </div>
                                                        <div class="stat">
                                                            <div class="name">TD Passes</div>
                                                            <div class="value">7</div>
                                                        </div>
                                                        <div class="stat">
                                                            <div class="name">Y/G</div>
                                                            <div class="value">92.1</div>
                                                        </div>
                                                        <div class="stat">
                                                            <div class="name">Cmp</div>
                                                            <div class="value">58</div>
                                                        </div>
                                                        <div class="stat">
                                                            <div class="name">Att</div>
                                                            <div class="value">99</div>
                                                        </div>
                                                        <div class="stat bdrrgt">
                                                            <div class="name">Int</div>
                                                            <div class="value">4</div>
                                                        </div>
                                                        <div class="gradient"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>	
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- END: RESUME -->

        <!-- START: RESUME -->
        <div class="section" id="RECRUITERS">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-4 col-12">
                        <div class="showTime_Logo" (click)="gotoShowTimeProfileCreation()">
                            <img src="assets/images/logo_showtime.jpg" alt="resume" title="resume">
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-md-4 col-12">
                        <!-- <div class="scroll-prompt" scroll-prompt="" ng-show="showPrompt" style="opacity: 1;">
                            <div class="scroll-prompt-arrow-container">
                                <div class="scroll-prompt-arrow"><div></div></div>
                                <div class="scroll-prompt-arrow"><div></div></div>
                            </div>
                        </div> -->
                        <div class="recruiterBtn">
                            <div class="animation">
                                <div class="scroll-prompt" scroll-prompt="" ng-show="showPrompt" style="opacity: 1;">
                                    <div class="scroll-prompt-arrow-container">
                                        <div class="scroll-prompt-arrow"><div></div></div>
                                        <div class="scroll-prompt-arrow"><div></div></div>
                                    </div>
                                </div>
                            </div>
                            <div class="animation-text">
                                <h4>Recruiters</h4>
                            </div>
                            <div class="animation">
                                <div class="scroll-prompt" scroll-prompt="" ng-show="showPrompt" style="opacity: 1;">
                                    <div class="scroll-prompt-arrow-container">
                                        <div class="scroll-prompt-arrow"><div></div></div>
                                        <div class="scroll-prompt-arrow"><div></div></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- START: RESUME -->
        <div class="section" id="RESUME">
            <div class="container">
                <div class="c-show-time-bottom">
                    <!-- <div class="c-show-time-bottom-btn">
                        <button class="c-btn-1" (click)="gotoShowTimeProfileCreation()">
                            Create My Show Time Profile
                        </button>
                    </div> -->
                    <div class="c-show-time-bottom-con">
                        <div class="row  justify-content-center">
                            <div class="col-lg-7 col-md-12">
                                <h4>Recruiters Get 5,000 prospects and <br> twitter profile</h4>
                                <video  height="280" width="100%" loop  muted controls>
                                    <source src="assets/images/cg-video.mp4" type="video/mp4">
                                </video>
                                <div class="c-show-time-bottom-btn-2">
                                    <button class="c-btn-1" (click)="gotoCollegeUserRegistration()">
                                        Sign Up
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="waves"></div>


    <div class="mobileView">
        <div class="mobileView-wrap">
            <div class="mobileView-content">
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
                <defs>
                    <linearGradient id="grad1" x1="100%" y1="100%" x2="0%" y2="60%">
                        <stop offset="40%" style="stop-color:#ff1ba6;stop-opacity:1"></stop>
                        <stop offset="100%" style="stop-color:#ed2a4a;stop-opacity:1"></stop>
                    </linearGradient>
                </defs>
                <text fill="url(#grad1)" font-size="60" x="0" y="100" style="
                font-weight: 700;
            ">Too small!</text>
                </svg>
                <h4 class="mb-3">MAKE YOUR BROWSER WINDOW WIDER</h4>
                <p> Please resize the browser window wider, you are not in mobile device. Switch to mobile device to see the mobile view.</p>
            </div>
        </div>
    </div>

</div>

<div  data-spy="scroll" data-target=".navbar" class="c-shotime-w" *ngIf="userName === 'wr'">
    <!-- START: Preloader -->
    <div id="preloader" class="preloader">
        <div class="spinner-grow text-light" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
    <!-- END: Preloader -->

    <header>
        <div class="container">
            <div class="d-flex justify-content-between align-items-center">
                <a href="showtime-profile;_user=wr#HOME" class="header-logo">Will Rosenmeyer</a>

                <div class="menu d-flex justify-content-center align-items-center">

                    <!-- <a href="#CONTACT" class="head-contact btn btn-small btn-gradient mr-4">Contact</a> -->

                    <button class="btn btn-small collapsed navbar-toggler" data-toggle="collapse" data-target="#" aria-controls="" aria-expanded="false" aria-label="Toggle navigation"> 
                        <svg class="ham hamRotate ham1" viewBox="0 0 100 100" width="40"><path class="line top" d="m 30,33 h 40 c 0,0 9.044436,-0.654587 9.044436,-8.508902 0,-7.854315 -8.024349,-11.958003 -14.89975,-10.85914 -6.875401,1.098863 -13.637059,4.171617 -13.637059,16.368042 v 40"></path><path class="line middle" d="m 30,50 h 40"></path><path class="line bottom" d="m 30,67 h 40 c 12.796276,0 15.357889,-11.717785 15.357889,-26.851538 0,-15.133752 -4.786586,-27.274118 -16.667516,-27.274118 -11.88093,0 -18.499247,6.994427 -18.435284,17.125656 l 0.252538,40"></path></svg>
                    </button>

                    <div class="navbar-collapse collapse" id="">
                        <div class="container">
                            <div class="navbar-content">
                                <div class="navbar-links h-100vh d-flex align-items-center">
                                    <ul>	
                                        <li>
                                            <span><i class="lni lni-cloud"></i></span>
                                            <a href="showtime-profile;_user=wr#HIGHLIGHTS">Highlights</a>
                                        </li>
                                        <li>
                                            <span><i class="lni lni-book"></i></span>
                                            <a href="showtime-profile;_user=wr#ABOUT">ABOUT</a>
                                        </li>		
                                        <li>
                                            <span><i class="lni lni-offer"></i></span>
                                            <a href="showtime-profile;_user=wr#OFFERS">OFFERS</a>
                                        </li>
                                        <li>
                                            <span><i class="lni lni-bar-chart"></i></span>
                                            <a href="showtime-profile;_user=wr#STATS">STATS</a>
                                        </li>
                                    </ul>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>	
            </div>
        </div>
    </header>

    <div id="pagepiling">

        <!-- START: Home -->
        <div class="section section-home" id="HOME">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-md-7 col-12">
                        <div class="hero-content text-center">
                            <span class="hello mb-4 d-block"></span>
                            <h1><span>Will Rosenmeyer</span></h1>

                            <div id="autoplay" class="my-4">
                                <span>CB, SS, FS, WR</span>
                                <span>6'1", 168lbs, 2024, 33", 77", </span>
                                <span>Rock Canyon | (Highlands Ranch, CO)</span>
                            </div>  

                        </div>
                    </div>
                    <div class="col-md-5 col-12">
                        <div class="rigtBox">
                            <img src="assets/images/will-rosenmeyer.png" alt="player-profile" title="player-profile" class="img-fluid">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- END: Home -->

        <!-- START: PORTFOLIO -->
        <div class="section" id="PORTFOLIO">
            <div class="container">
                <!-- <div class="section-head text-center">
                    <span>TAKE A LOOK AT</span>
                    <h2>Portfolio</h2>
                </div>	 -->
                <div class="container swiper-container swiper-portfolio wow fadeInUp mt-5 pt-5" data-wow-duration="1.5s">
                    <div class="swiper-wrapper">
                        <div class="portfolio-item swiper-slide " data-groups="all slideshow">
                            <a href="https://va.hudl.com/p-highlights/User/6502114/6363d2614deadf0c74a505cf/5d4c6c4b_720.mp4?v=C6B13C8EB42FDB08" class="portfolio-item--content simple-ajax-popup">
                                <!-- <div class="portfolio-item--img b-box">
                                    <img src="assets/images/cg-img-1.jpg" class="img-fluid mx-auto" alt="portfolio">
                                </div> -->
                            
                                <div class="portfolio-item--img b-box">
                                    <!-- <img src="assets/images/ytv2.mp4" class="img-fluid mx-auto" alt="portfolio"> -->
                                    <video width="100%" height="400" controls="false" autoplay="flase" muted loop>
                                        <source src="https://va.hudl.com/p-highlights/User/6502114/6363d2614deadf0c74a505cf/5d4c6c4b_720.mp4?v=C6B13C8EB42FDB08" type="video/mp4">
                                    </video>
                                </div>
                            </a>
                        </div>

                        <div class="portfolio-item swiper-slide image" data-groups="all image">
                            <a href="https://va.hudl.com/p-highlights/User/6502114/636682e215f5c503b854e907/13fd7b6f_720.mp4?v=9915748EB42FDB08" class="portfolio-item--content simple-ajax-video-popup">
                                <div class="portfolio-item--img b-box">
                                    <!-- <img src="assets/images/ytv2.mp4" class="img-fluid mx-auto" alt="portfolio"> -->
                                    <video width="100%" height="400" controls="false" autoplay="flase" muted loop>
                                        <source src="https://va.hudl.com/p-highlights/User/6502114/636682e215f5c503b854e907/13fd7b6f_720.mp4?v=9915748EB42FDB08" type="video/mp4">
                                    </video>
                                </div>
                                <!-- <div class="portfolio-item--desc d-flex justify-content-center align-items-center">
                                    <div class="portfolio-item--desc-in">
                                        <h3 class="mb-2 text-white">The wall</h3>
                                        <p class="text-white">Slideshow</p>
                                    </div>
                                </div> -->
                            </a>
                        </div>

                        <div class="portfolio-item swiper-slide video" data-groups="all video">
                            <a href="https://va.hudl.com/p-highlights/User/6502114/62f933222ab0730f9c1781e8/748d7829_720.mp4?v=21154182B42FDB08" class="portfolio-item--content simple-ajax-video-popup">
                                <div class="portfolio-item--img b-box">
                                    <!-- <img src="assets/images/ytv2.mp4" class="img-fluid mx-auto" alt="work-img"> -->
                                    <video width="100%" height="400" controls="false" autoplay="autoplay" muted loop>
                                        <source src="https://va.hudl.com/p-highlights/User/6502114/62f933222ab0730f9c1781e8/748d7829_720.mp4?v=21154182B42FDB08" type="video/mp4">
                                    </video>
                                </div>
                                <!-- <div class="portfolio-item--desc d-flex justify-content-center align-items-center">
                                    <div class="portfolio-item--desc-in">
                                        <h3 class="mb-2 text-white">The wall</h3>
                                        <p class="text-white">Slideshow</p>
                                    </div>
                                </div> -->
                            </a>
                        </div>

                        <div class="portfolio-item swiper-slide slideshow" data-groups="all slideshow">
                            <a href="https://va.hudl.com/p-highlights/User/6502114/615b2d364df6110228f5c451/d8294fe1_720.mp4?v=F6290A641088D908" class="portfolio-item--content simple-ajax-video-popup">
                                <div class="portfolio-item--img b-box">
                                    <!-- <img src="assets/images/p1-1.jpg" class="img-fluid mx-auto" alt="work-img"> -->
                                    <video width="100%" height="400" controls="false" autoplay="autoplay" muted loop>
                                        <source src="https://va.hudl.com/p-highlights/User/6502114/615b2d364df6110228f5c451/d8294fe1_720.mp4?v=F6290A641088D908" type="video/mp4">
                                    </video>
                                </div>
                                <!-- <div class="portfolio-item--desc d-flex justify-content-center align-items-center">
                                    <div class="portfolio-item--desc-in">
                                        <h3 class="mb-2 text-white">The wall</h3>
                                        <p class="text-white">Slideshow</p>
                                    </div>
                                </div> -->
                            </a>
                        </div>

                        <div class="portfolio-item swiper-slide video" data-groups="all video">
                            <a href="https://va.hudl.com/p-highlights/User/6502114/618a941602b1d40f48cdad04/1ed99ff3_720.mp4?v=0E4E880496A3D908" class="portfolio-item--content simple-ajax-video-popup">
                                <div class="portfolio-item--img b-box">
                                    <!-- <img src="assets/images/ytv2.mp4" class="img-fluid mx-auto" alt="work-img"> -->
                                    <video width="100%" height="400" controls="false" autoplay="autoplay" muted loop>
                                        <source src="https://va.hudl.com/p-highlights/User/6502114/618a941602b1d40f48cdad04/1ed99ff3_720.mp4?v=0E4E880496A3D908" type="video/mp4">
                                    </video>
                                </div>
                                <!-- <div class="portfolio-item--desc d-flex justify-content-center align-items-center">
                                    <div class="portfolio-item--desc-in">
                                        <h3 class="mb-2 text-white">The wall</h3>
                                        <p class="text-white">Slideshow</p>
                                    </div>
                                </div> -->
                            </a>
                        </div>

                    </div>
                    <div class="portfolio-pagination">
                        
                    </div>
                </div>
            </div>
        </div>
        <!-- END: PORTFOLIO -->

        <!-- START: ABOUT -->
        <div class="section" id="ABT">
            <div class="container">

                <div class="row justify-content-center align-items-center">
                    <div class="col-md-5">
                        <img class="about-img about-showtime-profile img-fluid wow fadeInUp" data-wow-duration="1s" src="assets/images/will_rosenmeyer.jpg" alt="about-picture" title="about-picture">
                    </div>
                    <div class="col-md-7">

                        <div class="about-desc wow fadeInUp" data-wow-duration="1s"  data-wow-delay="500ms">
                            <div class="about-desc-content">
                                <!-- <h1 class="font-weight-light mb-5">DJ Bordeaux</h1> -->
                                <p>Will Rosenmeyer is a DB, WR at Rock Canyon - Highlands Ranch, CO</p>
                                <p>“Will Rosenmeyer is a kid who just gets it. On the field, he is attentive and ready to work every single time he laces up the cleats. Pair that with plenty of natural ability and you have a dangerous combo. It should be fun to watch him these next few years.”</p>
                                <P>Will Rosenmeyer began training with Jenkins Elite in January of 2019. He is the son of Travis and Molly Rosenmeyer and brother to Emma.</P>
                            </div>

                            <div class="about-desc-more text-left mt-5">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="about-desc-info">
                                            <div class="about-desc-info-content d-flex align-items-center hover-state b-box">
                                                <div class="about-desc-icon">
                                                    <i class="lni lni-calendar"></i>
                                                </div>
                                                <div class="about-desc-info-desc">
                                                    <span>Junior • 2024</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="about-desc-info">
                                            <div class="about-desc-info-content d-flex align-items-center hover-state b-box">
                                                <div class="about-desc-icon">
                                                    <i class="lni lni-pin"></i>
                                                </div>
                                                <div class="about-desc-info-desc">
                                                    <span>Rock Canyon</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="about-desc-info">
                                            <div class="about-desc-info-content d-flex align-items-center hover-state b-box">
                                                <div class="about-desc-icon">
                                                    <i class="lni lni-graduation"></i>
                                                </div>
                                                <div class="about-desc-info-desc">
                                                    <span>V. Track & Field, V. Football</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="about-desc-info">
                                            <div class="about-desc-info-content d-flex align-items-center hover-state b-box">
                                                <div class="about-desc-icon">
                                                    <i class="lni lni-twitter"></i>
                                                </div>
                                                <div class="about-desc-info-desc">
                                                    <a href="https://twitter.com/WRosenmeyer" title="https://twitter.com/WRosenmeyer" target="_blank">https://twitter.com/WRosenmeyer</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>			
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- END: ABOUT -->

        <!-- START: TESTIMONY -->
        <div class="section feed-section" id="TESTIMONY">
            <div class="container">
                <!-- <div class="section-head text-center">
                    <span>Kind Words</span>
                    <h2>testimonial</h2>
                </div> -->
                <div class="row">
                    <div class="col-md-4 col-12">
                        <blockquote class="twitter-tweet">
                            <p lang="en" dir="ltr">Thanks to <a href="https://twitter.com/L_Armstrong3?ref_src=twsrc%5Etfw">@L_Armstrong3</a>
                                for the camp invite! Looking forward to competing! <a
                                    href="https://twitter.com/TulsaFootball?ref_src=twsrc%5Etfw">@TulsaFootball</a> <a
                                    href="https://twitter.com/chris_polizzi?ref_src=twsrc%5Etfw">@chris_polizzi</a> <a
                                    href="https://twitter.com/macefarq?ref_src=twsrc%5Etfw">@macefarq</a> <a
                                    href="https://twitter.com/TeamFullGorilla?ref_src=twsrc%5Etfw">@TeamFullGorilla</a> <a
                                    href="https://twitter.com/CoachThomas04?ref_src=twsrc%5Etfw">@CoachThomas04</a> <a
                                    href="https://t.co/IOFcH9UNJl">pic.twitter.com/IOFcH9UNJl</a></p>&mdash; Will Rosenmeyer (@WRosenmeyer) <a
                                href="https://twitter.com/WRosenmeyer/status/1662946981696897024?ref_src=twsrc%5Etfw">May 28, 2023</a>
                        </blockquote>
                    </div>
                    <div class="col-md-4 col-12">
                        <blockquote class="twitter-tweet">
                            <p lang="en" dir="ltr">Full Junior Year Highlights <a
                                    href="https://twitter.com/TeamFullGorilla?ref_src=twsrc%5Etfw">@TeamFullGorilla</a> <a
                                    href="https://twitter.com/CoachThomas04?ref_src=twsrc%5Etfw">@CoachThomas04</a> <a
                                    href="https://twitter.com/coachwes_23?ref_src=twsrc%5Etfw">@coachwes_23</a> <a
                                    href="https://twitter.com/RCHSFootball1?ref_src=twsrc%5Etfw">@RCHSFootball1</a> <a
                                    href="https://twitter.com/JR_Sandlin?ref_src=twsrc%5Etfw">@JR_Sandlin</a> <a
                                    href="https://twitter.com/Coach_Travasos?ref_src=twsrc%5Etfw">@Coach_Travasos</a> <a
                                    href="https://twitter.com/coachscottyd?ref_src=twsrc%5Etfw">@coachscottyd</a> <a
                                    href="https://twitter.com/samantha_patten?ref_src=twsrc%5Etfw">@samantha_patten</a> <a
                                    href="https://twitter.com/the_BBoyd?ref_src=twsrc%5Etfw">@the_BBoyd</a> <a
                                    href="https://twitter.com/CoachWilk0?ref_src=twsrc%5Etfw">@CoachWilk0</a> <a
                                    href="https://twitter.com/Ballhawk__8?ref_src=twsrc%5Etfw">@Ballhawk__8</a> <a
                                    href="https://twitter.com/djbryant13?ref_src=twsrc%5Etfw">@djbryant13</a> <a
                                    href="https://twitter.com/zacheinrichs?ref_src=twsrc%5Etfw">@zacheinrichs</a> <a
                                    href="https://twitter.com/CoachCoop84?ref_src=twsrc%5Etfw">@CoachCoop84</a> <a
                                    href="https://twitter.com/marcuspatton4?ref_src=twsrc%5Etfw">@marcuspatton4</a> <a
                                    href="https://twitter.com/VinnyG_ASU?ref_src=twsrc%5Etfw">@VinnyG_ASU</a> <a
                                    href="https://twitter.com/CoachFuchs?ref_src=twsrc%5Etfw">@CoachFuchs</a> <a
                                    href="https://twitter.com/CamdenDietz?ref_src=twsrc%5Etfw">@CamdenDietz</a> <a
                                    href="https://t.co/l4aicSayLI">pic.twitter.com/l4aicSayLI</a></p>&mdash; Will Rosenmeyer (@WRosenmeyer) <a
                                href="https://twitter.com/WRosenmeyer/status/1597420153179774977?ref_src=twsrc%5Etfw">November 29, 2022</a>
                        </blockquote>
                    </div>
                    <div class="col-md-4 col-12">
                        <blockquote class="twitter-tweet">
                            <p lang="en" dir="ltr">Thank you <a href="https://twitter.com/IanFriedFB?ref_src=twsrc%5Etfw">@IanFriedFB</a> for
                                the invite. Looking forward to competing! <a
                                    href="https://twitter.com/ronwhitcomb?ref_src=twsrc%5Etfw">@ronwhitcomb</a> <a
                                    href="https://twitter.com/coach_cope?ref_src=twsrc%5Etfw">@coach_cope</a> <a
                                    href="https://twitter.com/MikeCaputo_7?ref_src=twsrc%5Etfw">@MikeCaputo_7</a> <a
                                    href="https://twitter.com/TeamFullGorilla?ref_src=twsrc%5Etfw">@TeamFullGorilla</a> <a
                                    href="https://twitter.com/CoachThomas04?ref_src=twsrc%5Etfw">@CoachThomas04</a> <a
                                    href="https://twitter.com/coachwes_23?ref_src=twsrc%5Etfw">@coachwes_23</a> <a
                                    href="https://twitter.com/PrepRedzoneCO?ref_src=twsrc%5Etfw">@PrepRedzoneCO</a> <a
                                    href="https://t.co/UDsXQ2sIYS">pic.twitter.com/UDsXQ2sIYS</a></p>&mdash; Will Rosenmeyer (@WRosenmeyer) <a
                                href="https://twitter.com/WRosenmeyer/status/1658849402759290882?ref_src=twsrc%5Etfw">May 17, 2023</a>
                        </blockquote>
                    </div>
                </div>
            </div>
        </div>
        <!-- END: TESTIMONY -->

        <!-- START: SERVICES -->
        <div class="section" id="SERVICES">
            <div class="container">
                <!-- <div class="section-head text-center">
                    <span>WHAT I DO</span>
                    <h2>My Services</h2>
                </div> -->

                <div class="row mt-4 justify-content-center align-content-center">
                    <div class="col-md-12 col-12">
                        <div class="row justify-content-center">
                            <div class="col-sm-3 col-lg-3">
                                <div class="services-list mt-4 wow fadeInUp" data-wow-duration="1.5s">
                                    <div class="services-list--content d-flex align-items-center b-box hover-state p-4">
                                        <!-- <div class="services-list-icon">
                                            <i class="lni-color-pallet"></i>
                                            <span>01</span>
                                        </div> -->
                                        <div class="services-list-desc">
                                            <!-- <h5 class="mb-0">Graphic Design</h5> -->
                                            <img src="assets/images/will-offer-1.jpg" alt="offer" title="offer" class="img-fluid">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-3 col-lg-3">
                                <div class="services-list mt-4 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay=".2s">
                                    <div class="services-list--content d-flex align-items-center b-box hover-state p-4">
                                        <!-- <div class="services-list-icon">
                                            <i class="lni-laptop-phone"></i>
                                            <span>02</span>
                                        </div> -->
                                        <div class="services-list-desc">
                                            <!-- <h5 class="mb-0">Web Development</h5> -->
                                            <img src="assets/images/will-offer-2.png" alt="offer" title="offer" class="img-fluid">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-3 col-lg-3">
                                <div class="services-list mt-4 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay=".3s">
                                    <div class="services-list--content d-flex align-items-center b-box hover-state p-4">
                                        <!-- <div class="services-list-icon">
                                            <i class="lni-video"></i>
                                            <span>03</span>
                                        </div> -->
                                        <div class="services-list-desc">
                                            <!-- <h5 class="mb-0">Media Marketing</h5> -->
                                            <img src="assets/images/will-offer-3.jpg" alt="offer" title="offer" class="img-fluid">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-3 col-lg-3">
                                <div class="services-list mt-4 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay=".4s">
                                    <div class="services-list--content d-flex align-items-center b-box hover-state p-4">
                                        <div class="services-list-desc">
                                            <img src="assets/images/will-offer-4.png" alt="example image" class="img-fluid">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-3 col-lg-3">
                                <div class="services-list mt-4 wow fadeInUp" data-wow-duration="1.5s">
                                    <div class="services-list--content d-flex align-items-center b-box hover-state p-4">
                                        <!-- <div class="services-list-icon">
                                            <i class="lni-color-pallet"></i>
                                            <span>01</span>
                                        </div> -->
                                        <div class="services-list-desc">
                                            <!-- <h5 class="mb-0">Graphic Design</h5> -->
                                            <img src="assets/images/will-offer-5.jpg" alt="offer" title="offer" class="img-fluid">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-3 col-lg-3">
                                <div class="services-list mt-4 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay=".2s">
                                    <div class="services-list--content d-flex align-items-center b-box hover-state p-4">
                                        <!-- <div class="services-list-icon">
                                            <i class="lni-laptop-phone"></i>
                                            <span>02</span>
                                        </div> -->
                                        <div class="services-list-desc">
                                            <!-- <h5 class="mb-0">Web Development</h5> -->
                                            <img src="assets/images/will-offer-6.png" alt="offer" title="offer" class="img-fluid">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-3 col-lg-3">
                                <div class="services-list mt-4 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay=".3s">
                                    <div class="services-list--content d-flex align-items-center b-box hover-state p-4">
                                        <!-- <div class="services-list-icon">
                                            <i class="lni-video"></i>
                                            <span>03</span>
                                        </div> -->
                                        <div class="services-list-desc">
                                            <!-- <h5 class="mb-0">Media Marketing</h5> -->
                                            <img src="assets/images/will-offer-7.jpg" alt="offer" title="offer" class="img-fluid">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-3 col-lg-3">
                                <div class="services-list mt-4 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay=".4s">
                                    <div class="services-list--content d-flex align-items-center b-box hover-state p-4">
                                        <div class="services-list-desc">
                                            <img src="assets/images/will-offer-8.jpg" alt="offer" title="offer" class="img-fluid">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- END: SERVICES -->

        <!-- START: PRICE -->
        <div class="section" id="PRICE">
            <!-- <div class="section-head text-center">
                <span>WHAT I DO</span>
                <h2>My Pricing</h2>
            </div> -->
            <div class="section-skills-wrap">
                <div class="container">
                    <div class="row ">

                        <div class="col-md-12">
                            <div class="resume-list statslists b-box p-4 mb-4">
                                <div>
                                    <h5>DEFENSIVE STATISTICS<span class="badge badge-light d-inline-block my-3">2022-2023</span></h5>
                                    <h4 class="d-flex justify-content-between align-items-center">
                                        <p>GD: Jr</p>
                                        <p>TEAM: Var</p>
                                    </h4>
                                    <div class="yearly-stats defensive-stats">
                                        <div class="stat">
                                            <div class="name">GP</div>
                                            <div class="value">12</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">INT</div>
                                            <div class="value">1</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">INT YDS</div>
                                            <div class="value">0.0</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">AVG</div>
                                            <div class="value">0.0</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">AVG</div>
                                            <div class="value">0.0</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">AVG</div>
                                            <div class="value">0.0</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">INT/G</div>
                                            <div class="value">0.1</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">PD</div>
                                            <div class="value">9</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">FMB REC</div>
                                            <div class="value">0</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">FR YDS</div>
                                            <div class="value">0</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">CAUS</div>
                                            <div class="value">0</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">BLK PNTS</div>
                                            <div class="value">0</div>
                                        </div>
                                        <div class="stat bdrrgt">
                                            <div class="name">BLK FGS</div>
                                            <div class="value">0</div>
                                        </div>
                                        <div class="gradient"></div>
                                    </div>
                                </div>
                            </div>
                        </div>	
                        
                        <div class="col-md-6">
                            <div class="resume-list statslists b-box p-4 mb-4">
                                <div>
                                    <h5>TACKLES <span class="badge badge-light d-inline-block my-3">2022-2023</span></h5>
                                    <h4 class="d-flex justify-content-between align-items-center">
                                        <p>GD: Jr.</p>
                                        <p>TEAM: Var</p>
                                    </h4>
                                    <div class="yearly-stats tackle-stats">
                                        <div class="stat">
                                            <div class="name">GP</div>
                                            <div class="value">12</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">SOLO</div>
                                            <div class="value">36</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">ASST</div>
                                            <div class="value">19</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">TOT TCKLS</div>
                                            <div class="value">55</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">T/G</div>
                                            <div class="value">4.6</div>
                                        </div>
                                        <div class="stat bdrrgt">
                                            <div class="name">TFL</div>
                                            <div class="value">2.0</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <!-- END: PRICE -->

        <!-- START: RESUME -->
        <div class="section" id="RECRUITERS">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-4 col-12">
                        <div class="showTime_Logo" (click)="gotoShowTimeProfileCreation()">
                            <img src="assets/images/logo_showtime.jpg" alt="resume" title="resume">
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-md-4 col-12">
                        <!-- <div class="scroll-prompt" scroll-prompt="" ng-show="showPrompt" style="opacity: 1;">
                            <div class="scroll-prompt-arrow-container">
                                <div class="scroll-prompt-arrow"><div></div></div>
                                <div class="scroll-prompt-arrow"><div></div></div>
                            </div>
                        </div> -->
                        <div class="recruiterBtn">
                            <div class="animation">
                                <div class="scroll-prompt" scroll-prompt="" ng-show="showPrompt" style="opacity: 1;">
                                    <div class="scroll-prompt-arrow-container">
                                        <div class="scroll-prompt-arrow"><div></div></div>
                                        <div class="scroll-prompt-arrow"><div></div></div>
                                    </div>
                                </div>
                            </div>
                            <div class="animation-text">
                                <h4>Recruiters</h4>
                            </div>
                            <div class="animation">
                                <div class="scroll-prompt" scroll-prompt="" ng-show="showPrompt" style="opacity: 1;">
                                    <div class="scroll-prompt-arrow-container">
                                        <div class="scroll-prompt-arrow"><div></div></div>
                                        <div class="scroll-prompt-arrow"><div></div></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- START: RESUME -->
        <div class="section" id="RESUME">
            <div class="container">
                <!-- <div class="row justify-content-center align-items-center">
                    <div class="col-md-3">


                        <ul class="nav nav-tabs">
                            <li class="nav-item">
                                <a class="nav-link active" data-toggle="tab" href="#tab2">Teams</a>
                            </li>
                        </ul>
                        

                    </div>

                    <div class="col-md-9">
                        <div class="tab-content">
                            <div class="tab-pane active" id="tab1">
                                <div class="ExperienceList pb-3">
                                    <div class="resume-list b-box p-4">
                                        <div class="row align-items-center">
                                            
                                            <div class="col-md-8 pb-2 pb-md-0">
                                                <h5 class="mb-3">ThunderRidge Varsity Football</h5>
                                                <p>WR, Junior</p>
                                            </div>
                                            <div class="col-md-4 text-md-right"><h6 class="badge badge-light">2022 - 2023 </h6></div>
                                        </div>
                                    </div>
                                    <div class="resume-list b-box p-4">
                                        <div class="row align-items-center">
                                           
                                            <div class="col-md-8 pb-2 pb-md-0">
                                                <h5 class="mb-3">ThunderRidge High School</h5>
                                                <p>WR, Sophomore</p>
                                            </div>
                                            <div class="col-md-4 text-md-right"><h6 class="badge badge-light">2021 - 2022</h6></div>
                                        </div>
                                    </div>
                                    <div class="resume-list b-box p-4">
                                        <div class="row align-items-center">
                                            
                                            <div class="col-md-8 pb-2 pb-md-0">
                                                <h5 class="mb-3">ThunderRidge Varsity Football</h5>
                                                <p>WR, Freshman</p>
                                            </div>
                                            <div class="col-md-4 text-md-right"><h6 class="badge badge-light">2020 - 2021</h6></div>
                                            
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>

                            <div class="tab-pane" id="tab2">
                                <div class="EducationList">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <div class="resume-list b-box p-4 mb-4">
                                                <div>
                                                    <h5>ThunderRidge High School - Boys Varsity Football</h5>
                                                    <h6 class="badge badge-light d-inline-block my-3">2022</h6>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane" id="tab3">
                                <div class="section-skills-wrap">
                                    <div class="row ">
                                        <div class="col-md-10">
                                            <div class="resume-list b-box p-4 mb-4">
                                                <div>
                                                    <h5>Football Stats</h5>
                                                    <h6 class="badge badge-light d-inline-block my-3">2022-2023</h6>
                                                    <div class="yearly-stats">
                                                        <div class="stat">
                                                            <div class="name">Yds</div>
                                                            <div class="value">829</div>
                                                        </div>
                                                        <div class="stat">
                                                            <div class="name">TD Passes</div>
                                                            <div class="value">7</div>
                                                        </div>
                                                        <div class="stat">
                                                            <div class="name">Y/G</div>
                                                            <div class="value">92.1</div>
                                                        </div>
                                                        <div class="stat">
                                                            <div class="name">Cmp</div>
                                                            <div class="value">58</div>
                                                        </div>
                                                        <div class="stat">
                                                            <div class="name">Att</div>
                                                            <div class="value">99</div>
                                                        </div>
                                                        <div class="stat bdrrgt">
                                                            <div class="name">Int</div>
                                                            <div class="value">4</div>
                                                        </div>
                                                        <div class="gradient"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>	
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
                <div class="c-show-time-bottom">
                    <!-- <div class="c-show-time-bottom-btn">
                        <button class="c-btn-1" (click)="gotoShowTimeProfileCreation()">
                            Create My Show Time Profile
                        </button>
                    </div> -->
                    <div class="c-show-time-bottom-con">
                        <div class="row  justify-content-center">
                            <div class="col-lg-7 col-md-12">
                                <h4>Recruiters Get 5,000 prospects and <br> twitter profile</h4>
                                <video  height="280" width="100%" loop  muted controls>
                                    <source src="assets/images/cg-video.mp4" type="video/mp4">
                                </video>
                                <div class="c-show-time-bottom-btn-2">
                                    <button class="c-btn-1" (click)="gotoCollegeUserRegistration()">
                                        Sign Up
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="waves"></div>

    <div class="mobileView">
        <div class="mobileView-wrap">
            <div class="mobileView-content">
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
                <defs>
                    <linearGradient id="grad1" x1="100%" y1="100%" x2="0%" y2="60%">
                        <stop offset="40%" style="stop-color:#ff1ba6;stop-opacity:1"></stop>
                        <stop offset="100%" style="stop-color:#ed2a4a;stop-opacity:1"></stop>
                    </linearGradient>
                </defs>
                <text fill="url(#grad1)" font-size="60" x="0" y="100" style="
                font-weight: 700;
            ">Too small!</text>
                </svg>
                <h4 class="mb-3">MAKE YOUR BROWSER WINDOW WIDER</h4>
                <p> Please resize the browser window wider, you are not in mobile device. Switch to mobile device to see the mobile view.</p>
            </div>
        </div>
    </div>
</div>

<div  data-spy="scroll" data-target=".navbar" class="c-shotime-w" *ngIf="userName === 'wf'">
    <!-- START: Preloader -->
    <div id="preloader" class="preloader">
        <div class="spinner-grow text-light" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
    <!-- END: Preloader -->

    <header>
        <div class="container">
            <div class="d-flex justify-content-between align-items-center">
                <a href="showtime-profile;_user=wf#HOME" class="header-logo">Will Fisher</a>

                <div class="menu d-flex justify-content-center align-items-center">

                    <!-- <a href="#CONTACT" class="head-contact btn btn-small btn-gradient mr-4">Contact</a> -->

                    <button class="btn btn-small collapsed navbar-toggler" data-toggle="collapse" data-target="#" aria-controls="" aria-expanded="false" aria-label="Toggle navigation"> 
                        <svg class="ham hamRotate ham1" viewBox="0 0 100 100" width="40"><path class="line top" d="m 30,33 h 40 c 0,0 9.044436,-0.654587 9.044436,-8.508902 0,-7.854315 -8.024349,-11.958003 -14.89975,-10.85914 -6.875401,1.098863 -13.637059,4.171617 -13.637059,16.368042 v 40"></path><path class="line middle" d="m 30,50 h 40"></path><path class="line bottom" d="m 30,67 h 40 c 12.796276,0 15.357889,-11.717785 15.357889,-26.851538 0,-15.133752 -4.786586,-27.274118 -16.667516,-27.274118 -11.88093,0 -18.499247,6.994427 -18.435284,17.125656 l 0.252538,40"></path></svg>
                    </button>

                    <div class="navbar-collapse collapse" id="">
                        <div class="container">
                            <div class="navbar-content">
                                <div class="navbar-links h-100vh d-flex align-items-center">
                                    <ul>	
                                        <li>
                                            <span><i class="lni lni-cloud"></i></span>
                                            <a href="showtime-profile;_user=wf#HIGHLIGHTS">Highlights</a>
                                        </li>
                                        <li>
                                            <span><i class="lni lni-book"></i></span>
                                            <a href="showtime-profile;_user=wf#ABOUT">ABOUT</a>
                                        </li>		
                                        <li>
                                            <span><i class="lni lni-offer"></i></span>
                                            <a href="showtime-profile;_user=wf#OFFERS">OFFERS</a>
                                        </li>
                                        <li>
                                            <span><i class="lni lni-bar-chart"></i></span>
                                            <a href="showtime-profile;_user=wf#STATS">STATS</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>	
            </div>
        </div>
    </header>

    <div id="pagepiling">

        <!-- START: Home -->
        <div class="section section-home" id="HOME">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-md-7 col-12">
                        <div class="hero-content text-center">
                            <span class="hello mb-4 d-block"></span>
                            <h1><span>Will Fisher</span></h1>

                            <div id="autoplay" class="my-4">
                                <span>MLB, FB</span>
                                <span>5'10", 195 lbs</span>
                                <span>ThunderRidge | (Highlands Ranch, CO)</span>
                            </div>  

                        </div>
                    </div>
                    <div class="col-md-5 col-12">
                        <div class="rigtBox">
                            <img src="assets/images/will-fisher.png" alt="player-profile" title="player-profile" class="img-fluid">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- END: Home -->

        <!-- START: PORTFOLIO -->
        <div class="section" id="PORTFOLIO">
            <div class="container">
                <!-- <div class="section-head text-center">
                    <span>TAKE A LOOK AT</span>
                    <h2>Portfolio</h2>
                </div>	 -->
                <div class="container swiper-container swiper-portfolio wow fadeInUp mt-5 pt-5" data-wow-duration="1.5s">
                    <div class="swiper-wrapper">
                        <div class="portfolio-item swiper-slide " data-groups="all slideshow">
                            <a href="https://vf.hudl.com/p-highlights/User/15215375/637b0dd164df0909f47897a7/3e471761_720.mp4?v=7613EA44D2CFDA08" class="portfolio-item--content simple-ajax-popup">
                                <!-- <div class="portfolio-item--img b-box">
                                    <img src="assets/images/cg-img-1.jpg" class="img-fluid mx-auto" alt="portfolio">
                                </div> -->
                            
                                <div class="portfolio-item--img b-box">
                                    <!-- <img src="assets/images/ytv2.mp4" class="img-fluid mx-auto" alt="portfolio"> -->
                                    <video width="100%" height="400" controls="false" autoplay="flase" muted loop>
                                        <source src="https://vf.hudl.com/p-highlights/User/15215375/637b0dd164df0909f47897a7/3e471761_720.mp4?v=7613EA44D2CFDA08" type="video/mp4">
                                    </video>
                                </div>
                            </a>
                        </div>

                        <div class="portfolio-item swiper-slide image" data-groups="all image">
                            <a href="https://vf.hudl.com/p-highlights/User/15215375/63689c0fe985810cac208bb3/89032f8b_720.mp4?v=DD8E29D584C0DA08" class="portfolio-item--content simple-ajax-video-popup">
                                <div class="portfolio-item--img b-box">
                                    <!-- <img src="assets/images/ytv2.mp4" class="img-fluid mx-auto" alt="portfolio"> -->
                                    <video width="100%" height="400" controls="false" autoplay="flase" muted loop>
                                        <source src="https://vf.hudl.com/p-highlights/User/15215375/63689c0fe985810cac208bb3/89032f8b_720.mp4?v=DD8E29D584C0DA08" type="video/mp4">
                                    </video>
                                </div>
                                <!-- <div class="portfolio-item--desc d-flex justify-content-center align-items-center">
                                    <div class="portfolio-item--desc-in">
                                        <h3 class="mb-2 text-white">The wall</h3>
                                        <p class="text-white">Slideshow</p>
                                    </div>
                                </div> -->
                            </a>
                        </div>

                        <div class="portfolio-item swiper-slide video" data-groups="all video">
                            <a href="https://vf.hudl.com/p-highlights/User/15215375/6344e10dbed68208e0f23abb/a01190bb_720.mp4?v=DE655A607FBCDA08" class="portfolio-item--content simple-ajax-video-popup">
                                <div class="portfolio-item--img b-box">
                                    <!-- <img src="assets/images/ytv2.mp4" class="img-fluid mx-auto" alt="portfolio"> -->
                                    <video width="100%" height="400" controls="false" autoplay="autoplay" muted loop>
                                        <source src="https://vf.hudl.com/p-highlights/User/15215375/6344e10dbed68208e0f23abb/a01190bb_720.mp4?v=DE655A607FBCDA08" type="video/mp4">
                                    </video>
                                </div>
                                <!-- <div class="portfolio-item--desc d-flex justify-content-center align-items-center">
                                    <div class="portfolio-item--desc-in">
                                        <h3 class="mb-2 text-white">The wall</h3>
                                        <p class="text-white">Slideshow</p>
                                    </div>
                                </div> -->
                            </a>
                        </div>

                        <div class="portfolio-item swiper-slide slideshow" data-groups="all slideshow">
                            <a href="https://vf.hudl.com/p-highlights/User/15215375/6344dd7dc124290a7818a41a/15e70ca7_720.mp4?v=5698CBE535ABDA08" class="portfolio-item--content simple-ajax-video-popup">
                                <div class="portfolio-item--img b-box">
                                    <!-- <img src="assets/images/p1-1.jpg" class="img-fluid mx-auto" alt="portfolio"> -->
                                    <video width="100%" height="400" controls="false" autoplay="autoplay" muted loop>
                                        <source src="https://vf.hudl.com/p-highlights/User/15215375/6344dd7dc124290a7818a41a/15e70ca7_720.mp4?v=5698CBE535ABDA08" type="video/mp4">
                                    </video>
                                </div>
                                <!-- <div class="portfolio-item--desc d-flex justify-content-center align-items-center">
                                    <div class="portfolio-item--desc-in">
                                        <h3 class="mb-2 text-white">The wall</h3>
                                        <p class="text-white">Slideshow</p>
                                    </div>
                                </div> -->
                            </a>
                        </div>

                        <div class="portfolio-item swiper-slide video" data-groups="all video">
                            <a href="https://vf.hudl.com/p-highlights/User/15215375/634485d3bd6b0e04844e6610/6e06fa95_720.mp4?v=0F1CFF3F01ABDA08" class="portfolio-item--content simple-ajax-video-popup">
                                <div class="portfolio-item--img b-box">
                                    <!-- <img src="assets/images/ytv2.mp4" class="img-fluid mx-auto" alt="portfolio"> -->
                                    <video width="100%" height="400" controls="false" autoplay="autoplay" muted loop>
                                        <source src="https://vf.hudl.com/p-highlights/User/15215375/634485d3bd6b0e04844e6610/6e06fa95_720.mp4?v=0F1CFF3F01ABDA08" type="video/mp4">
                                    </video>
                                </div>
                                <!-- <div class="portfolio-item--desc d-flex justify-content-center align-items-center">
                                    <div class="portfolio-item--desc-in">
                                        <h3 class="mb-2 text-white">The wall</h3>
                                        <p class="text-white">Slideshow</p>
                                    </div>
                                </div> -->
                            </a>
                        </div>

                    </div>
                    <div class="portfolio-pagination">
                        
                    </div>
                </div>
            </div>
        </div>
        <!-- END: PORTFOLIO -->

        <!-- START: ABOUT -->
        <div class="section" id="ABT">
            <div class="container">

                <div class="row justify-content-center align-items-center">
                    <div class="col-md-5">
                        <img class="about-img about-showtime-profile img-fluid wow fadeInUp" data-wow-duration="1s" src="assets/images/will-fisher-about.jpg" alt="about-picture" title="about-picture">
                    </div>
                    <div class="col-md-7">

                        <div class="about-desc wow fadeInUp" data-wow-duration="1s"  data-wow-delay="500ms">
                            <div class="about-desc-content">
                                <!-- <h1 class="font-weight-light mb-5">DJ Bordeaux</h1> -->
                                <p>Will Fisher is a MLB, FB at ThunderRidge High School, Highlands Ranch, CO, Varsity Football/Baseball | 2x All Conference MLB /Team Captain|Catcher | 5’11 210lbs | 3.4 GPA.</p>
                                <P>Will is ranked in the top 75 in Colorado for 1 stat, top 33 in Division 5A for 1 stat, and top 6 in 5A League 5 for 1 stat.</P>
                            </div>

                            <div class="about-desc-more text-left mt-5">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="about-desc-info">
                                            <div class="about-desc-info-content d-flex align-items-center hover-state b-box">
                                                <div class="about-desc-icon">
                                                    <i class="lni lni-calendar"></i>
                                                </div>
                                                <div class="about-desc-info-desc">
                                                    <span>Junior • 2024</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="about-desc-info">
                                            <div class="about-desc-info-content d-flex align-items-center hover-state b-box">
                                                <div class="about-desc-icon">
                                                    <i class="lni lni-pin"></i>
                                                </div>
                                                <div class="about-desc-info-desc">
                                                    <span>ThunderRidge | (Highlands Ranch, CO)</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="about-desc-info">
                                            <div class="about-desc-info-content d-flex align-items-center hover-state b-box">
                                                <div class="about-desc-icon">
                                                    <i class="lni lni-graduation"></i>
                                                </div>
                                                <div class="about-desc-info-desc">
                                                    <span>V. Baseball, V. Football</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="about-desc-info">
                                            <div class="about-desc-info-content d-flex align-items-center hover-state b-box">
                                                <div class="about-desc-icon">
                                                    <i class="lni lni-twitter"></i>
                                                </div>
                                                <div class="about-desc-info-desc">
                                                    <a href="https://twitter.com/will_fisher42?s=08" title="https://twitter.com/will_fisher42?s=08" target="_blank">https://twitter.com/will_fisher42?s=08</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>			
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- END: ABOUT -->

        <!-- START: TESTIMONY -->
        <div class="section feed-section" id="TESTIMONY">
            <div class="container">
                <!-- <div class="section-head text-center">
                    <span>Kind Words</span>
                    <h2>testimonial</h2>
                </div> -->
                <div class="row justify-content-center">
                    <div class="col-md-4 col-12">
                        <blockquote class="twitter-tweet">
                            <p lang="en" dir="ltr">Check out my highlight! JUNIOR SEASON HIGHLIGHTS 10-1🟢🔵Onto the second round of playoffs
                                this Friday.<a href="https://twitter.com/CoachNise?ref_src=twsrc%5Etfw">@CoachNise</a> <a
                                    href="https://twitter.com/TR_GrizzliesFB?ref_src=twsrc%5Etfw">@TR_GrizzliesFB</a> @Coach_KaiEllis <a
                                    href="https://twitter.com/PrepRedzoneCO?ref_src=twsrc%5Etfw">@PrepRedzoneCO</a> <a
                                    href="https://twitter.com/Coach_HunterH?ref_src=twsrc%5Etfw">@Coach_HunterH</a> <a
                                    href="https://twitter.com/BreskeJosh?ref_src=twsrc%5Etfw">@BreskeJosh</a> <a
                                    href="https://twitter.com/Coach_Hauck?ref_src=twsrc%5Etfw">@Coach_Hauck</a> <a
                                    href="https://twitter.com/Jas_Bains_12?ref_src=twsrc%5Etfw">@Jas_Bains_12</a> <a
                                    href="https://twitter.com/CoachWristen?ref_src=twsrc%5Etfw">@CoachWristen</a> <a
                                    href="https://twitter.com/FLCCoach_Cox?ref_src=twsrc%5Etfw">@FLCCoach_Cox</a> <a
                                    href="https://t.co/MXdx6MhwAu">https://t.co/MXdx6MhwAu</a></p>&mdash; Will Fisher (@will_fisher42) <a
                                href="https://twitter.com/will_fisher42/status/1590864288276975616?ref_src=twsrc%5Etfw">November 11, 2022</a>
                        </blockquote>
                    </div>
                    <div class="col-md-4 col-12">
                        <blockquote class="twitter-tweet">
                            <p lang="en" dir="ltr">Check out my Junior Season Highlights! Didn’t end with the result we wanted but still had a
                                great run. Thanks to <a href="https://twitter.com/CoachNise?ref_src=twsrc%5Etfw">@CoachNise</a> and <a
                                    href="https://twitter.com/CoachCarnes?ref_src=twsrc%5Etfw">@CoachCarnes</a> we were the last undefeated team
                                in 5A. 1 year left! <a href="https://t.co/gfLAjNuHUU">https://t.co/gfLAjNuHUU</a></p>&mdash; Will Fisher
                            (@will_fisher42) <a href="https://twitter.com/will_fisher42/status/1596557430917586946?ref_src=twsrc%5Etfw">November
                                26, 2022</a>
                        </blockquote>
                    </div>
                    <!-- <div class="col-md-4 col-12">
                        <blockquote class="twitter-tweet">
                            <p lang="en" dir="ltr">Thank you <a href="https://twitter.com/IanFriedFB?ref_src=twsrc%5Etfw">@IanFriedFB</a> for
                                the invite. Looking forward to competing! <a
                                    href="https://twitter.com/ronwhitcomb?ref_src=twsrc%5Etfw">@ronwhitcomb</a> <a
                                    href="https://twitter.com/coach_cope?ref_src=twsrc%5Etfw">@coach_cope</a> <a
                                    href="https://twitter.com/MikeCaputo_7?ref_src=twsrc%5Etfw">@MikeCaputo_7</a> <a
                                    href="https://twitter.com/TeamFullGorilla?ref_src=twsrc%5Etfw">@TeamFullGorilla</a> <a
                                    href="https://twitter.com/CoachThomas04?ref_src=twsrc%5Etfw">@CoachThomas04</a> <a
                                    href="https://twitter.com/coachwes_23?ref_src=twsrc%5Etfw">@coachwes_23</a> <a
                                    href="https://twitter.com/PrepRedzoneCO?ref_src=twsrc%5Etfw">@PrepRedzoneCO</a> <a
                                    href="https://t.co/UDsXQ2sIYS">pic.twitter.com/UDsXQ2sIYS</a></p>&mdash; Will Rosenmeyer (@WRosenmeyer) <a
                                href="https://twitter.com/WRosenmeyer/status/1658849402759290882?ref_src=twsrc%5Etfw">May 17, 2023</a>
                        </blockquote>
                    </div> -->
                </div>
            </div>
        </div>
        <!-- END: TESTIMONY -->

        <!-- START: SERVICES -->
        <div class="section" id="SERVICES">
            <div class="container">
                <!-- <div class="section-head text-center">
                    <span>WHAT I DO</span>
                    <h2>My Services</h2>
                </div> -->

                <div class="row mt-4 justify-content-center align-content-center">
                    <div class="col-md-12 col-12">
                        <div class="row justify-content-center">
                            <div class="col-sm-4 col-lg-4">
                                <div class="services-list mt-4 wow fadeInUp" data-wow-duration="1.5s">
                                    <div class="services-list--content d-flex align-items-center b-box hover-state p-4">
                                        <!-- <div class="services-list-icon">
                                            <i class="lni-color-pallet"></i>
                                            <span>01</span>
                                        </div> -->
                                        <div class="services-list-desc">
                                            <!-- <h5 class="mb-0">Graphic Design</h5> -->
                                            <img src="assets/images/fisher-offer-1.jpg" alt="offer" title="offer" class="img-fluid">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- END: SERVICES -->

        <!-- START: PRICE -->
        <div class="section" id="PRICE">
            <!-- <div class="section-head text-center">
                <span>WHAT I DO</span>
                <h2>My Pricing</h2>
            </div> -->
            <div class="section-skills-wrap">
                <div class="container">
                    <div class="row ">

                        <div class="col-md-6">
                            <div class="resume-list statslists b-box p-4 mb-4">
                                <div>
                                    <h5>RUSHING<span class="badge badge-light d-inline-block my-3">2022-2023</span></h5>
                                    <h4 class="d-flex justify-content-between align-items-center">
                                        <p>GD: Jr.</p>
                                        <p>TEAM: Var</p>
                                    </h4>
                                    <div class="yearly-stats rushing-stats">
                                        <div class="stat">
                                            <div class="name">GP</div>
                                            <div class="value">13</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">CAR</div>
                                            <div class="value">15</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">YDS</div>
                                            <div class="value">22</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">AVG</div>
                                            <div class="value">1.5</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">Y/G</div>
                                            <div class="value">1.7</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">LNG</div>
                                            <div class="value">3</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">100+</div>
                                            <div class="value">0</div>
                                        </div>
                                        <div class="stat bdrrgt">
                                            <div class="name">TD</div>
                                            <div class="value">6</div>
                                        </div>
                                        <div class="gradient"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="resume-list statslists b-box p-4 mb-4">
                                <div>
                                    <h5>TOTAL YARDS<span class="badge badge-light d-inline-block my-3">2022-2023</span></h5>
                                    <h4 class="d-flex justify-content-between align-items-center">
                                        <p>GD: Jr.</p>
                                        <p>TEAM: Var</p>
                                    </h4>
                                    <div class="yearly-stats tackle-stats">
                                        <div class="stat">
                                            <div class="name">GP</div>
                                            <div class="value">13</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">RUSH</div>
                                            <div class="value">22</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">PASS</div>
                                            <div class="value">0</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">REC</div>
                                            <div class="value">0</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">TOTAL</div>
                                            <div class="value">22</div>
                                        </div>
                                        <div class="stat bdrrgt">
                                            <div class="name">Y/G</div>
                                            <div class="value">1.7</div>
                                        </div>
                                        <div class="gradient"></div>
                                    </div>
                                </div>
                            </div>
                        </div>	
                        
                        <div class="col-md-6">
                            <div class="resume-list statslists b-box p-4 mb-4">
                                <div>
                                    <h5>TACKLES <span class="badge badge-light d-inline-block my-3">2022-2023</span></h5>
                                    <h4 class="d-flex justify-content-between align-items-center">
                                        <p>GD: Jr.</p>
                                        <p>TEAM: Var</p>
                                    </h4>
                                    <div class="yearly-stats tackle-stats">
                                        <div class="stat">
                                            <div class="name">GP</div>
                                            <div class="value">13</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">SOLO</div>
                                            <div class="value">34</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">ASST</div>
                                            <div class="value">67</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">TOT TCKLS</div>
                                            <div class="value">101</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">T/G</div>
                                            <div class="value">7.8</div>
                                        </div>
                                        <div class="stat bdrrgt">
                                            <div class="name">TFL</div>
                                            <div class="value">6.0</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="resume-list statslists b-box p-4 mb-4">
                                <div>
                                    <h5>SACKS <span class="badge badge-light d-inline-block my-3">2022-2023</span></h5>
                                    <h4 class="d-flex justify-content-between align-items-center">
                                        <p>GD: Jr.</p>
                                        <p>TEAM: Var</p>
                                    </h4>
                                    <div class="yearly-stats tackle2-stats">
                                        <div class="stat">
                                            <div class="name">GP</div>
                                            <div class="value">13</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">SACKS</div>
                                            <div class="value">1.0</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">YDL</div>
                                            <div class="value">13</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">S/G</div>
                                            <div class="value">0.1</div>
                                        </div>
                                        <div class="stat bdrrgt">
                                            <div class="name">HURS</div>
                                            <div class="value">0</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <!-- END: PRICE -->

        <!-- START: RESUME -->
        <div class="section" id="RECRUITERS">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-4 col-12">
                        <div class="showTime_Logo" (click)="gotoShowTimeProfileCreation()">
                            <img src="assets/images/logo_showtime.jpg" alt="resume" title="resume">
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-md-4 col-12">
                        <!-- <div class="scroll-prompt" scroll-prompt="" ng-show="showPrompt" style="opacity: 1;">
                            <div class="scroll-prompt-arrow-container">
                                <div class="scroll-prompt-arrow"><div></div></div>
                                <div class="scroll-prompt-arrow"><div></div></div>
                            </div>
                        </div> -->
                        <div class="recruiterBtn">
                            <div class="animation">
                                <div class="scroll-prompt" scroll-prompt="" ng-show="showPrompt" style="opacity: 1;">
                                    <div class="scroll-prompt-arrow-container">
                                        <div class="scroll-prompt-arrow"><div></div></div>
                                        <div class="scroll-prompt-arrow"><div></div></div>
                                    </div>
                                </div>
                            </div>
                            <div class="animation-text">
                                <h4>Recruiters</h4>
                            </div>
                            <div class="animation">
                                <div class="scroll-prompt" scroll-prompt="" ng-show="showPrompt" style="opacity: 1;">
                                    <div class="scroll-prompt-arrow-container">
                                        <div class="scroll-prompt-arrow"><div></div></div>
                                        <div class="scroll-prompt-arrow"><div></div></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- START: RESUME -->
        <div class="section" id="RESUME">
            <div class="container">
                <!-- <div class="row justify-content-center align-items-center">
                    <div class="col-md-3">


                        <ul class="nav nav-tabs">
                            <li class="nav-item">
                                <a class="nav-link active" data-toggle="tab" href="#tab2">Teams</a>
                            </li>
                        </ul>
                        

                    </div>

                    <div class="col-md-9">
                        <div class="tab-content">
                            <div class="tab-pane active" id="tab1">
                                <div class="ExperienceList pb-3">
                                    <div class="resume-list b-box p-4">
                                        <div class="row align-items-center">
                                            
                                            <div class="col-md-8 pb-2 pb-md-0">
                                                <h5 class="mb-3">ThunderRidge Varsity Football</h5>
                                                <p>WR, Junior</p>
                                            </div>
                                            <div class="col-md-4 text-md-right"><h6 class="badge badge-light">2022 - 2023 </h6></div>
                                        </div>
                                    </div>
                                    <div class="resume-list b-box p-4">
                                        <div class="row align-items-center">
                                           
                                            <div class="col-md-8 pb-2 pb-md-0">
                                                <h5 class="mb-3">ThunderRidge High School</h5>
                                                <p>WR, Sophomore</p>
                                            </div>
                                            <div class="col-md-4 text-md-right"><h6 class="badge badge-light">2021 - 2022</h6></div>
                                        </div>
                                    </div>
                                    <div class="resume-list b-box p-4">
                                        <div class="row align-items-center">
                                            
                                            <div class="col-md-8 pb-2 pb-md-0">
                                                <h5 class="mb-3">ThunderRidge Varsity Football</h5>
                                                <p>WR, Freshman</p>
                                            </div>
                                            <div class="col-md-4 text-md-right"><h6 class="badge badge-light">2020 - 2021</h6></div>
                                            
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>

                            <div class="tab-pane" id="tab2">
                                <div class="EducationList">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <div class="resume-list b-box p-4 mb-4">
                                                <div>
                                                    <h5>ThunderRidge High School - Boys Varsity Football</h5>
                                                    <h6 class="badge badge-light d-inline-block my-3">2022</h6>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane" id="tab3">
                                <div class="section-skills-wrap">
                                    <div class="row ">
                                        <div class="col-md-10">
                                            <div class="resume-list b-box p-4 mb-4">
                                                <div>
                                                    <h5>Football Stats</h5>
                                                    <h6 class="badge badge-light d-inline-block my-3">2022-2023</h6>
                                                    <div class="yearly-stats">
                                                        <div class="stat">
                                                            <div class="name">Yds</div>
                                                            <div class="value">829</div>
                                                        </div>
                                                        <div class="stat">
                                                            <div class="name">TD Passes</div>
                                                            <div class="value">7</div>
                                                        </div>
                                                        <div class="stat">
                                                            <div class="name">Y/G</div>
                                                            <div class="value">92.1</div>
                                                        </div>
                                                        <div class="stat">
                                                            <div class="name">Cmp</div>
                                                            <div class="value">58</div>
                                                        </div>
                                                        <div class="stat">
                                                            <div class="name">Att</div>
                                                            <div class="value">99</div>
                                                        </div>
                                                        <div class="stat bdrrgt">
                                                            <div class="name">Int</div>
                                                            <div class="value">4</div>
                                                        </div>
                                                        <div class="gradient"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>	
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
                <div class="c-show-time-bottom">
                    <!-- <div class="c-show-time-bottom-btn">
                        <button class="c-btn-1" (click)="gotoShowTimeProfileCreation()">
                            Create My Show Time Profile
                        </button>
                    </div> -->
                    <div class="c-show-time-bottom-con">
                        <div class="row  justify-content-center">
                            <div class="col-lg-7 col-md-12">
                                <h4>Recruiters Get 5,000 prospects and <br> twitter profile</h4>
                                <video  height="280" width="100%" loop  muted controls>
                                    <source src="assets/images/cg-video.mp4" type="video/mp4">
                                </video>
                            </div>
                        </div>
                    </div>
                    <div class="c-show-time-bottom-btn-2">
                        <button class="c-btn-1" (click)="gotoCollegeUserRegistration()">
                            Sign Up
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="waves"></div>

    <div class="mobileView">
        <div class="mobileView-wrap">
            <div class="mobileView-content">
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
                <defs>
                    <linearGradient id="grad1" x1="100%" y1="100%" x2="0%" y2="60%">
                        <stop offset="40%" style="stop-color:#ff1ba6;stop-opacity:1"></stop>
                        <stop offset="100%" style="stop-color:#ed2a4a;stop-opacity:1"></stop>
                    </linearGradient>
                </defs>
                <text fill="url(#grad1)" font-size="60" x="0" y="100" style="
                font-weight: 700;
            ">Too small!</text>
                </svg>
                <h4 class="mb-3">MAKE YOUR BROWSER WINDOW WIDER</h4>
                <p> Please resize the browser window wider, you are not in mobile device. Switch to mobile device to see the mobile view.</p>
            </div>
        </div>
    </div>
</div>

<div  data-spy="scroll" data-target=".navbar" class="c-shotime-w" *ngIf="userName === 'sb'">
    <!-- START: Preloader -->
    <div id="preloader" class="preloader">
        <div class="spinner-grow text-light" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
    <!-- END: Preloader -->

    <header>
        <div class="container">
            <div class="d-flex justify-content-between align-items-center">
                <a href="#HOME" class="header-logo" >Seid Benak</a>

                <div class="menu d-flex justify-content-center align-items-center">

                    <!-- <a href="#CONTACT" class="head-contact btn btn-small btn-gradient mr-4">Contact</a> -->

                    <button class="btn btn-small collapsed navbar-toggler" data-toggle="collapse" data-target="#" aria-controls="" aria-expanded="false" aria-label="Toggle navigation"> 
                        <svg class="ham hamRotate ham1" viewBox="0 0 100 100" width="40"><path class="line top" d="m 30,33 h 40 c 0,0 9.044436,-0.654587 9.044436,-8.508902 0,-7.854315 -8.024349,-11.958003 -14.89975,-10.85914 -6.875401,1.098863 -13.637059,4.171617 -13.637059,16.368042 v 40"></path><path class="line middle" d="m 30,50 h 40"></path><path class="line bottom" d="m 30,67 h 40 c 12.796276,0 15.357889,-11.717785 15.357889,-26.851538 0,-15.133752 -4.786586,-27.274118 -16.667516,-27.274118 -11.88093,0 -18.499247,6.994427 -18.435284,17.125656 l 0.252538,40"></path></svg>
                    </button>

                    <div class="navbar-collapse collapse" id="">
                        <div class="container">
                            <div class="navbar-content">
                                <div class="navbar-links h-100vh d-flex align-items-center">
                                    <ul>	
                                        <li>
                                            <span><i class="lni lni-cloud"></i></span>
                                            <a href="showtime-profile;_user=sb#HIGHLIGHTS">Highlights</a>
                                        </li>
                                        <li>
                                            <span><i class="lni lni-book"></i></span>
                                            <a href="showtime-profile;_user=sb#ABOUT">ABOUT</a>
                                        </li>		
                                        <li>
                                            <span><i class="lni lni-offer"></i></span>
                                            <a href="showtime-profile;_user=sb#OFFERS">OFFERS</a>
                                        </li>
                                        <li>
                                            <span><i class="lni lni-bar-chart"></i></span>
                                            <a href="showtime-profile;_user=sb#STATS">STATS</a>
                                        </li>
                                    </ul>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>	
            </div>
        </div>
    </header>

    <div id="pagepiling">

        <!-- START: Home -->
        <div class="section section-home" id="HOME">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-md-7 col-12">
                        <div class="hero-content text-center">
                            <span class="hello mb-4 d-block"></span>
                            <h1><span>Seid Benak</span></h1>

                            <div id="autoplay" class="my-4">
                            <span>WR</span>
                            <span>5'9", 150 lbs, 2024</span>
                            <span>ThunderRidge | (Highlands Ranch, CO)</span>
                            </div>   

                        </div>
                    </div>
                    <div class="col-md-5 col-12">
                        <div class="rigtBox">
                            <img src="assets/images/benak_banner.png" alt="player-profile" title="player-profile" class="img-fluid">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- END: Home -->

        <!-- START: PORTFOLIO -->
        <div class="section" id="PORTFOLIO">
            <div class="container">
                <div class="row justify-content-center align-items-center">
                    <div class="col-md-6 col-12">
                        <div class="resume-list statslists noHighlight b-box p-4 mb-4">
                            <h4>No Highlights Data Found</h4>
                        </div>
                    </div>
                </div>
                
                <!-- <div class="container swiper-container swiper-portfolio wow fadeInUp mt-5 pt-5" data-wow-duration="1.5s">
                    <div class="swiper-wrapper">
                        <div class="portfolio-item swiper-slide " data-groups="all slideshow">
                            <a href="https://static.hudl.com/users/prod/11589817_41ebc2e257084d5897e6382dd7e85046.jpg" class="portfolio-item--content simple-ajax-popup">
                                <div class="portfolio-item--img b-box">
                                    <img src="https://static.hudl.com/users/prod/11589817_41ebc2e257084d5897e6382dd7e85046.jpg" class="img-fluid mx-auto" alt="work-img">
                                </div>
                            </a>
                        </div>

                        <div class="portfolio-item swiper-slide image" data-groups="all image">
                            <a href="https://vd.hudl.com/p-highlights/Team/383321/602efd4d42071a06f8f43c5d/e7065201_720.mp4?v=F97CE81968D4D808" class="portfolio-item--content simple-ajax-video-popup">
                                <div class="portfolio-item--img b-box">
                                    <video width="100%" height="400" controls="false" autoplay="flase" muted loop>
                                        <source src="https://vd.hudl.com/p-highlights/Team/383321/602efd4d42071a06f8f43c5d/e7065201_720.mp4?v=F97CE81968D4D808" type="video/mp4">
                                    </video>
                                </div>
                            </a>
                        </div>

                        <div class="portfolio-item swiper-slide slideshow" data-groups="all slideshow">
                            <a href="https://vd.hudl.com/p-highlights/Team/383321/603f6d47b3d29c135c565bee/1fbad3be_720.mp4?v=8F7A78754EDED808" class="portfolio-item--content simple-ajax-video-popup">
                                <div class="portfolio-item--img b-box">
                                    <video width="100%" height="400" controls="false" autoplay="autoplay" muted loop>
                                        <source src="https://vd.hudl.com/p-highlights/Team/383321/603f6d47b3d29c135c565bee/1fbad3be_720.mp4?v=8F7A78754EDED808" type="video/mp4">
                                    </video>
                                </div>
                            </a>
                        </div>

                        <div class="portfolio-item swiper-slide video" data-groups="all video">
                            <a href="https://vd.hudl.com/p-highlights/Team/383321/604368a50dca9c15dc570fa9/a77b9e22_720.mp4?v=93AA0AF9C6E0D808" class="portfolio-item--content simple-ajax-video-popup">
                                <div class="portfolio-item--img b-box">
                                    <video width="100%" height="400" controls="false" autoplay="autoplay" muted loop>
                                        <source src="https://vd.hudl.com/p-highlights/Team/383321/604368a50dca9c15dc570fa9/a77b9e22_720.mp4?v=93AA0AF9C6E0D808" type="video/mp4">
                                    </video>
                                </div>
                            </a>
                        </div>

                        <div class="portfolio-item swiper-slide  slideshow" data-groups="all slideshow">
                            <a href="https://pbs.twimg.com/media/FriTgLuaEAEZYIC?format=jpg&name=large" class="portfolio-item--content simple-ajax-popup">
                                <div class="portfolio-item--img b-box">
                                    <img src="https://pbs.twimg.com/media/FriTgLuaEAEZYIC?format=jpg&name=large" class="img-fluid mx-auto" alt="work-img">
                                </div>
                            </a>
                        </div>

                    </div>
                    <div class="portfolio-pagination">
                        
                    </div>
                </div> -->
            </div>
        </div>
        <!-- END: PORTFOLIO -->

        <!-- START: ABOUT -->
        <div class="section" id="ABT">
            <div class="container">

                <div class="row justify-content-center align-items-center">
                    <div class="col-md-5">
                        <img class="about-img about-showtime-profile img-fluid wow fadeInUp" data-wow-duration="1s" src="assets/images/benak_about.jpg" alt="about-picture" title="about-picture">
                    </div>
                    <div class="col-md-7">

                        <div class="about-desc wow fadeInUp" data-wow-duration="1s"  data-wow-delay="500ms">
                            <div class="about-desc-content">
                                <p>Seid Benak is a WR at ThunderRidge | Highlands Ranch, CO,  Football 5’8 WR. 720-899-7226 NCAA ID#2305894464.  GPA:3.4</p>
                                <!-- <P>Will is ranked in the top 75 in Colorado for 1 stat, top 33 in Division 5A for 1 stat, and top 6 in 5A League 5 for 1 stat.</P> -->
                            </div>

                            <div class="about-desc-more text-left mt-5">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="about-desc-info">
                                            <div class="about-desc-info-content d-flex align-items-center hover-state b-box">
                                                <div class="about-desc-icon">
                                                    <i class="lni lni-calendar"></i>
                                                </div>
                                                <div class="about-desc-info-desc">
                                                    <span>Junior • 2024</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="about-desc-info">
                                            <div class="about-desc-info-content d-flex align-items-center hover-state b-box">
                                                <div class="about-desc-icon">
                                                    <i class="lni lni-pin"></i>
                                                </div>
                                                <div class="about-desc-info-desc">
                                                    <span>Highlands Ranch, CO</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="about-desc-info">
                                            <div class="about-desc-info-content d-flex align-items-center hover-state b-box">
                                                <div class="about-desc-icon">
                                                    <i class="lni lni-graduation"></i>
                                                </div>
                                                <div class="about-desc-info-desc">
                                                    <span>Thunderridge High School</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="about-desc-info">
                                            <div class="about-desc-info-content d-flex align-items-center hover-state b-box">
                                                <div class="about-desc-icon">
                                                    <i class="lni lni-twitter"></i>
                                                </div>
                                                <div class="about-desc-info-desc">
                                                    <a href="https://twitter.com/BenakSeid?s=08" title="https://twitter.com/BenakSeid?s=08" target="_blank">https://twitter.com/BenakSeid?s=08</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>			
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- END: ABOUT -->

        <!-- START: TESTIMONY -->
        <div class="section feed-section" id="TESTIMONY">
            <div class="container">
                <div class="row justify-content-center align-items-center">
                    <div class="col-md-4 col-12">
                        <blockquote class="twitter-tweet">
                            <p lang="en" dir="ltr">After a great showcase camp in Golden, I am blessed to receive my first offer <a
                                    href="https://twitter.com/AdamsStateFB?ref_src=twsrc%5Etfw">@AdamsStateFB</a>. Thank you <a
                                    href="https://twitter.com/coachkham1_?ref_src=twsrc%5Etfw">@coachkham1_</a> <a
                                    href="https://twitter.com/TeamFullGorilla?ref_src=twsrc%5Etfw">@Teamfullgorilla</a>, <a
                                    href="https://twitter.com/CoachThomas04?ref_src=twsrc%5Etfw">@CoachThomas04</a>, <a
                                    href="https://twitter.com/SamCampfield?ref_src=twsrc%5Etfw">@SamCampfield</a>, <a
                                    href="https://twitter.com/glvwrktraining?ref_src=twsrc%5Etfw">@glvwrktraining</a>, TR_GrizzliesFB. <a
                                    href="https://t.co/Rrl2MOa92s">pic.twitter.com/Rrl2MOa92s</a></p>&mdash; Seid Benak (@BenakSeid) <a
                                href="https://twitter.com/BenakSeid/status/1652418147193090049?ref_src=twsrc%5Etfw">April 29, 2023</a>
                        </blockquote>
                    </div>
                    <div class="col-md-4 col-12">
                        <blockquote class="twitter-tweet">
                            <p lang="en" dir="ltr">Big things coming. Congrats brotha. <a
                                    href="https://t.co/bnpNIOYlNz">https://t.co/bnpNIOYlNz</a></p>&mdash; Seid Benak (@BenakSeid) <a
                                href="https://twitter.com/BenakSeid/status/1643351559151972354?ref_src=twsrc%5Etfw">April 4, 2023</a>
                        </blockquote>
                    </div>
                    <!-- <div class="col-md-4 col-12">
                        <blockquote class="twitter-tweet"><p lang="en" dir="ltr">Blessed to receive my 4th D1 offer from the university of Memphis! Huge thanks to <a href="https://twitter.com/CoachBradSalem?ref_src=twsrc%5Etfw">@CoachBradSalem</a> and the rest of the staff for the opportunity!<a href="https://twitter.com/TR_GrizzliesFB?ref_src=twsrc%5Etfw">@TR_GrizzliesFB</a> <a href="https://twitter.com/SamCampfield?ref_src=twsrc%5Etfw">@SamCampfield</a> <a href="https://twitter.com/ThunderRidge_AD?ref_src=twsrc%5Etfw">@ThunderRidge_AD</a> <a href="https://t.co/OtWSTClNw9">pic.twitter.com/OtWSTClNw9</a></p>&mdash; Zach Smith (@Zachari68893966) <a href="https://twitter.com/Zachari68893966/status/1647329153086005248?ref_src=twsrc%5Etfw">April 15, 2023</a></blockquote>
                    </div> -->
                </div>
            </div>
        </div>
        <!-- END: TESTIMONY -->

         <!-- START: SERVICES -->
        <div class="section" id="SERVICES">
            <div class="container">
                <!-- <div class="section-head text-center">
                    <span>WHAT I DO</span>
                    <h2>My Services</h2>
                </div> -->

                <div class="row mt-4 justify-content-center align-content-center">
                    <div class="col-md-8 col-12">
                        <div class="row justify-content-center align-items-center">
                            <div class="col-sm-6 col-lg-6">
                                <div class="services-list mt-4 wow fadeInUp" data-wow-duration="1.5s">
                                    <div class="services-list--content d-flex align-items-center b-box hover-state p-4">
                                        <!-- <div class="services-list-icon">
                                            <i class="lni-color-pallet"></i>
                                            <span>01</span>
                                        </div> -->
                                        <div class="services-list-desc">
                                            <!-- <h5 class="mb-0">Graphic Design</h5> -->
                                            <img src="assets/images/benak-offer-1.jpeg" alt="offer" title="offer" class="img-fluid">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col-sm-6 col-lg-6">
                                <div class="services-list mt-4 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay=".2s">
                                    <div class="services-list--content d-flex align-items-center b-box hover-state p-4">
                                        <div class="services-list-desc">
                                            <img src="assets/images/zach-offer1.png" alt="offer" class="img-fluid">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 col-lg-6">
                                <div class="services-list mt-4 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay=".3s">
                                    <div class="services-list--content d-flex align-items-center b-box hover-state p-4">
                                        <div class="services-list-desc">
                                            <img src="assets/images/zach-offer2.png" alt="offer" class="img-fluid">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 col-lg-6">
                                <div class="services-list mt-4 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay=".4s">
                                    <div class="services-list--content d-flex align-items-center b-box hover-state p-4">
                                        <div class="services-list-desc">
                                            <img src="assets/images/zach-offer3.png" alt="offer" class="img-fluid">
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- END: SERVICES -->

        <!-- START: PRICE -->
        <div class="section" id="PRICE">
            <!-- <div class="section-head text-center">
                <span>WHAT I DO</span>
                <h2>My Pricing</h2>
            </div> -->
            <div class="section-skills-wrap">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-md-6">
                            <div class="resume-list statslists b-box p-4 mb-4">
                                <div>
                                    <h5 class="mb-3">Top Stats </h5>
                                    <div class="yearly-stats seidbenak-stats">
                                        <div class="stat bdrrgt">
                                            <div class="name">GP</div>
                                            <div class="value">5</div>
                                        </div>
                                        <div class="gradient"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- END: PRICE -->

        <!-- START: RESUME -->
        <div class="section" id="RECRUITERS">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-4 col-12">
                        <div class="showTime_Logo" (click)="gotoShowTimeProfileCreation()">
                            <img src="assets/images/logo_showtime.jpg" alt="resume" title="resume">
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-md-4 col-12">
                        <!-- <div class="scroll-prompt" scroll-prompt="" ng-show="showPrompt" style="opacity: 1;">
                            <div class="scroll-prompt-arrow-container">
                                <div class="scroll-prompt-arrow"><div></div></div>
                                <div class="scroll-prompt-arrow"><div></div></div>
                            </div>
                        </div> -->
                        <div class="recruiterBtn">
                            <div class="animation">
                                <div class="scroll-prompt" scroll-prompt="" ng-show="showPrompt" style="opacity: 1;">
                                    <div class="scroll-prompt-arrow-container">
                                        <div class="scroll-prompt-arrow"><div></div></div>
                                        <div class="scroll-prompt-arrow"><div></div></div>
                                    </div>
                                </div>
                            </div>
                            <div class="animation-text">
                                <h4>Recruiters</h4>
                            </div>
                            <div class="animation">
                                <div class="scroll-prompt" scroll-prompt="" ng-show="showPrompt" style="opacity: 1;">
                                    <div class="scroll-prompt-arrow-container">
                                        <div class="scroll-prompt-arrow"><div></div></div>
                                        <div class="scroll-prompt-arrow"><div></div></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- START: RESUME -->
        <div class="section" id="RESUME">
            <div class="container">
                <div class="c-show-time-bottom">
                    <!-- <div class="c-show-time-bottom-btn">
                        <button class="c-btn-1" (click)="gotoShowTimeProfileCreation()">
                            Create My Show Time Profile
                        </button>
                    </div> -->
                    <div class="c-show-time-bottom-con">
                        <div class="row  justify-content-center">
                            <div class="col-lg-7 col-md-12">
                                <h4>Recruiters Get 5,000 prospects and <br> twitter profile</h4>
                                <video  height="280" width="100%" loop  muted controls>
                                    <source src="assets/images/cg-video.mp4" type="video/mp4">
                                </video>
                                <div class="c-show-time-bottom-btn-2">
                                    <button class="c-btn-1" (click)="gotoCollegeUserRegistration()">
                                        Sign Up
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="waves"></div>


    <div class="mobileView">
        <div class="mobileView-wrap">
            <div class="mobileView-content">
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
                <defs>
                    <linearGradient id="grad1" x1="100%" y1="100%" x2="0%" y2="60%">
                        <stop offset="40%" style="stop-color:#ff1ba6;stop-opacity:1"></stop>
                        <stop offset="100%" style="stop-color:#ed2a4a;stop-opacity:1"></stop>
                    </linearGradient>
                </defs>
                <text fill="url(#grad1)" font-size="60" x="0" y="100" style="
                font-weight: 700;
            ">Too small!</text>
                </svg>
                <h4 class="mb-3">MAKE YOUR BROWSER WINDOW WIDER</h4>
                <p> Please resize the browser window wider, you are not in mobile device. Switch to mobile device to see the mobile view.</p>
            </div>
        </div>
    </div>

</div>

<div  data-spy="scroll" data-target=".navbar" class="c-shotime-w" *ngIf="userName === 'mh'">
    <!-- START: Preloader -->
    <div id="preloader" class="preloader">
        <div class="spinner-grow text-light" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
    <!-- END: Preloader -->

    <header>
        <div class="container">
            <div class="d-flex justify-content-between align-items-center">
                <a href="showtime-profile;_user=mh#HOME" class="header-logo" >Max Hughes</a>

                <div class="menu d-flex justify-content-center align-items-center">

                    <!-- <a href="#CONTACT" class="head-contact btn btn-small btn-gradient mr-4">Contact</a> -->

                    <button class="btn btn-small collapsed navbar-toggler" data-toggle="collapse" data-target="#" aria-controls="" aria-expanded="false" aria-label="Toggle navigation"> 
                        <svg class="ham hamRotate ham1" viewBox="0 0 100 100" width="40"><path class="line top" d="m 30,33 h 40 c 0,0 9.044436,-0.654587 9.044436,-8.508902 0,-7.854315 -8.024349,-11.958003 -14.89975,-10.85914 -6.875401,1.098863 -13.637059,4.171617 -13.637059,16.368042 v 40"></path><path class="line middle" d="m 30,50 h 40"></path><path class="line bottom" d="m 30,67 h 40 c 12.796276,0 15.357889,-11.717785 15.357889,-26.851538 0,-15.133752 -4.786586,-27.274118 -16.667516,-27.274118 -11.88093,0 -18.499247,6.994427 -18.435284,17.125656 l 0.252538,40"></path></svg>
                    </button>

                    <div class="navbar-collapse collapse" id="">
                        <div class="container">
                            <div class="navbar-content">
                                <div class="navbar-links h-100vh d-flex align-items-center">
                                    <ul>	
                                        <li>
                                            <span><i class="lni lni-cloud"></i></span>
                                            <a href="showtime-profile;_user=mh#HIGHLIGHTS">Highlights</a>
                                        </li>
                                        <li>
                                            <span><i class="lni lni-book"></i></span>
                                            <a href="showtime-profile;_user=mh#ABOUT">ABOUT</a>
                                        </li>		
                                        <!-- <li>
                                            <span><i class="lni lni-offer"></i></span>
                                            <a href="showtime-profile;_user=mh#OFFERS">OFFERS</a>
                                        </li> -->
                                        <li>
                                            <span><i class="lni lni-bar-chart"></i></span>
                                            <a href="showtime-profile;_user=mh#STATS">STATS</a>
                                        </li>
                                    </ul>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>	
            </div>
        </div>
    </header>

    <div id="pagepiling">

        <!-- START: Home -->
        <div class="section section-home" id="HOME">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-md-7 col-12">
                        <div class="hero-content text-center">
                            <span class="hello mb-4 d-block"></span>
                            <h1><span>Max Hughes</span></h1>

                            <div id="autoplay" class="my-4">
                            <span> RB, MLB, OLB 2024</span>
                            <span>5'11", 26</span>
                            <span>Highlands Ranch, CO</span>
                            </div>   

                        </div>
                    </div>
                    <div class="col-md-5 col-12">
                        <div class="rigtBox">
                            <img src="assets/images/max-hudg.png" alt="player-profile" title="player-profile" class="img-fluid">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- END: Home -->

        <!-- START: PORTFOLIO -->
        <div class="section" id="PORTFOLIO">
            <div class="container">
                <div class="container swiper-container swiper-portfolio wow fadeInUp mt-5 pt-5" data-wow-duration="1.5s">
                    <div class="swiper-wrapper">
                        <!-- <div class="portfolio-item swiper-slide " data-groups="all slideshow">
                            <a href="https://static.hudl.com/users/prod/11589817_41ebc2e257084d5897e6382dd7e85046.jpg" class="portfolio-item--content simple-ajax-popup">
                                <div class="portfolio-item--img b-box">
                                    <img src="https://static.hudl.com/users/prod/11589817_41ebc2e257084d5897e6382dd7e85046.jpg" class="img-fluid mx-auto" alt="work-img">
                                </div>
                            </a>
                            
                        </div> -->
                        <div class="portfolio-item swiper-slide image" data-groups="all image">
                            <a href="https://vf.hudl.com/p-highlights/User/15207949/634b1ad806622b04642515b3/281dbc1d_720.mp4?v=5C5F9D29B4D0DA08" class="portfolio-item--content simple-ajax-video-popup">
                                <div class="portfolio-item--img b-box">
                                    <!-- <img src="assets/images/ytv2.mp4" class="img-fluid mx-auto" alt="work-img"> -->
                                    <video width="100%" height="400" controls="false" autoplay="flase" muted loop>
                                        <source src="https://vf.hudl.com/p-highlights/User/15207949/634b1ad806622b04642515b3/281dbc1d_720.mp4?v=5C5F9D29B4D0DA08" type="video/mp4">
                                    </video>
                                </div>
                            </a>
                        </div>

                        <div class="portfolio-item swiper-slide image" data-groups="all image">
                            <a href="https://vf.hudl.com/p-highlights/User/15207949/637978d6688e640a3863e18f/0b71ca91_720.mp4?v=E55F0838B4D0DA08" class="portfolio-item--content simple-ajax-video-popup">
                                <div class="portfolio-item--img b-box">
                                    <!-- <img src="assets/images/ytv2.mp4" class="img-fluid mx-auto" alt="work-img"> -->
                                    <video width="100%" height="400" controls="false" autoplay="flase" muted loop>
                                        <source src="https://vf.hudl.com/p-highlights/User/15207949/637978d6688e640a3863e18f/0b71ca91_720.mp4?v=E55F0838B4D0DA08" type="video/mp4">
                                    </video>
                                </div>
                            </a>
                        </div>

                        <div class="portfolio-item swiper-slide slideshow" data-groups="all slideshow">
                            <a href="https://vf.hudl.com/p-highlights/User/15207949/6337c99234488805c8782004/03920ca7_720.mp4?v=27114428B4D0DA08" class="portfolio-item--content simple-ajax-video-popup">
                                <div class="portfolio-item--img b-box">
                                    <!-- <img src="assets/images/p1-1.jpg" class="img-fluid mx-auto" alt="work-img"> -->
                                    <video width="100%" height="400" controls="false" autoplay="autoplay" muted loop>
                                        <source src="https://vf.hudl.com/p-highlights/User/15207949/6337c99234488805c8782004/03920ca7_720.mp4?v=27114428B4D0DA08" type="video/mp4">
                                    </video>
                                </div>
                            </a>
                        </div>

                        <div class="portfolio-item swiper-slide video" data-groups="all video">
                            <a href="https://vf.hudl.com/p-highlights/User/15207949/633f9b7a8cdf28054479a059/a7ad5bc6_720.mp4?v=8C830335B4D0DA08" class="portfolio-item--content simple-ajax-video-popup">
                                <div class="portfolio-item--img b-box">
                                    <!-- <img src="assets/images/ytv2.mp4" class="img-fluid mx-auto" alt="work-img"> -->
                                    <video width="100%" height="400" controls="false" autoplay="autoplay" muted loop>
                                        <source src="https://vf.hudl.com/p-highlights/User/15207949/633f9b7a8cdf28054479a059/a7ad5bc6_720.mp4?v=8C830335B4D0DA08" type="video/mp4">
                                    </video>
                                </div>
                            </a>
                        </div>
                        <div class="portfolio-item swiper-slide video" data-groups="all video">
                            <a href="https://vf.hudl.com/p-highlights/User/15207949/634a4c8db3c8d10b981b6e1f/a2137ec1_720.mp4?v=D16A79AAD0C1DA08" class="portfolio-item--content simple-ajax-video-popup">
                                <div class="portfolio-item--img b-box">
                                    <!-- <img src="assets/images/ytv2.mp4" class="img-fluid mx-auto" alt="work-img"> -->
                                    <video width="100%" height="400" controls="false" autoplay="autoplay" muted loop>
                                        <source src="https://vf.hudl.com/p-highlights/User/15207949/634a4c8db3c8d10b981b6e1f/a2137ec1_720.mp4?v=D16A79AAD0C1DA08" type="video/mp4">
                                    </video>
                                </div>
                            </a>
                        </div>

                        <!-- <div class="portfolio-item swiper-slide  slideshow" data-groups="all slideshow">
                            <a href="https://vf.hudl.com/p-highlights/User/15207949/634a4c8db3c8d10b981b6e1f/a2137ec1_720.mp4?v=D16A79AAD0C1DA08" class="portfolio-item--content simple-ajax-popup">
                                <div class="portfolio-item--img b-box">
                                    <img src="https://vf.hudl.com/p-highlights/User/15207949/634a4c8db3c8d10b981b6e1f/a2137ec1_720.mp4?v=D16A79AAD0C1DA08" class="img-fluid mx-auto" alt="work-img">
                                </div>
                            </a>
                        </div> -->
                        
                    </div>
                    <div class="portfolio-pagination">
                        
                    </div>
                </div>
            </div>
        </div>
        <!-- END: PORTFOLIO -->

        <!-- START: ABOUT -->
        <div class="section" id="ABT">
            <div class="container">

                <div class="row justify-content-center align-items-center">
                    <div class="col-md-5">
                        <img class="about-img about-showtime-profile img-fluid wow fadeInUp" data-wow-duration="1s" src="assets/images/max-hudg-about.jpg" alt="about-picture">
                    </div>
                    <div class="col-md-7">

                        <div class="about-desc wow fadeInUp" data-wow-duration="1s"  data-wow-delay="500ms">
                            <div class="about-desc-content">
                                <!-- <h1 class="font-weight-light mb-5">DJ Bordeaux</h1> -->
                                <!-- <p class="font-weight-light my-3">Zach Smith athletic achievements -
                                    <li>All Conference Honorable Mention Varsity Basketball 2022</li>
                                    <li>All Star Basketball team 2022</li>
                                    <li>Newcomer (Rookie) of the Year 2021 Varsity Football - Highlands Ranch, CO</li></p> -->
                                    <p>Max is ranked in the top 49 in Colorado for 1 stat, top 12 in Division 5A for 1 stat, and top 3 in 5A League 5 for 1 stat.</p>
                            </div>

                            <div class="about-desc-more text-left mt-5">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="about-desc-info">
                                            <div class="about-desc-info-content d-flex align-items-center hover-state b-box">
                                                <div class="about-desc-icon">
                                                    <i class="lni lni-calendar"></i>
                                                </div>
                                                <div class="about-desc-info-desc">
                                                    <span>Junior • 2024</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="about-desc-info">
                                            <div class="about-desc-info-content d-flex align-items-center hover-state b-box">
                                                <div class="about-desc-icon">
                                                    <i class="lni lni-pin"></i>
                                                </div>
                                                <div class="about-desc-info-desc">
                                                    <span>Highlands Ranch, CO</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="about-desc-info">
                                            <div class="about-desc-info-content d-flex align-items-center hover-state b-box">
                                                <div class="about-desc-icon">
                                                    <i class="lni lni-graduation"></i>
                                                </div>
                                                <div class="about-desc-info-desc">
                                                    <span>Thunderridge High School</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="about-desc-info">
                                            <div class="about-desc-info-content d-flex align-items-center hover-state b-box">
                                                <div class="about-desc-icon">
                                                    <i class="lni lni-twitter"></i>
                                                </div>
                                                <div class="about-desc-info-desc">
                                                    <a href="https://twitter.com/MaxHughes26?s=08" title="https://twitter.com/MaxHughes26?s=08" target="_blank">https://twitter.com/MaxHughes26?s=08</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>			
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- END: ABOUT -->

        <!-- START: TESTIMONY -->
        <div class="section feed-section" id="TESTIMONY">
            <div class="container">
                <div class="row">
                    <div class="col-md-4 col-12">
                        <blockquote class="twitter-tweet">
                            <p lang="en" dir="ltr">Checkout my Junior Season Highlights! So thankful for my teammates and coaches who always
                                push me to be my best. Thank you <a href="https://twitter.com/CoachNise?ref_src=twsrc%5Etfw">@CoachNise</a> and
                                <a href="https://twitter.com/CoachCarnes?ref_src=twsrc%5Etfw">@CoachCarnes</a> for a great year. Excited for my
                                Senior Season! <a href="https://t.co/onrmT1LKqN">https://t.co/onrmT1LKqN</a></p>&mdash; Max Hughes
                            (@MaxHughes26) <a href="https://twitter.com/MaxHughes26/status/1596967850430070784?ref_src=twsrc%5Etfw">November 27,
                                2022</a>
                        </blockquote>
                    </div>
                    <div class="col-md-4 col-12">
                        <blockquote class="twitter-tweet">
                            <p lang="en" dir="ltr">Week 1-5 Highlights 5-0<a href="https://t.co/HDAILdYr7q">https://t.co/HDAILdYr7q</a></p>
                            &mdash; Max Hughes (@MaxHughes26) <a
                                href="https://twitter.com/MaxHughes26/status/1573756520524677120?ref_src=twsrc%5Etfw">September 24, 2022</a>
                        </blockquote>
                        <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
                    </div>
                    <div class="col-md-4 col-12">
                        <blockquote class="twitter-tweet">
                            <p lang="en" dir="ltr">Sophomore regular season highlights! ⁦<a
                                    href="https://twitter.com/CoachNise?ref_src=twsrc%5Etfw">@CoachNise</a>⁩⁦⁦<a
                                    href="https://twitter.com/TR_GrizzliesFB?ref_src=twsrc%5Etfw">@TR_GrizzliesFB</a>⁩<br><br>- <a
                                    href="https://t.co/Kq152WFBPq">https://t.co/Kq152WFBPq</a></p>&mdash; Max Hughes (@MaxHughes26) <a
                                href="https://twitter.com/MaxHughes26/status/1455766220410527746?ref_src=twsrc%5Etfw">November 3, 2021</a>
                        </blockquote>
                    </div>
                </div>
            </div>
        </div>
        <!-- END: TESTIMONY -->

        <!-- START: SERVICES -->
        <!-- <div class="section" id="SERVICES">
            <div class="container">
                <div class="section-head text-center">
                    <span>WHAT I DO</span>
                    <h2>My Services</h2>
                </div>

                <div class="row mt-4 justify-content-center align-content-center">
                    <div class="col-md-6 col-12">
                        <div class="resume-list statslists noHighlight b-box p-4 mb-4">
                            <h4>No Offers Data Found</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- END: SERVICES -->

        <!-- START: PRICE -->
        <div class="section" id="PRICE">
            <!-- <div class="section-head text-center">
                <span>WHAT I DO</span>
                <h2>My Pricing</h2>
            </div> -->
            <div class="section-skills-wrap">
                <div class="container">
                    <div class="row ">
                        <div class="col-md-6">
                            <div class="resume-list statslists b-box p-4 mb-4">
                                <div>
                                    <h5>TACKLES <span class="badge badge-light d-inline-block my-3">2022-2023</span></h5>
                                    <h4 class="d-flex justify-content-between align-items-center">
                                        <p>GD: Jr.</p>
                                        <p>TEAM: Var</p>
                                    </h4>
                                    <div class="yearly-stats tackle-stats">
                                        <div class="stat">
                                            <div class="name">GP</div>
                                            <div class="value">13</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">SOLO</div>
                                            <div class="value">33</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">ASST</div>
                                            <div class="value">43</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">TOT TCKLS</div>
                                            <div class="value">76</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">T/G</div>
                                            <div class="value">5.8</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">TFL</div>
                                            <div class="value">14.0</div>
                                        </div>
                                        <div class="gradient"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="resume-list statslists b-box p-4 mb-4">
                                <div>
                                    <h5>SACKS <span class="badge badge-light d-inline-block my-3">2022-2023</span></h5>
                                    <h4 class="d-flex justify-content-between align-items-center">
                                        <p>GD: Jr.</p>
                                        <p>TEAM: Var</p>
                                    </h4>
                                    <div class="yearly-stats tackle2-stats">
                                        <div class="stat">
                                            <div class="name">GP</div>
                                            <div class="value">13</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">SACKS </div>
                                            <div class="value">7.0</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">YDL</div>
                                            <div class="value">74</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">S/G</div>
                                            <div class="value">0.5</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">HURS</div>
                                            <div class="value">6</div>
                                        </div>
                                        <div class="gradient"></div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="col-md-12">
                            <div class="resume-list statslists b-box p-4 mb-4">
                                <div>
                                    <h5>DEFENSIVE STATISTICS <span class="badge badge-light d-inline-block my-3">2022-2023</span></h5>
                                    <h4 class="d-flex justify-content-between align-items-center">
                                        <p>GD: Jr.</p>
                                        <p>TEAM: Var</p>
                                    </h4>
                                    <div class="yearly-stats fisher1-stats">
                                        <div class="stat">
                                            <div class="name">GP</div>
                                            <div class="value">13</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">INT</div>
                                            <div class="value">0</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">INT YDS</div>
                                            <div class="value">0</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">AVG</div>
                                            <div class="value">108</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">INT/G</div>
                                            <div class="value">0</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">PD</div>
                                            <div class="value">7</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">FMB REC</div>
                                            <div class="value">1</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">FR YDS</div>
                                            <div class="value">0</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">CAUS</div>
                                            <div class="value">2</div>
                                        </div>
                                        <div class="stat">
                                            <div class="name">BLK PNTS</div>
                                            <div class="value">0</div>
                                        </div>
                                        <div class="stat bdrrgt">
                                            <div class="name">BLK FGS</div>
                                            <div class="value">0</div>
                                        </div>
                                        <div class="gradient"></div>
                                    </div>
                                </div>
                            </div>
                        </div>	
                    </div>
                </div>
            </div>
        </div>
        <!-- END: PRICE -->

        <!-- START: RESUME -->
        <div class="section" id="RECRUITERS">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-4 col-12">
                        <div class="showTime_Logo" (click)="gotoShowTimeProfileCreation()">
                            <img src="assets/images/logo_showtime.jpg" alt="resume" title="resume">
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-md-4 col-12">
                        <!-- <div class="scroll-prompt" scroll-prompt="" ng-show="showPrompt" style="opacity: 1;">
                            <div class="scroll-prompt-arrow-container">
                                <div class="scroll-prompt-arrow"><div></div></div>
                                <div class="scroll-prompt-arrow"><div></div></div>
                            </div>
                        </div> -->
                        <div class="recruiterBtn">
                            <div class="animation">
                                <div class="scroll-prompt" scroll-prompt="" ng-show="showPrompt" style="opacity: 1;">
                                    <div class="scroll-prompt-arrow-container">
                                        <div class="scroll-prompt-arrow"><div></div></div>
                                        <div class="scroll-prompt-arrow"><div></div></div>
                                    </div>
                                </div>
                            </div>
                            <div class="animation-text">
                                <h4>Recruiters</h4>
                            </div>
                            <div class="animation">
                                <div class="scroll-prompt" scroll-prompt="" ng-show="showPrompt" style="opacity: 1;">
                                    <div class="scroll-prompt-arrow-container">
                                        <div class="scroll-prompt-arrow"><div></div></div>
                                        <div class="scroll-prompt-arrow"><div></div></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- START: RESUME -->
        <div class="section" id="RESUME">
            <div class="container">
                <div class="c-show-time-bottom">
                    <!-- <div class="c-show-time-bottom-btn">
                        <button class="c-btn-1" (click)="gotoShowTimeProfileCreation()">
                            Create My Show Time Profile
                        </button>
                    </div> -->
                    <div class="c-show-time-bottom-con">
                        <div class="row  justify-content-center">
                            <div class="col-lg-7 col-md-12">
                                <h4>Recruiters Get 5,000 prospects and <br> twitter profile</h4>
                                <video  height="280" width="100%" loop  muted controls>
                                    <source src="assets/images/cg-video.mp4" type="video/mp4">
                                </video>
                                <div class="c-show-time-bottom-btn-2">
                                    <button class="c-btn-1" (click)="gotoCollegeUserRegistration()">
                                        Sign Up
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="waves"></div>


    <div class="mobileView">
        <div class="mobileView-wrap">
            <div class="mobileView-content">
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
                <defs>
                    <linearGradient id="grad1" x1="100%" y1="100%" x2="0%" y2="60%">
                        <stop offset="40%" style="stop-color:#ff1ba6;stop-opacity:1"></stop>
                        <stop offset="100%" style="stop-color:#ed2a4a;stop-opacity:1"></stop>
                    </linearGradient>
                </defs>
                <text fill="url(#grad1)" font-size="60" x="0" y="100" style="
                font-weight: 700;
            ">Too small!</text>
                </svg>
                <h4 class="mb-3">MAKE YOUR BROWSER WINDOW WIDER</h4>
                <p> Please resize the browser window wider, you are not in mobile device. Switch to mobile device to see the mobile view.</p>
            </div>
        </div>
    </div>

</div>
    