<div class="media profile-media">
  <!-- <img class="b-r-10" src="assets/images/dashboard/profile.jpg" alt=""> -->

  <div class="media-body onhover-dropdown-1">
    <h4>{{name}}</h4>
    <span>{{userName}}</span>
    <h4 class="c-role-heading">{{role}}</h4>
    <!-- <p class="mb-0 font-roboto">{{roleName}} <i class="middle fa fa-angle-down"></i></p> -->
    <!-- <h4 *ngIf="!this.isLifeTime">{{this.packageName}}</h4>
    <h4 *ngIf="this.isLifeTime">LIFETIME ACCOUNT</h4> -->
  </div>
</div>
<ul class="profile-dropdown onhover-show-div">
  <!-- <li><a href="#"><app-feather-icons [icon]="'user'"></app-feather-icons><span>Profile </span></a></li> -->
  <!-- <li><a href="#"><app-feather-icons [icon]="'mail'"></app-feather-icons><span>Inbox</span></a></li>
  <li><a href="#"><app-feather-icons [icon]="'file-text'"></app-feather-icons><span>Taskboard</span></a></li>-->
  <li *ngIf="userType == 'H'">
    <span style="cursor: pointer;" (click)="openPlayerList()">
      <app-feather-icons [icon]="'key'"></app-feather-icons><span>Player Login</span>
    </span>
  </li>
  <li *ngIf="isViewCoachLogin == true">
    <span class="header-search" (click)="goToCoachLogin()">
      <app-feather-icons [icon]="'key'"></app-feather-icons>Back to my Login
    </span>
  </li>
  <li *ngIf="isPackagePaid == true">
    <span style="cursor: pointer;" (click)="accSetting()">
      <app-feather-icons [icon]="'settings'"></app-feather-icons><span>Settings</span>
    </span>
  </li>
  <!-- <li>
    <a href="javascript:void(0)" (click)="changePass()">
      <i class="fa fa-key"></i><span>&nbsp; Change Password</span>
    </a>
  </li> -->
  <li>
    <span style="cursor: pointer;" (click)="logout()">
      <app-feather-icons [icon]="'log-out'"></app-feather-icons><span>Log Out</span>
    </span>
  </li>
</ul>

<div class="customizer-contain" [ngClass]="{'open' : customizer }">
  <div class="tab-content" id="c-pills-tabContent">
    <div class="customizer-header">
      <i class="icofont-close icon-close" (click)="Customizer()"></i>
      <h5>Theme Settings</h5>
      <!-- <p class="mb-0">Try It Real Time <i class="fa fa-thumbs-o-up txt-primary"></i></p> -->
      <!-- <button class="btn btn-primary plus-popup mt-2" (click)="openModal(popup)">Configuration</button> -->
      <ng-template #popup let-modal>
        <!--             <div class="modal-header modal-copy-header">
               <h5 class="headerTitle mb-0">Customizer configuration</h5>
               <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
               </button>
            </div> -->
        <div class="modal-body">
          <div class="modal-header config-popup">
            <div>
              <pre>
                        <!-- @CHANDAN - THIS SAVES THE WEB Prefernces I believe - Need API save : User Preferences.... or something -->
<!-- <code>
      public config = &#123;
         settings&#58; &#123;
            layout_type&#58; '{{layout.config.settings.layout_type}}',
            layout_version&#58; '{{layout.config.settings.layout_version}}',
            sidebar_type&#58; '{{layout.config.settings.sidebar_type}}',
            sidebar&#58; &#123;
               type&#58; '{{layout.config.settings.sidebar.type}}',
               body_type&#58; '{{layout.config.settings.sidebar.body_type}}'
            &#125;
         &#125;,
         color&#58; &#123;
            primary_color&#58; '{{layout.config.color.primary_color}}',
            secondary_color&#58; '{{layout.config.color.secondary_color}}'
         &#125;
      &#125;
</code> -->
                  </pre>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
    <div class="customizer-body custom-scrollbar">
      <div class="tab-pane fade" [ngClass]="{'active show': customizer }" id="c-pills-home" role="tabpanel"
        aria-labelledby="c-pills-home-tab">
        <h6>Layout</h6>
        <ul class="main-layout layout-grid">
          <li data-attr="ltr" [ngClass]="{'active': layoutType == 'ltr'}" (click)="customizeLayoutType('ltr')">
            <div class="header bg-light">
              <ul>
                <li></li>
                <li></li>
                <li></li>
              </ul>
            </div>
            <div class="body">
              <ul>
                <li class="bg-light sidebar"></li>
                <li class="bg-light body"><span class="badge badge-primary">LTR</span></li>
              </ul>
            </div>
          </li>
          <li data-attr="Box" class='box-layout' [ngClass]="{'active': layoutType == 'box-layout'}"
            (click)="customizeLayoutType('box-layout')">
            <div class="header bg-light">
              <ul>
                <li></li>
                <li></li>
                <li></li>
              </ul>
            </div>
            <div class="body">
              <ul>
                <li class="bg-light sidebar"></li>
                <li class="bg-light body"> <span class="badge badge-primary">BOX</span> </li>
              </ul>
            </div>
          </li>
        </ul>
        <h6>Sidebar</h6>
        <ul class="sidebar-type layout-grid">
          <li data-attr="normal-sidebar" [class.d-none]="screenwidth < 991"
            [ngClass]="{'active': sidebarType == 'horizontal-wrapper'}"
            (click)="customizeSidebarType('horizontal-wrapper')">
            <div class="header bg-light">
              <ul>
                <li></li>
                <li></li>
                <li></li>
              </ul>
            </div>
            <div class="body">
              <ul>
                <li class="bg-dark sidebar"></li>
                <li class="bg-light body"> </li>
              </ul>
            </div>
          </li>
          <li data-attr="compact-sidebar" [ngClass]="{'active': sidebarType == 'compact-wrapper'}"
            (click)="customizeSidebarType('compact-wrapper')">
            <div class="header bg-light">
              <ul>
                <li></li>
                <li></li>
                <li></li>
              </ul>
            </div>
            <div class="body">
              <ul>
                <li class="bg-dark sidebar compact"></li>
                <li class="bg-light body"></li>
              </ul>
            </div>
          </li>
        </ul>
        <h6 class="">Select Color</h6>
        <ul class="layout-grid unlimited-color-layout">
          <input id="ColorPicker1" [(ngModel)]="primary_color" type="color" value="#7366ff">
          <input id="ColorPicker2" [(ngModel)]="secondary_color" type="color" value="#f73164">
          <button type="button" class="color-apply-btn btn btn-primary color-apply-btn"
            (click)="applyColor()">Apply</button>
          <button type="button" class="color-apply-btn btn btn-primary color-apply-btn ml-2"
            (click)="resetColor()">Reset</button>
        </ul>
        <h6>Dark/Light Layout</h6>
        <ul class="layout-grid customizer-mix">
          <li class="color-layout" data-attr="default" [ngClass]="{'active': MIXLayout == 'default'}"
            (click)="customizeMixLayout('default')">
            <div class="header bg-light">
              <ul>
                <li></li>
                <li></li>
                <li></li>
              </ul>
            </div>
            <div class="body">
              <ul>
                <li class="bg-light sidebar"></li>
                <li class="bg-light body"> </li>
              </ul>
            </div>
          </li>
          <li class="color-layout" data-attr="dark-sidebar" [ngClass]="{'active': MIXLayout == 'dark-sidebar'}"
            (click)="customizeMixLayout('dark-sidebar')">
            <div class="header bg-light">
              <ul>
                <li></li>
                <li></li>
                <li></li>
              </ul>
            </div>
            <div class="body">
              <ul>
                <li class="bg-dark sidebar"></li>
                <li class="bg-light body"> </li>
              </ul>
            </div>
          </li>
          <li class="color-layout" data-attr="dark-only" [ngClass]="{'active': MIXLayout == 'dark-only'}"
            (click)="customizeMixLayout('dark-only')">
            <div class="header bg-dark">
              <ul>
                <li></li>
                <li></li>
                <li></li>
              </ul>
            </div>
            <div class="body">
              <ul>
                <li class="bg-dark sidebar"></li>
                <li class="bg-dark body"> </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<ng-template #playerListModal let-modal>

  <div class="modal-header">
    <h5 class="modal-title">Player List</h5>
    <button type="button" class="close" data-dismiss="modal" (click)="closeModal(modal)">
      <i class="icofont icofont-close"></i>
    </button>
  </div>
  <div class="modal-body p-3 c-searchForm-form">
    <div>
      <form [formGroup]="searchForm" (ngSubmit)="search()">
        <div class="input-group mb-3">
          <input #searchBox type="search" class="form-control" placeholder="Search.." data-original-title="" title=""
            formControlName="searchText" aria-describedby="basic-addon1">
          <button class="input-group-append c-search-btn">
            <span class="input-group-text searchBx" id="basic-addon1"><i class="fa fa-search"></i></span>
          </button>
        </div>
      </form>
      <div class="listboxex c-searchForm-list">
        <table class="table">
          <tr>
            <th>Player Name</th>
            <th>Login</th>
          </tr>
          <tr *ngFor="let player of rows; let i = index;">
            <td>{{player.name}}</td>
            <td><i class="fa fa-key" aria-hidden="true" (click)="goToPalyerLogin(player)" style="cursor: pointer;"></i>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</ng-template>