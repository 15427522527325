import { Component, OnInit, OnDestroy } from '@angular/core';
import { LoaderService } from '../../services/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  public show: boolean = true;
  message = 'Please Wait';

  constructor(private loaderService: LoaderService) {

    this.loaderService.isLoading.subscribe((v) => {
      //console.log(v);
      this.show = v;
    });
    this.loaderService.loadingMessage.subscribe((msg) => {
      this.message = msg;
    });
    // setTimeout(() => {
    //   this.show = false;
    // }, 3000);
  } 

  ngOnInit() { }

  ngOnDestroy() { }

}
