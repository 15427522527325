<section class="parrentPurchase">
    <a class="back-home-btn" ngbTooltip="Back To Home" placement="right" style="cursor: pointer;" (click)="go_home()">
        <img src="../../assets/images/home.png" class="img-fluid" alt="home" title="home">
    </a>
    <div class="row justify-content-center">
        <div class="col-md-6 col-12 col-lg-6 playbookPackage" *ngIf="isPlaybookShow">
            <div>
                <div class="headdingPlay">
                    <h2>Playbook Purchase</h2>
                    <!-- <h4>Onetime</h4>
                    <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ipsam, consequatur quae pariatur nostrum in, quisquam veniam aut temporibus aliquam sapiente nihil reprehenderit ducimus repellendus fuga adipisci asperiores. Nemo, rem id.</p> -->
                </div>
                <div class="row justify-content-center align-items-center mt-5">
                    <div class="playbookPackageBox d-flex justify-content-center align-items-center">
                        <div class="pricingtable">
                            <div class="price-value">
                                <span class="currency">$</span>
                                <span class="amount">{{playbook?.price}}</span>
                            </div>
                            <ul class="pricing-content">
                                <li>Rename Playbook to fit your scheme</li>
                                <li>Includes Full Playbook {{playbook?.name}}
                                    <ol class="innerlist mt-3">
                                        <li>Over {{purchasePlayBookDetails?.videoCount}} videos</li>
                                        <li>Over {{purchasePlayBookDetails?.diagramCount}} Diagrams</li>
                                        <li>{{purchasePlayBookDetails?.fomationSetCount}} Formation Set</li>
                                        <li>{{purchasePlayBookDetails?.modifierCount}} Modifiers</li>
                                        <li>{{purchasePlayBookDetails?.fomationCount}} Formations</li>
                                        <li>{{purchasePlayBookDetails?.conceptCount}} Concepts</li>
                                        <li>{{purchasePlayBookDetails?.playsCount}} Plays</li>
                                    </ol>
                                </li>
                            </ul>
                            <div class="pricingtable-signup">
                                <a class="btn btn-primary btn-lg" href="{{getPaymentUrl()}}">Buy Now</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-6 col-12 col-lg-6" [ngClass]="isPlaybookShow ? 'highlightPackage' : ''">
            <div class="row justify-content-center align-items-center h-100">
                <div class="col-md-8 col-lg-8 col-12">
                    <div class="offerTitle">
                        <!-- <h2>Purchase this package &amp; <span>{{upgradePackage?.saveMoney > 0 ? ' saved $ ' +
                                upgradePackage?.saveMoney : ' get all playbook'}}</span></h2> -->
                    </div>
                    <div class="c-price-box">
                        <div class="c-price-header-w">
                            <div class="c-price-header-in">
                                <h2>{{upgradePackage?.package?.name}}</h2>
                                <!-- <h3 *ngIf="upgradePackage?.package?.isLifeTime">Lifetime</h3> -->
                                <div class="c-price-number">
                                    ${{upgradePackage?.packagePricePlan?.price}}<br>
                                    <p>every 30 days</p>
                                </div>
                            </div>
                        </div>
                        <div class="c-price-feature-list">
                            <ul>
                                <li>
                                    <p>No Limits on any software or tools</p>
                                    <p> -Unlimited Players</p>
                                    <p> -Unlimited Coaches</p>
                                    <p> -Unlimited Playbook</p>
                                </li>
                                <!-- <li>
                                    <h4>Playbooks</h4>
                                    <ol>
                                        <li>All Current &amp; Future Playbooks you can edit them infinity times</li>
                                        <li>Cannot create or Add new playbooks</li>
                                    </ol>
                                </li> -->
                                <!-- <li>
                                    <h4>Unlimited Tools</h4>
                                    <p>List all of them and add as included</p>
                                </li> -->
                                <li>
                                    <h4>Film</h4>
                                    <p>Pay for what you use with film</p>
                                    <ol>
                                        <li>{{upgradePackage?.package?.freeStreamingSpace}} GBs of free streamed flim
                                            then pay {{upgradePackage?.package?.streamingChargePerGb}}
                                            cents per GB after
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <p>For 95% of teams, every player and every coach watching every game would cost >
                                        $1,000</p>
                                </li>
                            </ul>
                        </div>
                        <div class="c-price-footer-w">
                            <a class="c-btn-1" href="{{getUpgradePackageUrl()}}"><span>Subscribe</span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="exchangeIcon pulse" *ngIf="isPlaybookShow">
            <img src="../../assets/images/transfer.png" class="img-fluid" alt="transfer" title="transfer">
        </div>
    </div>
</section>