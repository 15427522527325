import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PlaybookService } from '../data/services/offense';

@Component({
  selector: 'app-playbook-package-purchase',
  templateUrl: './playbook-package-purchase.component.html',
  styleUrls: ['./playbook-package-purchase.component.css']
})
export class PlaybookPackagePurchaseComponent implements OnInit {
  public playbookId: any;
  public playbook: any = {};
  public isPlaybookShow: boolean = false;
  purchasePlayBookDetails: any;
  upgradePackage: any;
  constructor(
    private playbookService: PlaybookService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.playbookId = this.route.snapshot.paramMap.get('playbookId');
    if (this.playbookId) {
      this.isPlaybookShow = true;
      forkJoin(this.playbookService.get(this.playbookId),
        this.playbookService.getPurchasablePlaybookDetails(this.playbookId),
        this.playbookService.upgradePackage())
        .subscribe(([response1, response2, response3]) => {
          this.playbook = response1;
          this.purchasePlayBookDetails = response2;
          this.upgradePackage = response3;
        });
    }
    else {
      this.playbookService.upgradePackage().subscribe(resp => {
        this.upgradePackage = resp;
      });
    }
  }

  getPaymentUrl() {
    return environment.printPath + "Payments/playbook?playbookId=" + this.playbookId + "&accountId=" + this.upgradePackage?.accountId;
  };

  getUpgradePackageUrl() {
    localStorage.removeItem('__qu__remeber_User__');
    localStorage.removeItem('__qu__');
    return environment.printPath + "Payments/30days-subscription?packageId=" + this.upgradePackage?.package?.id + "&accountId=" + this.upgradePackage?.accountId;
    //return environment.printPath + "Payments?packageId=" + this.upgradePackage?.package?.id + "&accountId=" + this.upgradePackage?.accountId;
  }

  go_home() {
    this.router.navigate(["/auth/login"]);
  };
}
