<div class="container product-wrapper">
  <div class="justify-content-center product-grid row">
    <div class="col-lg-12 ">
      <div class="c-faq-hw" *ngIf="!afterVerifiationAccess">
        <button type="button" class="c-faq-back" (click)="imgLogoclick()">
          <i class="fa fa-home" aria-hidden="true"></i>
        </button>
        <div (click)="imgLogoclick()" class="c-faq-logo">
          <a class="logo text-left">
            <img class="img-fluid for-light" src="assets/images/logo/login.png" alt="looginpage" title="looginpage">
            <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="looginpage" title="looginpage">
          </a>
        </div>
      </div>


      <div class="c-faq-page-w">
        <div class="row justify-content-center">
          <div class="col-md-9">
            <div class="c-faq-search-panel">
              <h2>How can we help you</h2>
              <form [formGroup]="searchForm" (ngSubmit)="search()">
                <div class="input-group mb-3">
                  <input #searchBox type="search" class="form-control" placeholder="Search.." data-original-title=""
                    title="" formControlName="searchText" aria-describedby="basic-addon1">
                  <button class="input-group-append c-search-btn">
                    <span class="input-group-text searchBx" id="basic-addon1"><i class="fa fa-search"></i></span>
                  </button>
                </div>
              </form>
              <div class="c-faq-search-panel-con c-faq-search-panel-con-new">
                <div class="row">
                  <div class="col-lg-12 col-md-12">
                    <ngb-accordion #accT="ngbAccordion" [activeIds]="activeId" (panelChange)="toggleAccordian($event)">
                      <ngb-panel [id]="'ngb-panel-' + i" title="{{acc.name}}" *ngFor="let acc of faqs ; let i = index">
                        <ng-template ngbPanelContent>
                          <p>{{acc.description}}</p>
                          <div *ngIf="acc.faqLink != null">
                            <!-- <p class="mb-1"><strong>To know more information click on the below link </strong> </p> -->
                            <div
                              style="position:relative;height:0;width:100%;overflow:hidden;box-sizing:border-box;padding-bottom:calc(51.95924764890282% - 0px)"
                              *ngIf="acc.faqLink != null">
                              <iframe [src]="acc.faqSantizeUrl" width="100%" height="100%"
                                style="position:absolute;inset:0" allowfullscreen frameborder="0"></iframe>
                            </div>
                            <!-- <a class="c-know-btn"  href="{{acc.faqLink}}" target="_blank">{{acc.faqLink}}</a> -->
                          </div>
                        </ng-template>
                      </ngb-panel>
                    </ngb-accordion>
                  </div>
                  <!-- <div class="col-lg-5 col-md-12">
                               <div class="c-faq-page-img">
                                    <img src="../../../assets/images/faq-img.png" alt="">
                               </div>
                          </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>