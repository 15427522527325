import { Component, OnInit } from '@angular/core';
import { LoaderService } from '../shared/services/loader.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UrlSharingService } from '../data/services/global/url-sharing.service';
import { parse } from 'url';

@Component({
  selector: 'app-url-sharing',
  templateUrl: './url-sharing.component.html',
  styleUrls: ['./url-sharing.component.css']
})
export class UrlSharingComponent implements OnInit {
  code: any;

  constructor(
    private loaderService: LoaderService,
    private route: ActivatedRoute,
    private router: Router,
    public toster: ToastrService,
    private urlSharingService: UrlSharingService
  ) { }

  ngOnInit() {
    this.loaderService.isLoading.next(true);
    this.code = this.route.snapshot.paramMap.get('_code');
    if (this.code) {
      this.urlSharingService.getOrginalUrl(this.code).subscribe(resp => {
        if (resp && resp.success) {
          this.loaderService.isLoading.next(false);
          window.location.href = resp.response;
        }
        else {
          this.loaderService.isLoading.next(false);
          this.router.navigate(["/404"]);
        }
      })
    }
    else {
      this.loaderService.isLoading.next(false);
      this.router.navigate(["/404"]);
    }
  }
}
