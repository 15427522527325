<header class="main-header">
	<div class="container">
		<div class="row">
			<div class="col-12 p-0">
				<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
					<a class="navbar-brand" href="index.html"><img src="../../assets/images/home-logo-ss.png"
							alt="STRONGSIDE" height="40" title="STRONGSIDE" /></a>
					<button class="navbar-toggler" type="button" data-toggle="collapse"
						data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
						aria-expanded="false" aria-label="Toggle navigation">
						<span class="navbar-toggler-icon"></span>
					</button>
					<div class="collapse navbar-collapse" id="navbarSupportedContent">
						<ul class="navbar-nav ml-auto">
							<li class="nav-item">
								<a class="nav-link" href="/">HOME</a>
							</li>
							<!-- <li class="nav-item">
								<a class="nav-link" href="#prices">PRICE</a>
							</li> -->
							<li class="nav-item">
								<a class="nav-link" href="/docs">FAQ</a>
							</li>
							<li class="nav-item">
								<a class="nav-link" href="#">ABOUT US</a>
							</li>
							<li class="nav-item">
								<a class="nav-link" href="/careers">CAREERS</a>
							</li>
						</ul>
						<ul class="navbar-nav ml-auto">
							<div class="btn-grid m-0">
								<a href="authentication/register" class="primary-link-btn" title="Start">Sign Up</a>
								&nbsp;
								<a href="auth/login" class="default-link-btn" title="Sign In">sign in</a>
							</div>
						</ul>
					</div>
				</nav>
			</div>
		</div>
	</div>
</header>

<div class="page-main-banner-section" id="home">
	<div class="container-fluid">
		<div class="row align-items-center">
			<div class="col-lg">
				<div class="banner-information">
					<h1 style="padding-top: 40px" class="wow fadeInDown" data-wow-delay="1s">
						<span>STRONG</span>SIDE
					</h1>
					<h3 class="wow fadeInDown" data-wow-delay="1s">
						Welcome to <span> Our Football Program</span>
					</h3>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="features-wraper" id="features">
	<div class="container">
		<div class="row">
			<div class="col-lg-8 col-md-12 col-sm-12">
				<div class="features-grid wow fadeInDown" data-wow-delay="0.3s">
					<!-- NEED A "Wokring Image - or need the dashboard working" -->
					<h3 class="title text-left" style="color: #b6f9c9">Lost</h3>
					<p class="text-left" style="font-size: 15px">
						A year ago we ended a season with a playoff loss to the enventual
						champions. And that doesn't make an L feel any better. After we had a
						number of coaches, opponents, and my mom say things like
					</p>
					<div>
						<h4>
							That was a good game plan.<br />
							That was the best anyone has played us. <br />
						</h4>
					</div>
					<p class="text-left" style="font-size: 15px">
						And it was, and it's a credit to the excellent coaches on our staff. I
						don't generally fall into that category - excellent coach is not me. It
						isn't some humble thing. I'm not a bad coach. Not great. I'm lost at
						times. In setting out to get better this happened.
					</p>
				</div>
				<div class="features-grid wow fadeInDown" data-wow-delay="0.3s">
					<!-- NEED A "Wokring Image - or need the dashboard working" -->
					<h3 class="title text-left" style="color: #b6f9c9">Broken</h3>
					<p class="text-left" style="font-size: 15px">
						The number of coaches who are willing to share what they know is always
						astounding to me. I can't imagine any other place where people truly love
						talking about the same thing. That's where I started, focused time
						learning from experts. After sitting in one coaches kitchen for 5 hours
						and scratching the surface of understanding basic coverages. I pulled up
						the playbook software our team used. And I found the biggest problem I
						would face to learning. Most playbooks have no sense of scale.
					</p>
					<div>
						<h4>
							Players were 2 yards wide
							<br />
							Numbers were 5 feet wide
							<br />
						</h4>
					</div>
					<p class="text-left" style="font-size: 15px">
						If 10 yards from the LOS was to scale then 20 yards was the same as 15
						yards and the field would need to be 30 yards wide
					</p>
				</div>
				<div class="features-grid wow fadeInDown" data-wow-delay="0.3s">
					<!-- NEED A "Wokring Image - or need the dashboard working" -->
					<h3 class="title text-left" style="color: #b6f9c9">The Best Coach</h3>
					<p class="text-left" style="font-size: 15px">
						There are some wierd things to every coach. Drills, techniques, ideas,
						goals, teaching styles. My strangeness as a coach tends to come with some
						program development. I'm pretty sure the best possible coaching I do
						happens infront of a monitor When I decided I didn't have the tool I
						needed to get better I started to make one That has very little
						resemblance to what this program does. It was a start and enough for me.
						The downside of coaching with me is about once a week you're going to get
						ideas you don't want. 4 page emails on the importance of colored armbands
						Videos breaking down esports A 15 minute conversation forced upon you
						about how we should spend 2-3 days practicing with the cheerleading team
						because the execute the same things over and over much better than we do
						with what is core to our sport. Our head coach doesn't usually stop me
						from those things. Which is fun. And sometimes a big waste of time. In
						this case
					</p>

					<h4>Hey I want to build a better playbook program, should we do that?</h4>
					was met with
					<h4>Sure if it works we'll use it</h4>

					<h3 class="title text-left" style="color: #b6f9c9">
						Somethings Never Stop
					</h3>
					<p class="text-left" style="font-size: 15px">
						A playbook in Febuary of 2020. 4 weeks later turned into how do we teach
						when no one can be in the same room? Learning that our players mainly use
						film to make highlights Finding out we can probably fix those things. 20
						hours spent on making all the 3D characters Robots. Finding out Robots was
						a bad idea. And year later feeling pretty good about whats done cleaning
						it up and getting it to other teams. The question about when is it done
						keeps coming up and that's the thing with building something worthwhile.
						It never stops.
					</p>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="features-wraper" id="features"></div>
<div class="faq-sec" id="faqs">
	<div class="container">
		<div class="row justify-content-center">
			<div class="col">
				<div class="container">
					<div class="row justify-content-center">
						<div class="col">
							<div class="site-main-title">
								<h1 class="default-title">Join the Team</h1>
								@*
								<p>Experience The First Platform Made For Football Teams by Coaches.</p>
								*@
							</div>
						</div>
					</div>
					<div class="row justify-content-center">
						<div class="col-lg-4 col-md-6 col-sm-12">
							<div class="card" style="background-color: darkslategrey">
								<div class="features-grid wow fadeInDown" data-wow-delay="0.3s">
									<img class="img-fluid" style="width: 250px; height: 250px"
										src="https://images.pexels.com/photos/3018125/pexels-photo-3018125.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
										alt="MANAGE YOUR TEAM" title="MANAGE YOUR TEAM" />
									<!-- NEED A "Wokring Image - or need the dashboard working" -->
									<h3 class="title">Head Coach</h3>
									<h4>Product</h4>
									<p>
										We need a product manager, and with everything we've done it's with
										football coaches in mind.
									</p>
								</div>
							</div>
						</div>
						<div class="col-lg-4 col-md-6 col-sm-12">
							<div class="card" style="background-color: darkslategrey">
								<div class="features-grid wow fadeInDown" data-wow-delay="0.3s">
									<img class="img-fluid" style="width: 250px; height: 250px"
										src="https://images.pexels.com/photos/270085/pexels-photo-270085.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260"
										alt="MANAGE YOUR TEAM" title="MANAGE YOUR TEAM" />

									<h3 class="title">Content</h3>
									<h4>Make Fans</h4>
									<p>
										There is no end to what we're building, be the one to write that story
									</p>
								</div>
							</div>
						</div>
						<div class="col-lg-4 col-md-6 col-sm-12">
							<div class="card" style="background-color: darkslategrey">
								<div class="features-grid wow fadeInDown" data-wow-delay="0.3s">
									<img class="img-fluid" style="width: 250px; height: 250px"
										src="https://images.pexels.com/photos/260024/pexels-photo-260024.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
										alt="MANAGE YOUR TEAM" title="MANAGE YOUR TEAM" />
									<h3 class="title">Customer Success</h3>
									<h4>Help Teams Win</h4>
									<p>
										We're building to make more wins happen for the groups we're apart of.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="footer-sec" id="contact">
	<div class="container">
		<div class="row">
			<div class="col-lg-4 col-md-6 col-sm-12">
				<div class="footer-grid">
					<h1 class="title">QUICK LINKS</h1>
					<ul>
						<li><a href="#">HOME</a></li>
						<li><a href="#features">FEATURES</a></li>
						<!-- <li><a href="#prices">PRICE</a></li> -->
						<li><a href="#contact">CONTACT</a></li>
						<li><a href="#">CAREERS</a></li>
						<li><a href="/offense-public/free-playbook-register">Request Playbook Link</a></li>
					</ul>
				</div>
			</div>
			<div class="col-lg-4 col-md-6 col-sm-12">
				<div class="footer-grid">
					<h1 class="title">POLICIES</h1>
					<ul>
						<li><a href="/terms-condition">Terms & Conditions</a></li>
						<li><a href="/privacy-policy">Privacy Policy</a></li>
						<li><a href="#">Cookie Policy</a></li>
						<li><a href="/affiliate">Affiliate Program</a></li>
					</ul>
				</div>
			</div>
			<div class="col-lg-4 col-md-6 col-sm-12">
				<div class="footer-grid">
					<h1 class="title">CONTACT US</h1>
					<form>
						<div class="form-grid">
							<div class="form-group">
								<input type="text" class="foot-form-control" placeholder="example@example.com"
									required />
							</div>
							<div class="form-group">
								<textarea name="" class="foot-form-control" required></textarea>
							</div>
							<div class="form-group">
								<input type="submit" class="input-btn" value="SEND" />
							</div>
						</div>
					</form>
				</div>
			</div>
			<div class="col-lg-12">
				<p class="my-3 text-white">© Strongside 2020 All Rights Reserved.</p>
			</div>
		</div>
	</div>
</div>